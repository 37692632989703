import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ILibraryItem } from '@repeat/models';

import { EngineerService } from '../EngineerService';

interface FormState {
    enName: string;
    enElemProps: { enDescription: string; availableValues: { enTitle: string }[] }[];
    enElemParams: { enDescription: string }[];
    enStateParameters: { enDescription: string; enUnit: string }[];
}

interface FormContextType {
    formState: ILibraryItem | undefined;
    updateFormState: (name: string, value: any) => void;
    updateEnFormState: (name: string, value: any) => void;
    updateWholeFormState: (block: ILibraryItem) => void;
    fetchBlocks: () => Promise<ILibraryItem[]>;
    fetchEnBlocks: () => Promise<ILibraryItem[]>;
    getEnBlock: (type: string) => Promise<ILibraryItem | null>;
    engineerBlocks?: ILibraryItem[] | undefined;
    enFormState: ILibraryItem | undefined;
    updateBlockToIndexedDB: (ruBlock: ILibraryItem, enBlock?: ILibraryItem) => void;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

const engineerService = new EngineerService('engineerBlocks');
const engineerServiceEN = new EngineerService('engineerBlocksEN');

export const EngineerFormProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();

    const [formState, setFormState] = useState<ILibraryItem>();

    const [enFormState, setEnFormState] = useState<ILibraryItem>();

    const [engineerBlocks, setEngineerBlocks] = useState<ILibraryItem[]>();
    const [enEngineerBlocks, setEnEngineerBlocks] = useState<ILibraryItem[]>();

    const updateFormState = (propKey: string, value: any) => {
        setFormState((prev) => ({ ...prev, [propKey]: value } as ILibraryItem));
    };

    const updateEnFormState = (propKey: string, value: any) => {
        setEnFormState((prev) => ({ ...prev, [propKey]: value } as ILibraryItem));
    };

    const updateWholeFormState = async (block: ILibraryItem) => {
        setFormState(block);
        const getEnBlocks = await engineerServiceEN.getBlock(block.type!);
        if (!getEnBlocks) setEnFormState(block);
        else setEnFormState(getEnBlocks);
    };

    const updateBlockToIndexedDB = async (ruBlock: ILibraryItem, enBlock?: ILibraryItem) => {
        if (!ruBlock.propertiesToDisplay && !ruBlock.parametersToDisplay) {
            ruBlock = { ...ruBlock, propertiesToDisplay: null };
            ruBlock = { ...ruBlock, parametersToDisplay: [] };
        }
        if (enFormState) {
            const getEnBlock = await engineerServiceEN.getBlock(enFormState.type!);
            if (getEnBlock) {
                await engineerServiceEN.updateBlockByType(enFormState.type!, enFormState!);
            } else {
                await engineerServiceEN.addBlock(enFormState!);
            }
        }
        await engineerService.updateBlockByType(ruBlock!.type!, ruBlock!).then(() => navigate(0));
    };

    const fetchBlocks = async () => {
        try {
            const blocks = await engineerService.getBlocks();
            setEngineerBlocks(blocks);

            if (engineerBlocks) {
                return engineerBlocks;
            }
            return blocks;
        } catch (error) {
            console.error('Ошибка при получении блоков:', error);
            throw error;
        }
    };

    const fetchEnBlocks = async () => {
        try {
            const blocks = await engineerServiceEN.getBlocks();
            setEnEngineerBlocks(blocks);

            if (enEngineerBlocks) {
                return enEngineerBlocks;
            }
            return blocks;
        } catch (error) {
            console.error('Ошибка при получении блоков:', error);
            throw error;
        }
    };

    const getEnBlock = async (type: string) => {
        try {
            const block = await engineerServiceEN.getBlock(type);
            return block;
        } catch (error) {
            console.error('Ошибка при получении блока:', error);
            throw error;
        }
    };

    useEffect(() => {
        fetchBlocks();
    }, []);

    return (
        <FormContext.Provider
            value={{
                formState,
                updateFormState,
                updateWholeFormState,
                fetchBlocks,
                fetchEnBlocks,
                getEnBlock,
                enFormState,
                updateEnFormState,
                updateBlockToIndexedDB,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

export const useFormContext = () => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error('useFormContext must be used within a FormProvider');
    }
    return context;
};
