import type { ItemInterface } from 'react-sortablejs';

import { CANVAS, TYPE_CONNECTION_COLOR_MAP } from '@repeat/constants';
import { ElemParams, ILibraryItemViewData, PortPositions, TLibraryItemPort } from '@repeat/models';

export const BLOCK_MIN_WIDTH = CANVAS.ELEMENT_MIN_WIDTH;
export const BLOCK_MIN_HEIGHT = CANVAS.ELEMENT_MIN_HEIGHT;
export const PORT_SIZE = 15;
export const PORT_MARGIN = CANVAS.PORT_MARGIN;
export const BLOCK_PADDING = 8;
export const BLOCK_BORDER = 2;
export const IMAGE_DEFAULT_SIZE = BLOCK_MIN_WIDTH - BLOCK_PADDING * 2 - BLOCK_BORDER * 2;

export interface IVisualManagerPortInterface extends ItemInterface {
    title: string;
    isConditional: boolean;
}
export interface IVisualManagerPortsMap {
    left: IVisualManagerPortInterface[];
    top: IVisualManagerPortInterface[];
    right: IVisualManagerPortInterface[];
    bottom: IVisualManagerPortInterface[];
}

const getDefaultPortPositionByName = (name: string) => {
    const nameParts = name.split('_');
    const inOutType = nameParts[0];
    return inOutType === 'in' ? 'left' : 'right';
};

export const makeVisualManagerPorts = (
    elemParams: ElemParams[],
    availablePorts: TLibraryItemPort[]
): IVisualManagerPortsMap => {
    const portsMap: IVisualManagerPortsMap = {
        left: [],
        top: [],
        right: [],
        bottom: [],
    };

    const elemParamsMap: { [name: string]: ElemParams } = {};
    elemParams.forEach((elemParameter) => {
        elemParamsMap[elemParameter.name] = elemParameter;
    });

    availablePorts.forEach((item: TLibraryItemPort) => {
        const itemPosition = item.position.length > 0 ? item.position : getDefaultPortPositionByName(item.name);
        const elemParameter = elemParamsMap[item.name] ?? null;

        portsMap[itemPosition] = [
            ...portsMap[itemPosition],
            {
                ...item,
                id: item.name,
                name: item.name,
                title: elemParamsMap[item.name]?.description ?? '-',
                isConditional:
                    elemParameter && elemParameter?.visibilityConditions
                        ? elemParameter.visibilityConditions.length > 0
                        : false,
            },
        ];
    });

    return portsMap;
};
export const makeAvailablePorts = (portsMap: IVisualManagerPortsMap): TLibraryItemPort[] => {
    return [
        ...portsMap.left.map((port) => {
            const { id, title, isConditional, ...availablePort } = port;
            return { ...availablePort, position: PortPositions.LEFT } as TLibraryItemPort;
        }),
        ...portsMap.top.map((port) => {
            const { id, title, isConditional, ...availablePort } = port;
            return { ...availablePort, position: PortPositions.TOP } as TLibraryItemPort;
        }),
        ...portsMap.right.map((port) => {
            const { id, title, isConditional, ...availablePort } = port;
            return { ...availablePort, position: PortPositions.RIGHT } as TLibraryItemPort;
        }),
        ...portsMap.bottom.map((port) => {
            const { id, title, isConditional, ...availablePort } = port;
            return { ...availablePort, position: PortPositions.BOTTOM } as TLibraryItemPort;
        }),
    ];
};
export const makeView = (view: ILibraryItemViewData, width: number, height: number): ILibraryItemViewData => {
    return {
        ...view,
        minWidth: width,
        minHeight: height,
    };
};

export const getPortColor = (port: ItemInterface): string => {
    return TYPE_CONNECTION_COLOR_MAP.get(port.typeConnection) ?? '#ccc';
};
