export const CANVAS = {
    MIN_ZOOM: 0.05,
    MAX_ZOOM: 1.5,
    GRID_STEP: 15,
    ELEMENT_MIN_WIDTH: 90,
    ELEMENT_MIN_HEIGHT: 90,
    PORT_MARGIN: 15,
    //// consts below are not used in react flow
    DEFAULT_ZOOM: 0.25,
    ELEMENT: {
        DEFAULT_SIZE: 30,
        MIN_WIDTH: 630,
        MIN_HEIGHT: 630,
        MARGIN: 10,
        TEXT_MARGIN: {
            X: 130,
            Y: 170,
        },
        STROKE_WIDTH: 20,
        TEXT_STYLE: {
            fontFamily: ['RosAtom', 'Arial', 'sans-serif'],
            fontWeight: 'bold',
            fontSize: 130,
        },
    },
    PORT: {
        WIDTH: 50,
        MARGIN: 100,
        STROKE_WIDTH: 15,
        PROTECTED_DELTA: 35,
    },
};

export const TYPE_CONNECTION_COLOR_MAP = new Map<string, string>([
    ['auto_in', '#f5aba7'],
    ['auto_out', '#f5aba7'],
    ['ang_speed_in', '#b6d6b2'],
    ['ang_speed_out', '#b6d6b2'],
    ['torque_in', '#f5aba7'],
    ['torque_out', '#f5aba7'],
    ['speed_in', '#b6d6b2'],
    ['speed_out', '#b6d6b2'],
    ['force_in', '#b6d6b2'],
    ['force_out', '#b6d6b2'],
    ['thermohydraulics_in', '#9281F6'],
    ['thermohydraulics_out', '#9281F6'],
    ['isothermal_hydraulics_in', '#A6E4ED'],
    ['isothermal_hydraulics_out', '#A6E4ED'],
    ['thermohydraulicsnew_in', '#e6ba61'],
    ['thermohydraulicsnew_out', '#e6ba61'],
    ['heattransfer_in', '#ffb96f'],
    ['heattransfer_out', '#ffb96f'],
    ['electrocity_in', '#e9b073'],
    ['electrocity_out', '#e9b073'],
    ['electronics_in', '#c1e0f4'],
    ['electronics_out', '#c1e0f4'],
    ['car_in', '#B372C4'],
    ['car_out', '#B372C4'],
    ['voltage_in', '#f5aba7'],
    ['voltage_out', '#f5aba7'],
]);

export const ELEMENTS_TYPE: { [key: string]: string } = {
    text: 'text',
    element: 'element',
    image: 'image',
    'fsm-state': 'fsm-state',
    'fsm-start': 'fsm-start',
};

export const ELEMENTS_COLORS: { [key: string]: string } = {
    electrocity: '#b65b00',
    electrocitydc: '#b65b00',
    simulation: '#0a007f',
    systemDesign: '#ad1213',
    externalModels: '#000000',
    common: '#000000',
    auto: '#000000',
    cms: '#000000',
};

export const MEMORY_USAGE_LIMIT = 1000;
