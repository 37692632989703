import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { workspaceActions } from '@repeat/store';
import { TElement, TWorkspaceMode } from '@repeat/models';
import { useAppDispatch } from '@repeat/hooks';
import { IconButton, uiColors } from '@repeat/ui-kit';

export const useBlockImage = (data: TElement, workspaceMode: TWorkspaceMode) => {
    const dispatch = useAppDispatch();

    const [image, setImage] = useState<File | null>(null);
    const [imageURL, setImageURL] = useState<string | null>(null);

    const convertFileToDataURL = useCallback((file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }, []);

    const handleImageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setImage(file);
        }
    }, []);

    useEffect(() => {
        if (image && image instanceof File) {
            convertFileToDataURL(image).then((dataURL) => {
                setImageURL(dataURL);
                dispatch(workspaceActions.changeElementCustomImage({ id: data.id, image: dataURL, workspaceMode }));
            });
        }
    }, [image]);

    const ImageLoader: FC<{ text: string }> = ({ text }) => {
        const ref = useRef<HTMLInputElement | null>(null);

        const handleInputClick = () => {
            if (ref && ref.current) {
                ref.current.click();
            }
        };

        return (
            <>
                <input
                    ref={ref}
                    style={{ display: 'none' }}
                    id='file'
                    type='file'
                    accept='image/*'
                    onChange={handleImageChange}
                />
                <IconButton fill={uiColors.darkGrey} noHover onClick={handleInputClick} name={'plus'} title={text} />
            </>
        );
    };

    return { ImageLoader, convertFileToDataURL, handleImageChange, imageURL, setImageURL, image, setImage };
};
