import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { FSMVariablesTable } from '@components/FSMVariablesTable/FSMVariablesTable';
import { CustomTabs } from '@components/Tabs/Tabs';
import Button from '@mui/material/Button';

import { DATE_TIME_FORMAT, IS_SHOW_PROJECT_HISTORY } from '@repeat/constants';
import { environment } from '@repeat/environment';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import {
    SchemaSelectionModes,
    Statuses,
    TSchemaNode,
    TSchemaSelectionMode,
    TWorkspaceMode,
    WorkspaceModes,
} from '@repeat/models';
import { getProjects, workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Icon, IconButton, layoutSize, pxToRem, ShowMore, SidebarContent, uiColors } from '@repeat/ui-kit';

import { GroupName } from './GroupName';
import { ModeControlButtons } from './ModeControlButtons';
import { SBlockWrapper, SElementId, SElementName, SOptionsWrapper } from './SRightBar';
import { messages } from './translation';

import { IBlockDescription, ModalContext } from '../BlockDescriptionModal/BlockDescriptionModalContext';
import { ConnectionCurrent } from '../CurrentItemOptions/ConnectionCurrent';
import { NodeCurrent } from '../CurrentItemOptions/NodeCurrent';
import { ElementParameters } from '../CurrentItemOptions/PropertiesAndParameters/ElementParameters';
import { ElementPropertiesCustom } from '../CurrentItemOptions/PropertiesAndParameters/ElementPropertiesCustom';
import { ProjectDate } from '../Project/ProjectDate';
import { ProjectHistory } from '../ProjectHistory/ProjectHistory';
import { ProjectSettings } from '../ProjectSettings/ProjectSettings';
import { useWorkspaceDataContext } from '../Workspace/DataProvider/DataProvider';
import { WorkspaceProjectName } from '../Workspace/ProjectName/ProjectName';

const { DOCS_URL } = environment;

export const RightBar = () => {
    const { formatMessage, locale } = useIntl();
    const dispatch = useAppDispatch();

    const { readonly, mode: workspaceMode } = useWorkspaceDataContext();

    const libraryItems = useAppSelector(workspaceSelectors.libraryItems);
    const selectedItems = useAppSelector((state) => state.workspace.schema.selectedItems);
    const isUserBlockEditorMode = useAppSelector(workspaceSelectors.isUserBlockEditorMode);
    const metaElementId = useAppSelector(workspaceSelectors.workspaceMetaElementId);

    const isMainGroupLevel = useAppSelector(workspaceSelectors.isMainGroupLevel);
    const projects = useAppSelector((state) => state.projects);
    const currentNodeNameId = useAppSelector(workspaceSelectors.currentNodeIndicator);
    const currentNodeNameAndDescription = useAppSelector(workspaceSelectors.currentNodeNameAndDescription);

    const rightBarScrollRef = useRef(null);

    const availableProjectViewModes = [WorkspaceModes.MAIN, WorkspaceModes.DEMO, WorkspaceModes.PREVIEW];

    const selectedNode = selectedItems.elements.length === 1 ? selectedItems.elements[0] : null;
    const isProjectBlockSelected = selectedNode?.data.type === 'project';
    const currentSubmodelProject = useAppSelector(
        workspaceSelectors.currentSubmodelProjectById(selectedItems.elements[0]?.id)
    );
    let currentSubmodelProjectUpdatedAt = '';
    let currentSubmodelProjectCreatedAt = '';
    const projectsItems = projects.items || [];
    if (projects.status === Statuses.SUCCEEDED && isProjectBlockSelected) {
        currentSubmodelProjectUpdatedAt =
            projectsItems.find((pr) => pr.projectId === Number(currentSubmodelProject?.projectId))?.updatedAt || '';
        currentSubmodelProjectCreatedAt =
            projectsItems.find((pr) => pr.projectId === Number(currentSubmodelProject?.projectId))?.createdAt || '';
    }

    const selectedConnection = selectedItems.wires.length === 1 ? selectedItems.wires[0] : null;

    const getMode = (): TSchemaSelectionMode => {
        let newMode = SchemaSelectionModes.PROJECT;
        if (selectedItems.elements.length === 0 && selectedItems.wires.length === 0) {
            newMode = SchemaSelectionModes.PROJECT;
        }
        if (selectedNode && !selectedConnection) {
            newMode = SchemaSelectionModes.NODE;
        }
        if (!selectedNode && selectedConnection) {
            newMode = SchemaSelectionModes.CONNECTION;
        }
        if (selectedItems.elements.length > 1) {
            newMode = SchemaSelectionModes.MULTIPLE_OBJECTS;
        }
        if (selectedItems.wires.length > 1) {
            newMode = SchemaSelectionModes.MULTIPLE_OBJECTS;
        }
        if (selectedItems.elements.length >= 1 && selectedItems.wires.length >= 1) {
            newMode = SchemaSelectionModes.MULTIPLE_OBJECTS;
        }

        return newMode;
    };

    const [mode, setMode] = useState<TSchemaSelectionMode>(getMode());

    useEffect(() => {
        setMode(getMode());
    }, [selectedNode, selectedConnection, selectedItems, workspaceMode]);

    useEffect(() => {
        dispatch(getProjects());
    }, []);

    const handleDelete = () => {
        dispatch(workspaceActions.deleteSchemaItems());
    };

    const getObjectTitle = (mode: TSchemaSelectionMode): string => {
        switch (mode) {
            case SchemaSelectionModes.CONNECTION:
                return formatMessage(messages[TranslationKey.WORKSPACE_CONNECTION_TITLE]);
            case SchemaSelectionModes.NODE:
            default:
                return formatMessage(messages[TranslationKey.WORKSPACE_ELEMENT_TITLE]);
        }
    };

    const { openModal } = useContext(ModalContext)!;

    // TODO use modal when docs API is ready
    const handleModal = (id: string, name: string, description: string) => {
        const newModal: IBlockDescription = { id: id, name: name, description: description };
        openModal(newModal);
    };

    const hasDocsUrl = useCallback((node: TSchemaNode | null) => {
        const exceptions = ['group', 'userBlock'];
        return !!node?.data && !exceptions.includes(node.data.type);
    }, []);

    const blockDocsUrl = useCallback((node: TSchemaNode) => {
        const block = node.data;

        const libraryItem = libraryItems.find(
            (item) => item.type === node.data.type && item?.subtype === node.data?.subtype
        );

        if (!libraryItem) {
            return `${DOCS_URL}/${locale}/search.html?q=${block.name}`;
        }

        const blockFullLibrary: string = libraryItem.subLibrary
            ? `${libraryItem.library}/${libraryItem.subLibrary}`
            : libraryItem.library;

        const blockFullType = block.subtype ? `${block.type}_${block.subtype}` : block.type;
        const blockDocsUrl = `${DOCS_URL}/${locale}/reference/blocklib/${blockFullLibrary}/${blockFullType}/index.html`;

        return blockDocsUrl;
    }, []);

    const title = isUserBlockEditorMode
        ? formatMessage(messages[TranslationKey.WORKSPACE_BLOCK_TITLE])
        : formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_TITLE]);

    const getElementName = (node: TSchemaNode) => {
        if (!node) return '';
        const { type, elemProps } = node.data;
        if (type === 'project') {
            const projectNameProp = elemProps.find((prop) => prop.name === 'projectName');
            return projectNameProp ? projectNameProp.value : '';
        } else if (type === 'group') {
            return <GroupName name={currentNodeNameAndDescription?.name || ''} />;
        } else {
            return currentNodeNameAndDescription?.name || '';
        }
    };

    return (
        <div
            ref={rightBarScrollRef}
            data-tour='right-sidebar'
            style={{
                height: `100%`,
                overflowY: 'auto',
                maxHeight: `calc(100vh - ${layoutSize.header} - ${layoutSize.footer})`,
            }}
            id='rightBar'
        >
            {mode === SchemaSelectionModes.MULTIPLE_OBJECTS && !readonly && (
                <SidebarContent title={formatMessage(messages[TranslationKey.WORKSPACE_OBJECTS_TITLE])}>
                    <Button onClick={handleDelete} type='button' style={{ width: '94px', margin: '8px' }}>
                        <Icon name='close' fill={uiColors.mainBlue} />
                        {formatMessage(messages[TranslationKey.DELETE])}
                    </Button>
                </SidebarContent>
            )}

            {mode === SchemaSelectionModes.PROJECT && (
                <SidebarContent title={title}>
                    <ShowMore>
                        <div style={{ marginTop: `${pxToRem(16)}` }}>
                            <WorkspaceProjectName noSave noClose />
                            {availableProjectViewModes.includes(workspaceMode as TWorkspaceMode) &&
                                !isUserBlockEditorMode && (
                                    <CustomTabs
                                        tabHeader={[
                                            {
                                                name: 'settings',
                                                title: formatMessage(messages[TranslationKey.SETTINGS]),
                                            },
                                            {
                                                name: 'submodel',
                                                title: formatMessage(messages[TranslationKey.WORKSPACE_ELEMENT_TITLE]),
                                            },
                                            ...(IS_SHOW_PROJECT_HISTORY && [
                                                {
                                                    name: 'history',
                                                    title: formatMessage(
                                                        messages[TranslationKey.PROJECT_VERSIONS_HISTORY]
                                                    ),
                                                },
                                            ]),
                                        ]}
                                        tabContent={[
                                            <ProjectSettings />,
                                            <SBlockWrapper>
                                                <ElementPropertiesCustom mode={mode} />
                                                <ElementParameters mode={mode} />
                                            </SBlockWrapper>,
                                            ...(IS_SHOW_PROJECT_HISTORY && [
                                                <ProjectHistory scrollRef={rightBarScrollRef} />,
                                            ]),
                                        ]}
                                    />
                                )}

                            {workspaceMode === WorkspaceModes.FSM_EDITOR && !!metaElementId && (
                                <FSMVariablesTable
                                    id={'1'}
                                    initialValues={[]}
                                    label={{
                                        __html: formatMessage(messages[TranslationKey.WORKSPACE_PAGE_VARIABLES]),
                                    }}
                                    disabled={false}
                                />
                            )}
                        </div>
                        {isUserBlockEditorMode && isMainGroupLevel && (
                            <div style={{ margin: `0 -${pxToRem(16)}` }}>
                                <ElementPropertiesCustom mode={mode} />
                                <ElementParameters mode={mode} />
                            </div>
                        )}
                    </ShowMore>
                </SidebarContent>
            )}

            {(mode === SchemaSelectionModes.NODE || mode === SchemaSelectionModes.CONNECTION) && (
                <SidebarContent title={getObjectTitle(mode)}>
                    {currentNodeNameAndDescription?.name && (
                        <ShowMore>
                            <SElementName data-name={'element-name'}>
                                {selectedNode && getElementName(selectedNode)}
                                {hasDocsUrl(selectedNode) && (
                                    <a
                                        href={blockDocsUrl(selectedNode as TSchemaNode)}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <IconButton
                                            name='info'
                                            noHover
                                            fill={uiColors.darkGrey}
                                            style={{ minWidth: pxToRem(20) }}
                                        />
                                    </a>
                                )}
                                {!hasDocsUrl(selectedNode) && currentNodeNameAndDescription.description && (
                                    <IconButton
                                        name='info'
                                        noHover
                                        fill={uiColors.darkGrey}
                                        style={{ minWidth: pxToRem(20) }}
                                        onClick={() =>
                                            handleModal(
                                                currentNodeNameId.id.toString(),
                                                currentNodeNameAndDescription.name,
                                                currentNodeNameAndDescription.description
                                            )
                                        }
                                    />
                                )}
                            </SElementName>
                            {selectedNode?.id && <SElementId>Id: {selectedNode.id}</SElementId>}

                            {isProjectBlockSelected &&
                                (workspaceMode === WorkspaceModes.MAIN || workspaceMode === WorkspaceModes.GROUP) &&
                                !selectedNode.data.diff?.isErrorGettingProject && (
                                    <div style={{ fontSize: pxToRem(12), marginTop: pxToRem(8) }}>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ marginRight: '8px', color: 'var(--ui-text)' }}>
                                                {formatMessage(messages[TranslationKey.PROJECTS_COLUMN_CREATED_AT])}:
                                            </div>
                                            {currentSubmodelProjectCreatedAt.length !== 0 && (
                                                <ProjectDate
                                                    date={currentSubmodelProjectCreatedAt}
                                                    format={DATE_TIME_FORMAT}
                                                />
                                            )}
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ marginRight: '8px', color: uiColors.darkGrey }}>
                                                {formatMessage(messages[TranslationKey.PROJECTS_COLUMN_UPDATED_AT])}:
                                            </div>
                                            {currentSubmodelProjectUpdatedAt.length !== 0 && (
                                                <ProjectDate
                                                    date={currentSubmodelProjectUpdatedAt}
                                                    format={DATE_TIME_FORMAT}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                        </ShowMore>
                    )}
                    <SOptionsWrapper data-name={'options-container'}>
                        {mode === SchemaSelectionModes.NODE && <NodeCurrent />}
                        {mode === SchemaSelectionModes.CONNECTION && <ConnectionCurrent />}
                    </SOptionsWrapper>
                </SidebarContent>
            )}

            {mode !== SchemaSelectionModes.MULTIPLE_OBJECTS && mode !== SchemaSelectionModes.CONNECTION && (
                <ModeControlButtons />
            )}
        </div>
    );
};
