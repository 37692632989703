import styled from 'styled-components';

export const SUIForm = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
`;

export const SPortsVisualManagerWrapper = styled.div`
    position: sticky;
    top: 0;
    height: 500px;
`;
