import styled from 'styled-components';

import { pxToRem } from '@repeat/ui-kit';

import { BLOCK_BORDER, BLOCK_MIN_HEIGHT, BLOCK_MIN_WIDTH, BLOCK_PADDING, PORT_MARGIN } from './util';

export const SToolWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 12px;
`;
export const SContainerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-top: 1px solid var(--ui-border);
`;
export const Container = styled.div`
    display: grid;
    grid-template-rows: ${pxToRem(PORT_MARGIN)} 1fr ${pxToRem(PORT_MARGIN)};
    width: ${pxToRem(BLOCK_MIN_WIDTH + PORT_MARGIN * 2)};
    height: ${pxToRem(BLOCK_MIN_HEIGHT + PORT_MARGIN * 2)};
    min-width: auto;
    max-width: auto;
    min-height: auto;
    max-height: auto;
`;
export const TopItemsWrapper = styled.div`
    position: relative;
    top: 50%;
    margin: 0 ${pxToRem(PORT_MARGIN)};
    & > div {
        display: flex;
        justify-content: space-evenly;
    }
`;
export const BottomItemsWrapper = styled.div`
    position: relative;
    top: -50%;
    margin: 0 ${pxToRem(PORT_MARGIN)};
    & > div {
        display: flex;
        justify-content: space-evenly;
    }
`;
export const LeftItemsWrapper = styled.div`
    position: relative;
    left: 50%;
    & > div {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
    }
`;
export const RightItemsWrapper = styled.div`
    position: relative;
    left: -50%;
    & > div {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
    }
`;
export const MiddleContainer = styled.div`
    display: grid;
    grid-template-columns: ${pxToRem(PORT_MARGIN)} 1fr ${pxToRem(PORT_MARGIN)};
`;
export const SBlock = styled.div`
    display: flex;
    min-width: ${pxToRem(BLOCK_MIN_WIDTH)};
    min-height: ${pxToRem(BLOCK_MIN_HEIGHT)};
    background: var(--ui-tags);
    border: ${pxToRem(BLOCK_BORDER)} solid var(--ui-main-blue);
    border-radius: 8px;
    max-width: 600px;
    max-height: 600px;
    padding: ${pxToRem(BLOCK_PADDING)};
    align-items: center;
    justify-content: center;
`;

export const SSizesInfo = styled.div`
    display: grid;
    grid-template-columns: 120px 150px;
    font-size: 12px;
`;
export const SSizesInfoRow = styled.div``;
export const SSizesInfoLabel = styled.div``;
export const SSizesInfoValue = styled.div`
    padding: 2px 0;
`;
export const SSizesBlockInfoValueAccent = styled.span`
    border: 1px solid var(--ui-main-blue);
    background: var(--ui-tags);
    font-weight: 600;
    padding: 2px 4px;
    border-radius: 4px;
    display: inline-block;
`;
export const SSizesIconInfoValueAccent = styled.span`
    background: var(--ui-main-blue);
    font-weight: 600;
    color: #fff;
    padding: 2px 4px;
    border-radius: 4px;
    display: inline-block;
`;
