import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Autocomplete, {
    AutocompleteRenderGetTagProps,
    AutocompleteRenderGroupParams,
    AutocompleteRenderInputParams,
    AutocompleteRenderOptionState,
    createFilterOptions,
} from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import { MAX_PARAMETERS_AT_CHART, unicodeReplaceSymbol, unicodeToHtml } from '@repeat/constants';
import { useAppSelector } from '@repeat/hooks';
import { IChartItem, IChartItemParameter, TWatchSchemaItemParameter } from '@repeat/models';
import { elementsSelectors, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

import { SAutocomplete, SAutocompleteForm, SGroupHeader, SGroupItems } from './SAutocompleteParametersForm';

import { compileParameterOptions } from '../../helpers';
import { messages } from '../../translation';
import { useWorkspaceDataContext } from '../../../Workspace/DataProvider/DataProvider';

export interface IOptionProps {
    elementName: string;
    elementId: number;
    title: string;
    code: string;
    modelName: string;
    unit: string;
}

interface AutocompleteParametersFormProps {
    index: number;
    uuid: string;
    onSetParameters?: (
        index: number,
        uuid: string,
        YParameters: IChartItemParameter[],
        XParameters: IChartItemParameter
    ) => void;
}

interface IAutocompleteListBoxProps extends React.HTMLAttributes<HTMLUListElement> {
    'data-test-id'?: string;
}

export const AutocompleteParametersForm: FC<AutocompleteParametersFormProps> = ({ index, uuid, onSetParameters }) => {
    const intl = useIntl();

    const defaultParameterTitle = intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_CSV_TIME_TITLE]);
    const timeParameter = {
        key: 'time',
        title: defaultParameterTitle,
        elementTitle: defaultParameterTitle,
        parameterTitle: defaultParameterTitle,
    };

    const nodesMain = useAppSelector(elementsSelectors.getElements);
    const elementsGroup = useAppSelector(workspaceSelectors.elementsGroup);
    const nodes = elementsGroup && elementsGroup.length !== 0 ? elementsGroup : nodesMain;
    const workspaceMetaGroupId = useAppSelector(workspaceSelectors.workspaceMetaGroupId);
    const schemaGroups = useAppSelector(workspaceSelectors.schemaGroups) || [];
    const { elements: selectedNodes } = useAppSelector(elementsSelectors.getSelectedElements);
    const charts = useAppSelector((state) => state.workspace.graphs.charts);

    const { readonly } = useWorkspaceDataContext();

    const currentChart = charts[index] as IChartItem;

    const [XOptions, setXOptions] = useState<TWatchSchemaItemParameter[]>(
        compileParameterOptions(nodes, selectedNodes, schemaGroups, workspaceMetaGroupId)
    );
    const [YOptions, setYOptions] = useState<TWatchSchemaItemParameter[]>([timeParameter, ...XOptions]);
    const [XValue, setXValue] = useState<TWatchSchemaItemParameter>(timeParameter);
    const [YValues, setYValues] = useState<TWatchSchemaItemParameter[]>([]);

    useEffect(() => {
        const newOptions = compileParameterOptions(
            nodes,
            selectedNodes,
            schemaGroups,
            workspaceMetaGroupId
        ) as TWatchSchemaItemParameter[];
        setXOptions(newOptions);
        setYOptions([timeParameter, ...newOptions]);
    }, [nodesMain, selectedNodes, schemaGroups]);

    useEffect(() => {
        if (currentChart.YParameters?.length) {
            setYValues(currentChart.YParameters);
        } else {
            setYValues([]);
        }
        if (currentChart.XParameters !== null) {
            setXValue(currentChart.XParameters);
        } else {
            setXValue(timeParameter);
        }
    }, [Object.keys(charts).length, currentChart, charts[index]]);

    const handleTypeChange = (option: TWatchSchemaItemParameter) => {
        onSetParameters &&
            onSetParameters(index, uuid, [...(YValues as IChartItemParameter[])], option as IChartItemParameter);
        setXValue(option);
    };

    const renderGroup = (params: AutocompleteRenderGroupParams) => (
        <li key={`${params.group}`}>
            <SGroupHeader>{params.group}</SGroupHeader>
            <SGroupItems>{params.children}</SGroupItems>
        </li>
    );

    const renderInput = (label: string) => (params: AutocompleteRenderInputParams) =>
        <TextField {...params} label={label} />;

    const renderOption = (
        props: React.HTMLAttributes<HTMLElement>,
        option: TWatchSchemaItemParameter,
        state: AutocompleteRenderOptionState
    ) => {
        const innerHTML = `<span>${unicodeToHtml(option?.parameterTitle)}</span`;
        return <li {...props} key={option.key} dangerouslySetInnerHTML={{ __html: innerHTML }} />;
    };

    const renderTag = (tagValue: TWatchSchemaItemParameter[], getTagProps: AutocompleteRenderGetTagProps) => {
        return tagValue.map((option: TWatchSchemaItemParameter, index: number) => {
            const html = (
                <span
                    style={{ color: 'var(--ui-text)' }}
                    dangerouslySetInnerHTML={{
                        __html: `${option.elementTitle} - ${unicodeToHtml(option.parameterTitle)}`,
                    }}
                />
            );
            return <Chip {...getTagProps({ index })} label={html} />;
        });
    };

    const renderDataTestId = (value: string) =>
        ({
            'data-test-id': `${value}`,
        } as IAutocompleteListBoxProps);

    const getOptionLabel = (option: TWatchSchemaItemParameter) => unicodeReplaceSymbol(option?.parameterTitle) || '';

    const optionEqualToValue = (option: TWatchSchemaItemParameter, value: TWatchSchemaItemParameter) => {
        if (option && value) {
            return option.key === value.key;
        }
        return false;
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option: TWatchSchemaItemParameter) =>
            `${option.elementTitle} ${unicodeToHtml(option.parameterTitle)}`,
    });

    return (
        <SAutocompleteForm>
            <SAutocomplete>
                <Autocomplete
                    disabled={readonly}
                    multiple
                    disableClearable
                    noOptionsText={intl.formatMessage(messages[TranslationKey.WORKSPACE_PARAMETERS_NOT_FOUND])}
                    onChange={(e, options: TWatchSchemaItemParameter[]) => {
                        if (options.length <= MAX_PARAMETERS_AT_CHART) {
                            const newYParams = options;
                            let newXParam = XValue;
                            setYValues(options);
                            if (options.length > 1) {
                                newXParam = timeParameter;
                                setXValue(timeParameter);
                            }
                            onSetParameters &&
                                onSetParameters(
                                    index,
                                    uuid,
                                    newYParams as IChartItemParameter[],
                                    newXParam as IChartItemParameter
                                );
                        }
                    }}
                    size='small'
                    id='grouped-demo'
                    options={XOptions}
                    groupBy={(option: TWatchSchemaItemParameter) => option.elementTitle}
                    getOptionLabel={getOptionLabel}
                    value={YValues !== null ? YValues : []}
                    isOptionEqualToValue={optionEqualToValue}
                    renderInput={renderInput(intl.formatMessage(messages[TranslationKey.WORKSPACE_PARAMETERS]))}
                    renderGroup={renderGroup}
                    renderOption={renderOption}
                    renderTags={renderTag}
                    ListboxProps={renderDataTestId('visualization-params-box')}
                    filterOptions={filterOptions}
                />
            </SAutocomplete>
            <SAutocomplete>
                <FormControl size='small' fullWidth={true}>
                    <Autocomplete
                        disabled={readonly}
                        disableClearable
                        size={'small'}
                        id='demo-select-small'
                        value={XValue}
                        onChange={(e, option: TWatchSchemaItemParameter) => {
                            handleTypeChange(option);
                        }}
                        options={YOptions}
                        groupBy={(option: TWatchSchemaItemParameter) => option.elementTitle}
                        isOptionEqualToValue={optionEqualToValue}
                        getOptionLabel={getOptionLabel}
                        renderInput={renderInput(intl.formatMessage(messages[TranslationKey.WORKSPACE_PARAMETERS_X]))}
                        renderGroup={renderGroup}
                        renderOption={renderOption}
                        ListboxProps={renderDataTestId('visualization-XParams-box')}
                        filterOptions={filterOptions}
                    />
                </FormControl>
            </SAutocomplete>
        </SAutocompleteForm>
    );
};
