import { environment } from '@repeat/environment';

import { VERSION } from '../app';

export const libraryElementImageSrc = (
    assetsPath: string,
    solverType: string,
    libraryType: string,
    name: string,
    iconType: string
): string => {
    const extension = 'svg';

    const vesrsionHash = environment.APP_BUILD.length ? environment.APP_BUILD : VERSION;

    if (solverType === 'usds') {
        return `${assetsPath}/${libraryType.toLowerCase()}/images/${name}.${extension}?${vesrsionHash}`;
    }
    if (iconType === 'new') {
        return `${assetsPath}/library/images/new-icons/${name}.${extension}?${vesrsionHash}`;
    }

    return `${assetsPath}/library/images/${name}.${extension}?${vesrsionHash}`;
};
