import { FC, startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch } from 'react-redux';
import {
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Connection,
    DefaultEdgeOptions,
    Edge,
    EdgeChange,
    Node,
    NodeChange,
    OnSelectionChangeParams,
    ReactFlowInstance,
    useEdgesState,
    useNodesState,
    XYPosition,
} from 'reactflow';

import 'reactflow/dist/style.css';

import { ApplicationActions } from '@repeat/common-slices';
import {
    calculateGroupBlockCoordinates,
    CANVAS,
    ELEMENTS_INITIALIZED_BY_TEXT_FILE,
    LIBRARIES_DEFAULT_LIST,
} from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import {
    ILibraryItem,
    ModalTypes,
    NotificationTypes,
    SchemaItemTypes,
    Statuses,
    TElementConfigurationModal,
    TElementInitializationModal,
    TGroupCreateWarningModal,
    TLivePermissions,
    TProjectBlockInitialization,
    TSchemaConnection,
    TSchemaGroup,
    TSchemaNode,
    WorkspaceModes,
} from '@repeat/models';
import { makeSchemaConnection, makeSchemaNode } from '@repeat/services';
import {
    addGroup,
    fetchLibraryPortTypes,
    markConnectionPortsAsConnected,
    workspaceActions,
    workspaceSelectors,
} from '@repeat/store';

import ContextMenu from './ContextMenu/ContextMenu';
import { CustomEdge } from './CustomEdge/CustomEdge';
import { Element } from './Elements/Element/Element';
import { ImageElement } from './Elements/ImageElement/ImageElement';
import { TextElement } from './Elements/TextElement/TextElement';
import {
    calculateIndex,
    determineType,
    findConnectedMeters,
    mapElementsToNodes,
    mapWiresToConnections,
    validateElement,
    validateElementPermissions,
} from './helper/canvasHelper';
import { useCanvasMousePosition } from './hooks/useCanvasMousePosition';
import { SearchDropdownDirections, useSearchMode } from './hooks/useSearchMode';

import { ContextSearch } from '../../ContextSearch/ContextSearch';
import { useWorkspaceDataContext } from '../DataProvider/DataProvider';
import { DefaultCanvas } from '../DefaultCanvas/DefaultCanvas';

const defaultEdgeOptions: DefaultEdgeOptions = {
    type: 'custom-edge',
};

const WORKSPACE_LEFTBAR_WIDTH = 256;
const WORKSPACE_BOTTOMBAR_HEIGHT = 345;
const WORKSPACE_HEADER_HEIGHT = 46;
const WORKSPACE_SEARCH_INPUT_HEIGHT = 30;
const WORKSPACE_SEARCH_SAFE_DROPDOWN_HEIGHT = 256;

export const Canvas: FC = () => {
    const dispatch = useAppDispatch();

    const currentItems = useAppSelector(workspaceSelectors.currentItems);
    const elementsForSubmodel = useAppSelector((state) => state.workspace.schema.itemsForSubmodel.elements);

    const schemaGoToMap = useAppSelector(workspaceSelectors.schemaGoToMap);
    const libraryItems = useAppSelector(workspaceSelectors.libraryItems);
    const currentNodeProperties = useAppSelector(workspaceSelectors.currentNodeProperties);
    const itemsForSubmodel = useAppSelector((state) => state.workspace.schema.itemsForSubmodel);
    const livePermissions = useAppSelector((state) => state.workspace.livePermissions.permissions);
    const libraryPortTypes = useAppSelector(workspaceSelectors.libraryPortTypes);
    const groups = useAppSelector((state) => state.workspace.schema.schemaItems.groups);
    const getSubmodelsSchemaItemsStatus = useAppSelector(
        (state) => state.workspace.schema.getSubmodelsSchemaItems.status
    );
    const getProjectsForProjectsBlocksStatus = useAppSelector(
        (state) => state.workspace.schema.getProjectsForProjectsBlock.status
    );
    const useProjectVersionStatus = useAppSelector((state) => state.projects.versions.useVersion.status);
    const getUserBlockStatus = useAppSelector((state) => state.workspace.userBlocks.getUserBlock.status);

    const [isInitialized, setIsInitialized] = useState(false);

    const [isSelectionInitialized, setIsSelectionInitialized] = useState(false);
    const [menu, setMenu] = useState<{ id: number[]; top: number; left: number } | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    const [metersConnected, setMetersConnected] = useState<TSchemaNode[]>([]);
    const [addingGroupPayload, setAddingGroupPayload] = useState<{ elementId: string; node: TSchemaNode } | null>(null);

    const { readonly, mode } = useWorkspaceDataContext();

    const elements = currentItems?.elements || [];

    const wires = currentItems?.wires || [];

    const selectedItems = useAppSelector(workspaceSelectors.selectedItems);

    const [defaultSelectedNodes, setDefaultSelectedNodes] = useState<string[]>(
        selectedItems.elements.map((node) => node.id)
    );

    const [defaultSelectedConnections, setDefaultSelectedConnections] = useState<string[]>(
        selectedItems.wires.map((w) => w.id)
    );

    const nodeTypes = useMemo(() => ({ image: ImageElement, element: Element, text: TextElement }), []);
    const edgeTypes = useMemo(() => ({ 'custom-edge': CustomEdge }), []);

    const initialNodes = useMemo(() => mapElementsToNodes(elements, defaultSelectedNodes) as Node[], []);
    const initialEdges: TSchemaConnection[] = useMemo(
        () => mapWiresToConnections(wires, defaultSelectedConnections) as TSchemaConnection[],
        []
    );

    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
    const [nodes, setNodes] = useNodesState(initialNodes);
    const [edges, setEdges] = useEdgesState(initialEdges);

    const reactFlowWrapper = useRef<HTMLInputElement>(null);

    const { isSearchMode, searchMeta, turnOffSearchMode, turnOnSearchModeByClick } = useSearchMode();

    const layoutParams = useMemo(
        () => ({
            leftBarWidth: WORKSPACE_LEFTBAR_WIDTH,
            bottomBarHeight: WORKSPACE_BOTTOMBAR_HEIGHT,
            headerHeight: WORKSPACE_HEADER_HEIGHT,
            searchInputHeight: WORKSPACE_SEARCH_INPUT_HEIGHT,
            safeDropdownHeight: WORKSPACE_SEARCH_SAFE_DROPDOWN_HEIGHT,
        }),
        [reactFlowWrapper, reactFlowInstance]
    );

    useEffect(() => {
        startTransition(() => {
            if (isSelectionInitialized) {
                const selectedNodesBuffer = selectedItems.elements.map((node) => node.id);
                const selectedConnectionsBuffer = selectedItems.wires.map((connection) => connection.id);
                setNodes(mapElementsToNodes(elements, selectedNodesBuffer) as Node[]);

                setEdges(mapWiresToConnections(wires, selectedConnectionsBuffer) as TSchemaConnection[]);
            }
        });
    }, [elements, wires, selectedItems, isSelectionInitialized, mode]);

    useEffect(() => {
        const keyDownHandler = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                event.preventDefault();

                if (isSearchMode) {
                    turnOffSearchMode();
                }
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [isSearchMode]);

    useEffect(() => {
        if (reactFlowInstance && isInitialized) {
            reactFlowInstance.fitView();
        }
    }, [mode, isInitialized]);

    useEffect(() => {
        if (addingGroupPayload && metersConnected.length !== 0) {
            const modal: TGroupCreateWarningModal = {
                type: ModalTypes.GROUP_CREATE_WARNING,
                data: { ...addingGroupPayload, metersElements: metersConnected },
            };
            dispatch(ApplicationActions.showModal({ modal }));
            setAddingGroupPayload(null);
        }
    }, [addingGroupPayload, metersConnected]);

    const onConnect = useCallback((connection: Connection) => {
        const edge = makeSchemaConnection({
            id: Date.now(),
            index: '',
            ...connection,
        });
        setEdges((els: Edge[]) => addEdge(edge, els));
        batch(() => {
            dispatch(workspaceActions.addConnection(edge));
            dispatch(markConnectionPortsAsConnected(connection)); // TODO make this action in addConnection or make listener on addConnection
        });
    }, []);

    const onDragOver = useCallback((event: any) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const handleNodesChange = useCallback(
        (changes: NodeChange[]) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [nodes, currentItems]
    );

    const handleEdgesChange = useCallback(
        (changes: EdgeChange[]) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [wires, currentItems]
    );

    const handleSelectionChange = useCallback(
        ({ nodes, edges }: OnSelectionChangeParams) => {
            if (!isInitialized) {
                return;
            }
            return new Promise((resolve, reject) => {
                if (nodes.length === 0 && edges.length === 0) {
                    return reject({ nodes, edges });
                }
                if (nodes.length > 0 || edges.length > 0) {
                    return resolve({ nodes, edges });
                }
            })
                .then(({ nodes, edges }: { nodes: TSchemaNode[]; edges: TSchemaConnection[] }) => {
                    const selectedNodesBuffer = new Set([] as string[]);
                    const selectedEdgesBuffer = new Set([] as string[]);
                    nodes.forEach((node: TSchemaNode) => {
                        if (!selectedNodesBuffer.has(node.id)) {
                            selectedNodesBuffer.add(node.id);
                        }
                    });
                    edges.forEach((edge: TSchemaConnection) => {
                        if (!selectedEdgesBuffer.has(edge.id)) {
                            selectedEdgesBuffer.add(edge.id);
                        }
                    });
                    return {
                        selectedNodesBuffer: Array.from(selectedNodesBuffer),
                        selectedEdgesBuffer: Array.from(selectedEdgesBuffer),
                        nodes,
                    };
                })
                .then(({ selectedNodesBuffer, selectedEdgesBuffer, nodes }: any) => {
                    batch(() => {
                        dispatch(
                            workspaceActions.setSelectedItems({ ids: selectedNodesBuffer, type: SchemaItemTypes.NODE })
                        );
                        dispatch(
                            workspaceActions.setSelectedItems({
                                ids: selectedEdgesBuffer,
                                type: SchemaItemTypes.CONNECTION,
                            })
                        );
                    });
                    setIsSelectionInitialized(true);
                    return { selectedNodesBuffer, selectedEdgesBuffer, nodes };
                })
                .catch(() => {
                    batch(() => {
                        dispatch(workspaceActions.setSelectedItems({ ids: [], type: SchemaItemTypes.NODE }));
                        dispatch(workspaceActions.setSelectedItems({ ids: [], type: SchemaItemTypes.CONNECTION }));
                    });
                    setIsSelectionInitialized(true);
                    setDefaultSelectedNodes([]);
                });
        },
        [isInitialized]
    );

    const modalConfiguration: TElementConfigurationModal = {
        type: ModalTypes.ELEMENT_CONFIGURATION,
    };
    const modalInitialization: TElementInitializationModal = {
        type: ModalTypes.ELEMENT_INITIALIZATION,
    };

    const handleAddNode = async (
        item: ILibraryItem,
        elements: TSchemaNode[],
        position: XYPosition,
        livePermissions: TLivePermissions,
        mousePosition?: XYPosition,
        id?: number,
        isGroupWarning?: boolean
    ) => {
        const { hasConfigurations, isFromFile, isDisabled, type } = item;

        if (isDisabled) {
            return;
        }

        try {
            validateElementPermissions([item], livePermissions);
            validateElement(item, elements);
        } catch (e) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.WARNING,
                        message: e.message,
                    },
                })
            );
            return;
        }

        const elementId = id ? id : Date.now();
        const index = item.type === 'group' ? '' : calculateIndex(elements, item);
        const newNode = makeSchemaNode(
            item,
            {
                id: elementId,
                index,
                position,
                nodeType: determineType(item.type),
            },
            libraryPortTypes,
            schemaGoToMap
        );

        const {
            data: { view },
        } = newNode;
        const updatedNewNode = {
            ...newNode,
            width: view?.minWidth || CANVAS.ELEMENT_MIN_WIDTH,
            height: view?.minHeight || CANVAS.ELEMENT_MIN_HEIGHT,
            rotation: 0,
        };

        if (type !== 'project') {
            setNodes((nds: Node[]) => nds.concat(updatedNewNode));
        }

        batch(async () => {
            if (newNode.data.type === 'InPort' || newNode.data.type === 'OutPort') {
                await dispatch(fetchLibraryPortTypes());
                dispatch(
                    workspaceActions.addPortNode({
                        ...updatedNewNode,
                        data: {
                            ...updatedNewNode.data,
                            availablePorts: [
                                { ...updatedNewNode.data.availablePorts[0], libraries: LIBRARIES_DEFAULT_LIST },
                            ],
                        },
                    })
                );
            } else if (newNode.data.type === 'group') {
                if (isGroupWarning) {
                    setAddingGroupPayload({
                        elementId: elementId.toString(),
                        node: { ...updatedNewNode },
                    });
                } else {
                    dispatch(
                        addGroup({
                            elementId: elementId.toString(),
                            node: { ...updatedNewNode },
                        })
                    );
                }
            } else {
                if (type !== 'project') {
                    dispatch(workspaceActions.addNode({ ...updatedNewNode }));
                }
            }

            if (type !== 'project') {
                dispatch(workspaceActions.setSelectedItems({ ids: [newNode.id], type: SchemaItemTypes.NODE }));
            }
        });

        if (hasConfigurations) {
            dispatch(ApplicationActions.showModal({ modal: modalConfiguration }));
        }

        if (isFromFile) {
            if (ELEMENTS_INITIALIZED_BY_TEXT_FILE.includes(type)) {
                dispatch(
                    ApplicationActions.showModal({
                        modal: { type: ModalTypes.PULSEQ_INITIALIZATION, data: { type } },
                    })
                );
            } else {
                dispatch(ApplicationActions.showModal({ modal: modalInitialization }));
            }
        }
        if (type === 'project') {
            const modalProjectBlockInitialization: TProjectBlockInitialization = {
                type: ModalTypes.PROJECT_BLOCK_INITIALIZATION,
                data: {
                    node: updatedNewNode,
                },
            };
            dispatch(ApplicationActions.showModal({ modal: modalProjectBlockInitialization }));
            return;
        }
    };

    const onDrop = (event: any) => {
        event.preventDefault();

        if (reactFlowInstance && reactFlowWrapper?.current) {
            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const data = event.dataTransfer.getData('application/reactflow');

            // check if the dropped element is valid
            if (typeof data === 'undefined' || !data) {
                return;
            }

            const mousePosition: XYPosition = {
                x: event.clientX,
                y: event.clientY,
            };
            const position = reactFlowInstance.screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });

            const draggableLibraryItem: ILibraryItem = JSON.parse(data);

            handleAddNode(draggableLibraryItem, elements, position, livePermissions, mousePosition);
        }
    };

    const handleClickOnCanvas = useCallback(
        (event: React.MouseEvent) => {
            setMenu(null);
            switch (event.detail) {
                case 2: {
                    if (!isSearchMode) {
                        turnOnSearchModeByClick(event, reactFlowInstance, reactFlowWrapper, layoutParams);
                    }
                    break;
                }
                default: {
                    if (isSearchMode) {
                        turnOffSearchMode();
                    }
                    break;
                }
            }
        },
        [isSearchMode, reactFlowInstance, reactFlowWrapper]
    );

    const handleSaveSelectionPosition = useCallback(
        (nodes: TSchemaNode[]) => {
            startTransition(() => {
                const changedNodesBuffer: TSchemaNode[] = [];
                nodes.forEach((cNode) => {
                    const selectedNode = selectedItems.elements.find((sNode) => sNode.id === cNode.id);
                    if (selectedNode && JSON.stringify(selectedNode.position) !== JSON.stringify(cNode.position)) {
                        changedNodesBuffer.push(cNode as TSchemaNode);
                    }
                });
                if (changedNodesBuffer.length > 0) {
                    dispatch(workspaceActions.updateNodesPositions(changedNodesBuffer));
                }
            });
        },
        [nodes]
    );

    const handleSelectionDragStop = useCallback(
        (event: any, nds: Node[]) => {
            handleSaveSelectionPosition(nds as TSchemaNode[]);
        },
        [nodes]
    );

    const handleNodeDragStop = useCallback(
        (event: any, node: Node, draggedNodes: Node[]) => {
            handleSaveSelectionPosition(draggedNodes as TSchemaNode[]);
        },
        [nodes]
    );

    const handleInit = useCallback((reactFlowInstance: ReactFlowInstance) => {
        setReactFlowInstance(reactFlowInstance);
        setIsInitialized(true);
        reactFlowInstance.fitView();
    }, []);

    const MouseConsumer = () => {
        useCanvasMousePosition(reactFlowWrapper, reactFlowInstance);
        return null;
    };

    const onNodeContextMenu = useCallback(
        (event: any, nodes: any) => {
            event.preventDefault();
            const { clientX, clientY } = event;
            const eventNodes = nodes.length ? nodes.map((node: Node) => node.id) : [nodes.id];

            if (
                (menu && clientX + 45 !== menu.top) ||
                (menu && clientY + 45 !== menu.left) ||
                selectedItems.elements.length < 2
            ) {
                dispatch(workspaceActions.setSelectedItems({ ids: eventNodes, type: SchemaItemTypes.NODE }));
            }

            setMenu(() => ({
                id: eventNodes,
                top: clientY,
                left: clientX,
            }));
        },
        [selectedItems.elements, menu]
    );

    const onPaneClick = useCallback(() => {
        setMenu(null);
    }, []);

    const handleGroupClick = () => {
        const item: ILibraryItem | null = libraryItems.find((item) => item.type === 'group') || null;
        const position = calculateGroupBlockCoordinates(elementsForSubmodel);
        const id = Date.now();
        const metersElementsConnected = findConnectedMeters(itemsForSubmodel, elements);
        setMetersConnected(metersElementsConnected);
        const isGroupWarning = metersElementsConnected.length !== 0;
        dispatch(workspaceActions.addElementsForSubmodel(metersElementsConnected));

        if (item) {
            handleAddNode(item, elements, position, livePermissions, undefined, id, isGroupWarning);
        }
    };

    const handleDelete = () => {
        dispatch(workspaceActions.deleteSchemaItems());
    };

    const handleUseDefaultMode = {
        onConnect: onConnect,
        onDrop: onDrop,
        onDragOver: onDragOver,
        onNodeDragStop: handleNodeDragStop,
        onSelectionDragStop: handleSelectionDragStop,
        onPaneClick: handleClickOnCanvas,
        onNodeContextMenu: onNodeContextMenu,
        onSelectionContextMenu: onNodeContextMenu,
        onNodeClick: (event: React.MouseEvent, node: Node) => {
            const isMenuMustBeClosed = menu && !menu.id.includes(parseInt(node.id));
            if (isMenuMustBeClosed) {
                setMenu(null);
            }
        },
    };

    return (
        <DefaultCanvas
            isInitialized={isInitialized}
            isLoading={
                useProjectVersionStatus === Statuses.LOADING ||
                getSubmodelsSchemaItemsStatus === Statuses.LOADING ||
                getProjectsForProjectsBlocksStatus === Statuses.LOADING ||
                getUserBlockStatus === Statuses.LOADING
            }
            wrapperRef={reactFlowWrapper}
            ref={ref}
            {...handleUseDefaultMode}
            nodeTypes={nodeTypes}
            nodes={nodes}
            edges={edges}
            edgeTypes={edgeTypes}
            defaultEdgeOptions={defaultEdgeOptions}
            onInit={handleInit}
            onSelectionChange={handleSelectionChange}
            onNodesChange={handleNodesChange}
            onEdgesChange={handleEdgesChange}
        >
            {!readonly && isSearchMode && searchMeta.searchPosition && mode !== WorkspaceModes.SUBMODEL && (
                <ContextSearch
                    position={searchMeta.searchPosition}
                    isUpwards={searchMeta.searchDropdownDirection === SearchDropdownDirections.UPWARDS}
                    onAddNode={(item: ILibraryItem) =>
                        handleAddNode(item, elements, searchMeta.addNodePosition as XYPosition, livePermissions)
                    }
                    onClose={turnOffSearchMode}
                />
            )}
            <MouseConsumer />
            {menu && (
                <ContextMenu
                    {...menu}
                    onClick={onPaneClick}
                    onGroupClick={handleGroupClick}
                    isGroup={
                        selectedItems.elements.filter((el) => !['InPort', 'OutPort'].includes(el.data.type)).length > 1
                    }
                    onDelete={handleDelete}
                    isBlockSavingAvailable={
                        selectedItems.elements.length === 1 && selectedItems.elements[0].data.type === 'group'
                    }
                    isUserBlockTransformToGroupAvailable={
                        selectedItems.elements.length === 1 && selectedItems.elements[0].data.type === 'userBlock'
                    }
                    element={{
                        ...selectedItems.elements[0],
                        data: {
                            ...selectedItems?.elements[0]?.data,
                            elemProps: currentNodeProperties.elemProps,
                        },
                    }}
                    groups={groups as TSchemaGroup[]}
                />
            )}
        </DefaultCanvas>
    );
};
