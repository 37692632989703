import React from 'react';

export type Matrix = Array<Array<string>>;

export interface MatrixTableProps {
    initialMatrixString?: string;
    id?: string;
    disabled?: boolean;
    label?: string | { __html: string };
    onChange: (id: string, value: string) => void;
    help?: React.ReactNode;
}

export enum constructorFieldNames {
    ROW_COUNT = 'ROW_COUNT',
    COL_COUNT = 'COL_COUNT',
}
