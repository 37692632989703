import { createSlice } from '@reduxjs/toolkit';
import { IChartsDataMap, IGraphsEditorState, TChartsData } from 'libs/models/src/lib/charts';

import { abilitiesRulesMap } from '@repeat/common-ability';
import { getWorkspaceMode } from '@repeat/constants';
import {
    ClickTypes,
    ILibraryItem,
    ITrendDataMap,
    IWorkspaceState,
    ModelStatuses,
    Statuses,
    TLivePermissions,
    TrendsStatuses,
    TTrendData,
    WorkspaceModes,
} from '@repeat/models';

import { chartsReducer } from './charts/chartsSlice';
import { chartsEditorReducer } from './chartsEditor/chartsEditorSlice';
import { clickReducers } from './clickSlice';
import { eventLogReducer } from './eventLog';
import { draggableLibraryItemReducers } from './libraryItems/draggableLibraryItemSlice';
import { libraryItemsReducers } from './libraryItems/libraryItemsSlice';
import { libraryPortTypesReducers } from './libraryItems/libraryPortTypesSlice';
import { metaReducers } from './metaSlice';
import { modelControlReducers } from './modelControl/modelControlSlice';
import { modulesReducers } from './modules/modulesSlice';
import { initialState as schemaInitialState, schemaReducers } from './schema/schemaSlice';
import { shortCircuitReducer } from './shortCircuitSlice';
import { trendsReducers } from './trendsSlice';
import { undoRedoReducers } from './undoredo';
import { userBlocksReducers } from './userBlocks/userBlocksSlice';

const setLivePermissions = () => {
    const initialLivePermissions = {};
    Object.keys(abilitiesRulesMap).forEach((permission: string) => {
        Object.assign(initialLivePermissions, { [permission]: false });
    });
    return initialLivePermissions as TLivePermissions;
};

export const initialState: IWorkspaceState = {
    meta: {
        readonly:
            localStorage.getItem('workspaceMode') === WorkspaceModes.DEMO ||
            localStorage.getItem('previousMode') === WorkspaceModes.DEMO,
        isInitialized: false,
        isLoading: false,
        mode: getWorkspaceMode() || WorkspaceModes.MAIN,
        previousMode: getWorkspaceMode() || WorkspaceModes.MAIN,
        elementId: null,
        groupId: null,
        userBlockId: null,
        isUserBlockEditor: false,
        path: [],
    },
    libraryItems: {
        items: [],
        status: Statuses.IDLE,
        error: null,
    },
    libraryPortTypes: {
        items: [],
        status: Statuses.IDLE,
        error: null,
    },
    userBlocks: {
        items: [],
        addUserBlock: {
            status: Statuses.IDLE,
            error: null,
        },
        getUserBlock: {
            status: Statuses.IDLE,
            error: null,
        },
        getUserBlocks: {
            status: Statuses.IDLE,
            error: null,
        },
        deleteUserBlocks: {
            status: Statuses.IDLE,
            error: null,
        },
    },
    draggableLibraryItem: {
        item: {} as ILibraryItem,
    },
    schema: schemaInitialState,
    modelControl: {
        modelStatus: ModelStatuses.STOP,
        modelTime: 0,
        runProject: {
            status: Statuses.IDLE,
            error: null,
        },
        stopProject: {
            status: Statuses.IDLE,
            error: null,
        },
        softStopProject: {
            status: Statuses.IDLE,
            error: null,
        },
        freezeProject: {
            status: Statuses.IDLE,
            error: null,
        },
        generateSourcesCode: {
            status: Statuses.IDLE,
            error: null,
        },
        generateNoNameFile: {
            status: Statuses.IDLE,
            error: null,
        },
    },
    trends: {
        watchingParameters: [],
        YParameters: [],
        modelParameters: [],
        data: [] as TTrendData[],
        dataMap: {} as ITrendDataMap,
        dataBuffer: [],
        dataBufferLastTimeMs: 0,
        status: Statuses.IDLE,
        error: null,
        commonStatus: TrendsStatuses.DISCONNECTED,
        commonError: null,
        getTrendDataStatus: Statuses.IDLE,
        getTrendDataError: null,
        getTrendDataFailedAttempts: 0,
        getTrendLastTime: 0,
    },
    undoRedo: {
        type: null,
        canUndo: false,
        canRedo: false,
    },
    click: ClickTypes.DEFAULT_CLICK,
    eventLog: { list: [] },
    settings: {
        modellingTime: '100',
        discretizationStep: '50',
        isRealTimeSync: true,
        isCompression: false,
        compressionType: 'RDP',
        compressionAccuracy: '0.0001',
        integrationStep: '50',
        taskNumber: 0,
        version: '',
        systemType: '',
    },
    graphs: {
        charts: [],
        commonStatus: TrendsStatuses.DISCONNECTED,
        fetchChartsDataStatus: Statuses.IDLE,
        fetchChartsDataError: null,
        fetchChartsDataFailedAttempts: 0,
        fetchLastTime: 0,
        data: [] as TChartsData[],
        dataMap: {} as IChartsDataMap,
        editor: {
            isOpen: false,
            currentGraphUuid: null,
        } as IGraphsEditorState,
    },
    tools: {
        shortCircuit: {
            status: Statuses.IDLE,
            error: null,
        },
    },
    modules: null,
    livePermissions: {
        isEstablishingConnection: false,
        isConnected: false,
        permissions: {
            ...setLivePermissions(),
        },
        delayedMessages: [],
    },
};

const reducers = {
    ...metaReducers,
    ...libraryItemsReducers,
    ...draggableLibraryItemReducers,
    ...modelControlReducers,
    ...trendsReducers,
    ...chartsReducer,
    ...chartsEditorReducer,
    ...undoRedoReducers,
    ...clickReducers,
    ...eventLogReducer,
    ...schemaReducers,
    ...shortCircuitReducer,
    ...libraryPortTypesReducers,
    ...userBlocksReducers,
    ...modulesReducers,
};

export const workspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers,
});

export const { actions, reducer } = workspaceSlice;

export { downloadChartData } from './charts/chartsThunks';
export { fetchLibraryItems } from './libraryItems/libraryItemsSlice';
export { fetchLibraryPortTypes } from './libraryItems/libraryPortTypesSlice';
export { initializeWorkspace, uninitializeWorkspace, updateWorkspacePathItems } from './metaSlice';
export {
    continueProject,
    freezeProject,
    generateNoNameFile,
    generateSourcesCode,
    runDemoProject,
    runProject,
    softStopProject,
    stopProject,
} from './modelControl/modelControlSlice';
export { ECalculateAction } from './modules/modulesSlice';
export {
    getElementPropertiesSets,
    getProject,
    loadProject,
    markConnectionPortsAsConnected,
    saveProject,
    selectElementConfiguration,
    setDynamicOptions,
    setElementConfigurationCustomizable,
    showElementConfigurationsModal,
} from './schema/schemaSlice';
export { copyItems, pasteItems } from './schema/schemaSlices/copyPasteItemsSlice';
export { deleteParameter, setFSMParameters } from './schema/schemaSlices/setElementParametersSlice';
export { addProjectBlock, setSubmodelItems } from './schema/schemaSlices/submodelSlice';
export { shortCircuitCalculate } from './shortCircuitSlice';
export { appendChartData, runTrends } from './trendsSlice';
