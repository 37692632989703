import localforage from 'localforage';

import { ILibraryItem } from '@repeat/models';

interface IEngineerService {
    store: LocalForage;
    databaseName: string;
    getBlocks(): Promise<ILibraryItem[]>;
    getBlock(type: string): Promise<ILibraryItem | null>;
    addBlock(block: ILibraryItem): Promise<void>;
    deleteBlock(type: string): Promise<void>;
    deleteAll(): Promise<void>;
    updateBlockByType(type: string, updatedBlock: ILibraryItem): Promise<void>;
}

abstract class AEngineerService implements IEngineerService {
    databaseName: string;
    store: LocalForage;

    constructor(databaseName: string) {
        this.databaseName = databaseName;
        this.store = localforage.createInstance({
            name: this.databaseName,
            driver: localforage.INDEXEDDB,
            storeName: this.databaseName,
        });
    }
    public abstract getBlocks(): Promise<ILibraryItem[]>;
    public abstract getBlock(type: string): Promise<ILibraryItem | null>;
    public abstract addBlock(block: ILibraryItem): Promise<void>;
    public abstract deleteBlock(type: string): Promise<void>;
    public abstract deleteAll(): Promise<void>;
    public abstract updateBlockByType(type: string, updatedBlock: ILibraryItem): Promise<void>;
}

export class EngineerService extends AEngineerService {
    constructor(databaseName: string) {
        super(databaseName);
    }

    async getBlocks(): Promise<ILibraryItem[]> {
        const items: ILibraryItem[] = [];

        await this.store.iterate((value: ILibraryItem) => {
            items.push(value);
        });

        return items;
    }

    async getBlock(type: string) {
        return await this.store.getItem<ILibraryItem>(type);
    }

    async addBlock(block: ILibraryItem) {
        await this.store.setItem(block.type, block).catch((error) => {
            console.error('Failed to add block: ', error);
        });
    }

    async deleteBlock(type: string) {
        const itemExists = await this.store.getItem(type);

        if (itemExists !== null) {
            await this.store.removeItem(type).catch((err) => console.error(err));
        }
    }

    async deleteAll() {
        await this.store.clear();
    }

    async updateBlockByType(type: string, updatedBlock: ILibraryItem) {
        const item = await this.store.getItem<ILibraryItem>(type);
        if (item) {
            await this.store.setItem(type, updatedBlock);
        }
    }
}
