import { PayloadAction } from '@reduxjs/toolkit';

import { ApplicationActions } from '@repeat/common-slices';
import { setWorkspaceMode } from '@repeat/constants';
import {
    IWorkspaceState,
    NotificationTypes,
    TElement,
    TState,
    TWorkspaceMode,
    TWorkspacePathItem,
    WorkspaceModes,
} from '@repeat/models';
import { workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

import { loadProject } from './schema/schemaSlice';
import { loadUserBlock } from './userBlocks/userBlocksSlice';

import { actions, initialState, stopProject } from '.';

import { AppDispatch, RootStateFn } from '../../store';

export const setPrevMode = ({ mode, previousMode, isUserBlockEditor }: any) => {
    let currentPrevMode = WorkspaceModes.MAIN;
    if (mode === WorkspaceModes.DEMO || previousMode === WorkspaceModes.DEMO) {
        currentPrevMode = WorkspaceModes.DEMO;
    }
    if (mode === WorkspaceModes.USER_BLOCK && isUserBlockEditor) {
        currentPrevMode = WorkspaceModes.USER_BLOCK;
    }
    return currentPrevMode;
};

export const metaReducers = {
    initializeWorkspaceRequest: (state: IWorkspaceState) => ({
        ...state,
        meta: {
            ...state.meta,
            isInitialized: false,
            isLoading: true,
            elementId: null,
        },
    }),
    initializeWorkspaceSuccess: (state: IWorkspaceState) => ({
        ...state,
        meta: {
            ...state.meta,

            isInitialized: true,
            isLoading: false,
        },
    }),
    initializeWorkspaceFailed: (state: IWorkspaceState) => ({
        ...state,
        meta: {
            ...state.meta,
            isInitialized: true,
            isLoading: false,
        },
    }),
    resetWorkspace: () => ({
        ...initialState,
    }),
    changeWorkspaceMode: (
        state: IWorkspaceState,
        {
            payload,
        }: PayloadAction<{
            mode: TWorkspaceMode;
            elementId?: string | null;
            groupId?: string | null;
            userBlockId?: string | null;
            previousMode?: TWorkspaceMode | null;
            readonly?: boolean;
        }>
    ) => {
        const { previousMode, isUserBlockEditor } = state.meta;
        const newMeta = {
            ...state.meta,
            ...payload,
            previousMode:
                (payload.mode === WorkspaceModes.MAIN && WorkspaceModes.MAIN) ||
                (payload.mode === WorkspaceModes.DEMO && WorkspaceModes.DEMO) ||
                (payload.mode === WorkspaceModes.PREVIEW && WorkspaceModes.PREVIEW) ||
                (isUserBlockEditor && WorkspaceModes.USER_BLOCK) ||
                previousMode,
        };
        setWorkspaceMode(newMeta.mode, newMeta.previousMode);
        return {
            ...state,
            meta: newMeta,
        };
    },
    setIsUserBlockEditor: (state: IWorkspaceState) => {
        const meta = {
            ...state.meta,
            isUserBlockEditor: true,
        };
        return {
            ...state,
            meta,
        };
    },
    addWorkspacePathItem: (
        state: IWorkspaceState,
        {
            payload,
        }: PayloadAction<{
            nodeData: TElement;
        }>
    ) => {
        const { mode, readonly, isUserBlockEditor } = state.meta;
        const { groups, userBlockGroups } = state.schema.schemaItems;

        const {
            nodeData: { id, name },
        } = payload;
        let pathId: number | null = id;
        if (mode === WorkspaceModes.USER_BLOCK) {
            pathId = isUserBlockEditor
                ? groups?.find((obj) => obj.id.toString() === id.toString())?.id ||
                  userBlockGroups?.find((group) => group.parentGroupId === null)?.id ||
                  null
                : userBlockGroups?.find((group) => group.parentGroupId === null)?.id || null;
        }
        if (pathId) {
            state.meta.path.push({ mode, id: pathId.toString(), name, readonly });
        }
    },
    updateWorkspacePathItems: (state: IWorkspaceState, { payload }: PayloadAction<TWorkspacePathItem[]>) => {
        state.meta.path = payload;
    },
    resetWorkspacePath: (state: IWorkspaceState) => {
        const { isUserBlockEditor } = state.meta;
        if (isUserBlockEditor) {
            state.meta.path.splice(1);
        } else {
            state.meta.path = [];
        }
    },
};
export const updateWorkspacePathItems =
    (id: string, mode: TWorkspaceMode | null) => async (dispatch: AppDispatch, getState: RootStateFn) => {
        const state = getState() as TState;
        const pathState = state.workspace.meta.path;
        const index = pathState.findIndex((item) => item.id === id);

        if (index === -1) {
            return;
        }
        const path = pathState.slice(0, index + 1);
        dispatch(actions.updateWorkspacePathItems(path));
        const isUserBlockModeInPreviousModes =
            path.filter((item) => item.mode === WorkspaceModes.USER_BLOCK).length !== 0;
        if (!isUserBlockModeInPreviousModes && mode) {
            dispatch(actions.changeWorkspaceMode({ mode, userBlockId: null }));
        }
    };

export const initializeWorkspace =
    (isLibraryFromDB?: boolean) => async (dispatch: AppDispatch, getState: RootStateFn) => {
        const state = getState() as TState;
        const isLoading = state.workspace.meta.isLoading;
        if (isLoading) {
            return;
        }

        dispatch(actions.initializeWorkspaceRequest());

        try {
            const projectIdString = localStorage.getItem('projectId');
            const userBlockId = localStorage.getItem('userBlockId'); // режим редактирования блока
            const isUserBlock = localStorage.getItem('isUserBlock'); // true - только в режиме просмотра блока
            if (userBlockId) {
                await dispatch(loadUserBlock(userBlockId));
                dispatch(actions.initializeWorkspaceSuccess());
            }
            if (isUserBlock) {
                localStorage.removeItem('isUserBlock');
            }
            const projectId = projectIdString ? parseInt(projectIdString) : null;
            if (!projectId) {
                return;
            }

            await dispatch(stopProject());
            dispatch(workspaceActions.setModelTime(initialState.modelControl.modelTime));
            await dispatch(loadProject(projectId, isLibraryFromDB));

            dispatch(actions.initializeWorkspaceSuccess());
        } catch (error) {
            dispatch(actions.initializeWorkspaceFailed());
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.ERROR_UNKNOWN,
                    },
                })
            );
        }
    };

export const uninitializeWorkspace = () => async (dispatch: AppDispatch) => {
    dispatch(workspaceActions.resetWorkspace());
};
