import React from 'react';
import { ReactFlowProvider } from 'reactflow';

import { WorkspacePageLayout } from './WorkspacePageLayout';

import { WorkspaceDataContextProvider } from '../../features/Workspace/DataProvider/DataProvider';

export const WorkspacePage = () => {
    return (
        <ReactFlowProvider>
            <WorkspaceDataContextProvider>
                <WorkspacePageLayout />
            </WorkspaceDataContextProvider>
        </ReactFlowProvider>
    );
};
