import React, { memo, useState } from 'react';
import { useIntl } from 'react-intl';

import { TranslationKey } from '@repeat/translations';
import { IconButton, InputField, uiColors } from '@repeat/ui-kit';

import { SVariableItemWrapper, SVariableRow } from './SVariables';
import { messages } from './translation';
import { useWorkspaceDataContext } from '../DataProvider/DataProvider';

export interface IVariableRow {
    name: string;
    value: string;
    onChangeKey: any;
    onChangeValue: any;
    onDelete: any;
    index: number;
    variables: { [key: string]: string };
}

export const VariableItem = memo(
    ({ variables, index, name, value, onChangeKey, onChangeValue, onDelete }: IVariableRow) => {
        const intl = useIntl();
        const { readonly } = useWorkspaceDataContext();

        const [error, setError] = useState<{ [key: string]: boolean | string }>({
            nameError: '',
            valueError: '',
        });

        return (
            <SVariableRow data-error={!!error.nameError || !!error.valueError} id={`variable-item-row-${index}`}>
                <td>
                    <InputField
                        disabled={readonly}
                        value={name}
                        onChange={(event) => {
                            const target = event.target;
                            const value = target.value;
                            if (value !== '') {
                                if (Object.keys(variables).includes(value)) {
                                    setError({
                                        ...error,
                                        nameError: intl.formatMessage(
                                            messages[TranslationKey.WORKSPACE_PAGE_ERROR_VARIABLES]
                                        ),
                                    });
                                } else {
                                    setError({ ...error, nameError: '' });
                                }
                            } else {
                                setError({
                                    ...error,
                                    nameError: intl.formatMessage(
                                        messages[TranslationKey.WORKSPACE_PAGE_ERROR_NAME_VARIABLES]
                                    ),
                                });
                            }
                            onChangeKey(index, value);
                        }}
                        error={!!error.nameError as boolean}
                        errorText={error.nameError}
                    />
                </td>
                <td>
                    <SVariableItemWrapper>
                        <InputField
                            disabled={readonly}
                            value={value}
                            onChange={(event) => {
                                const target = event.target;
                                const value = target.value;
                                if (value !== '') {
                                    if (!isFinite(parseInt(value))) {
                                        setError({
                                            ...error,
                                            valueError: intl.formatMessage(
                                                messages[TranslationKey.WORKSPACE_PAGE_ERROR_VALUE_VARIABLES]
                                            ),
                                        });
                                    } else {
                                        setError({ ...error, valueError: '' });
                                    }
                                }
                                onChangeValue(index, value);
                            }}
                            error={!!error.valueError as boolean}
                            errorText={error.valueError}
                        />
                        {!readonly && (
                            <IconButton
                                fill={uiColors.darkGrey}
                                noHover
                                name={'delete'}
                                onClick={() => onDelete(index)}
                            />
                        )}
                    </SVariableItemWrapper>
                </td>
            </SVariableRow>
        );
    }
);
