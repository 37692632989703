import styled, { css } from 'styled-components';

export const SItemWrapper = styled.div<{ position: string }>`
    width: 15px;
    height: 15px;
    z-index: 10;

    ${(props) =>
        ['left', 'right'].includes(props.position) &&
        css`
            margin: 7.5px 0;
        `}
    ${(props) =>
        ['top', 'bottom'].includes(props.position) &&
        css`
            margin: 0 7.5px;
        `}
`;
export const STitleWrapper = styled.div<{ position: string }>`
    position: absolute;

    ${(props) =>
        props.position === 'left' &&
        css`
            right: 100%;
            text-align: right;
            padding: 0 2px;
        `}
    ${(props) =>
        props.position === 'top' &&
        css`
            bottom: 100%;
            text-align: left;
            writing-mode: sideways-lr;
            padding: 2px 0;
        `}
    ${(props) =>
        props.position === 'right' &&
        css`
            left: 100%;
            text-align: left;
            padding: 0 2px;
        `}
    ${(props) =>
        props.position === 'bottom' &&
        css`
            top: 100%;
            text-align: right;
            writing-mode: sideways-lr;
            padding: 2px 0;
        `}
`;
export const STitle = styled.div`
    white-space: nowrap;
    font-size: 12px;
`;
export const SSubTitle = styled.div<{ position: string }>`
    color: #cccccc;
    font-size: 10px;

    ${(props) =>
        ['left', 'right'].includes(props.position) &&
        css`
            margin-top: -4px;
        `}
    ${(props) =>
        ['top', 'bottom'].includes(props.position) &&
        css`
            margin-left: -4px;
        `}
`;
export const SItem = styled.div<{ isConditional?: boolean; color?: string }>`
    display: inline-block;
    width: 15px;
    height: 15px;
    align-content: center;
    border-radius: 50%;
    border: 2px solid #000;
    background-color: #ccc;
    ${(props) =>
        props.isConditional &&
        css`
            border-style: dashed;
        `}
    ${(props) =>
        props.color &&
        css`
            background-color: ${props.color};
        `}

    &.sortable-ghost {
        background-color: #c8ebfb;
    }
`;
