import React, { FC } from 'react';

import { SInputListLabel } from '@components/InputDataTable/SInputList';
import { SInputMatrixField } from '@components/InputMatrixTable/SInputMatrixTable';

import { IconButton, InputField } from '@repeat/ui-kit';

export interface IInputMatrixTable {
    value: string;
    refId: string;
    disabled?: boolean;
    error?: boolean;
    errorText?: string;
    label?: string | { __html: string };
    help?: React.ReactNode;
    onChange: (e: React.ChangeEvent) => void;
    onOpenMatrix: () => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    onPaste?: (e: React.ClipboardEvent) => void;
}

export const InputMatrixField: FC<IInputMatrixTable> = ({
    label,
    refId,
    help,
    value,
    error,
    errorText,
    disabled,
    onKeyDown,
    onOpenMatrix,
    onChange,
}) => {
    return (
        <SInputMatrixField>
            {label && (
                <SInputListLabel
                    htmlFor={refId}
                    dangerouslySetInnerHTML={
                        label as {
                            __html: string;
                        }
                    }
                >
                    {help && help}
                </SInputListLabel>
            )}
            <InputField
                data-name='matrix-table-field'
                id={refId}
                type='text'
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onPaste={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }}
                size={'small'}
                disabled={disabled}
                error={error}
                errorText={errorText}
            />
            <IconButton
                data-name='matrix-table-edit-button'
                fill={'var(--ui-icons)'}
                name={'edit'}
                noHover
                onClick={onOpenMatrix}
                disabled={error}
            />
        </SInputMatrixField>
    );
};
