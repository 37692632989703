import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.USER_BLOCK_TITLE]: {
        id: TranslationKey.USER_BLOCK_TITLE,
        defaultMessage: ru[TranslationKey.USER_BLOCK_TITLE],
    },
    [TranslationKey.USER_BLOCK_SUBSECTION]: {
        id: TranslationKey.USER_BLOCK_SUBSECTION,
        defaultMessage: ru[TranslationKey.USER_BLOCK_SUBSECTION],
    },
    [TranslationKey.USER_BLOCK_SUBSECTION_PLACEHOLDER]: {
        id: TranslationKey.USER_BLOCK_SUBSECTION_PLACEHOLDER,
        defaultMessage: ru[TranslationKey.USER_BLOCK_SUBSECTION_PLACEHOLDER],
    },
    [TranslationKey.USER_BLOCK_NO_SUBSECTIONS]: {
        id: TranslationKey.USER_BLOCK_NO_SUBSECTIONS,
        defaultMessage: ru[TranslationKey.USER_BLOCK_NO_SUBSECTIONS],
    },
    [TranslationKey.USER_BLOCK_ICON]: {
        id: TranslationKey.USER_BLOCK_ICON,
        defaultMessage: ru[TranslationKey.USER_BLOCK_ICON],
    },
    [TranslationKey.USER_BLOCK_ICON_CHANGE]: {
        id: TranslationKey.USER_BLOCK_ICON_CHANGE,
        defaultMessage: ru[TranslationKey.USER_BLOCK_ICON_CHANGE],
    },
    [TranslationKey.USER_BLOCK_ICON_SCALE]: {
        id: TranslationKey.USER_BLOCK_ICON_SCALE,
        defaultMessage: ru[TranslationKey.USER_BLOCK_ICON_SCALE],
    },
    [TranslationKey.USER_BLOCK_ICON_ROTATE]: {
        id: TranslationKey.USER_BLOCK_ICON_ROTATE,
        defaultMessage: ru[TranslationKey.USER_BLOCK_ICON_ROTATE],
    },
    [TranslationKey.USER_BLOCK_DESCRIPTION]: {
        id: TranslationKey.USER_BLOCK_DESCRIPTION,
        defaultMessage: ru[TranslationKey.USER_BLOCK_DESCRIPTION],
    },
    [TranslationKey.USER_BLOCK_NEW_ERROR_DUPLICATE]: {
        id: TranslationKey.USER_BLOCK_NEW_ERROR_DUPLICATE,
        defaultMessage: ru[TranslationKey.USER_BLOCK_NEW_ERROR_DUPLICATE],
    },
});
