import React, { startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { batch } from 'react-redux';
import ReactFlow, {
    Background,
    BackgroundVariant,
    Connection,
    Edge,
    MarkerType,
    Node,
    OnSelectionChangeParams,
    ReactFlowInstance,
    XYPosition,
    addEdge,
    useEdgesState,
    useNodesState,
} from 'reactflow';

import 'reactflow/dist/style.css';
import '../Canvas/style.css';

import { Controls } from '@components/ReactFlow/ReactFlowControls';
import { ReactFlowMiniMap } from '@components/ReactFlow/ReactFlowMiniMap/ReactFlowMiniMap';

import { ApplicationActions } from '@repeat/common-slices';
import { CANVAS, setFSMStateTitle } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import {
    ILibraryItem,
    NotificationTypes,
    SchemaItemTypes,
    TSchemaConnection,
    TSchemaNode,
    WorkspaceModes,
} from '@repeat/models';
import { makeSchemaConnection, makeSchemaNode } from '@repeat/services';
import { workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

import { FloatingConnectionLine, FloatingEdge } from './FSMFloatingEdge/FloatingEdge';
import { FSMStartElement } from './FSMStartElement/FSMStartElement';
import { FSMStateElement } from './FSMStateElement/FSMStateElement';
import { messages } from './FSMTranslations';

import { SCanvasWrapper } from '../Canvas/SCanvas';
import {
    calculateIndex,
    determineType,
    mapElementsToNodes,
    mapWiresToConnections,
} from '../Canvas/helper/canvasHelper';
import { useCanvasMousePosition } from '../Canvas/hooks/useCanvasMousePosition';
import { useWorkspaceDataContext } from '../DataProvider/DataProvider';

const connectionLineStyle = {
    strokeWidth: 1,
    stroke: 'var(--ui-label)',
};

const nodeTypes = {
    'fsm-state': FSMStateElement,
    'fsm-start': FSMStartElement,
};

const edgeTypes = {
    floating: FloatingEdge,
};

const defaultEdgeOptions = {
    style: { strokeWidth: 1, stroke: 'black' },
    type: 'floating',
    markerEnd: {
        color: 'black',
        type: MarkerType.ArrowClosed,
    },
};

export const FSMCanvas = () => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const { readonly, mode } = useWorkspaceDataContext();
    const reactFlowWrapper = useRef<HTMLInputElement>(null);

    const currentSubModelItems = useAppSelector(workspaceSelectors.currentItems);
    const selectedItems = useAppSelector(workspaceSelectors.selectedItems);

    const elements = currentSubModelItems?.elements || [];
    const wires = currentSubModelItems?.wires || [];

    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isSelectionInitialized, setIsSelectionInitialized] = useState(false);
    const [defaultSelectedNodes, setDefaultSelectedNodes] = useState<string[]>(
        selectedItems.elements.map((node) => node.id)
    );
    const [defaultSelectedConnections, setDefaultSelectedConnections] = useState<string[]>(
        selectedItems.wires.map((w) => w.id)
    );

    const initialNodes = useMemo(() => mapElementsToNodes(elements, defaultSelectedNodes) as Node[], []);
    const initialEdges: TSchemaConnection[] = useMemo(
        () => mapWiresToConnections(wires, defaultSelectedConnections) as TSchemaConnection[],
        []
    );

    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const MouseConsumer = () => {
        useCanvasMousePosition(reactFlowWrapper, reactFlowInstance);
        return null;
    };

    useEffect(() => {
        startTransition(() => {
            if (isSelectionInitialized) {
                const selectedNodesBuffer = selectedItems.elements.map((node) => node.id);
                const selectedConnectionsBuffer = selectedItems.wires.map((connection) => connection.id);
                setNodes(mapElementsToNodes(elements, selectedNodesBuffer) as Node[]);

                setEdges(mapWiresToConnections(wires, selectedConnectionsBuffer) as TSchemaConnection[]);
            }
        });
    }, [elements, wires, selectedItems, isSelectionInitialized, mode]);

    useEffect(() => {
        if (reactFlowInstance) {
            reactFlowInstance.fitView();
        }
    }, [mode]);

    const onConnect = useCallback(
        (connection: Connection) => {
            const nodeId = connection.target;
            const targetNode = elements.find((element) => element.id === nodeId);
            const targetNodeType = targetNode && targetNode?.type === 'fsm-start';

            if (connection.source !== connection.target && !targetNodeType) {
                const edge = makeSchemaConnection(
                    {
                        id: Date.now(),
                        index: '',
                        ...connection,
                    },
                    WorkspaceModes.FSM_EDITOR
                );
                setEdges((eds: Edge[]) => addEdge(edge, eds));
                dispatch(workspaceActions.addConnection(edge));
            }
        },
        [setEdges]
    );

    const handleAddNode = useCallback(
        async (
            item: ILibraryItem,
            elements: TSchemaNode[],
            position: XYPosition,
            mousePosition?: XYPosition,
            id?: number
        ) => {
            const { isDisabled } = item;

            if (isDisabled) {
                return;
            }

            const elementId = id ? id : Date.now();
            const index = calculateIndex(elements, item);
            const newNode = makeSchemaNode(
                item,
                {
                    id: elementId,
                    index,
                    position,
                    nodeType: determineType(item.type),
                },
                [],
                null
            );

            const {
                data: { view },
            } = newNode;

            const updatedNewNode = {
                ...newNode,
                width: view?.minWidth || CANVAS.ELEMENT_MIN_WIDTH,
                height: view?.minHeight || CANVAS.ELEMENT_MIN_HEIGHT,
                rotation: 0,
                type: determineType(item.type),
            };

            dispatch(workspaceActions.addNode({ ...updatedNewNode }));
        },
        [elements]
    );

    const handleOnDrop = useCallback(
        async (event: React.DragEvent) => {
            event.preventDefault();

            if (reactFlowInstance && reactFlowWrapper?.current) {
                const data = event.dataTransfer.getData('application/reactflow');

                if (typeof data === 'undefined' || !data) {
                    return;
                }

                const { type } = JSON.parse(data);
                const elementTypes = new Set(elements.map((element) => element.data.type));

                if (type === 'fsm-start' && Array.from(elementTypes).includes('fsm-start')) {
                    dispatch(
                        ApplicationActions.showNotification({
                            notification: {
                                type: NotificationTypes.WARNING,
                                message: formatMessage(messages[TranslationKey.FSM_WARNING_START_POINT]),
                            },
                        })
                    );
                    return;
                }

                const mousePosition: XYPosition = {
                    x: event.clientX,
                    y: event.clientY,
                };

                const position = reactFlowInstance.screenToFlowPosition({
                    x: mousePosition.x,
                    y: mousePosition.y,
                });

                const draggableLibraryItem: ILibraryItem =
                    type === 'fsm-state'
                        ? { ...JSON.parse(data), elemProps: setFSMStateTitle(elements, JSON.parse(data).elemProps) }
                        : JSON.parse(data);

                await handleAddNode(draggableLibraryItem, elements || [], position, mousePosition);
            }
        },
        [reactFlowInstance, elements]
    );

    const handleInit = useCallback((reactFlowInstance: ReactFlowInstance) => {
        setReactFlowInstance(reactFlowInstance);
        setIsInitialized(true);
        reactFlowInstance.fitView();
    }, []);

    const handleDragOver = useCallback((event: any) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const handleSaveSelectionPosition = useCallback(
        (nodes: TSchemaNode[]) => {
            startTransition(() => {
                const changedNodesBuffer: TSchemaNode[] = [];
                nodes.forEach((cNode) => {
                    const selectedNode = selectedItems.elements.find((sNode) => sNode.id === cNode.id);
                    if (selectedNode && JSON.stringify(selectedNode.position) !== JSON.stringify(cNode.position)) {
                        changedNodesBuffer.push(cNode as TSchemaNode);
                    }
                });
                dispatch(workspaceActions.updateNodesPositions(changedNodesBuffer));
            });
        },
        [nodes]
    );

    const handleSelectionChange = useCallback(
        ({ nodes, edges }: OnSelectionChangeParams) => {
            if (!isInitialized) {
                return;
            }
            return new Promise((resolve, reject) => {
                if (nodes.length === 0 && edges.length === 0) {
                    return reject({ nodes, edges });
                }
                if (nodes.length > 0 || edges.length > 0) {
                    return resolve({ nodes, edges });
                }
            })
                .then(({ nodes, edges }: { nodes: TSchemaNode[]; edges: TSchemaConnection[] }) => {
                    const selectedNodesBuffer = new Set([] as string[]);
                    const selectedEdgesBuffer = new Set([] as string[]);
                    nodes.forEach((node: TSchemaNode) => {
                        if (!selectedNodesBuffer.has(node.id)) {
                            selectedNodesBuffer.add(node.id);
                        }
                    });
                    edges.forEach((edge: TSchemaConnection) => {
                        if (!selectedEdgesBuffer.has(edge.id)) {
                            selectedEdgesBuffer.add(edge.id);
                        }
                    });
                    return {
                        selectedNodesBuffer: Array.from(selectedNodesBuffer),
                        selectedEdgesBuffer: Array.from(selectedEdgesBuffer),
                        nodes,
                    };
                })
                .then(({ selectedNodesBuffer, selectedEdgesBuffer, nodes }: any) => {
                    batch(() => {
                        dispatch(
                            workspaceActions.setSelectedItems({ ids: selectedNodesBuffer, type: SchemaItemTypes.NODE })
                        );
                        dispatch(
                            workspaceActions.setSelectedItems({
                                ids: selectedEdgesBuffer,
                                type: SchemaItemTypes.CONNECTION,
                            })
                        );
                    });
                    setIsSelectionInitialized(true);
                    return { selectedNodesBuffer, selectedEdgesBuffer, nodes };
                })
                .catch(() => {
                    batch(() => {
                        dispatch(workspaceActions.setSelectedItems({ ids: [], type: SchemaItemTypes.NODE }));
                        dispatch(workspaceActions.setSelectedItems({ ids: [], type: SchemaItemTypes.CONNECTION }));
                    });
                    setIsSelectionInitialized(true);
                    setDefaultSelectedNodes([]);
                });
        },
        [isInitialized]
    );

    const handleSelectionDragStop = useCallback(
        (_: React.DragEvent, nds: Node[]) => {
            handleSaveSelectionPosition(nds as TSchemaNode[]);
        },
        [nodes]
    );

    const handleNodeDragStop = useCallback(
        (_: React.DragEvent, node: Node, draggedNodes: Node[]) => {
            handleSaveSelectionPosition(draggedNodes as TSchemaNode[]);
        },
        [nodes]
    );

    const handleUseDefaultMode = {
        onDrop: handleOnDrop,
        onSelectionDragStop: handleSelectionDragStop,
        onNodeDragStop: handleNodeDragStop,
        onConnect: onConnect,
        onDragOver: handleDragOver,
        onSelectionChange: handleSelectionChange,
        onNodesChange: onNodesChange,
        onEdgesChange: onEdgesChange,
    };

    return (
        <SCanvasWrapper data-name='canvasContainer' ref={reactFlowWrapper}>
            <ReactFlow
                {...(!readonly && handleUseDefaultMode)}
                fitView
                proOptions={{ hideAttribution: true }}
                minZoom={CANVAS.MIN_ZOOM}
                maxZoom={CANVAS.MAX_ZOOM}
                snapGrid={[CANVAS.GRID_STEP, CANVAS.GRID_STEP]}
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                defaultEdgeOptions={defaultEdgeOptions}
                connectionLineComponent={FloatingConnectionLine}
                connectionLineStyle={connectionLineStyle}
                onInit={handleInit}
                nodesDraggable={!readonly}
                nodesConnectable={!readonly}
            >
                <MouseConsumer />
                <Background variant={BackgroundVariant.Dots} gap={CANVAS.GRID_STEP} />
                <Controls />
                <ReactFlowMiniMap />
            </ReactFlow>
        </SCanvasWrapper>
    );
};
