import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { TNewBlockInfoModal } from 'libs/models/src/lib/modal';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ILibraryItem, ModalTypes, TPropertyValue } from '@repeat/models';
import { Button, InputField, Modal, StyledForm, uiColors } from '@repeat/ui-kit';

import { EditBlockEn } from '../EngineerMode/EditBlockForm/EditBlockEn';
import { EditBlockUI } from '../EngineerMode/EditBlockForm/EditBlockUI';
import { useFormContext } from '../EngineerMode/EngineerFormContext/EngineerFormContext';
import { EngineerService } from '../EngineerMode/EngineerService';
import { SInputNotice, SPropertyValuesForm, StyledButtons } from '../EngineerMode/styled/SEditBlock';

const engineerService = new EngineerService('engineerBlocks');
const engineerServiceEN = new EngineerService('engineerBlocksEN');

interface IFormData {
    enName: string;
    elemProps: { enDescription: string; availableValues: { enTitle: string }[] }[];
    elemParams: { enDescription: string }[];
    stateParameters: { enDescription: string; enUnit: string }[];
}

export const NewBlockInfoModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors, isValid },
    } = useForm({
        mode: 'onChange',
    });

    const nestedInputRefs = useRef(new Map());

    const modals = useAppSelector((state) => state.app.modals);
    const modal = modals.find((m) => m.type === ModalTypes.NEWBLOCK_INFO) as TNewBlockInfoModal;
    const [block] = useState(modal.data.block);
    const [enBlock, setEnBlock] = useState<ILibraryItem>();
    const [selectedTabs, setSelectedTabs] = useState({ settings: true, block: false });
    const { formState, enFormState, updateFormState, updateWholeFormState, getEnBlock, updateBlockToIndexedDB } =
        useFormContext();

    const [focusedIndex, setFocusedIndex] = useState<string>('');

    const [localBlock, setLocalBlock] = useState<ILibraryItem>(block);

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.NEWBLOCK_INFO }));
    };

    useEffect(() => {
        const getEngineerBlock = async () => {
            try {
                const engineerBlock = await getEnBlock(block.type);
                if (engineerBlock) setEnBlock(engineerBlock);
            } catch (error) {
                console.error('Ошибка при получении блоков:', error);
            }
        };
        getEngineerBlock();
    }, []);

    useEffect(() => {
        updateWholeFormState(block);
    }, []);

    useEffect(() => {
        if (nestedInputRefs.current.get(focusedIndex)) {
            nestedInputRefs.current.get(focusedIndex).focus();
        }
    }, [formState]);

    const handleSaveClick = useCallback(async () => {
        if (formState && !enFormState) {
            await updateBlockToIndexedDB(formState);
        } else if (formState && enFormState) {
            await updateBlockToIndexedDB(formState, enFormState);
        }
    }, [formState, enFormState]);

    return (
        <Modal
            id={ModalTypes.NEWBLOCK_INFO}
            title={block.name}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='large'
            footer={null}
        >
            <StyledForm>
                <Tabs style={{ width: '100%' }}>
                    <TabList style={{ display: 'flex' }}>
                        <Tab
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '14px',
                                width: '100%',
                                color: `${selectedTabs.settings ? uiColors.mainBlue : uiColors.darkGrey}`,
                            }}
                            onClick={() => setSelectedTabs({ settings: true, block: false })}
                        >
                            RU
                        </Tab>
                        <Tab
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '14px',
                                width: '100%',
                                color: `${selectedTabs.settings ? uiColors.mainBlue : uiColors.darkGrey}`,
                            }}
                            onClick={() => setSelectedTabs({ settings: true, block: false })}
                        >
                            Порты + Визуал
                        </Tab>
                        <Tab
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '14px',
                                width: '100%',
                                color: `${selectedTabs.settings ? uiColors.mainBlue : uiColors.darkGrey}`,
                            }}
                            onClick={() => setSelectedTabs({ settings: true, block: false })}
                        >
                            EN
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <InputField
                            label='Название'
                            type='text'
                            value={localBlock.name}
                            onChange={(e) => setLocalBlock((prev) => ({ ...prev, name: e.target.value }))}
                            onBlur={(e) => {
                                updateFormState('name', e.target.value);
                            }}
                            id='standard-required'
                        />
                        <h5>Свойства блока</h5>
                        {formState?.elemProps?.map((elemProp, index) => (
                            <>
                                <InputField
                                    ref={(el) => nestedInputRefs.current.set(`elemProps-${index}`, el)}
                                    key={elemProp.name}
                                    label={elemProp.name}
                                    type='text'
                                    onChange={(e) => {
                                        const currentProps = formState.elemProps;
                                        const updatedProps = currentProps?.map((item, i) =>
                                            i === index ? { ...item, description: e.target.value } : item
                                        );
                                        updateFormState('elemProps', updatedProps);

                                        setFocusedIndex(`elemProps-${index}`);
                                    }}
                                    value={elemProp.description ?? ''}
                                    id='standard-required'
                                />
                                {elemProp.availableValues?.map((avaliableValue, index2) => {
                                    return (
                                        <SPropertyValuesForm>
                                            <h6>Значение свойства "{avaliableValue.title}"</h6>

                                            <InputField
                                                ref={(el) =>
                                                    nestedInputRefs.current.set(
                                                        `availableValues-${index}-${index2}`,
                                                        el
                                                    )
                                                }
                                                key={avaliableValue.value}
                                                type='text'
                                                size='medium'
                                                value={
                                                    formState?.elemProps
                                                        ? formState?.elemProps[index].availableValues?.[index2].title
                                                        : ''
                                                }
                                                onChange={(e) => {
                                                    const currentProps = formState.elemProps;
                                                    const currentElemProp = formState.elemProps![index];
                                                    let changedAvValues: TPropertyValue[];
                                                    if (currentElemProp.availableValues) {
                                                        changedAvValues = currentElemProp.availableValues.map(
                                                            (item, i) =>
                                                                i === index2 ? { ...item, title: e.target.value } : item
                                                        );
                                                    }
                                                    const newElemProps = currentProps?.map((item, i) =>
                                                        i === index
                                                            ? { ...item, availableValues: changedAvValues }
                                                            : item
                                                    );
                                                    updateFormState('elemProps', newElemProps);

                                                    setFocusedIndex(`availableValues-${index}-${index2}`);
                                                }}
                                                id='standard-required'
                                            />
                                        </SPropertyValuesForm>
                                    );
                                })}
                            </>
                        ))}

                        <h5>Параметры блока</h5>
                        {formState?.elemParams?.map((elemParam, index) => (
                            <InputField
                                ref={(el) => nestedInputRefs.current.set(`elemParams-${index}`, el)}
                                key={elemParam.name}
                                label={elemParam.name}
                                type='text'
                                value={elemParam.description ?? ''}
                                onChange={(e) => {
                                    const currentParams = formState.elemParams;
                                    const updatedParams = currentParams?.map((item, i) =>
                                        i === index ? { ...item, description: e.target.value } : item
                                    );
                                    updateFormState('elemParams', updatedParams);

                                    setFocusedIndex(`elemParams-${index}`);
                                }}
                            />
                        ))}

                        <h5>Параметры датчика (EN)</h5>
                        {formState?.stateParameters?.map((stateParam, index) => (
                            <>
                                <InputField
                                    ref={(el) => nestedInputRefs.current.set(`stateParams-${index}`, el)}
                                    value={stateParam.description ?? ''}
                                    key={stateParam.name}
                                    label={stateParam.name}
                                    type='text'
                                    onChange={(e) => {
                                        const currentParams = formState.stateParameters;
                                        const updatedParams = currentParams?.map((item, i) =>
                                            i === index ? { ...item, description: e.target.value } : item
                                        );
                                        updateFormState('stateParameters', updatedParams);
                                        setFocusedIndex(`stateParams-${index}`);
                                    }}
                                />

                                <SPropertyValuesForm>
                                    <InputField
                                        ref={(el) => nestedInputRefs.current.set(`stateParamsUnit-${index}`, el)}
                                        value={stateParam.unit ?? ''}
                                        label={'Единица измерения'}
                                        type='text'
                                        onChange={(e) => {
                                            const currentParams = formState.stateParameters;
                                            const updatedParams = currentParams?.map((item, i) =>
                                                i === index ? { ...item, unit: e.target.value } : item
                                            );
                                            updateFormState('stateParameters', updatedParams);

                                            setFocusedIndex(`stateParamsUnit-${index}`);
                                        }}
                                    />
                                </SPropertyValuesForm>

                                <SInputNotice>{stateParam.description}</SInputNotice>
                            </>
                        ))}
                    </TabPanel>
                    <TabPanel>
                        <EditBlockUI
                            currentBlockProp={formState as Required<ILibraryItem>}
                            engineerService={engineerService}
                        />
                    </TabPanel>
                    <TabPanel>
                        <EditBlockEn currentBlockProp={block!} engineerService={engineerServiceEN} />
                    </TabPanel>
                </Tabs>
                <StyledButtons>
                    <Button variant='secondary' onClick={handleClose}>
                        Отмена
                    </Button>
                    <Button onClick={handleSaveClick}>Сохранить</Button>
                </StyledButtons>
            </StyledForm>
        </Modal>
    );
};
