import React, { memo, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useDebounce } from 'use-debounce';
import { v4 as uuidV4 } from 'uuid';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { EFSMVariableType, IFSMVariable, VariableType } from '@repeat/models';
import { setFSMParameters, workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { IconButton } from '@repeat/ui-kit';

import {
    SFSMInput,
    SFSMSelect,
    SFSMTable,
    SFSMTableCell,
    SFSMTableHead,
    SFSMTableHeader,
    SFSMTableLabel,
    SFSMTableRow,
    SFSMTableWrapper,
} from './SFSMVariablesTable';

import { messages } from '../../features/RightBar/translation';
import { useWorkspaceDataContext } from '../../features/Workspace/DataProvider/DataProvider';

interface IFSMVariablesTable {
    id: string;
    initialValues: IFSMVariable[];
    label: string | { __html: string };
    disabled: boolean;
}

const defaultValue: IFSMVariable = { type: EFSMVariableType.IN, name: '', value: '', typeJava: 'double' };

enum EFSMErrorCode {
    NAME_REQUIRED = 'Name required',
    VALUE_REQUIRED = 'Value required',
    UNIQUE = 'unique',
    FLOAT = 'Float',
}

interface IErrorState {
    name: string;
    value: string;
}

export const FSMVariablesTable: React.FC<IFSMVariablesTable> = memo(({ id, initialValues, label, disabled }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { readonly } = useWorkspaceDataContext();

    const nodePropValue = useAppSelector((state) => {
        const prop = state.workspace.schema.schemaItems.elements.find((el) => el.id === state.workspace.meta.elementId)
            ?.data.elemProps[0];
        if (prop?.value) {
            return JSON.parse(prop.value.toString());
        }
    });
    const metaElementId = useAppSelector(workspaceSelectors.workspaceMetaElementId);

    const fieldId = useMemo(() => uuidV4(), []);

    const values = useMemo(() => {
        return initialValues.length > 0 ? initialValues : nodePropValue || [defaultValue];
    }, [initialValues]);

    const [variables, setVariables] = useState<IFSMVariable[]>(values);
    const [errors, setErrors] = useState<IErrorState[]>(Array(values.length).fill({ name: '', value: '' }));
    const [variablesBuffer, setVariablesBuffer] = useState<IFSMVariable[]>([]);
    const [debouncedValue] = useDebounce(variablesBuffer, 500);

    const onChange = (value: IFSMVariable[]) => {
        const inputPortsCount = variables.filter((value: IFSMVariable) => value.type === EFSMVariableType.IN).length;
        const outputPortsCount = variables.filter((value: IFSMVariable) => value.type === EFSMVariableType.OUT).length;
        dispatch(
            workspaceActions.setElementPropertiesValues({
                id: Number(metaElementId),
                elemProps: {
                    variables: JSON.stringify(value),
                    Input_ports: inputPortsCount.toString(),
                    Output_ports: outputPortsCount.toString(),
                },
            })
        );
        dispatch(setFSMParameters(value));
    };

    useEffect(() => {
        if (debouncedValue.length !== 0) {
            onChange(debouncedValue);
        }
    }, [debouncedValue]);

    const handleValidate = () => {
        const newErrors: IErrorState[] = [...errors];
        let isValid = true;

        const nameSet = new Set<string>();

        variables.forEach((variable, index) => {
            newErrors[index] = { name: '', value: '' };

            if (!variable.name) {
                newErrors[index].name = EFSMErrorCode.NAME_REQUIRED;
                isValid = false;
            } else if (nameSet.has(variable.name)) {
                newErrors[index].name = EFSMErrorCode.UNIQUE;
                isValid = false;
            }

            nameSet.add(variable.name);
        });
        return { isValid, newErrors };
    };

    const handleAddRow = () => {
        const { isValid, newErrors } = handleValidate();
        if (isValid) {
            setVariables([...variables, { ...defaultValue }]);
            setVariablesBuffer([...variables, { ...defaultValue }]);
            setErrors([...newErrors, { name: '', value: '' }]);
        } else {
            setErrors(newErrors);
        }
    };

    const handleRemoveRow = (index: number) => {
        const newVariables = [...variables];
        newVariables.splice(index, 1);
        setVariables(newVariables);
        setVariablesBuffer(newVariables);

        const newErrors = [...errors];
        newErrors.splice(index, 1);
        setErrors(newErrors);

        onChange(newVariables);
    };

    const handleChange = (index: number, field: 'name' | 'value' | 'type', value: string) => {
        const newVariables = [...variables];
        newVariables[index] = {
            ...newVariables[index],
            [field]: value,
        };
        setVariables(newVariables);
        setVariablesBuffer(newVariables);

        const { newErrors } = handleValidate();

        if (field !== 'type') {
            if (newErrors[index][field]) {
                setErrors(newErrors);
            } else {
                setErrors((prevErrors) => {
                    const updatedErrors = [...prevErrors];
                    updatedErrors[index][field] = '';
                    return updatedErrors;
                });
            }
        }
    };

    const handleBlur = (index: number, field: 'name' | 'value') => {
        const { newErrors } = handleValidate();

        if (newErrors[index][field]) {
            setErrors(newErrors);
        } else {
            setErrors((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index][field] = '';
                return updatedErrors;
            });
        }
    };

    useEffect(() => {
        setErrors(() => {
            if (initialValues) {
                const { newErrors } = handleValidate();

                return newErrors;
            }
            return Array(values.length).fill({ name: '', value: '' });
        });
    }, [initialValues]);

    return (
        <SFSMTableWrapper id={id}>
            <SFSMTableLabel htmlFor={fieldId} dangerouslySetInnerHTML={label as { __html: string }} />
            <IconButton
                disabled={readonly || errors.some((error) => error.name !== '') || disabled}
                data-name={'FSM-list-add-button'}
                noHover
                name={'plus'}
                onClick={handleAddRow}
            />
            <SFSMTable>
                <SFSMTableHead>
                    <SFSMTableRow>
                        <SFSMTableHeader>
                            {intl.formatMessage(messages[TranslationKey.TABLE_DOCUMENT_COLUMN_TYPE])}
                        </SFSMTableHeader>
                        <SFSMTableHeader>{intl.formatMessage(messages[TranslationKey.NAME])}</SFSMTableHeader>
                        <SFSMTableHeader></SFSMTableHeader>
                    </SFSMTableRow>
                </SFSMTableHead>
                <tbody>
                    {variables.map((variable, index) => (
                        <SFSMTableRow key={index}>
                            <SFSMTableCell>
                                <SFSMSelect
                                    disabled={readonly}
                                    value={variable.type}
                                    onChange={(e) => handleChange(index, 'type', e.target.value as VariableType)}
                                >
                                    <option value={EFSMVariableType.IN}>
                                        {intl.formatMessage(messages[TranslationKey.FSM_IN_VARIABLE])}
                                    </option>
                                    <option value={EFSMVariableType.OUT}>
                                        {intl.formatMessage(messages[TranslationKey.FSM_OUT_VARIABLE])}
                                    </option>
                                    <option value={EFSMVariableType.INTERNAL}>
                                        {intl.formatMessage(messages[TranslationKey.FSM_INTERNAL_VARIABLE])}
                                    </option>
                                </SFSMSelect>
                            </SFSMTableCell>
                            <SFSMTableCell>
                                <SFSMInput
                                    disabled={readonly}
                                    data-error={errors[index].name !== ''}
                                    type='text'
                                    value={variable.name}
                                    onChange={(e) => handleChange(index, 'name', e.target.value)}
                                    onBlur={() => handleBlur(index, 'name')}
                                />
                            </SFSMTableCell>
                            <SFSMTableCell>
                                <IconButton
                                    disabled={readonly}
                                    fill={'var(--ui-icons)'}
                                    style={{ minWidth: '16px' }}
                                    data-name={'FSM-list-remove-button'}
                                    noHover
                                    name={'close'}
                                    onClick={() => handleRemoveRow(index)}
                                />
                            </SFSMTableCell>
                        </SFSMTableRow>
                    ))}
                </tbody>
            </SFSMTable>
        </SFSMTableWrapper>
    );
});
