import styled from 'styled-components';

import { pxToRem } from '@repeat/ui-kit';

export const SAutocomplete = styled.div`
    margin-bottom: ${pxToRem(16)};
`;
 export const SUserBlockFormItem = styled.div`
     margin-bottom: ${pxToRem(8)};
 `
