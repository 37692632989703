import styled from 'styled-components';

import { layoutSize, pxToRem, zIndex } from '@repeat/ui-kit';

export const SInputMatrix = styled.div`
    [data-name='help'] {
        position: absolute;
        top: 8px;
        right: 8px;
    }
`;

export const SInputMatrixTableButtons = styled.div`
    display: flex;

    button {
        border: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        padding: 0;
        font-size: 16px;
        justify-content: center;
        position: absolute;
        max-height: ${pxToRem(24)};
        max-width: ${pxToRem(24)};
        height: ${pxToRem(24)};
        width: ${pxToRem(24)};

        &[data-name='add-row'] {
            top: calc(100% - 12px);
            left: calc(100% / 2 - 12px);
        }

        &[data-name='add-column'] {
            top: calc(100% / 2 - 12px);
            left: calc(100% - 12px);
        }
    }
`;

export const SInputMatrixTableWrapper = styled.div`
    background-color: var(--ui-sidebar);
    display: flex;
    flex-direction: column;
    padding: 0 0 ${pxToRem(10)};
    height: 100%;
    position: relative;

    header {
        align-items: center;
        background-color: var(--ui-primary);
        height: ${pxToRem(parseInt(layoutSize.header))};
        max-width: 100%;
        padding: 0 ${pxToRem(16)};
        width: 100%;
        z-index: ${zIndex.header};
        color: var(--ui-icon-button);
        display: flex;
        flex: 0 0 auto;
        justify-content: space-between;

        button {
            order: 2;
        }

        svg {
            fill: var(--ui-icon-button);
        }
    }

    footer {
        align-items: center;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: flex-end;
        gap: ${pxToRem(16)};
        min-height: ${pxToRem(parseInt(layoutSize.header))};
        padding: 0 ${pxToRem(16)};
        width: 100%;
    }
`;

export const SInputMatrixTableAlert = styled.div`
    margin: 0 ${pxToRem(16)};
    width: calc(100% - 16px * 2);

    [type] {
        display: flex;
        justify-content: space-between;
    }
`;

export const SInputMatrixTable = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-height: calc(100% - 48px * 2);
    overflow: auto;
    position: relative;

    table {
        background-color: var(--ui-sidebar);
        border-collapse: collapse;
        font-family: Arial, sans-serif;
        font-size: 14px;
        margin: ${pxToRem(16)};
        width: calc(100% - 16px * 2);
        position: relative;
    }

    th,
    td {
        border: 1px solid #ddd;
        text-align: center;
        transition: background-color 0.3s ease;

        button {
            border: 0;
            background: transparent;
            cursor: pointer;
            padding: 8px;
            width: 100%;
        }

        input {
            background: var(--ui-input);
            border: 0;
            border-radius: 0;
            min-height: ${pxToRem(32)};
            padding: 0 ${pxToRem(8)};
            width: 100%;
            outline: none;

            &:focus,
            &:active {
                background-color: var(--ui-table-hover);
            }

            &:hover {
                background-color: var(--ui-table-hover);
            }
        }
    }

    td:first-child,
    th {
        background-color: var(--ui-border-hover);
        font-weight: bold;
    }
`;

export const SInputMatrixConstructor = styled.div`
    background-color: transparent;
    display: flex;
    align-items: flex-end;
    gap: ${pxToRem(16)};
    margin: ${pxToRem(16)};
    margin-bottom: 0;
    max-width: calc(100% - 32px);
    width: 100%;

    button {
        min-height: ${pxToRem(28)};
    }

    > div {
        max-width: 20%;
        margin-bottom: 0;
    }
`;

export const SInputMatrixFieldWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${pxToRem(4)};

    button {
        margin-top: ${pxToRem(4)};
    }

    [data-type='text'] {
        width: 100%;
    }
`;

export const SInputMatrixField = styled.div`
    position: relative;
    width: 100%;

    [data-name='matrix-table-field'] {
        padding-right: ${pxToRem(24)};
    }

    [data-name='matrix-table-edit-button'] {
        background: var(--ui-input);
        border-left: 1px solid var(--ui-border);
        border-radius: 0;
        margin: 0 !important;
        position: absolute;
        top: 2px;
        right: 1px;
        height: ${pxToRem(24)};
        width: ${pxToRem(20)};

        i {
            max-width: ${pxToRem(16)};
            min-width: ${pxToRem(16)};
        }
    }
`;
