import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { sortLibrariesCompare } from '@repeat/constants';
import { environment } from '@repeat/environment';
import { useAppSelector, useLibraryItemsFilter } from '@repeat/hooks';
import { ILibraryItem, Statuses, TLibraryType, TSolverType, User } from '@repeat/models';
import { appUserSelectors, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { LoaderFullscreen, SidebarContent, TagCloud } from '@repeat/ui-kit';

import { LibraryItems } from './LibraryItems/LibraryItems';
import { LibraryItemsSearch } from './LibraryItems/LibraryItemsSearch/LibraryItemsSearch';
import { messages } from './LibraryItems/translation';

import { EngineerService } from '../EngineerMode/EngineerService';

const { production } = environment || { production: false };
const isDevelopmentEnv = !production;

const engineerService = new EngineerService('engineerBlocks');

export const LeftBar: FC = () => {
    const intl = useIntl();

    const user = useAppSelector(appUserSelectors.currentUser);
    const libraryItemsState = useAppSelector(workspaceSelectors.libraryItemsState);
    const libraryItems = useAppSelector(workspaceSelectors.availableLibraryItems(user as User));
    const solverType = useAppSelector(workspaceSelectors.solverType);
    const libraryType = useAppSelector(workspaceSelectors.libraryType);
    const useProjectVersionStatus = useAppSelector((state) => state.projects.versions.useVersion.status);

    const [tagList, setTagList] = useState<string[]>([]);
    const [isSort, setIsSort] = useState<boolean>(false);

    const [filter, setFilter] = useState({ query: '' });
    const [devBlocks, setDevBlocks] = useState<ILibraryItem[]>([]);

    const isLoading = useMemo(() => {
        return useProjectVersionStatus === Statuses.LOADING || libraryItemsState.status === Statuses.LOADING;
    }, [libraryItemsState, useProjectVersionStatus]);

    useEffect(() => {
        const fetchData = async () => {
            const allData = await engineerService.getBlocks();
            setDevBlocks(allData);
        };

        fetchData().catch((err) => console.error('Failed to get blocks: ', err));
    }, []);

    useEffect(() => {
        if (tagList.length > 0) {
            setIsSort(true);
        } else {
            setIsSort(false);
        }
    }, [tagList]);

    const onCloseHandler = () => setFilter({ query: '' });

    const handleSelectTags = (list: string[]) => {
        setTagList(list);
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFilter({ ...filter, query: e.target.value });
    };

    const getLibraryName = () => {
        if (libraryType === ('electrocity' as TLibraryType)) {
            return intl.formatMessage(messages[TranslationKey.ELECTROCITY]);
        }

        if (libraryType === ('electrocitydc' as TLibraryType)) {
            return intl.formatMessage(messages[TranslationKey.ELECTROCITY_DC]);
        }

        return intl.formatMessage(messages[TranslationKey.PROJECT_LIBRARY]);
    };

    const searchedItems = useLibraryItemsFilter(libraryItems, filter.query);
    const searchedDevBlocks = useLibraryItemsFilter(devBlocks, filter.query);
    const isEngineer = useAppSelector((state) => state.appUser.currentUser?.settings?.isEngineer);
    const isEngineerAndIsDev = isEngineer && isDevelopmentEnv && searchedDevBlocks;
    const getFilteredItems = (libraryType: string) => {
        return searchedItems.filter((item) => item.library === libraryType);
    };

    //TODO: unused, check
    const getFilteredDevItems = (libraryType: string) => {
        return searchedDevBlocks.filter((item) => item.library === libraryType);
    };

    const tagsCloudItems: string[] = useMemo(() => {
        return libraryItems
            .filter((item: ILibraryItem) => item.isActive)
            .map((item: ILibraryItem) => item.library)
            .filter((x, i, a) => a.indexOf(x) === i)
            .sort(sortLibrariesCompare)
            .map((i) => {
                const key = i.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).toUpperCase();
                const index = Object.keys(TranslationKey).indexOf(key);
                if (index < 0) {
                    return null;
                }

                return intl.formatMessage({ id: Object.values(TranslationKey)[index] });
            })
            .filter((i) => i !== null) as string[];
    }, [libraryItems]);

    return (
        <SidebarContent iconName={'book'} title={getLibraryName()} style={{ height: 'calc(100% - 38px)' }}>
            {isLoading && <LoaderFullscreen overlay={false} />}
            {!isLoading && (
                <LibraryItemsSearch
                    libraryItemsState={libraryItemsState}
                    filter={filter}
                    onChange={onChange}
                    onClose={onCloseHandler}
                />
            )}
            {!isLoading && (
                <TagCloud
                    data-name={'tag-cloud'}
                    watch={(list: string[]) => handleSelectTags(list)}
                    items={tagsCloudItems}
                />
            )}
            {!isLoading && (
                <LibraryItems
                    isSort={isSort}
                    tagList={tagList}
                    libraryType={libraryType as TLibraryType}
                    solverType={solverType as TSolverType}
                    searchedItems={searchedItems}
                    getFilteredItems={getFilteredItems}
                    isFiltered={filter.query.trim().length !== 0}
                />
            )}
        </SidebarContent>
    );
};
