import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { messages } from '@components/InputMatrixTable/translations';

import { TranslationKey } from '@repeat/translations';
import { IconButton } from '@repeat/ui-kit';

export interface IInputMatrixHeaderProps {
    onOpenMatrixTable: () => void;
}

export const InputMatrixHeader: FC<IInputMatrixHeaderProps> = ({ onOpenMatrixTable }) => {
    const { formatMessage } = useIntl();

    return (
        <header>
            <span>{formatMessage(messages[TranslationKey.MATRIX_TABLE_EDITOR_HEADER])}</span>
            <IconButton
                noHover
                name='close'
                onClick={() => {
                    onOpenMatrixTable();
                }}
            />
        </header>
    );
};
