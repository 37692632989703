import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';

import { useAppSelector } from '@repeat/hooks';
import { TWorkspaceMode, TWorkspacePathItem } from '@repeat/models';

export const STORED_KEYS = ['currentProjectName', 'projectId', 'userBlockName'];

export interface IWorkspaceContext {
    readonly: boolean;
    mode: TWorkspaceMode | null;
    previousMode: TWorkspaceMode | null;
    userBlockEditor: boolean;
    userBlockId: string | null;
    projectName: null | string;
    userBlockName: null | string;
    projectId: string | null;
    groupId: string | null;
    elementId: string | null;
    path: TWorkspacePathItem[];
}

const WorkspaceDataContext = createContext<IWorkspaceContext | null>(null);

const DataProvider = WorkspaceDataContext.Provider;

export const WorkspaceDataContextProvider: FC<{ children: React.ReactNode }> = ({ children, ...props }) => {
    const {
        mode,
        previousMode,
        readonly,
        isUserBlockEditor: userBlockEditor,
        groupId,
        userBlockId,
        elementId,
        path,
    } = useAppSelector((state) => state.workspace.meta);

    const [userBlockName, setUserBlockName] = useState(localStorage.getItem('userBlockName'));
    const [projectName, setProjectName] = useState(localStorage.getItem('currentProjectName'));
    const [projectId, setProjectId] = useState(localStorage.getItem('projectId'));

    const handleProjectNameChange = useCallback(
        (event: StorageEvent) => {
            if (STORED_KEYS.includes(event.key as string)) {
                switch (event.key) {
                    case 'currentProjectName':
                        setProjectName(event.newValue);
                        break;
                    case 'userBlockName':
                        setUserBlockName(event.newValue);
                        break;
                    case 'projectId':
                        setProjectId(event.newValue);
                        break;
                }
            }
        },
        [projectName]
    );

    useEffect(() => {
        const handleLocalStorageChange = () => {
            setProjectName(localStorage.getItem('currentProjectName'));
            setProjectId(localStorage.getItem('projectId'));
        };

        window.addEventListener('localStorageChange', handleLocalStorageChange);
        return () => window.removeEventListener('localStorageChange', handleLocalStorageChange);
    }, []);

    useEffect(() => {
        window.addEventListener('storage', handleProjectNameChange, false);

        return () => window.removeEventListener('storage', handleProjectNameChange, false);
    }, []);

    return (
        <DataProvider
            value={{
                groupId,
                userBlockId,
                readonly,
                mode,
                previousMode,
                userBlockEditor,
                projectName,
                userBlockName,
                projectId,
                elementId,
                path,
            }}
        >
            {children}
        </DataProvider>
    );
};

export const useWorkspaceDataContext = () => {
    const context = useContext(WorkspaceDataContext);
    if (!context) {
        throw new Error('Use WorkspaceDataContext');
    }
    return context;
};
