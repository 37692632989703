import { PayloadAction } from '@reduxjs/toolkit';

import {
    IWorkspaceState,
    SchemaItemTypes,
    TSchemaConnection,
    TSchemaGroup,
    TSchemaItemType,
    TSchemaNode,
    WorkspaceModes,
} from '@repeat/models';

export const SelectElementsSlice = {
    // selection
    setSelectedItems: (state: IWorkspaceState, action: PayloadAction<{ ids: string[]; type: TSchemaItemType }>) => {
        const { ids, type } = action.payload;
        const { mode, elementId, groupId, userBlockId, isUserBlockEditor } = state.meta;
        const { elements, wires, groups, userBlockGroups } = state.schema.schemaItems;

        const filterNodesByIds = (nodes: TSchemaNode[]) => nodes.filter((node) => ids.includes(node.id));
        const filterConnectionsByIds = (connections: TSchemaConnection[]) =>
            connections.filter((connection) => ids.includes(connection.id));

        const handleMain = (): { elements: TSchemaNode[]; wires: TSchemaConnection[] } => {
            return type === SchemaItemTypes.NODE
                ? {
                      elements: filterNodesByIds(elements),
                      wires: state.schema.selectedItems.wires,
                  }
                : {
                      elements: state.schema.selectedItems.elements,
                      wires: filterConnectionsByIds(wires),
                  };
        };

        const handleFSMEditorAndSubmodel = (): { elements: TSchemaNode[]; wires: TSchemaConnection[] } => {
            const targetGroup = groupId ? groups?.find((g) => g.id?.toString() === groupId.toString()) : null;

            const targetElement = targetGroup
                ? targetGroup.elements?.find((el) => el.id === elementId)
                : state.schema.schemaItems.elements?.find((el) => el.id === elementId);

            return type === SchemaItemTypes.NODE
                ? {
                      elements: filterNodesByIds(targetElement?.data.submodelItems?.elements || []),
                      wires: state.schema.selectedItems.wires,
                  }
                : {
                      elements: state.schema.selectedItems.elements,
                      wires: filterConnectionsByIds(targetElement?.data.submodelItems?.wires || []),
                  };
        };
        const handleGroup = (): { elements: TSchemaNode[]; wires: TSchemaConnection[] } => {
            let targetGroup: TSchemaGroup | null;
            if (!groupId) {
                return { elements: [], wires: [] };
            }
            if (!userBlockId) {
                targetGroup = groups?.find((g) => g.id?.toString() === groupId.toString()) || null;
            } else {
                targetGroup = isUserBlockEditor
                    ? groups?.find((g) => g.id?.toString() === groupId.toString()) || null
                    : userBlockGroups?.find((group) => group.id.toString() === groupId.toString()) || null;
            }
            return type === SchemaItemTypes.NODE
                ? {
                      elements: filterNodesByIds(targetGroup?.elements || []),
                      wires: state.schema.selectedItems.wires,
                  }
                : {
                      elements: state.schema.selectedItems.elements,
                      wires: filterConnectionsByIds(targetGroup?.wires || []),
                  };
        };
        const handleUserBlock = (): { elements: TSchemaNode[]; wires: TSchemaConnection[] } => {
            let targetGroup: TSchemaGroup | null;
            if (!groupId) {
                return { elements: [], wires: [] };
            }
            if (isUserBlockEditor) {
                targetGroup = groups?.find((g) => g.id?.toString() === groupId.toString()) || null;
            } else {
                targetGroup = userBlockGroups?.find((group) => group.parentGroupId === null) || null;
            }

            return type === SchemaItemTypes.NODE
                ? {
                      elements: filterNodesByIds(targetGroup?.elements || []),
                      wires: state.schema.selectedItems.wires,
                  }
                : {
                      elements: state.schema.selectedItems.elements,
                      wires: filterConnectionsByIds(targetGroup?.wires || []),
                  };
        };

        const handlers: Partial<Record<WorkspaceModes, () => { elements: TSchemaNode[]; wires: TSchemaConnection[] }>> =
            {
                [WorkspaceModes.MAIN]: handleMain,
                [WorkspaceModes.PREVIEW]: handleMain,
                [WorkspaceModes.DEMO]: handleMain,
                [WorkspaceModes.FSM_EDITOR]: handleFSMEditorAndSubmodel,
                [WorkspaceModes.SUBMODEL]: handleFSMEditorAndSubmodel,
                [WorkspaceModes.GROUP]: handleGroup,
                [WorkspaceModes.USER_BLOCK]: handleUserBlock,
            };

        const handler = handlers[mode];
        if (handler) {
            const selectedItems = handler();
            state.schema.selectedItems = { elements: selectedItems.elements, wires: selectedItems.wires };
        }
    },
    setItemsForSubmodel: (state: IWorkspaceState, action: PayloadAction<{ ids: string[]; type: TSchemaItemType }>) => {
        const { ids, type } = action.payload;
        const { mode, elementId, groupId } = state.meta;
        let nodes: TSchemaNode[] = [];
        let connections: TSchemaConnection[] = [];

        if (type === SchemaItemTypes.NODE) {
            const nodesFilteredMain = state.schema.schemaItems.elements.filter((node: TSchemaNode) =>
                ids.includes(node.id)
            );
            const currentElement = state.schema.schemaItems.elements.find((el: TSchemaNode) => el.id === elementId);

            const nodesFilteredSubmodel =
                currentElement && currentElement.data.submodelItems
                    ? currentElement.data.submodelItems.elements.filter((node: TSchemaNode) => ids.includes(node.id))
                    : [];
            const nodesFiltered = mode === WorkspaceModes.SUBMODEL ? nodesFilteredSubmodel : nodesFilteredMain;

            nodes = ids.length ? nodesFiltered : [];
            connections = state.schema.selectedItems.wires;
        } else if (type === SchemaItemTypes.CONNECTION) {
            const connectionsFiltered = state.schema.schemaItems.wires.filter((connection: TSchemaConnection) =>
                ids.includes(connection.id)
            );
            nodes = state.schema.selectedItems.elements;
            connections = ids.length ? connectionsFiltered : [];
        }

        return {
            ...state,
            schema: {
                ...state.schema,
                itemsForSubmodel: {
                    elements: nodes,
                    wires: connections,
                },
            },
        };
    },
    addElementsForSubmodel: (state: IWorkspaceState, action: PayloadAction<TSchemaNode[]>) => {
        state.schema.itemsForSubmodel.elements.push(...action.payload);
    },
    selectAllItems: (state: IWorkspaceState) => {
        const { elementId, groupId, mode } = state.meta;
        const { elements, wires } = state.schema.schemaItems;
        const groups = state.schema.schemaItems.groups || [];

        const handleGroupAndUserBlock = () => {
            const group = groups.find((g) => g.id.toString() === groupId?.toString());

            if (group) {
                const { elements, wires } = group;
                state.schema.selectedItems = { elements, wires };
            }
        };

        const handlers: Partial<Record<WorkspaceModes, () => void>> = {
            [WorkspaceModes.MAIN]: () => {
                state.schema.selectedItems = { elements, wires };
            },
            [WorkspaceModes.FSM_EDITOR]: () => {
                if (groupId) {
                    const group = groups?.find((group) => group.id.toString() === groupId);
                    const element = group?.elements.find((el) => el.id === elementId);
                    if (element && element.data.submodelItems) {
                        const { elements, wires } = element.data.submodelItems;
                        state.schema.selectedItems = { elements, wires };
                    }
                } else {
                    const items = state.schema.schemaItems.elements.find((el) => el.id === elementId)?.data
                        .submodelItems || { elements: [], wires: [] };
                    state.schema.selectedItems = items;
                }
            },
            [WorkspaceModes.GROUP]: handleGroupAndUserBlock,
            [WorkspaceModes.USER_BLOCK]: handleGroupAndUserBlock,
        };

        const handler = handlers[mode];
        if (handler) {
            handler();
        }
    },
    setSelectedGroup: (
        state: IWorkspaceState,
        action: PayloadAction<{ nodes: TSchemaNode[]; connections: TSchemaConnection[] }>
    ) => {
        state.schema.selectedItems.elements = action.payload.nodes;
        state.schema.selectedItems.wires = action.payload.connections;
    },
};
