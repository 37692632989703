import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { SInputMatrixTableAlert } from '@components/InputMatrixTable/SInputMatrixTable';
import { messages } from '@components/InputMatrixTable/translations';

import { TranslationKey } from '@repeat/translations';
import { Alert, AlertType, IconButton } from '@repeat/ui-kit';

export interface IInputMatrixAlertProps {
    selectionError: boolean;
    setSelectionError: (val: boolean) => void;
    removeRowError: boolean;
    removeColError: boolean;
    setRemoveColError: (val: boolean) => void;
    setRemoveRowError: (val: boolean) => void;
}

export const InputMatrixAlert: FC<IInputMatrixAlertProps> = ({
    selectionError,
    setSelectionError,
    removeRowError,
    removeColError,
    setRemoveRowError,
    setRemoveColError,
}) => {
    const { formatMessage } = useIntl();

    return (
        <SInputMatrixTableAlert>
            {selectionError && (
                <Alert type={AlertType.WARNING}>
                    {formatMessage(messages[TranslationKey.MATRIX_TABLE_WARNING_DELETE])}
                    <IconButton noHover name={'close'} onClick={() => setSelectionError(false)} />
                </Alert>
            )}
            {(removeRowError || removeColError) && (
                <Alert type={AlertType.WARNING}>
                    {formatMessage(messages[TranslationKey.MATRIX_TABLE_WARNING_DELETE_LAST])}
                    <IconButton
                        noHover
                        name={'close'}
                        onClick={() => {
                            setRemoveColError(false);
                            setRemoveRowError(false);
                        }}
                    />
                </Alert>
            )}
        </SInputMatrixTableAlert>
    );
};
