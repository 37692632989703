import { batch } from 'react-redux';
import { Connection } from 'reactflow';

import { AnyAction, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import hash from 'object-hash';

import { TProxyMap } from 'libs/models/src/lib/element';
import { UploadFileTypes } from 'libs/services/src/lib/FileUploadService';

import { ApplicationActions } from '@repeat/common-slices';
import {
    API_ALL_VMS_ARE_BUSY,
    API_BAD_REQUEST_CODE,
    API_INTERNAL_ERROR_CODE,
    CANVAS,
    ELEMENTS_WITH_INITIALIZATION,
    VERSION,
    calculateBlockHeight,
    calculatePortsLength,
    countMaxOfInputsAndOutputs,
    extractNumbers,
    filterParentWires,
    findDefaultPorts,
    findGroups,
    findInnerGroupsProperties,
    findMissingNumbers,
    findMissingNumbersInDescriptions,
    findOuterGroupsForDeletingProperties,
    findOuterGroupsProperties,
    findParameterNameByProxyMap,
    findPortDetails,
    getAppLocale,
    getHandleName,
    getParentParameterName,
    getPortElementLibraryName,
    propertiesSetsFormatting,
    setDefaultPort,
    setParameterDescription,
    setPortIndex,
    setPorts,
    setPortsNamesByNodeId,
    sortParametersByNames,
    updateGroupElementsAfterPortsDeleting,
    updatePortElementPropertiesValues,
    updatePortsParentParameterAfterPortDeleting,
    updateProxyMapWithParameter,
} from '@repeat/constants';
import {
    ElemParams,
    ElemProps,
    IElementConfiguration,
    ILibraryItem,
    INewSchemaState,
    IProjectHashData,
    ITool,
    IWorkspaceState,
    ModalTypes,
    NotificationTypes,
    PortConnectionTypes,
    PortPositions,
    PortTypes,
    SchemaItemTypes,
    SchemaUpdateTypes,
    SolverTypes,
    Statuses,
    TElement,
    TElementConfigurationModal,
    TErrorModal,
    TLibraryType,
    TModelConnection,
    TPortConnectionType,
    TPortTypeComplex,
    TSchemaConnection,
    TSchemaElementWithParams,
    TSchemaGroup,
    TSchemaHandle,
    TSchemaNode,
    TSchemaUpdateType,
    TSolverType,
    TTask,
    WireTypes,
    WorkspaceModes,
} from '@repeat/models';
import {
    ChartsAdapterService,
    ElementsPropertiesService,
    ErrorReportService,
    FMIService,
    FileUploadService,
    ProjectsService,
    SchemaAdapterService,
    TInitializeFMIPayload,
    TUploadedFmuFile,
    prepareBlockData,
    updateSchemaGroupConnections,
} from '@repeat/services';
import { ProjectsActions, stopProject, workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

import { decreaseUserBlocksCount, getFileExtension, increaseUserBlocksCount } from './helper';
import { blockNotificationsSlices } from './schemaSlices/blockNotificationsSlices';
import { ChangeElementSlices } from './schemaSlices/changeElementSlices';
import { copyPasteItemsSlice } from './schemaSlices/copyPasteItemsSlice';
import { ElementInitializationSlices } from './schemaSlices/elementInitializationSlices';
import { externalInfoSlice } from './schemaSlices/externalProjectInfoSlice';
import { GetElementProperties } from './schemaSlices/getElementProperties';
import { GetProjectSlices } from './schemaSlices/getProjectSlices';
import { goToMapSlices } from './schemaSlices/goToMapSlices';
import { SaveProjectSlices } from './schemaSlices/saveProjectsSlices';
import { SelectElementsSlice } from './schemaSlices/selectElementsSlice';
import { setElementParametersSlice } from './schemaSlices/setElementParametersSlice';
import { submodelSlice, updateProjectBlocks } from './schemaSlices/submodelSlice';

import { actions, fetchLibraryItems, fetchLibraryPortTypes } from '..';
import { AppDispatch, RootStateFn } from '../../../store';
import { compareElements } from '../undoredo';
import { getUserBlocks, updateUserBlocks } from '../userBlocks/userBlocksSlice';

const CONNECTION_USDS_PROPS = [
    {
        description: 'Минимальное сопротивление, Ом',
        name: 'minr',
        value: '1e-8',
        editable: true,
    },
];

const CONNECTION_USDS_PARAMS = [
    {
        description: 'Полная составляющая тока в начале',
        name: 'i_i_b',
        modelName: '',
        value: '',
        unit: 'А',
    },
    {
        description: 'Полная составляющая тока в конце',
        name: 'i_i_e',
        modelName: '',
        value: '',
        unit: 'А',
    },
    {
        description: 'Реактивная  составляющая тока в начале',
        name: 'i_ii_b',
        modelName: '',
        value: '',
        unit: 'А',
    },
    {
        description: 'Реактивная составляющая тока в конце',
        name: 'i_ii_e',
        modelName: '',
        value: '',
        unit: 'А',
    },
    {
        description: 'Активная  составляющая тока в начале',
        name: 'i_ir_b',
        modelName: '',
        value: '',
        unit: 'А',
    },
    {
        description: 'Активная составляющая тока в конце',
        name: 'i_ir_e',
        modelName: '',
        value: '',
        unit: 'А',
    },
    {
        description: 'Активная мощность',
        name: 'w_p',
        modelName: '',
        value: '',
        unit: 'Вт',
    },
    {
        description: 'Реактивная мощность',
        name: 'w_q',
        modelName: '',
        value: '',
        unit: 'Вар',
    },
    {
        description: 'Полная мощность',
        name: 'w_s',
        modelName: '',
        value: '',
        unit: 'ВА',
    },
];

const ELEMENT_ELECTROCITY_BUS_TYPE = 'electrocityBus';
const ELEMENT_ADDER_TYPE = 'sum';
const ELEMENT_MULTI_TYPE = 'SWProperties';
const ELEMENT_SELECT_PORT_TYPE = 'AnnularLeakage';
const ELEMENT_CASE_TYPE = '_Case';
const ELEMENTS_WITH_INDEXED_OUTPUT_PORTS = ['branching3', 'SWProperties', 'Demux'];
const ELEMENTS_WITH_INDEXED_INPUT_PORTS = ['sum', 'multiplication', 'MultiLogicOperator', 'MinimumTrueInputEvaluator'];

export const initialState: INewSchemaState = {
    version: null,
    libraryType: null,
    solverType: null,
    rawSchemaItems: {
        elements: [],
        wires: [],
    },
    schemaItems: {
        elements: [],
        wires: [],
        groups: [],
        userBlockGroups: [],
    },
    userBlocksCount: {},
    elementsWithParams: [],
    selectedItems: {
        elements: [],
        wires: [],
    },
    selectedItemProperties: null,
    itemsToCopy: {
        elements: [],
        wires: [],
    },
    goToMap: null,
    blockNotifications: null,
    itemsForSubmodel: {
        elements: [],
        wires: [],
    },
    getProject: {
        status: Statuses.IDLE,
        error: null,
    },
    saveProject: {
        status: Statuses.IDLE,
        error: null,
    },
    getElementPropertiesSets: {
        status: Statuses.IDLE,
        error: null,
        elementType: null,
        libraryType: null,
        solverType: null,
        configurations: [],
    },
    getSubmodelsSchemaItems: {
        status: Statuses.IDLE,
        error: null,
    },
    getProjectsForProjectsBlock: {
        status: Statuses.IDLE,
        error: null,
    },
    getProjectBlock: {
        status: Statuses.IDLE,
        error: null,
        project: null,
    },
    elementInitialization: {
        elementId: null,
        projectId: null,
        uuid: null,
        originalName: null,
        filesize: 0,
        data: null,
        uploadElementSourcesFile: {
            fileSizeBytes: 0,
            uploadedBytes: 0,
            status: Statuses.IDLE,
            error: null,
        },
        initializeElementByFile: {
            status: Statuses.IDLE,
            error: null,
        },
    },
    externalInfo: null,
    proxyMap: null,
};

const addExternalParameter = (state: IWorkspaceState, elementId: string, type: string) => {
    const {
        meta: { mode: workspaceMode },
        schema: {
            schemaItems: { elements },
            externalInfo,
            proxyMap,
        },
        libraryItems: { items },
    } = state;
    if (workspaceMode === WorkspaceModes.SUBMODEL) {
        return;
    }
    let paramsNew;
    let index = 1;
    let indexInDescription = 1;
    const description = getPortElementLibraryName(items, type);

    if (externalInfo) {
        const params = externalInfo?.parameters || [];
        const missedIndexes = findMissingNumbers(params, type);
        const missedIndexesInDescriptions = findMissingNumbersInDescriptions(params, description);
        index = missedIndexes.length === 0 ? params.filter((p) => p.name.includes(type))?.length + 1 : missedIndexes[0];
        indexInDescription =
            missedIndexesInDescriptions.length === 0
                ? params.filter((p) => p.description.includes(description))?.length + 1
                : missedIndexesInDescriptions[0];
        paramsNew = [
            ...(externalInfo?.parameters || []),
            {
                description: `${description}_${indexInDescription}`,
                name: `${type}_${index}`,
                modelName: '',
                unit: '',
                value: '',
            },
        ];

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: updatePortElementPropertiesValues(
                        elements,
                        elementId,
                        type,
                        description,
                        index,
                        indexInDescription
                    ),
                },
                externalInfo: {
                    properties: state.schema.externalInfo?.properties || [],
                    parameters: sortParametersByNames(paramsNew),
                },
                proxyMap: updateProxyMapWithParameter(proxyMap, type, index, elementId),
            },
        };
    }
    paramsNew = [
        {
            description: `${description}_${indexInDescription}`,
            name: `${type}_${index}`,
            modelName: '',
            unit: '',
            value: '',
        },
    ];

    return {
        ...state,
        schema: {
            ...state.schema,
            schemaItems: {
                ...state.schema.schemaItems,
                elements: updatePortElementPropertiesValues(
                    elements,
                    elementId,
                    type,
                    description,
                    index,
                    indexInDescription
                ),
            },
            externalInfo: { properties: [], parameters: sortParametersByNames(paramsNew) },
            proxyMap: updateProxyMapWithParameter(proxyMap, type, index, elementId),
        },
    };
};

const updateGroupBlock = (
    state: IWorkspaceState,
    type: string,
    parameters: ElemParams[],
    proxyMap: TProxyMap | null,
    payloadId: string
) => {
    const {
        libraryItems: { items },
    } = state;

    const description = getPortElementLibraryName(items, type);

    const missedIndexes = findMissingNumbers(parameters, type);
    const missedIndexesInDescriptions = findMissingNumbersInDescriptions(parameters, description);

    const index =
        missedIndexes.length === 0 ? parameters.filter((p) => p.name.includes(type))?.length + 1 : missedIndexes[0];
    const indexInDescription =
        missedIndexesInDescriptions.length === 0
            ? parameters.filter((p) => p.description.includes(description))?.length + 1
            : missedIndexesInDescriptions[0];

    const parameterNew = {
        description: `${description}_${indexInDescription}`,
        name: `${type}_${index}`,
        modelName: '',
        unit: '',
        value: '',
    };

    return {
        parameterNew,
        proxyMap: updateProxyMapWithParameter(proxyMap, type, index, payloadId),
        index,
        indexInDescription,
    };
};

const prepareProjectHashData = (
    elements: TSchemaNode[],
    wires: TSchemaConnection[],
    proxyMap: TProxyMap | null,
    externalInfo: { properties: ElemProps[]; parameters: ElemParams[] } | null
): IProjectHashData => {
    const portIdSplit = (id: string | null | undefined) => {
        if (!id) {
            return {
                elementId: 0,
                portNumber: 0,
                portName: '',
            };
        }

        const data = id.split('-');
        return {
            elementId: Number(data[0]),
            portNumber: extractNumbers(data[2]),
            portName: data[2],
        };
    };

    return {
        data: {
            elements: elements
                .filter((el) => !el.data.isViewOnly)
                .map((node: TSchemaNode) => {
                    const element = node.data;
                    const modelElement = element.proxyMap
                        ? {
                              id: element.id,
                              type: element.type,
                              elemParams: element.elemParams,
                              elemProps: element.elemProps,
                              position: node.position,
                              proxyMap: element.proxyMap,
                          }
                        : {
                              id: element.id,
                              type: element.type,
                              elemParams: element.elemParams,
                              elemProps: element.elemProps,
                              position: node.position,
                          };
                    if (element.uuid) {
                        return { ...modelElement, uuid: element.uuid };
                    }
                    return modelElement;
                }),
            wires: wires.map((connection: TSchemaConnection) => {
                return {
                    id: Number(connection.id),
                    firstElement: Number(connection.source),
                    firstPort: portIdSplit(connection.sourceHandle).portName,
                    secondElement: Number(connection.target),
                    secondPort: portIdSplit(connection.targetHandle).portName,
                    type: connection.data?.type,
                    wireParams: connection.data?.wireParams,
                    wireProps: connection.data?.wireProps,
                } as TModelConnection;
            }),
        },
        proxyMap,
        externalInfo,
    };
};

export const schemaReducers = {
    ...SaveProjectSlices,
    ...GetProjectSlices,
    ...GetElementProperties,
    ...SelectElementsSlice,
    ...ElementInitializationSlices,
    ...copyPasteItemsSlice,
    ...ChangeElementSlices,
    ...setElementParametersSlice,
    ...submodelSlice,
    ...externalInfoSlice,
    ...goToMapSlices,
    ...blockNotificationsSlices,
    // schema actions
    addNode: (state: IWorkspaceState, action: PayloadAction<TSchemaNode>) => {
        const { mode, elementId, groupId } = state.meta;
        const { groups, elements } = state.schema.schemaItems;
        const blockId = action.payload.data.blockId;

        const handleGroupAndUserBlock = () => {
            const groups = state.schema.schemaItems.groups || [];
            const group = groups.find((g) => g.id.toString() === groupId?.toString());
            if (group) {
                group.elements.push(action.payload);
            }
        };

        if (action.payload.data.type === 'userBlock' && blockId) {
            state.schema.userBlocksCount = increaseUserBlocksCount(state.schema.userBlocksCount || {}, [blockId]);
        }

        const handlers: Partial<Record<WorkspaceModes, () => void>> = {
            [WorkspaceModes.MAIN]: () => {
                state.schema.schemaItems.elements.push(action.payload);
                state.schema.elementsWithParams.push({
                    id: action.payload.data.id,
                    blockId: action.payload.data.blockId || null,
                    name: action.payload.data.name,
                    index: action.payload.data.index,
                    elemParams: action.payload.data.elemParams,
                } as TSchemaElementWithParams);
            },
            [WorkspaceModes.FSM_EDITOR]: () => {
                if (groupId) {
                    const group = groups?.find((group) => group.id.toString() === groupId.toString());
                    const element = group?.elements.find((el) => el.id === elementId);
                    if (element) {
                        element.data.submodelItems ??= { elements: [], wires: [] };
                        element.data.submodelItems.elements.push(action.payload);
                    }
                } else {
                    const element = elements.find((el) => el.id === elementId);
                    if (element) {
                        element.data.submodelItems ??= { elements: [], wires: [] };
                        element.data.submodelItems.elements.push(action.payload);
                    }
                }
            },
            [WorkspaceModes.GROUP]: handleGroupAndUserBlock,
            [WorkspaceModes.USER_BLOCK]: handleGroupAndUserBlock,
        };

        const handler = handlers[mode];
        if (handler) {
            handler();
        }
    },
    addPortNode: (state: IWorkspaceState, action: PayloadAction<TSchemaNode>) => {
        const { mode, elementId } = state.meta;

        if (mode === WorkspaceModes.MAIN) {
            const stateUpdated = {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: {
                        ...state.schema.schemaItems,
                        elements: [...state.schema.schemaItems.elements, action.payload],
                    },
                    elementsWithParams: [
                        ...state.schema.elementsWithParams,
                        {
                            id: action.payload.data.id,
                            name: action.payload.data.name,
                            index: action.payload.data.index,
                            elemParams: [...action.payload.data.elemParams],
                        } as TSchemaElementWithParams,
                    ],
                },
            };

            if (action.payload.data.type === 'InPort') {
                return addExternalParameter(stateUpdated, action.payload.id, 'in');
            }
            if (action.payload.data.type === 'OutPort') {
                return addExternalParameter(stateUpdated, action.payload.id, 'out');
            }
            return stateUpdated;
        }
        if ([WorkspaceModes.GROUP, WorkspaceModes.USER_BLOCK].includes(mode)) {
            const groupsState = state.schema.schemaItems.groups || [];
            const libraryItems = state.libraryItems.items;
            const portsTypes = state.libraryPortTypes.items;

            const libraryItem = libraryItems.find((item) => item.type === 'group') || null;

            const { defaultInputPort, defaultOutputPort } = findDefaultPorts(libraryItem);

            const type = action.payload.data.type === 'InPort' ? 'in' : 'out';

            const groupMetaElement = groupsState.find((group) => group.id.toString() === elementId); // группа в массиве групп по мета элементу
            if (!groupMetaElement) {
                return;
            }
            const parentGroupId = groupMetaElement.parentGroupId;
            let parameterAdding;
            let indexInParameterDescription = 0;
            if (parentGroupId === null) {
                // значит искать элемент с типом группа надо на верхнем уровне - в основном массиве
                const elementGroup = state.schema.schemaItems.elements.find((el) => el.id === elementId);

                if (!elementGroup) {
                    return;
                }
                const { parameterNew, proxyMap, indexInDescription } = updateGroupBlock(
                    state,
                    type,
                    elementGroup.data.elemParams,
                    elementGroup.data.proxyMap || null,
                    action.payload.id
                );

                parameterAdding = parameterNew;
                indexInParameterDescription = indexInDescription;
                const elemParams = [...elementGroup.data.elemParams, parameterAdding];
                const inputPortDetails = portsTypes.find((type) => type.type === defaultInputPort.typeConnection) || {
                    ...setDefaultPort(defaultInputPort.name, portsTypes),
                    type: defaultInputPort.typeConnection,
                };
                const outputPortDetails = portsTypes.find((type) => type.type === defaultOutputPort.typeConnection) || {
                    ...setDefaultPort(defaultOutputPort.name, portsTypes),
                    type: defaultOutputPort.typeConnection,
                };

                const availablePorts = setPorts(
                    elemParams,
                    defaultInputPort,
                    defaultOutputPort,
                    elementGroup.data.availablePorts,
                    inputPortDetails,
                    outputPortDetails
                );

                elementGroup.data = {
                    ...elementGroup.data,
                    availablePorts: availablePorts,
                    elemParams,
                    proxyMap,
                };
                elementGroup.height = calculateBlockHeight(availablePorts);

                const elementGroupWithParams = state.schema.elementsWithParams.find(
                    (el) => el.id.toString() === elementId
                );
                if (!elementGroupWithParams) {
                    return;
                }
                elementGroupWithParams.elemParams = [
                    ...elementGroupWithParams.elemParams,
                    parameterAdding as ElemParams,
                ];
            } else {
                // если группа вложенная, т е parentGroupId !== null
                const groupParent = groupsState.find((group) => group.id.toString() === parentGroupId);
                if (!groupParent) {
                    return;
                }
                const elementGroup = groupParent.elements.find((el) => el.id === elementId);
                if (elementGroup) {
                    const { parameterNew, proxyMap, indexInDescription } = updateGroupBlock(
                        state,
                        type,
                        elementGroup.data.elemParams,
                        elementGroup.data.proxyMap || null,
                        action.payload.id
                    );

                    parameterAdding = parameterNew;
                    indexInParameterDescription = indexInDescription;

                    const elemParams = [...elementGroup.data.elemParams, parameterAdding];

                    const inputPortDetails = portsTypes.find(
                        (type) => type.type === defaultInputPort.typeConnection
                    ) || {
                        ...setDefaultPort(defaultInputPort.name, portsTypes),
                        type: defaultInputPort.typeConnection,
                    };
                    const outputPortDetails = portsTypes.find(
                        (type) => type.type === defaultOutputPort.typeConnection
                    ) || {
                        ...setDefaultPort(defaultOutputPort.name, portsTypes),
                        type: defaultOutputPort.typeConnection,
                    };

                    const availablePorts = setPorts(
                        elemParams,
                        defaultInputPort,
                        defaultOutputPort,
                        elementGroup.data.availablePorts,
                        inputPortDetails,
                        outputPortDetails
                    );
                    //

                    elementGroup.data = {
                        ...elementGroup.data,
                        availablePorts: availablePorts,
                        elemParams,
                        proxyMap,
                    };
                    elementGroup.height = calculateBlockHeight(availablePorts);
                }
            }
            groupMetaElement.elements = updatePortElementPropertiesValues(
                [...groupMetaElement.elements, { ...action.payload, data: { ...action.payload.data, index: '' } }],
                action.payload.id,
                type,
                getPortElementLibraryName(libraryItems, type),
                setPortIndex(groupMetaElement.elements, action.payload.data.type),
                indexInParameterDescription
            );
        }
        return state;
    },
    deleteNode: (state: IWorkspaceState) => {
        const selectedElement = state.schema.selectedItems.elements[0];
        if (!selectedElement) return;

        state.schema.schemaItems.elements = state.schema.schemaItems.elements.filter(
            (el) => el.data.id !== selectedElement.data.id
        );
        state.schema.elementsWithParams = state.schema.elementsWithParams.filter(
            (el) => el.id !== selectedElement.data.id
        );
    },
    addConnection: (state: IWorkspaceState, action: PayloadAction<TSchemaConnection>) => {
        const solverType = state.schema.solverType;
        const portTypes = state.libraryPortTypes.items;
        const wires = [...state.schema.schemaItems.wires];
        const { mode, elementId, groupId } = state.meta;
        const groups = state.schema.schemaItems.groups || [];

        const getMaxIndex = (a: number, connection: TSchemaConnection) => {
            const b = extractNumbers(connection?.data?.index || '');

            return Math.max(a, b);
        };
        const maxIndexNumber = wires.reduce(getMaxIndex, 0);
        const newIndexNumber = maxIndexNumber + 1;

        const schemaConnection = {
            ...action.payload,
            data: {
                id: Number(action.payload.id),
                type: WireTypes.WIRE,
                index: `w${newIndexNumber}`,
                wireProps: solverType === SolverTypes.USDS ? CONNECTION_USDS_PROPS : [],
                wireParams: solverType === SolverTypes.USDS ? CONNECTION_USDS_PARAMS : [],
            },
        };

        const getDefaultType = (portBlock: TSchemaNode) => {
            return portBlock.data.type === 'OutPort'
                ? PortConnectionTypes.DEFAULT_OUTPUT
                : PortConnectionTypes.DEFAULT_INPUT;
        };
        const parentGroupId = state.schema.schemaItems.groups?.find(
            (group) => group.id.toString() === groupId
        )?.parentGroupId;
        const typeConnectionByPortName: { [name: string]: TPortConnectionType } = {};

        const handleGroupAndUserBlock = () => {
            const group = groups.find((g) => g.id.toString() === groupId?.toString());
            if (group) {
                const groupElements = group.elements;
                const findElementTypeById = (elementId: string, elements: TSchemaNode[]) => {
                    return elements.find((el) => el.id === elementId)?.data.type;
                };
                const sourceType = findElementTypeById(schemaConnection.source, groupElements) || '';
                const targetType = findElementTypeById(schemaConnection.target, groupElements) || '';
                const portsTypes = ['InPort', 'OutPort'];
                if (portsTypes.includes(sourceType)) {
                    const portBlock = groupElements.find((el) => el.id === schemaConnection.source);
                    if (portBlock) {
                        const parentParameterValue = getParentParameterName(portBlock);

                        const connectedElement = groupElements.find((el) => el.id === schemaConnection.target);
                        const defaultType = getDefaultType(portBlock);

                        if (connectedElement) {
                            const connectedElementPortType =
                                connectedElement.data.availablePorts.find(
                                    (port) => port.name === getHandleName(schemaConnection.targetHandle)
                                )?.typeConnection || defaultType;
                            typeConnectionByPortName[parentParameterValue] = connectedElementPortType;
                        }
                    }
                }
                if (portsTypes.includes(targetType)) {
                    const portBlock = groupElements.find((el) => el.id === schemaConnection.target);
                    if (portBlock) {
                        const parentParameterValue = getParentParameterName(portBlock);

                        const connectedElement = groupElements.find((el) => el.id === schemaConnection.source);
                        const defaultType = getDefaultType(portBlock);

                        if (connectedElement) {
                            const connectedElementPortType =
                                connectedElement.data.availablePorts.find(
                                    (port) => port.name === getHandleName(schemaConnection.sourceHandle)
                                )?.typeConnection || defaultType;
                            typeConnectionByPortName[parentParameterValue] = connectedElementPortType;
                        }
                    }
                }
                group.wires.push(schemaConnection);
            }
        };

        const handlers: Partial<Record<WorkspaceModes, () => void>> = {
            [WorkspaceModes.MAIN]: () => {
                state.schema.schemaItems.wires.push(schemaConnection);
            },
            [WorkspaceModes.FSM_EDITOR]: () => {
                if (groupId) {
                    const group = groups?.find((group) => group.id.toString() === groupId.toString());
                    const element = group?.elements.find((el) => el.id === elementId);
                    if (element) {
                        element.data.submodelItems ??= { elements: [], wires: [] };
                        element.data.submodelItems.wires.push(schemaConnection);
                    }
                } else {
                    const element = state.schema.schemaItems.elements.find((el) => el.id === elementId);
                    if (element) {
                        element.data.submodelItems ??= { elements: [], wires: [] };
                        element.data.submodelItems.wires.push(schemaConnection);
                    }
                }
            },
            [WorkspaceModes.GROUP]: handleGroupAndUserBlock,
            [WorkspaceModes.USER_BLOCK]: handleGroupAndUserBlock,
        };

        const handler = handlers[mode];
        if (handler) {
            handler();
        }

        if (!parentGroupId) {
            const element = state.schema.schemaItems.elements.find((el) => el.id === groupId);
            if (element) {
                element.data.availablePorts = element.data.availablePorts.map((port) => {
                    if (port.name === Object.keys(typeConnectionByPortName)[0]) {
                        const typeConnection = Object.values(typeConnectionByPortName)[0];
                        const { compatibleTypes, direction } = findPortDetails(portTypes, typeConnection, port.name);
                        return { ...port, typeConnection, compatibleTypes, direction };
                    }
                    return port;
                });
            }
        }
    },
    updateSchema: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ schema: INewSchemaState; type: TSchemaUpdateType }>
    ) => {
        state.schema = payload.schema;
    },
    updateNodesPositions: (state: IWorkspaceState, { payload }: PayloadAction<TSchemaNode[]>) => {
        const { mode, elementId, groupId } = state.meta;
        const groups = state.schema.schemaItems.groups || [];

        const updateElements = (elements: TSchemaNode[]) => {
            const changedElementsIds = payload.map((item) => item.id);
            return elements.map((item) => {
                if (changedElementsIds.includes(item.id)) {
                    const cItem = payload.find((fItem) => fItem && item.id === fItem.id);
                    return {
                        ...item,
                        position: cItem ? cItem.position : item.position,
                    };
                }
                return item;
            });
        };

        const handleGroupAndUserBlock = () => {
            const groups = state.schema.schemaItems.groups || [];
            const group = groups.find((g) => g.id.toString() === groupId?.toString());
            if (group) {
                group.elements = updateElements(group.elements);
            }
        };

        const handlers: Partial<Record<WorkspaceModes, () => void>> = {
            [WorkspaceModes.MAIN]: () => {
                state.schema.schemaItems.elements = updateElements(state.schema.schemaItems.elements);
            },
            [WorkspaceModes.FSM_EDITOR]: () => {
                if (groupId) {
                    const group = groups.find((group) => group.id.toString() === groupId.toString());
                    const element = group?.elements.find((el) => el.id === elementId);
                    if (element) {
                        element.data.submodelItems ??= { elements: [], wires: [] };
                        element.data.submodelItems.elements = updateElements(element.data.submodelItems.elements);
                    }
                } else {
                    const element = state.schema.schemaItems.elements.find((el) => el.id === elementId);
                    if (element) {
                        element.data.submodelItems ??= { elements: [], wires: [] };
                        element.data.submodelItems.elements = updateElements(element.data.submodelItems.elements);
                    }
                }
            },
            [WorkspaceModes.GROUP]: handleGroupAndUserBlock,
            [WorkspaceModes.USER_BLOCK]: handleGroupAndUserBlock,
        };

        const handler = handlers[mode];
        if (handler) {
            handler();
        }
    },
    deleteSchemaItems: (state: IWorkspaceState) => {
        const selectedItems = state.schema.selectedItems;
        const { mode: workspaceMode, elementId, userBlockId } = state.meta;
        const groups = state.schema.schemaItems.groups || [];
        const wires = state.schema.schemaItems.wires;
        let userBlocksCount = state.schema.userBlocksCount || {};
        const userBlocksIds = selectedItems.elements
            .filter((item) => item.data.type === 'userBlock' && item.data.blockId)
            .map((item) => item.data.blockId || '');

        if (userBlocksIds.length !== 0) {
            userBlocksCount = decreaseUserBlocksCount({ ...userBlocksCount }, userBlocksIds);
        }

        const selectedElementsIds = selectedItems.elements.map((el) => el.id);

        const setWiresAfterDeletion = (
            selectedItems: { elements: TSchemaNode[]; wires: TSchemaConnection[] },
            wires: TSchemaConnection[]
        ) => {
            const newWires = wires.filter((x) => !selectedItems.wires.some((y) => x.id === y.id));

            const wiresToDelete: TSchemaConnection[] = [];

            newWires.map((wire: TSchemaConnection) => {
                selectedItems.elements.map((el) => {
                    if (wire.source === el.id || wire.target === el.id) {
                        wiresToDelete.push(wire);
                    }
                });
            });

            return newWires.filter((x: TSchemaConnection) => !wiresToDelete.some((y) => x.id === y.id));
        };

        if (workspaceMode === WorkspaceModes.FSM_EDITOR) {
            const elements = state.schema.schemaItems.elements.map((el) => {
                if (el.id === elementId) {
                    const submodelItems = el.data.submodelItems || { elements: [], wires: [] };
                    const elements = submodelItems.elements.filter(
                        (x) => !selectedItems.elements.some((y) => x.id === y.id)
                    );
                    const wires = setWiresAfterDeletion(selectedItems, submodelItems.wires);
                    return { ...el, data: { ...el.data, submodelItems: { ...submodelItems, elements, wires } } };
                }
                return el;
            });
            return {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: {
                        ...state.schema.schemaItems,
                        elements,
                    },
                    selectedItems: initialState.selectedItems,
                },
            };
        }

        const portsBlocks = selectedItems.elements.filter(
            (el) => el.data.type === 'InPort' || el.data.type === 'OutPort'
        );
        const parametersForDelete: string[] = [];
        const portsBlocksIds = portsBlocks.map((el) => el.data.id);

        if (portsBlocks.length !== 0) {
            portsBlocks.forEach((block) => {
                parametersForDelete.push(getParentParameterName(block));
            });
        }

        const parametersToDeleteByGroupIds: { [key: string]: string[] } = {};
        const blocksToDeleteIdsByGroupIds: { [key: string]: number[] } = {};
        if (elementId) {
            if (elementId in blocksToDeleteIdsByGroupIds) {
                blocksToDeleteIdsByGroupIds[elementId].push(...portsBlocksIds);
                parametersToDeleteByGroupIds[elementId].push(...parametersForDelete);
            } else {
                blocksToDeleteIdsByGroupIds[elementId] = portsBlocksIds;
                parametersToDeleteByGroupIds[elementId] = parametersForDelete;
            }
        }

        let groupsUpdated: TSchemaGroup[] = [];
        let wiresMainFiltered: TSchemaConnection[] = state.schema.schemaItems.wires;
        if ([WorkspaceModes.GROUP, WorkspaceModes.USER_BLOCK].includes(workspaceMode)) {
            const parentGroupId = groups.find((group) => group.id.toString() === elementId)?.parentGroupId;

            if (parentGroupId === null) {
                wiresMainFiltered = filterParentWires(
                    wiresMainFiltered,
                    state.schema.schemaItems.elements,
                    elementId,
                    portsBlocksIds
                );
            }

            groupsUpdated = groups
                .map((group) => {
                    if (group.id.toString() === elementId) {
                        const elements = group.elements.filter(
                            (x) => !selectedItems.elements.some((y) => x.id === y.id)
                        );

                        const inPortElements = updatePortsParentParameterAfterPortDeleting(elements, 'InPort');

                        const outPortElements = updatePortsParentParameterAfterPortDeleting(elements, 'OutPort');

                        const elementsOthers = elements.filter(
                            (el) => el.data.type !== 'InPort' && el.data.type !== 'OutPort'
                        );

                        const wires = setWiresAfterDeletion(selectedItems, group.wires);

                        return {
                            ...group,
                            elements: [...elementsOthers, ...inPortElements, ...outPortElements],
                            wires,
                        };
                    }

                    if (group.id.toString() === parentGroupId) {
                        const wires = filterParentWires(group.wires, group.elements, elementId, portsBlocksIds);
                        const wiresForDelete = group.wires.filter(
                            (x: TSchemaConnection) => !wires.some((y) => x.id === y.id)
                        );

                        const portsNamesByNodeId = setPortsNamesByNodeId(wiresForDelete);

                        const elements = group.elements.map((el) => {
                            if (el.id === elementId) {
                                // блок Группа, который редактируем
                                const newByOldParamNames: { [key: string]: string } = {};

                                const parametersForDelete = blocksToDeleteIdsByGroupIds[el.id].map(
                                    (id) => el.data.proxyMap && findParameterNameByProxyMap(el.data.proxyMap, id)
                                );
                                const elemParams = el.data.elemParams.filter(
                                    (param) => !parametersForDelete.includes(param.name)
                                );

                                const availablePorts = el.data.availablePorts.filter(
                                    (port) => !parametersForDelete.includes(port.name)
                                );

                                const proxyMap = el.data.proxyMap
                                    ? {
                                          ...el.data.proxyMap,
                                          params: el.data.proxyMap.params.filter(
                                              (param) => !portsBlocksIds.includes(param.internalBlockId)
                                          ),
                                      }
                                    : { params: [], props: [] };

                                const height = calculateBlockHeight(availablePorts);

                                return {
                                    ...el,
                                    data: { ...el.data, elemParams, availablePorts, proxyMap },
                                    height,
                                };
                            } else {
                                if (el.id in portsNamesByNodeId) {
                                    const availablePorts = el.data.availablePorts.map((port) => {
                                        if (portsNamesByNodeId[el.id].includes(port.name)) {
                                            return { ...port, isConnected: false };
                                        }
                                        return port;
                                    });
                                    return { ...el, data: { ...el.data, availablePorts } };
                                }
                            }
                            return el;
                        });

                        // wires --- ????

                        return { ...group, elements, wires };
                    }

                    return group;
                })
                .filter((group) => !selectedItems.elements.some((el) => group.id.toString() === el.id));
        } else {
            const innerGroups: TSchemaGroup[] = [];
            const selectedGroups = groups
                ? groups.filter((el) => selectedItems.elements.map((el) => el.id).includes(el.id.toString()))
                : [];
            if (groups) {
                selectedGroups?.forEach((group) => innerGroups.push(...findGroups(group, groups)));
            }
            const groupsElementsToDelete = [...selectedGroups, ...innerGroups]
                .map((group) => [...group.elements])
                .flat();

            const userBlocksIds = groupsElementsToDelete
                .filter((item) => item.data.type === 'userBlock' && item.data.blockId)
                .map((item) => item.data.blockId || '');
            if (userBlocksIds.length !== 0) {
                userBlocksCount = decreaseUserBlocksCount({ ...userBlocksCount }, userBlocksIds);
            }

            groupsUpdated = groups
                ? groups.filter((x) => ![...selectedGroups, ...innerGroups].some((y) => x.id === y.id))
                : [];
        }
        const externalParameters =
            state.schema.externalInfo?.parameters !== undefined
                ? state.schema.externalInfo.parameters.filter((p) => !parametersForDelete.includes(p.name))
                : [];

        const externalParametersInput = externalParameters.filter((param) => param.name.includes('in'));
        const externalParametersOutput = externalParameters.filter((param) => param.name.includes('out'));

        const inputsUpd = externalParametersInput.map((p, index) => ({ ...p, name: `in_${index + 1}` }));
        const outputsUpd = externalParametersOutput.map((p, index) => ({ ...p, name: `out_${index + 1}` }));
        const upd = [...inputsUpd, ...outputsUpd];
        const externalProperties = state.schema.externalInfo
            ? state.schema.externalInfo.properties.filter(
                  (prop) => !selectedElementsIds.includes(prop.name.split('-')[1])
              )
            : [];

        const externalInfo = { properties: externalProperties, parameters: upd };

        const proxyMapParams = state.schema.proxyMap?.params.filter((p) => !parametersForDelete.includes(p.name)) || [];
        const proxyMapParamsInputsUpd = proxyMapParams
            .filter((p) => p.name.includes('in'))
            .map((p, index) => ({ ...p, name: `in_${index + 1}` }));
        const proxyMapParamsOutputUpd = proxyMapParams
            .filter((p) => p.name.includes('out'))
            .map((p, index) => ({ ...p, name: `out_${index + 1}` }));
        const proxyMapParamsUpd = [...proxyMapParamsInputsUpd, ...proxyMapParamsOutputUpd];

        const proxyMapProps = state.schema.proxyMap
            ? state.schema.proxyMap.props.filter(
                  (prop) => !selectedElementsIds.includes(prop.internalBlockId.toString())
              )
            : [];

        const proxyMap = { props: proxyMapProps, params: proxyMapParamsUpd };
        const newElements = state.schema.schemaItems.elements.filter(
            (x) => !selectedItems.elements.some((y) => x.id === y.id)
        );
        const newElementsWithParams = state.schema.elementsWithParams
            .filter((x) => !selectedItems.elements.some((y) => x.id === y.data.id))
            .map((el) => {
                if (el.id.toString() in parametersToDeleteByGroupIds) {
                    const elemParams = el.elemParams.filter(
                        (param) => !parametersToDeleteByGroupIds[el.id].includes(param.name)
                    );
                    return { ...el, elemParams };
                }
                return el;
            });
        const resultWires = setWiresAfterDeletion(selectedItems, wiresMainFiltered);
        const wiresForDelete = wires.filter((x: TSchemaConnection) => !resultWires.some((y) => x.id === y.id));

        const portsNamesByNodeId = setPortsNamesByNodeId(wiresForDelete);

        const { elements } = compareElements({
            oldList: state.schema.schemaItems.elements,
            newList: newElements,
        });
        const inPortElements = updatePortsParentParameterAfterPortDeleting(elements, 'InPort');

        const outPortElements = updatePortsParentParameterAfterPortDeleting(elements, 'OutPort');

        const elementsOthers = elements.filter((el) => el.data.type !== 'InPort' && el.data.type !== 'OutPort');

        // обновляем элементы-группы --- удаляем параметры, порты и прокси мап элемента, если этот элемент - группа --- судя по parametersToDeleteByGroupIds
        const outerGroups = findOuterGroupsForDeletingProperties(elementId, groups);
        const updatedElements = updateGroupElementsAfterPortsDeleting(
            [...elementsOthers, ...inPortElements, ...outPortElements],
            parametersToDeleteByGroupIds,
            portsBlocksIds
        ).map((el) => {
            if (el.id in portsNamesByNodeId) {
                const availablePorts = el.data.availablePorts.map((port) => {
                    if (portsNamesByNodeId[el.id].includes(port.name)) {
                        return { ...port, isConnected: false };
                    }
                    return port;
                });
                return { ...el, data: { ...el.data, availablePorts } };
            }
            const currentElement = outerGroups['MAIN'];
            if (currentElement) {
                const elemProps = el.data.elemProps.filter(
                    (prop) => !selectedElementsIds.some((id) => prop.name.includes(id))
                );
                const proxyMap = el.data.proxyMap
                    ? {
                          ...el.data.proxyMap,
                          props: el.data.proxyMap?.props.filter(
                              (prop) => !selectedElementsIds.some((id) => prop.name.includes(id))
                          ),
                      }
                    : { params: [], props: [] };
                return { ...el, data: { ...el.data, elemProps, proxyMap } };
            }
            return el;
        });

        const groupsRes = groupsUpdated.map((group) => {
            const currentGroup = outerGroups[group.id];
            if (currentGroup) {
                const elements = group.elements.map((el) => {
                    if (el.id === currentGroup) {
                        const elemProps = el.data.elemProps.filter(
                            (prop) => !selectedElementsIds.some((id) => prop.name.includes(id))
                        );

                        const proxyMap = el.data.proxyMap
                            ? {
                                  ...el.data.proxyMap,
                                  props: el.data.proxyMap?.props.filter(
                                      (prop) => !selectedElementsIds.some((id) => prop.name.includes(id))
                                  ),
                              }
                            : { params: [], props: [] };

                        return { ...el, data: { ...el.data, elemProps, proxyMap } };
                    }
                    return el;
                });
                return { ...group, elements };
            }
            return group;
        });

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    elements: updatedElements,
                    wires: resultWires,
                    groups: groupsRes,
                },
                userBlocksCount,
                selectedItems: initialState.selectedItems,
                elementsWithParams: newElementsWithParams,
                externalInfo,
                proxyMap,
            },
        };
    },
    deleteSchema: (state: IWorkspaceState) => ({
        ...state,
        schema: {
            ...state.schema,
            schemaItems: initialState.schemaItems,
            selectedItems: initialState.selectedItems,
            elementsWithParams: initialState.elementsWithParams,
        },
    }),

    updateNodesAndConnections: (
        state: IWorkspaceState,
        {
            payload,
        }: PayloadAction<{
            nodes: TSchemaNode[];
            connections: TSchemaConnection[];
            elementsWithParams?: TSchemaElementWithParams[];
        }>
    ) => {
        const { groupId, elementId, mode } = state.meta;
        const groups = state.schema.schemaItems.groups || [];

        const handleGroupAndUserBlock = () => {
            const group = groups.find((g) => g.id.toString() === groupId?.toString());
            if (group) {
                group.elements = payload.nodes;
                group.wires = payload.connections;
            }
        };
          const handleCodeEditor = () => {
              const group = groups.find((g) => g.id.toString() === groupId?.toString());
              if (group) {
                  group.elements = payload.nodes;
                  group.wires = payload.connections;
              } else {
                state.schema.schemaItems.elements = payload.nodes;
                state.schema.schemaItems.wires = payload.connections;
                state.schema.elementsWithParams = payload.elementsWithParams || [];
              }
          };

        const handlers: Partial<Record<WorkspaceModes, () => void>> = {
            [WorkspaceModes.MAIN]: () => {
                state.schema.schemaItems.elements = payload.nodes;
                state.schema.schemaItems.wires = payload.connections;
                state.schema.elementsWithParams = payload.elementsWithParams || [];
            },
            [WorkspaceModes.GROUP]: handleGroupAndUserBlock,
            [WorkspaceModes.USER_BLOCK]: handleGroupAndUserBlock,
            [WorkspaceModes.CODE_EDITOR]: handleCodeEditor,
        };

        const handler = handlers[mode];
        if (handler) {
            handler();
        }
    },
    setElementPropertiesValues: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ id: number; elemProps: { [key: string]: string } }>
    ) => {
        if (!Object.keys(payload.elemProps).length) {
            return;
        }

        let selectedElementProps: ElemProps[] = [];
        const metaElementId = state.meta.elementId;
        const metaGroupId = state.meta.groupId;
        const workspaceMode = state.meta.mode;

        const groupsState = state.schema.schemaItems.groups || [];
        const propertyName = Object.keys(payload.elemProps)[0];
        const value = Object.values(payload.elemProps)[0];
        const groupsWithElementsProperties = findInnerGroupsProperties(groupsState, propertyName);

        const groupsWithOuterGroupsProperties = findOuterGroupsProperties(payload, metaElementId, groupsState);

        const parentGroupBlockId = groupsState.find((group) => group.id.toString() === metaElementId)?.parentGroupId;

        const setPropertiesValues = (nodes: TSchemaNode[]) => {
            return nodes.map((node: TSchemaNode) => {
                if (node.data.id === payload.id) {
                    const propertiesWithDependencies = node.data.elemProps
                        .filter((property: ElemProps) => !!property?.dependency)
                        .map((property: ElemProps) => property.name);

                    const keys = Object.keys(payload.elemProps);
                    const newProps = node.data.elemProps.map((property: ElemProps) => {
                        for (let i = 0; i <= keys.length; i++) {
                            if (property.name === keys[i]) {
                                return {
                                    ...property,
                                    value: payload.elemProps[keys[i]].toString(),
                                };
                            }
                        }
                        if (
                            property?.dependency &&
                            payload.elemProps[property.dependency] &&
                            propertiesWithDependencies.includes(property.name)
                        ) {
                            return {
                                ...property,
                                value: '',
                            };
                        }

                        return property;
                    });
                    selectedElementProps = newProps;

                    return {
                        ...node,
                        data: {
                            ...node.data,
                            elemProps: newProps,
                        },
                    };
                }
                return node;
            });
        };
        if (workspaceMode === WorkspaceModes.FSM_EDITOR) {
            const setFSMPropertiesValues = (elementsArray: TSchemaNode[]) => {
                let elements = [];
                if (Object.keys(payload.elemProps)[0] === 'variables') {
                    elements = setPropertiesValues(elementsArray);
                } else {
                    elements = elementsArray.map((el) => {
                        if (el.id === metaElementId) {
                            const submodelItems = el.data.submodelItems || { elements: [], wires: [] };
                            return {
                                ...el,
                                data: {
                                    ...el.data,
                                    submodelItems: {
                                        ...submodelItems,
                                        elements: setPropertiesValues(submodelItems.elements),
                                    },
                                },
                            };
                        }
                        return el;
                    });
                }
                return elements;
            };

            if (metaGroupId) {
                const groups = state.schema.schemaItems.groups?.map((group) => {
                    if (group.id.toString() === metaGroupId) {
                        return { ...group, elements: setFSMPropertiesValues(group.elements) };
                    }
                    return group;
                });
                return {
                    ...state,
                    schema: {
                        ...state.schema,
                        schemaItems: {
                            ...state.schema.schemaItems,
                            groups,
                        },
                    },
                };
            }
            const elements = setFSMPropertiesValues(state.schema.schemaItems.elements);

            return {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: {
                        ...state.schema.schemaItems,
                        elements,
                    },
                },
            };
        }
        let groupsUpd = groupsState;
        let elementsUpd = state.schema.schemaItems.elements;
        let elementsWithParams = state.schema.elementsWithParams;

        if (Object.keys(payload.elemProps)[0] === 'userParameterName') {
            if (metaElementId && parentGroupBlockId === null) {
                const currentGroup = groupsState.find((group) => group.id.toString() === metaElementId);
                if (currentGroup) {
                    const elementPayload = currentGroup.elements.find((el) => el.id === payload.id.toString());
                    if (elementPayload) {
                        const parentParameter =
                            elementPayload.data.elemProps
                                .find((prop) => prop.name === 'parentParameter')
                                ?.value.toString() || '';
                        elementsUpd = elementsUpd.map((el) => {
                            if (el.id === metaElementId) {
                                return {
                                    ...el,
                                    data: {
                                        ...el.data,
                                        elemParams: setParameterDescription(
                                            el.data.elemParams,
                                            el.data.proxyMap,
                                            payload.id,
                                            Object.values(payload.elemProps)[0]
                                        ),
                                    },
                                };
                            }
                            return el;
                        });

                        elementsWithParams = elementsWithParams.map((el) => {
                            const element = elementsUpd.find((item) => item.id === el.id.toString());
                            if (element) {
                                return {
                                    ...el,
                                    elemParams: element.data.elemParams,
                                };
                            }
                            return el;
                        });
                    }
                }
            }

            if (metaElementId && parentGroupBlockId !== null) {
                const parentGroup = groupsState.find((group) => group.id.toString() === parentGroupBlockId);
                const currentGroup = groupsState.find((group) => group.id.toString() === metaElementId);

                if (parentGroup && currentGroup) {
                    const elementPayload = currentGroup.elements.find((el) => el.id === payload.id.toString());

                    const parentParameter =
                        elementPayload?.data.elemProps
                            .find((prop) => prop.name === 'parentParameter')
                            ?.value.toString() || '';

                    const groupElements = parentGroup.elements.map((el) => {
                        if (el.id === metaElementId) {
                            return {
                                ...el,
                                data: {
                                    ...el.data,
                                    elemParams: setParameterDescription(
                                        el.data.elemParams,
                                        el.data.proxyMap,
                                        payload.id,
                                        Object.values(payload.elemProps)[0]
                                    ),
                                },
                            };
                        }
                        return el;
                    });

                    groupsUpd = groupsUpd.map((group) => {
                        if (group.id === parentGroup.id) {
                            return { ...group, elements: groupElements };
                        }
                        return group;
                    });
                }
            }
        }

        const nodesTmp = setPropertiesValues(elementsUpd);
        const nodes = nodesTmp.map((node) => {
            if (
                groupsWithOuterGroupsProperties['MAIN'] &&
                groupsWithOuterGroupsProperties['MAIN'].elementId === node.id
            ) {
                const elemProps = node.data.elemProps.map((prop) => {
                    if (prop.name.includes(groupsWithOuterGroupsProperties['MAIN'].propertyName)) {
                        return { ...prop, value };
                    }
                    return prop;
                });
                return { ...node, data: { ...node.data, elemProps } };
            }
            return node;
        });

        const groups = groupsUpd.map((group: TSchemaGroup) => {
            if (
                metaElementId === group.id.toString() ||
                (workspaceMode === WorkspaceModes.CODE_EDITOR && metaGroupId === group.id.toString())
            ) {
                const elements = setPropertiesValues(group.elements);
                return { ...group, elements };
            }
            if (payload.id.toString() === group.id.toString()) {
                const [propName, elementId] = Object.keys(payload.elemProps)[0].split('-');
                const elements = group.elements.map((el) => {
                    if (el.id === elementId) {
                        const elemProps = el.data.elemProps.map((prop) => {
                            if (prop.name === propName) {
                                return { ...prop, value: Object.values(payload.elemProps)[0] };
                            }
                            return prop;
                        });
                        return { ...el, data: { ...el.data, elemProps } };
                    }
                    return el;
                });
                return { ...group, elements };
            }
            return group;
        });

        /// ПРОКИДЫВАЕМ ВНУТРЬ ДО ЭЛЕМЕНТА ЗНАЧЕНИЕ СВОЙСТВА
        const groupsRes = groups.map((group) => {
            if (groupsWithElementsProperties[group.id.toString()]) {
                const elements = group.elements.map((el) => {
                    if (el.id === groupsWithElementsProperties[group.id.toString()].elementId) {
                        const elemProps = el.data.elemProps.map((prop) => {
                            if (prop.name === groupsWithElementsProperties[group.id.toString()].propertyName) {
                                return { ...prop, value };
                            }
                            return prop;
                        });
                        return { ...el, data: { ...el.data, elemProps } };
                    }
                    return el;
                });
                return { ...group, elements };
            }
            const parentGroupId = groupsWithOuterGroupsProperties[group.id.toString()];
            if (parentGroupId) {
                const elements = group.elements.map((el) => {
                    if (el.id === parentGroupId.elementId) {
                        const elemProps = el.data.elemProps.map((prop) => {
                            if (prop.name.includes(parentGroupId.propertyName)) {
                                return { ...prop, value };
                            }
                            return prop;
                        });
                        return { ...el, data: { ...el.data, elemProps } };
                    }
                    return el;
                });
                return { ...group, elements };
            }
            return group;
        });

        const selectedElement = state.schema.selectedItems.elements[0];
        return {
            ...state,
            schema: {
                ...state.schema,
                elementsWithParams,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: nodes,
                    groups: groupsRes,
                },
                selectedItems: {
                    ...state.schema.selectedItems,
                    elements: [
                        { ...selectedElement, data: { ...selectedElement?.data, elemProps: selectedElementProps } },
                    ],
                },
            },
        };
    },
    addProjectBlock: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ node: TSchemaNode; elemProps: { [key: string]: string } }>
    ) => {
        if (!Object.keys(payload.elemProps).length) {
            return;
        }

        let projectBlock = payload.node;

        const keys = Object.keys(payload.elemProps);
        const newProps = projectBlock.data.elemProps.map((option: ElemProps) => {
            for (let i = 0; i <= keys.length; i++) {
                if (option.name === keys[i]) {
                    return {
                        ...option,
                        value: payload.elemProps[keys[i]].toString(),
                    };
                }
            }
            return option;
        });

        projectBlock = {
            ...projectBlock,
            data: {
                ...projectBlock.data,
                elemProps: newProps,
            },
        };

        const { mode, elementId } = state.meta;
        let userBlocksCount = state.schema.userBlocksCount || {};
        const blockId = projectBlock.data.blockId;
        if (projectBlock.data.type === 'userBlock' && blockId) {
            userBlocksCount = increaseUserBlocksCount({ ...userBlocksCount }, [blockId]);
        }
        state.schema.userBlocksCount = userBlocksCount;
        if (mode === WorkspaceModes.MAIN) {
            state.schema.schemaItems.elements.push(projectBlock);
            state.schema.elementsWithParams.push({
                id: projectBlock.data.id,
                blockId: projectBlock.data.blockId || null,
                name: projectBlock.data.name,
                index: projectBlock.data.index,
                elemParams: [...projectBlock.data.elemParams],
            } as TSchemaElementWithParams);
        } else {
            const groups = state.schema.schemaItems.groups
                ? state.schema.schemaItems.groups.map((group: TSchemaGroup) => {
                      if (group.id.toString() === elementId) {
                          return { ...group, elements: [...group.elements, projectBlock] };
                      }
                      return group;
                  })
                : [];
            state.schema.schemaItems.groups = groups;
        }
    },
    setElementParameters: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ [key: string]: { index: number; params: ElemParams[] } }>
    ) => {
        const parametersByGroupId: { [key: string]: ElemParams[] } = {};

        const elementsWithParams = state.schema.elementsWithParams.map((element: TSchemaElementWithParams) => {
            const keys = Object.keys(payload);
            for (let i = 0; i <= keys.length; i++) {
                if (element.id === Number(keys[i])) {
                    return {
                        ...element,
                        elemParams: payload[keys[i]].params,
                    };
                }
            }
            return element;
        });

        const groups = state.schema.schemaItems.groups?.map((group) => {
            const elements = group.elements.map((element: TSchemaNode) => {
                const keys = Object.keys(payload);
                for (let i = 0; i <= keys.length; i++) {
                    if (element.data.id === Number(keys[i])) {
                        return {
                            ...element,
                            data: { ...element.data, elemParams: payload[keys[i]].params },
                        };
                    }
                }
                return element;
            });
            return { ...group, elements };
        });
        state.schema.schemaItems.groups = groups;
        state.schema.elementsWithParams = [...elementsWithParams];
    },
    setElementParametersValues: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ [key: string]: string | number }>
    ) => {
        const parametersValuesByGroupId: { [key: string]: { paramId: string; value: string | number }[] } = {};

        const elementsWithParams = state.schema.elementsWithParams.map((element: TSchemaElementWithParams) => {
            let allParametersModelNames: string[] = [];
            if (payload) {
                allParametersModelNames = Object.keys(payload);
            }
            allParametersModelNames.forEach((name) => {
                const splittedModelName = name.split('.');
                if (splittedModelName?.length === 2) {
                    const [groupId, paramId] = splittedModelName;
                    if (
                        groupId in parametersValuesByGroupId &&
                        parametersValuesByGroupId[groupId].filter((item) => item.paramId === name).length === 0
                    ) {
                        parametersValuesByGroupId[groupId].push({
                            paramId: name,
                            value: payload[name],
                        });
                    } else {
                        parametersValuesByGroupId[groupId] = [
                            {
                                paramId: name,
                                value: payload[name],
                            },
                        ];
                    }
                }
            });

            const newParams = element.elemParams.map((param: ElemParams) => {
                let newValue: string | number = '';
                for (let i = 0; i <= allParametersModelNames.length; i++) {
                    if (param.modelName.toLowerCase() === allParametersModelNames[i]?.toLowerCase()) {
                        newValue =
                            payload[allParametersModelNames[i]] !== '-'
                                ? Number(payload[allParametersModelNames[i]])
                                : '-';
                        return { ...param, value: newValue };
                    }
                }
                return param;
            });
            return {
                ...element,
                elemParams: newParams,
            };
        });

        const groups = state.schema.schemaItems.groups;

        const groupsWithParamsValues = groups
            ? groups.map((group) => {
                  if (group.id.toString() in parametersValuesByGroupId) {
                      const elementsWithParams = group.elements.map((element: TSchemaNode) => {
                          const currentGroupParams = parametersValuesByGroupId[group.id.toString()];
                          const newParams = element.data.elemParams.map((param) => {
                              for (let i = 0; i <= currentGroupParams.length; i++) {
                                  if (currentGroupParams[i]?.paramId === param.modelName) {
                                      return { ...param, value: currentGroupParams[i]?.value };
                                  }
                              }

                              return param;
                          });
                          return { ...element, data: { ...element.data, elemParams: newParams || [] } };
                      });
                      return { ...group, elements: elementsWithParams };
                  }
                  return group;
              })
            : [];
        state.schema.schemaItems.groups = groupsWithParamsValues;
        state.schema.elementsWithParams = elementsWithParams;
    },
    setIndicatorParameter: (
        state: IWorkspaceState,
        {
            payload,
        }: PayloadAction<{
            indicatorId: number;
            connectedElementId: number;
            parameter: string;
            unitsPropValue: string;
            unitsFactor: string;
        }>
    ) => {
        const libraryItems = state.libraryItems.items;
        const workspaceMetaGroupId = state.meta.groupId;
        const groups = state.schema.schemaItems.groups || [];
        const schemaElements =
            workspaceMetaGroupId === null
                ? state.schema.schemaItems.elements
                : groups.find((group) => group.id.toString() === workspaceMetaGroupId)?.elements || [];

        const trackableNode =
            payload.connectedElementId > 0
                ? schemaElements.find((node) => node.data.id === payload.connectedElementId)
                : null;
        const trackableNodeLibraryName = trackableNode
            ? libraryItems.find((item) => item.type === trackableNode.data.type)?.name || ''
            : null;
        const trackableParameter = trackableNode
            ? trackableNode.data.stateParameters.find((elementParameter: ElemParams) => {
                  return elementParameter.name === payload.parameter;
              })
            : null;

        const updateMeterElemParams = (
            trackableNode: TSchemaNode,
            trackableParameter: ElemParams,
            elemParams: ElemParams[]
        ) => {
            const newUnitFactor = payload.unitsFactor;
            const parameterTitle = `${trackableNodeLibraryName} [${trackableNode.data.index}] / ${trackableParameter.description}`;
            const parameterUnit =
                newUnitFactor === '1' || newUnitFactor === '1.0'
                    ? trackableParameter.unit
                    : `${trackableParameter.unit} \u00B7 ${newUnitFactor}`;

            return elemParams.map((parameter: ElemParams) => {
                if (parameter.name === 'out_1') {
                    return {
                        ...parameter,
                        description: parameterTitle,
                        unit: `${parameterUnit}`,
                    };
                }
                return parameter;
            });
        };
        const updateElements = (elements: TSchemaNode[]) => {
            return elements.map((element: TSchemaNode) => {
                if (element.data.id !== payload.indicatorId && element.data.type !== 'project') {
                    return element;
                }

                const indicator = {
                    connectedElementId: payload.connectedElementId,
                    parameter: payload.parameter,
                    unitsPropValue: payload.unitsPropValue,
                    unitsFactor: payload.unitsFactor ? payload.unitsFactor : '1',
                };

                let elementParams = [...element.data.elemParams];
                let elementProps = [...element.data.elemProps];
                if (element?.data.isIndicator && element?.data.solver === SolverTypes.MDCORE) {
                    elementProps = elementProps.map((property) => {
                        if (property.name === 'parentID') {
                            return {
                                ...property,
                                value: String(payload.connectedElementId),
                            };
                        }
                        if (property.name === 'par') {
                            return {
                                ...property,
                                value: payload.parameter,
                            };
                        }
                        if (property.name === 'units') {
                            return {
                                ...property,
                                value: payload.unitsPropValue,
                            };
                        }
                        if (property.name === 'unitsFactor') {
                            return {
                                ...property,
                                value: payload.unitsFactor ? payload.unitsFactor : '1',
                            };
                        }
                        return { ...property };
                    });

                    if (trackableNode && trackableParameter) {
                        elementParams = updateMeterElemParams(
                            trackableNode,
                            trackableParameter,
                            element.data.elemParams
                        );
                    }
                }

                return {
                    ...element,
                    data: { ...element.data, indicator, elemProps: [...elementProps], elemParams: [...elementParams] },
                };
            });
        };

        if (workspaceMetaGroupId !== null) {
            const groupsState = state.schema.schemaItems.groups || [];
            const groups = groupsState.map((group) => {
                if (group.id.toString() === workspaceMetaGroupId) {
                    return { ...group, elements: updateElements(group.elements) };
                }
                return group;
            });
            return {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: {
                        ...state.schema.schemaItems,
                        groups,
                    },
                },
            };
        }

        const elements = updateElements(state.schema.schemaItems.elements);

        const elementsWithParams = !trackableNode
            ? { ...state.schema.elementsWithParams }
            : state.schema.elementsWithParams.map((element: TSchemaElementWithParams) => {
                  if (element.id !== payload.indicatorId || !trackableParameter) {
                      return element;
                  }

                  const elementParams = updateMeterElemParams(trackableNode, trackableParameter, element.elemParams);

                  return {
                      ...element,
                      elemParams: [...elementParams],
                  };
              });

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: [...elements],
                },
                elementsWithParams,
            },
        };
    },

    markPortAsConnected: (state: IWorkspaceState, { payload }: PayloadAction<{ elementId: number; name: string }>) => {
        const { elementId: metaElementId, groupId } = state.meta;
        const groups = state.schema.schemaItems.groups || [];

        const updateElementsPorts = (elements: TSchemaNode[], index: number) => {
            return [
                ...elements.slice(0, index),
                {
                    ...elements[index],
                    data: {
                        ...elements[index].data,
                        availablePorts: elements[index].data.availablePorts.map((p) => {
                            if (p.name !== payload.name) {
                                return p;
                            }

                            return {
                                ...p,
                                isConnected: true,
                            };
                        }),
                    },
                },
                ...elements.slice(index + 1),
            ];
        };
        if (metaElementId === null && groupId === null) {
            const elementIndex = state.schema.schemaItems.elements.findIndex((e) => e.data.id === payload.elementId);
            if (elementIndex !== -1) {
                const elements = updateElementsPorts(state.schema.schemaItems.elements, elementIndex);
                state.schema.schemaItems.elements = elements;
            }
        } else {
            const groupsUpdated = groups.map((group) => {
                if (group.id.toString() === metaElementId) {
                    const groupElementIndex = group.elements.findIndex((e) => e.data.id === payload.elementId);
                    if (groupElementIndex === -1) {
                        return { ...group };
                    }
                    const elements = updateElementsPorts(group.elements, groupElementIndex);
                    return { ...group, elements };
                }
                return group;
            });

            state.schema.schemaItems.groups = groupsUpdated;
        }
    },
    markConnectionsPortsAsUnconnected: (state: IWorkspaceState, { payload }: PayloadAction<TSchemaConnection[]>) => {
        const metaElementId = state.meta.elementId;
        const mode = state.meta.mode;
        const groups = state.schema.schemaItems.groups || [];
        const portsNamesByNodeIdMap: { [key: string]: string[] } = {};

        const updateElementsPorts = (elements: TSchemaNode[], portsNames: { [key: string]: string[] }) => {
            return elements.map((node) => {
                const availablePorts = node.data.availablePorts.map((port) => {
                    if (portsNames[node.id] && portsNames[node.id].includes(port.name)) {
                        return { ...port, isConnected: false };
                    }
                    return port;
                });
                return { ...node, data: { ...node.data, availablePorts } };
            });
        };

        payload.forEach((wire) => {
            if (portsNamesByNodeIdMap[wire.source] === undefined) {
                portsNamesByNodeIdMap[wire.source] = [getHandleName(wire.sourceHandle)];
            } else {
                portsNamesByNodeIdMap[wire.source] = [
                    ...portsNamesByNodeIdMap[wire.source],
                    getHandleName(wire.sourceHandle),
                ];
            }

            if (portsNamesByNodeIdMap[wire.target] === undefined) {
                portsNamesByNodeIdMap[wire.target] = [getHandleName(wire.targetHandle)];
            } else {
                portsNamesByNodeIdMap[wire.target] = [
                    ...portsNamesByNodeIdMap[wire.target],
                    getHandleName(wire.targetHandle),
                ];
            }
        });

        if (metaElementId && [WorkspaceModes.GROUP, WorkspaceModes.USER_BLOCK].includes(mode)) {
            const currentGroup = groups.find((group) => group.id.toString() === metaElementId);
            if (currentGroup) {
                const elements = updateElementsPorts(currentGroup.elements, portsNamesByNodeIdMap);
                const { elements: newElements } = compareElements({
                    oldList: currentGroup.elements,
                    newList: elements,
                });
            }

            const groupsUpdated = groups.map((group) => {
                if (group.id.toString() === metaElementId) {
                    const elements = updateElementsPorts(group.elements, portsNamesByNodeIdMap);
                    const { elements: newElements } = compareElements({
                        oldList: group.elements,
                        newList: elements,
                    });
                    return { ...group, elements: newElements };
                }
                return group;
            });
            return {
                ...state,
                schema: {
                    ...state.schema,
                    schemaItems: {
                        ...state.schema.schemaItems,
                        groups: groupsUpdated,
                    },
                },
            };
        }

        const elements = updateElementsPorts(state.schema.schemaItems.elements, portsNamesByNodeIdMap);

        const { elements: newElements } = compareElements({
            oldList: state.schema.schemaItems.elements,
            newList: elements,
        });

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    elements: newElements,
                },
            },
        };
    },
    setProjectSettings: (state: IWorkspaceState, { payload }: PayloadAction<any>) => {
        return {
            ...state,
            settings: {
                ...payload,
            },
        };
    },
    setGlobalVariables: (state: IWorkspaceState, { payload }: PayloadAction<any>) => {
        return {
            ...state,
            settings: {
                ...state.settings,
                globalVariables: {
                    ...payload,
                },
            },
        };
    },
    setTools: (state: IWorkspaceState, { payload }: PayloadAction<ITool[]>) => {
        return {
            ...state,
            settings: {
                ...state.settings,
                tools: [...payload],
            },
        };
    },
    updateProjectSettings: (state: IWorkspaceState, { payload }: PayloadAction<any>) => {
        return {
            ...state,
            settings: {
                ...payload,
            },
        };
    },
    setFSMConnectionPropertyValue: (
        state: IWorkspaceState,
        {
            payload,
        }: PayloadAction<{ id: string; fsmBlockId: string | null; propertyName: string; propertyValue: string }>
    ) => {
        const { groupId } = state.meta;
        const { id, fsmBlockId, propertyName, propertyValue } = payload;

        const setConnection = (elements: TSchemaNode[]) => {
            const fsmBlockIndex = elements.findIndex((node: TSchemaNode) => node.id === fsmBlockId);
            if (fsmBlockIndex < 0) {
                return elements;
            }

            const updatedElements = [...elements];
            const fsmBlock = updatedElements[fsmBlockIndex];

            if (!fsmBlock.data.submodelItems) {
                return elements;
            }

            updatedElements[fsmBlockIndex] = {
                ...fsmBlock,
                data: {
                    ...fsmBlock.data,
                    submodelItems: {
                        ...fsmBlock.data.submodelItems,
                        wires: fsmBlock.data.submodelItems.wires.map((connection) =>
                            connection.id === id
                                ? {
                                      ...connection,
                                      data: {
                                          ...connection.data,
                                          [propertyName]: propertyValue,
                                      },
                                  }
                                : connection
                        ),
                    },
                },
            };

            return updatedElements;
        };

        if (groupId === null) {
            state.schema.schemaItems.elements = setConnection(state.schema.schemaItems.elements);
        } else {
            const groupIndex = state.schema.schemaItems.groups?.findIndex((g) => g.id.toString() === groupId);
            if (groupIndex && groupIndex !== -1) {
                const updatedGroups = [...(state.schema.schemaItems.groups || [])];
                updatedGroups[groupIndex] = {
                    ...updatedGroups[groupIndex],
                    elements: setConnection(updatedGroups[groupIndex].elements),
                };
                state.schema.schemaItems.groups = updatedGroups;
            }
        }
    },
    setFSMConnectionPositionHandlers: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ id: string; fsmBlockId: string | null; value: any }>
    ) => {
        const { groupId } = state.meta;
        const { id, fsmBlockId, value } = payload;
        const setConnection = (elements: TSchemaNode[]) => {
            const fsmBlockIndex = elements.findIndex((node: TSchemaNode) => node.id === fsmBlockId);
            if (fsmBlockIndex < 0) {
                return elements;
            }

            const updatedElements = [...elements];
            const fsmBlock = updatedElements[fsmBlockIndex];

            if (!fsmBlock.data.submodelItems) {
                return elements;
            }

            updatedElements[fsmBlockIndex] = {
                ...fsmBlock,
                data: {
                    ...fsmBlock.data,
                    submodelItems: {
                        ...fsmBlock.data.submodelItems,
                        wires: fsmBlock.data.submodelItems.wires.map((connection) =>
                            connection.id === id
                                ? {
                                      ...connection,
                                      data: {
                                          ...connection.data,
                                          positionHandlers: value,
                                      },
                                  }
                                : connection
                        ),
                    },
                },
            };

            return updatedElements;
        };

        if (groupId === null) {
            state.schema.schemaItems.elements = setConnection(state.schema.schemaItems.elements);
        } else {
            const groupIndex = state.schema.schemaItems.groups?.findIndex((g) => g.id.toString() === groupId);
            if (groupIndex && groupIndex !== -1) {
                const updatedGroups = [...(state.schema.schemaItems.groups || [])];
                updatedGroups[groupIndex] = {
                    ...updatedGroups[groupIndex],
                    elements: setConnection(updatedGroups[groupIndex].elements),
                };
                state.schema.schemaItems.groups = updatedGroups;
            }
        }
    },
    setConnectionPositionHandlers: (state: IWorkspaceState, { payload }: PayloadAction<{ id: string; value: any }>) => {
        const workspaceMode = state.meta.mode;
        const isUserBlockEditor = state.meta.isUserBlockEditor;
        if (
            workspaceMode === WorkspaceModes.GROUP ||
            (workspaceMode === WorkspaceModes.USER_BLOCK && isUserBlockEditor)
        ) {
            const { groupId } = state.meta;
            const { groups } = state.schema.schemaItems;

            if (groupId) {
                const updatedGroups = updateSchemaGroupConnections(groups, groupId, payload.id, (connection) => ({
                    ...connection,
                    data: {
                        ...connection.data,
                        positionHandlers: payload.value,
                    },
                }));

                return {
                    ...state,
                    schema: {
                        ...state.schema,
                        schemaItems: {
                            ...state.schema.schemaItems,
                            groups: updatedGroups,
                        },
                    },
                };
            }

            return state;
        }

        const connections = state.schema.schemaItems.wires.map((connection: TSchemaConnection) => {
            if (connection.id === payload.id) {
                return {
                    ...connection,
                    data: {
                        ...connection.data,
                        positionHandlers: payload.value,
                    },
                };
            }
            return connection;
        });

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: {
                    ...state.schema.schemaItems,
                    wires: connections,
                },
            },
        };
    },
};

export const getProject = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.getProjectRequest());

    try {
        const response = await ProjectsService.loadProject(id);
        dispatch(actions.getProjectSuccess(response.data));
    } catch (error) {
        const errorKey = TranslationKey.ERROR_LOADING_SCHEMA;
        dispatch(actions.getProjectFailed({ error: errorKey }));
    }
};

export const getProjectVersionPreview = (projectId: number, versionId: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.getProjectRequest());

    try {
        const response = await ProjectsService.viewProjectVersion({ projectId, versionId });
        dispatch(actions.getProjectSuccess(response.data));
    } catch (error) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;
        dispatch(actions.getProjectFailed({ error: errorKey }));
    }
};

export const setProjectVersion = (projectId: number, versionId: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.getProjectRequest());

    try {
        const response = await ProjectsService.setProjectVersion({ projectId, versionId });
        dispatch(actions.getProjectSuccess(response.data));
    } catch (error) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;
        dispatch(actions.getProjectFailed({ error: errorKey }));
    }
};

export const loadProject =
    (projectId: number, isLibraryFromDB?: boolean, date?: string, versionId?: number, type?: string) =>
    async (dispatch: AppDispatch, getState: RootStateFn) => {
        try {
            if (date && versionId) {
                dispatch(ProjectsActions.useProjectVersionRequest());
                if (type === 'preview') {
                    await dispatch(getProjectVersionPreview(projectId, versionId));
                }
                if (type === 'set') {
                    await dispatch(setProjectVersion(projectId, versionId));
                }
            } else {
                await dispatch(getProject(projectId));
            }

            const state = getState();
            const { solverType, libraryType, userBlocksCount } = state.workspace.schema;

            if (!solverType || !libraryType) {
                return;
            }

            if (![SolverTypes.MDCORE, SolverTypes.JAUTO].includes(solverType)) {
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: TranslationKey.PROJECT_NOT_SUPPORTED_USDS,
                        },
                    })
                );
                return;
            }

            const locale = getAppLocale();

            await dispatch(
                fetchLibraryItems(solverType, libraryType.toLowerCase() as TLibraryType, locale, isLibraryFromDB)
            );
            await dispatch(fetchLibraryPortTypes());
            await dispatch(getUserBlocks());
            const {
                workspace: {
                    schema,
                    libraryItems: { items: libraryItems },
                    libraryPortTypes: { items: libraryPortTypes },
                    graphs: { charts },
                },
            } = getState();

            const versionFrom = schema.version;
            const versionTo = VERSION;
            const updatedSchema = SchemaAdapterService.upgrade(
                schema,
                libraryItems,
                versionFrom,
                versionTo,
                libraryPortTypes
            );

            const updatedCharts = ChartsAdapterService.upgrade(charts, updatedSchema, locale);
            dispatch(actions.updateSchema({ schema: updatedSchema, type: SchemaUpdateTypes.UPGRADE }));
            dispatch(actions.updateChartsList(updatedCharts));

            const projectBlocks = [
                ...updatedSchema.schemaItems.elements,
                ...(updatedSchema.schemaItems.groups?.flatMap((group) =>
                    group.elements.filter((el) => el.data.type === 'project')
                ) || []),
            ].filter((el) => el.data.type === 'project');

            const projectBlocksIds = projectBlocks.map((el) => el.id);
            const connectedProjectsIds = projectBlocks.map(
                (el) => el.data.elemProps.find((p) => p.name === 'projectId')?.value.toString() || ''
            );

            if (projectBlocksIds.length !== 0) {
                await dispatch(updateProjectBlocks({ projectsIds: connectedProjectsIds, projectBlocksIds }));
            }

            if (userBlocksCount && Object.keys(userBlocksCount).length !== 0) {
                await dispatch(updateUserBlocks());
            }

            if (date) {
                dispatch(ProjectsActions.useProjectVersionSuccess());
            }
        } catch (error: any) {
            if (error.response && error.response.status === API_INTERNAL_ERROR_CODE) {
                dispatch(actions.deleteSchema());
            } else {
                const modal: TErrorModal = {
                    type: ModalTypes.ERROR,
                    data: {
                        errorKey: TranslationKey.ERROR_OPEN_SCHEMA,
                    },
                };

                dispatch(ApplicationActions.showModal({ modal }));

                const userId = getState()?.appUser?.currentUser?.id;
                if (userId) {
                    ErrorReportService.reportError({
                        userId,
                        context: { projectId: projectId },
                        message: error.message,
                    });
                }
                dispatch(actions.deleteSchema());

                console.error(error);
            }
            if (date) {
                dispatch(ProjectsActions.useProjectVersionFailed({ error: 'Error get version' }));
            }
        }
    };

const prepareSchemaBlocksBeforeSave = (elements: TSchemaNode[], groups: TSchemaGroup[]) => {
    const preparedElements = elements.map((element: TSchemaNode) => prepareBlockData(element));
    const preparedGroups = groups.map((group: TSchemaGroup) => {
        const elements = group.elements.map((el) => prepareBlockData(el));
        return { ...group, elements };
    });

    return {
        elements: preparedElements,
        groups: preparedGroups,
    };
};

// TODO make using AppDispatch and RootStateFn again
export const saveProject =
    (id: number, libraryType: TLibraryType, solverType: TSolverType) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        dispatch(actions.saveProjectRequest());
        const {
            schema: {
                schemaItems: { elements, wires, groups },
                userBlocksCount,
                version,
                externalInfo,
                proxyMap,
                goToMap,
            },
            settings,
            graphs: { charts },
        } = getState().workspace;

        const task = getState().task as TTask;

        const { elements: preparedElements, groups: preparedGroups } = prepareSchemaBlocksBeforeSave(elements, groups);

        const hashData = hash(prepareProjectHashData(preparedElements, wires, proxyMap, externalInfo));

        const { globalVariables, tools } = settings;
        const isGlobalVariablesExist = Object.keys({ ...globalVariables })[0] !== '';
        const isToolsExist = Array.isArray(tools) && tools.length > 0;
        const newSettings = {
            ...settings,
            ...(isGlobalVariablesExist && globalVariables),
            ...(isToolsExist && { tools }),
        };

        try {
            await ProjectsService.saveProject(
                preparedElements,
                wires,
                preparedGroups || [],
                userBlocksCount || {},
                hashData,
                id,
                task,
                libraryType,
                solverType,
                version,
                externalInfo,
                proxyMap,
                goToMap,
                {
                    ...newSettings,
                    charts,
                }
            );

            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.SUCCESS,
                        message: TranslationKey.PROJECT_SAVED,
                    },
                })
            );
        } catch (error) {
            const errorKey = TranslationKey.ERROR_SAVING_SCHEMA;
            dispatch(actions.saveProjectFailed({ error: errorKey }));
        }
    };

export const markConnectionPortsAsConnected = (connection: Connection) => async (dispatch: AppDispatch) => {
    if (connection.targetHandle && connection.sourceHandle) {
        const targetHandleName = getHandleName(connection.targetHandle);
        const sourceHandleName = getHandleName(connection.sourceHandle);
        if (targetHandleName && sourceHandleName) {
            batch(() => {
                dispatch(actions.markPortAsConnected({ elementId: Number(connection.source), name: sourceHandleName }));
                dispatch(actions.markPortAsConnected({ elementId: Number(connection.target), name: targetHandleName }));
            });
        }
    }
};

export const getElementPropertiesSets =
    (payload: { elementType: string; libraryType: TLibraryType; solverType: TSolverType }) =>
    async (dispatch: AppDispatch, getState: RootStateFn) => {
        const status = getState().workspace.schema.getElementPropertiesSets.status;
        if (status === Statuses.LOADING) {
            return;
        }

        const { elementType, libraryType, solverType } = payload;

        dispatch(workspaceActions.getElementPropertiesSetsRequest({ elementType, libraryType, solverType }));
        const { elements } = getState().workspace.schema.selectedItems;
        const selectedElement = elements[0] as TSchemaNode;
        const id = selectedElement?.id;

        try {
            const response = await ElementsPropertiesService.getElementPropertiesSet(payload);
            if (response?.data?.ElementPropertySets) {
                const sets = response.data.ElementPropertySets;
                const selectedSetId = sets.find(
                    (set) => set.id === selectedElement?.data?.selectedConfiguration?.id
                )?.id;
                const selectedElementProperties = selectedElement.data.elemProps;
                const configArray: IElementConfiguration[] = sets.map((set) => {
                    return {
                        id: set.id,
                        name: set.name,
                        description: set.description,
                        elementProperties: propertiesSetsFormatting(set, selectedElementProperties),
                        isSelected: selectedSetId === set.id,
                    };
                });
                dispatch(workspaceActions.getElementPropertiesSetsSuccess({ id: parseInt(id), sets: configArray }));
            }
        } catch (error) {
            const errorKey = TranslationKey.ERROR_GETTING_ELEMENT_PROPERTIES_SETS;
            dispatch(workspaceActions.getElementPropertiesSetsFailed({ error: errorKey }));
        }
    };

export const selectElementConfiguration = (id: string, configurationId: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.setElementConfiguration({ id, configurationId }));
    dispatch(actions.setSelectedItems({ ids: [id], type: SchemaItemTypes.NODE }));
};

export const setElementConfigurationCustomizable = (id: string) => async (dispatch: AppDispatch) => {
    dispatch(actions.clearElementConfiguration());
    dispatch(actions.setSelectedItems({ ids: [id], type: SchemaItemTypes.NODE }));
    dispatch(ApplicationActions.hideModal({ type: ModalTypes.ELEMENT_CONFIGURATION }));
};

export const showElementConfigurationsModal = () => async (dispatch: AppDispatch) => {
    const modal: TElementConfigurationModal = {
        type: ModalTypes.ELEMENT_CONFIGURATION,
    };
    dispatch(ApplicationActions.showModal({ modal }));
};

export const uploadElementSourcesFile =
    (payload: { file: File; projectId: number; elementId: number }) => async (dispatch: AppDispatch) => {
        dispatch(
            actions.uploadElementSourcesFileRequest({
                elementId: payload.elementId,
                projectId: payload.projectId,
                fileSizeBytes: payload.file.size,
            })
        );

        const onChunkUploaded = (uploadedBytes: number) => {
            dispatch(actions.uploadElementSourcesFileProgress({ bytes: uploadedBytes }));
        };

        const onUploaded = (uploadedFile: TUploadedFmuFile) => {
            dispatch(actions.uploadElementSourcesFileSuccess(uploadedFile));
        };
        try {
            await FileUploadService.uploadFile(
                {
                    projectId: payload.projectId,
                    elementId: payload.elementId,
                    file: payload.file,
                    onChunkUploaded,
                    onUploaded,
                },
                UploadFileTypes.INITIALIZE
            );
        } catch (error) {
            const errorKey = 'error';
            dispatch(actions.uploadElementSourcesFileFailed({ error: errorKey }));
        }
    };
export const updateElementSourcesFile =
    (payload: { file: File; projectId: number; elementId: number; uuid?: string }) => async (dispatch: AppDispatch) => {
        dispatch(
            actions.uploadElementSourcesFileRequest({
                elementId: payload.elementId,
                projectId: payload.projectId,
                fileSizeBytes: payload.file.size,
            })
        );

        const onChunkUploaded = (uploadedBytes: number) => {
            dispatch(actions.uploadElementSourcesFileProgress({ bytes: uploadedBytes }));
        };

        const onUploaded = (uploadedFile: TUploadedFmuFile) => {
            dispatch(actions.uploadElementSourcesFileSuccess(uploadedFile));
        };
        try {
            await FileUploadService.uploadFile(
                {
                    projectId: payload.projectId,
                    elementId: payload.elementId,
                    file: payload.file,
                    onChunkUploaded,
                    onUploaded,
                    uuid: payload.uuid,
                },
                UploadFileTypes.UPDATE
            );
        } catch (error) {
            const errorKey = 'error';
            dispatch(actions.uploadElementSourcesFileFailed({ error: errorKey }));
        }
    };

export const initializeElementByFile = () => async (dispatch: AppDispatch, getState: RootStateFn) => {
    dispatch(actions.initializeElementByFileRequest());

    try {
        const schemaState = getState().workspace.schema;
        const originalName = schemaState.elementInitialization.originalName || '';
        const extension = getFileExtension(originalName);
        const requestData: TInitializeFMIPayload = {
            projectId: schemaState.elementInitialization.projectId as number,
            solverType: schemaState.solverType as TSolverType,
            libraryType: schemaState.libraryType as TLibraryType,
            uuid: schemaState.elementInitialization.uuid as string,
            fileType: extension,
        };

        const response = await FMIService.initializeFMI(requestData);

        dispatch(actions.initializeElementByFileSuccess({ data: response.data }));
    } catch (error: any) {
        let errorKey = 'error';
        if (error.response?.status === API_ALL_VMS_ARE_BUSY) {
            errorKey = TranslationKey.WARNING_ALL_VMS_ARE_BUSY;
        }
        if (error.response?.status === API_BAD_REQUEST_CODE) {
            errorKey = TranslationKey.ELEMENT_INITIALIZATION_FMU_NOT_VALID;
        }

        dispatch(actions.initializeElementByFileFailed({ error: errorKey }));
        dispatch(stopProject()); // it's temporary solution while backend is not ready to handle this case
    }
};

export const uploadSourcesFileAndInitialize =
    (payload: { file: File; projectId: number; elementId: number }) => async (dispatch: AppDispatch) => {
        dispatch(actions.resetElementInitialization());
        dispatch(uploadElementSourcesFile({ ...payload })).then(() => {
            setTimeout(() => {
                dispatch(initializeElementByFile());
            }, 500);
        });
    };

export const uploadSourcesFile =
    (payload: { file: File; projectId: number; elementId: number }) => async (dispatch: AppDispatch) => {
        dispatch(actions.resetElementInitialization());
        dispatch(uploadElementSourcesFile({ ...payload }));
    };
export const updateSourcesFileAndInitialize =
    (payload: { file: File; projectId: number; elementId: number; elementType: string; uuid?: string }) =>
    async (dispatch: AppDispatch) => {
        dispatch(actions.resetElementInitialization());
        if (ELEMENTS_WITH_INITIALIZATION.includes(payload.elementType)) {
            dispatch(updateElementSourcesFile({ ...payload })).then(() => {
                setTimeout(() => {
                    dispatch(initializeElementByFile());
                }, 500);
            });
        } else {
            dispatch(updateElementSourcesFile({ ...payload }));
        }
    };

export const initializeElement = () => async (dispatch: AppDispatch, getState: RootStateFn) => {
    const data = getState().workspace.schema.elementInitialization.data;
    if (!data) {
        return;
    }

    dispatch(actions.initializeElementSuccess(data));
    dispatch(ApplicationActions.hideModal({ type: ModalTypes.ELEMENT_INITIALIZATION }));
};

export const initializeUpdatedElement = () => async (dispatch: AppDispatch, getState: RootStateFn) => {
    const { data, elementId } = getState().workspace.schema.elementInitialization;
    if (!data) {
        return;
    }

    const schema = getState().workspace.schema;
    const {
        schemaItems: { elements, wires },
    } = schema;

    const { updatedElements, updatedWires } = updateNodesAndConnectionsAfterElementUpdate(
        wires,
        elements,
        elementId?.toString() || ''
    );

    dispatch(
        actions.updateSchema({
            schema: {
                ...schema,
                schemaItems: { ...schema.schemaItems, elements: updatedElements, wires: updatedWires },
            },
            type: SchemaUpdateTypes.UPGRADE,
        })
    );

    dispatch(actions.initializeElementSuccess(data));
    dispatch(ApplicationActions.hideModal({ type: ModalTypes.ELEMENT_FILE_UPDATE }));
};

const initializeRFSParametersBlock = (
    originalName: string,
    portTypes: TPortTypeComplex[]
): {
    elemParams: ElemParams[];
    availablePorts: TSchemaHandle[];
    inputPorts: number;
    outputPorts: number;
    height: number;
} => {
    const extension = getFileExtension(originalName);
    const portsAmount = extractNumbers(extension);

    const inputPorts = portsAmount % 2 ? Math.ceil(portsAmount / 2) : portsAmount / 2;
    const outputPorts = Math.floor(portsAmount / 2);

    const baseParameter = { description: '', name: '', value: '', modelName: '', unit: '' };

    const inputParams: ElemParams[] = [];
    const inputHandles: TSchemaHandle[] = [];
    const outputParams: ElemParams[] = [];
    const outputHandles: TSchemaHandle[] = [];

    let i = 1;
    while (i <= inputPorts) {
        inputParams.push({ ...baseParameter, name: `in_${i}` });
        const typeConnection = PortConnectionTypes.RF_IN;
        const name = `in_${i}`;
        const portDetails = findPortDetails(portTypes, typeConnection, name);
        inputHandles.push({
            name,
            position: PortPositions.LEFT,
            libraries: [],
            type: PortTypes.INPUT,
            typeConnection,
            isConnected: false,
            direction: portDetails.direction,
            compatibleTypes: portDetails.compatibleTypes,
        });
        i++;
    }

    let p = 1;
    while (p <= outputPorts) {
        outputParams.push({ ...baseParameter, description: `out_${p}`, name: `out_${p}` });
        const typeConnection = PortConnectionTypes.RF_OUT;
        const name = `out_${p}`;
        const portDetails = findPortDetails(portTypes, typeConnection, name);
        outputHandles.push({
            name,
            position: PortPositions.RIGHT,
            libraries: [],
            type: PortTypes.OUTPUT,
            typeConnection,
            isConnected: false,
            direction: portDetails.direction,
            compatibleTypes: portDetails.compatibleTypes,
        });
        p++;
    }

    let inDescriptionIndex = 1;
    let outDescriptionIndex = 2;
    inputParams.forEach((p) => {
        p.description = inDescriptionIndex.toString();
        inDescriptionIndex += 2;
    });
    outputParams.forEach((p) => {
        p.description = outDescriptionIndex.toString();
        outDescriptionIndex += 2;
    });

    const height = calculateBlockHeight([...inputHandles, ...outputHandles]);
    return {
        elemParams: [...inputParams, ...outputParams],
        availablePorts: [...inputHandles, ...outputHandles],
        inputPorts,
        outputPorts,
        height,
    };
};

const updateNodesAndConnectionsAfterElementUpdate = (
    wires: TSchemaConnection[],
    elements: TSchemaNode[],
    elementId: string
): { updatedWires: TSchemaConnection[]; updatedElements: TSchemaNode[] } => {
    const updatedWires = wires.filter((wire) => wire.source !== elementId && wire.target !== elementId);
    const deletedWires = wires.filter((x) => !updatedWires.some((y) => x.id === y.id));

    const portsToDisconnectByElementsIds: { [id: string]: string[] } = {};
    deletedWires.forEach((wire) => {
        if (wire.source === elementId) {
            const secondElement = wire.target;
            if (secondElement in portsToDisconnectByElementsIds) {
                portsToDisconnectByElementsIds[secondElement].push(getHandleName(wire.targetHandle));
            } else {
                portsToDisconnectByElementsIds[secondElement] = [getHandleName(wire.targetHandle)];
            }
        }
        if (wire.target === elementId) {
            const secondElement = wire.source;
            if (secondElement in portsToDisconnectByElementsIds) {
                portsToDisconnectByElementsIds[secondElement].push(getHandleName(wire.sourceHandle));
            } else {
                portsToDisconnectByElementsIds[secondElement] = [getHandleName(wire.sourceHandle)];
            }
        }
    });

    const updatedElements = elements.map((el) => {
        if (Object.keys(portsToDisconnectByElementsIds).includes(el.id)) {
            const ports = el.data.availablePorts.map((port) => {
                if (portsToDisconnectByElementsIds[el.id].includes(port.name)) {
                    return { ...port, isConnected: false };
                }
                return port;
            });
            return { ...el, data: { ...el.data, availablePorts: ports } };
        }
        return el;
    });
    return { updatedWires, updatedElements };
};

export const initializeElementByTextFile = (type: string) => async (dispatch: AppDispatch, getState: RootStateFn) => {
    const data = getState().workspace.schema.elementInitialization;
    const portTypes = getState().workspace.libraryPortTypes.items;

    if (!data) {
        return;
    }
    const { uuid, originalName } = data;
    if (!originalName || !uuid) {
        return;
    }
    const splittedName = originalName.split('.');
    const systemName = `${uuid}.${splittedName[splittedName.length - 1]}`;

    const payload = { systemName, userName: originalName, uuid };

    if (type === 'RFSParameters') {
        const { elemParams, availablePorts, inputPorts, outputPorts, height } = initializeRFSParametersBlock(
            originalName,
            portTypes
        );

        dispatch(
            actions.initializeElementByTextFileSuccess({
                ...payload,
                elemParams,
                availablePorts,
                inputPorts,
                outputPorts,
                height,
            })
        );
    }

    dispatch(actions.initializeElementByTextFileSuccess(payload));
    dispatch(ApplicationActions.hideModal({ type: ModalTypes.PULSEQ_INITIALIZATION }));
};
export const updateElementByTextFile = (type: string) => async (dispatch: AppDispatch, getState: RootStateFn) => {
    const data = getState().workspace.schema.elementInitialization;
    const portTypes = getState().workspace.libraryPortTypes.items;
    const elementId = data.elementId?.toString() || '';
    const schema = getState().workspace.schema;
    const {
        schemaItems: { elements, wires },
    } = schema;

    if (!data) {
        return;
    }
    const { uuid, originalName } = data;
    if (!originalName || !uuid) {
        return;
    }
    const splittedName = originalName.split('.');
    const systemName = `${uuid}.${splittedName[splittedName.length - 1]}`;

    const payload = { systemName, userName: originalName, uuid };
    if (type === 'RFSParameters') {
        const { elemParams, availablePorts, inputPorts, outputPorts, height } = initializeRFSParametersBlock(
            originalName,
            portTypes
        );

        const { updatedElements, updatedWires } = updateNodesAndConnectionsAfterElementUpdate(
            wires,
            elements,
            elementId
        );

        dispatch(
            actions.updateSchema({
                schema: {
                    ...schema,
                    schemaItems: { ...schema.schemaItems, elements: updatedElements, wires: updatedWires },
                },
                type: SchemaUpdateTypes.UPGRADE,
            })
        );
        dispatch(
            actions.initializeElementByTextFileSuccess({
                ...payload,
                elemParams,
                availablePorts,
                inputPorts,
                outputPorts,
                height,
            })
        );
    } else {
        dispatch(actions.initializeElementByTextFileSuccess({ systemName, userName: originalName, uuid }));
    }
    dispatch(ApplicationActions.hideModal({ type: ModalTypes.ELEMENT_FILE_UPDATE }));
};

export const deleteUninitializedElement = () => async (dispatch: AppDispatch) => {
    dispatch(actions.deleteNode());
    dispatch(ApplicationActions.hideModal({ type: ModalTypes.ELEMENT_INITIALIZATION }));
};

const hasParametersConditions = (libraryItem: ILibraryItem) => {
    if (libraryItem.elemParams.filter((p) => p.visibilityConditions).length !== 0) {
        return true;
    }
    return false;
};

const setParametersByVisibilityConditions = (
    libraryElement: ILibraryItem,
    options: { [key: string]: string },
    optionsArray?: string[],
    currentElementWithParams?: ElemParams[]
) => {
    const currParMap = new Map<string, ElemParams>();

    if (currentElementWithParams) {
        currentElementWithParams.forEach((param) => {
            currParMap.set(param.name, param);
        });
    }

    const optionKey = Object.keys(options)[0];
    const optionValue = Object.values(options)[0];

    const replaceParam = (param: ElemParams, isVisible?: boolean) => {
        currParMap.set(param.name, { ...param, isVisible: isVisible });
    };

    libraryElement.elemParams.forEach((param) => {
        if (!param.visibilityConditions) {
            replaceParam(param, true);
        } else {
            const visibilityConditionsKeys = param.visibilityConditions.map((item) => Object.keys(item)[0]);

            if (visibilityConditionsKeys.includes(optionKey)) {
                const condition = param.visibilityConditions.find((condition) => optionKey in condition);
                if (condition) {
                    if (Object.values(condition)[0].includes(optionValue)) {
                        replaceParam(param, true);
                    } else if (!Object.values(condition)[0].includes(optionValue)) {
                        replaceParam(param, false);
                    }
                } else {
                    const currStateOfIsVisible = currParMap.get(param.name)?.isVisible;
                    if (currentElementWithParams?.some((p) => p.isVisible === param.isVisible)) {
                        replaceParam({ ...param, isVisible: true });
                    }
                }
            }
        }
    });

    return Array.from(currParMap.values());
};

const setDynamicParameters = (
    libraryElement: ILibraryItem,
    type: string,
    numberOfInputs: number,
    numberOfOutputs: number,
    optionsArray?: string[],
    currentElementWithParams?: ElemParams[]
) => {
    const newParameters: ElemParams[] = [];
    if (type === ELEMENT_CASE_TYPE) {
        newParameters.push(libraryElement.elemParams.filter((param) => param.name.includes('in'))[0]);
    }
    let input = 1;
    let output = numberOfInputs + 1;
    let libraryElementInputParameter: ElemParams = {
        description: '',
        name: '',
        modelName: '',
        value: '',
        unit: '',
        connectionType: '',
    };
    let libraryElementOutputParameter: ElemParams = {
        description: '',
        name: '',
        modelName: '',
        value: '',
        unit: '',
        connectionType: '',
    };

    if (libraryElement) {
        if (type !== ELEMENT_CASE_TYPE) {
            libraryElementInputParameter = libraryElement.elemParams.filter((param) => param.name.includes('in'))[0];
        } else {
            const inputParameters = libraryElement.elemParams.filter((param) => param.name.includes('in'));
            libraryElementInputParameter = inputParameters[inputParameters.length - 1];
        }
        libraryElementOutputParameter = libraryElement.elemParams.filter((param) => param.name.includes('out'))[0];
    }

    if (ELEMENTS_WITH_INDEXED_INPUT_PORTS.includes(type)) {
        while (input <= numberOfInputs) {
            newParameters.push({
                ...libraryElementInputParameter,
                description: `${libraryElementInputParameter.description.replace(/[0-9]/g, '')}${input}`,
                name: `in_${input}`,
            });
            input++;
        }
        newParameters.push({
            description: `${libraryElementOutputParameter.description}`,
            name: `out_${numberOfInputs + 1}`,
            modelName: '',
            value: '',
            unit: '',
        });
    } else if (type === ELEMENT_CASE_TYPE) {
        while (input < numberOfInputs) {
            newParameters.push({
                ...libraryElementInputParameter,
                description: `${libraryElementInputParameter.description.replace(/[0-9]/g, '')}${input - 1}`,
                name: `in_${input + 1}`,
            });
            input++;
        }
        while (output <= numberOfInputs + numberOfOutputs) {
            newParameters.push({ ...libraryElementOutputParameter, name: `out_${output}` });
            output++;
        }
    } else {
        while (input <= numberOfInputs) {
            if (type === ELEMENT_MULTI_TYPE) {
                newParameters.push({
                    ...libraryElementInputParameter,
                    description: `${libraryElementInputParameter.description.replace(/[0-9]/g, '')}${input}`,
                    name: `in_${input}`,
                });
                input++;
            } else {
                newParameters.push({ ...libraryElementInputParameter, name: `in_${input}` });
                input++;
            }
        }
        while (output <= numberOfInputs + numberOfOutputs) {
            if (!ELEMENTS_WITH_INDEXED_OUTPUT_PORTS.includes(type)) {
                newParameters.push({ ...libraryElementOutputParameter, name: `out_${output}` });
            } else {
                if (type === ELEMENT_MULTI_TYPE) {
                    if (optionsArray?.length) {
                        const availableValues =
                            libraryElement.elemProps.find((item) => item.name === 'parametersVector')
                                ?.availableValues ?? [];
                        const newParams = optionsArray.flatMap((option) => {
                            return availableValues
                                .filter((item1) => option === item1.value)
                                .filter((item1) => !newParameters.some((param) => param.description === item1.title))
                                .map((item1) => ({
                                    ...libraryElementOutputParameter,
                                    description: item1.title,
                                    name: `out_${output++}`,
                                }));
                        });

                        newParameters.push(...newParams);
                    } else {
                        const availableValues =
                            libraryElement.elemProps.find((item) => item.name === 'parametersVector')
                                ?.availableValues ?? [];
                        if (currentElementWithParams) {
                            const newParams = currentElementWithParams.flatMap((option) => {
                                return availableValues
                                    .filter((item1) => option.description === item1.title)
                                    .filter(
                                        (item1) => !newParameters.some((param) => param.description === item1.title)
                                    )
                                    .map((item1) => ({
                                        ...libraryElementOutputParameter,
                                        description: item1.title,
                                        name: `out_${output++}`,
                                    }));
                            });
                            newParameters.push(...newParams);
                        }
                    }
                } else {
                    newParameters.push({
                        ...libraryElementOutputParameter,
                        description: `${libraryElementOutputParameter.description.replace(/[0-9]/g, '')}${output - 1}`,
                        name: `out_${output}`,
                    });
                }
            }
            output++;
        }
    }

    return newParameters;
};

const setDynamicPorts = (
    libraryElement: ILibraryItem,
    element: TElement,
    numberOfInputs: number,
    numberOfOutputs: number,
    libraryPortTypes: TPortTypeComplex[]
) => {
    const newInputAddingPorts: TSchemaHandle[] = [];
    const newOutputAddingPorts: TSchemaHandle[] = [];

    const defaultInputPorts = libraryElement?.availablePorts
        ? libraryElement?.availablePorts?.filter((port) => port.type === PortTypes.INPUT)
        : [];
    const defaultOutputPorts = libraryElement?.availablePorts
        ? libraryElement?.availablePorts?.filter((port) => port.type === PortTypes.OUTPUT)
        : [];

    const availablePorts = element.availablePorts;
    let newPorts: TSchemaHandle[] = availablePorts;

    const availableInputPorts = availablePorts.filter((port) => port.type === PortTypes.INPUT);
    const availableOutputPorts = availablePorts.filter((port) => port.type === PortTypes.OUTPUT);

    let inputPortsCount = availableInputPorts.length + 1;
    let updatedOutputPorts: TSchemaHandle[] = availableOutputPorts;

    if (availableInputPorts.length <= numberOfInputs) {
        const addingInputsAmount = numberOfInputs - availableInputPorts.length;

        while (inputPortsCount <= numberOfInputs) {
            if (defaultInputPorts.length) {
                if (element.type === ELEMENT_CASE_TYPE) {
                    const name = `in_${inputPortsCount}`;
                    const { direction, compatibleTypes } = findPortDetails(
                        libraryPortTypes,
                        defaultInputPorts[defaultInputPorts.length - 1].typeConnection,
                        name
                    );
                    newInputAddingPorts.push({
                        ...defaultInputPorts[defaultInputPorts.length - 1],
                        name,
                        isConnected: false,
                        direction,
                        compatibleTypes,
                    });
                } else {
                    const name = `in_${inputPortsCount}`;
                    const { direction, compatibleTypes } = findPortDetails(
                        libraryPortTypes,
                        defaultInputPorts[0].typeConnection,
                        name
                    );
                    newInputAddingPorts.push({
                        ...defaultInputPorts[0],
                        name,
                        isConnected: false,
                        direction,
                        compatibleTypes,
                    });
                }
                inputPortsCount++;
            }
        }

        updatedOutputPorts = availableOutputPorts.map((port) => {
            const index = availablePorts.findIndex((p) => p.name === port.name);
            if (index !== -1) {
                return { ...port, name: `out_${index + 1 + addingInputsAmount}` };
            }
            return port;
        });

        newPorts = [...availableInputPorts, ...newInputAddingPorts, ...updatedOutputPorts];
    } else {
        const deletedInputsAmount = availableInputPorts.length - numberOfInputs;
        const newInputPorts = availableInputPorts.slice(0, numberOfInputs);

        updatedOutputPorts = availableOutputPorts.map((port) => {
            const index = availablePorts.findIndex((p) => p.name === port.name);

            if (index !== -1) {
                return { ...port, name: `out_${index + 1 - deletedInputsAmount}` };
            }
            return port;
        });

        newPorts = [...newInputPorts, ...updatedOutputPorts];
    }

    if (availableOutputPorts.length <= numberOfOutputs) {
        let outputPortsCount = availableOutputPorts.length + 1;
        let count = 0;
        while (outputPortsCount <= numberOfOutputs) {
            if (defaultOutputPorts.length !== 0) {
                const name = `out_${newPorts.length + 1 + count}`;
                const { direction, compatibleTypes } = findPortDetails(
                    libraryPortTypes,
                    defaultOutputPorts[0].typeConnection,
                    name
                );
                newOutputAddingPorts.push({
                    ...defaultOutputPorts[0],
                    name,
                    isConnected: false,
                    direction,
                    compatibleTypes,
                });
            }
            outputPortsCount++;
            count++;
        }

        newPorts = [...newPorts, ...newOutputAddingPorts];
    } else {
        const newOutputPorts = updatedOutputPorts.slice(0, numberOfOutputs);
        const newInputPorts = newPorts.filter((port) => port.name.includes('in'));
        newPorts = [...newInputPorts, ...newOutputPorts];
    }

    return newPorts;
};

const setWire = (
    wire: TSchemaConnection,
    type: string,
    elementId: number,
    newPortsNamesByOldPortsNames: { [key: string]: string }
) => {
    if (wire.source === elementId.toString() && wire.sourceHandle.includes('out')) {
        const oldPortNameSplitted = wire.sourceHandle.split('-');
        const oldPortName = oldPortNameSplitted[oldPortNameSplitted.length - 1];
        if (oldPortName) {
            const newPortName = newPortsNamesByOldPortsNames[oldPortName];
            const newPortIndex = newPortName?.split('_')[1];
            if (newPortIndex && newPortName) {
                const newSourceHandle = `${wire.source}-${newPortIndex}-${newPortName}`;
                return { ...wire, sourceHandle: newSourceHandle };
            }
        }
    } else if (wire.target === elementId.toString() && wire.targetHandle?.includes('out')) {
        const oldPortNameSplitted = wire.targetHandle.split('-');
        const oldPortName = oldPortNameSplitted[oldPortNameSplitted.length - 1];
        if (oldPortName) {
            const newPortName = newPortsNamesByOldPortsNames[oldPortName];
            const newPortIndex = newPortName?.split('_')[1];
            if (newPortName && newPortIndex) {
                const newTargetHandle = `${wire.target}-${newPortIndex}-${newPortName}`;
                return { ...wire, targetHandle: newTargetHandle };
            }
        }
    }
    return wire;
};

export const setDynamicOptions =
    (payload: { element: Pick<TElement, 'id' | 'type' | 'availablePorts'>; options: { [key: string]: string } }) =>
    async (dispatch: AppDispatch, getState: RootStateFn) => {
        const { type, id: elementId } = payload.element;
        if (payload.options) {
            if (!Object.keys(payload.options).length) {
                return;
            }
            const optionsArray = payload.options['parametersVector']
                ? payload.options['parametersVector'].split(';')
                : [];
            const options = payload.options;
            const state = getState()?.workspace;

            const { mode, elementId: workspaceMetaElementId } = state.meta;
            const groups = state.schema.schemaItems.groups || [];
            const group = groups.find((group) => group.id.toString() === workspaceMetaElementId);

            const connections = [WorkspaceModes.GROUP, WorkspaceModes.USER_BLOCK].includes(mode)
                ? group?.wires || []
                : state.schema.schemaItems.wires;

            const currentElementWithParams = state.schema.elementsWithParams.find(
                (el: TSchemaElementWithParams) => el.id === elementId
            )?.elemParams;

            const libraryItems = state.libraryItems.items;
            const libraryElement = libraryItems.find((item) => item.type === type);
            const libraryPortTypes = state.libraryPortTypes.items;

            const availableInputPorts = payload.element.availablePorts.filter((port) => port.type === PortTypes.INPUT);
            const availableOutputPorts = payload.element.availablePorts.filter(
                (port) => port.type === PortTypes.OUTPUT
            );

            const oldOutputPortNames: string[] = availableOutputPorts.map((port) => port.name);

            const newPortsFullIds: string[] = [];
            const newPortsNamesByOldPortsNames: { [key: string]: string } = {};

            let numberOfInputs = 0;
            let numberOfOutputs = 0;

            if (type === ELEMENT_ADDER_TYPE) {
                numberOfInputs = Object.values(payload.options)[0]
                    ?.split(';')
                    .filter((value: string) => value !== '').length;
                numberOfOutputs = 1;
            } else if (type === ELEMENT_CASE_TYPE) {
                const { numberOfCases } = payload.options;

                numberOfInputs = Number(numberOfCases) + 1;
                numberOfOutputs = 1;
            } else if (type === ELEMENT_MULTI_TYPE) {
                const { Input_ports, Output_ports } = payload.options;
                if (optionsArray.length) {
                    numberOfOutputs = Object.values(payload.options)[0]
                        ?.split(';')
                        .filter((value: string) => value != '').length;
                } else {
                    numberOfOutputs = Output_ports ? Number(Output_ports) : availableOutputPorts.length;
                }

                numberOfInputs = 2;
            } else {
                const { Input_ports, Output_ports } = payload.options;

                numberOfInputs = Input_ports ? Number(Input_ports) : availableInputPorts.length;
                numberOfOutputs = Output_ports ? Number(Output_ports) : availableOutputPorts.length;
            }

            const updateNodes = (elementsState: TSchemaNode[]) => {
                return elementsState.map((node: TSchemaNode) => {
                    const element = node.data;

                    const handleMargin = element?.view?.portMargin || CANVAS.PORT_MARGIN;
                    if (element.id === elementId) {
                        let newParameters: ElemParams[] = [];
                        if (libraryElement) {
                            if (hasParametersConditions(libraryElement)) {
                                newParameters = setParametersByVisibilityConditions(
                                    libraryElement,
                                    options,
                                    optionsArray,
                                    currentElementWithParams
                                );
                            } else {
                                newParameters = setDynamicParameters(
                                    libraryElement,
                                    type,
                                    numberOfInputs,
                                    numberOfOutputs,
                                    optionsArray,
                                    currentElementWithParams
                                );
                            }
                        }

                        let newPorts: TSchemaHandle[] = [];
                        if (libraryElement) {
                            if (hasParametersConditions(libraryElement)) {
                                const currElPort = state.schema.schemaItems.elements.find(
                                    (el) => el.data.id === elementId
                                )?.data.availablePorts;
                                const currElParam = state.schema.schemaItems.elements.find(
                                    (el) => el.data.id === elementId
                                )?.data.elemParams;

                                const newPortsNames = setParametersByVisibilityConditions(
                                    libraryElement,
                                    options,
                                    optionsArray,
                                    currentElementWithParams
                                )
                                    .filter((p) => p.isVisible)
                                    .map((p) => p.name);
                                newPorts = libraryElement.availablePorts
                                    .filter((port) => newPortsNames.includes(port.name))
                                    .map((p) => {
                                        const portDetails = findPortDetails(libraryPortTypes, p.typeConnection, p.name);
                                        const { direction, compatibleTypes } = portDetails;

                                        return {
                                            ...p,
                                            isConnected:
                                                currElPort?.find((currPort) => currPort.name === p.name)?.isConnected ??
                                                false,
                                            direction,
                                            compatibleTypes,
                                        };
                                    });
                            } else {
                                newPorts = setDynamicPorts(
                                    libraryElement,
                                    element,
                                    numberOfInputs,
                                    numberOfOutputs,
                                    libraryPortTypes
                                );
                            }
                        }

                        newPorts.forEach((port, index) => {
                            newPortsFullIds.push(`${elementId}-${index + 1}-${port.name}`);
                        });

                        const newOutputPorts = newPorts.filter((port) => port.type === PortTypes.OUTPUT);

                        oldOutputPortNames.map((oldName, index) => {
                            newPortsNamesByOldPortsNames[oldName] = newOutputPorts[index]?.name;
                        });
                        const maxPortsLength = calculatePortsLength(countMaxOfInputsAndOutputs(newPorts), handleMargin);

                        let newWidth = libraryElement?.view?.minWidth || node.width;
                        let newHeight = libraryElement?.view?.minHeight || node.height;

                        const hasNumberOfPortsChanged =
                            node?.data.availablePorts.length !== newPorts.length &&
                            libraryElement?.availablePorts.every((value, index) => value !== newPorts[index]);
                        let isWidthDynamicSize = false;
                        let isHeightDynamicSize = false;
                        if (element.type !== ELEMENT_CASE_TYPE) {
                            isWidthDynamicSize =
                                libraryElement?.availablePorts?.filter((p) => p.type === PortTypes.INPUT)[0]
                                    ?.position === PortPositions.TOP;
                            isHeightDynamicSize =
                                libraryElement?.availablePorts?.filter((p) => p.type === PortTypes.INPUT)[0]
                                    ?.position === PortPositions.LEFT;
                        } else {
                            const inputPorts = libraryElement?.availablePorts?.filter(
                                (p) => p.type === PortTypes.INPUT
                            );
                            if (inputPorts) {
                                isHeightDynamicSize = inputPorts[inputPorts.length - 1].position === PortPositions.LEFT;
                            }
                        }

                        if (isWidthDynamicSize) {
                            newWidth = Math.max(maxPortsLength, newWidth);
                        }

                        if (isHeightDynamicSize && hasNumberOfPortsChanged) {
                            newHeight =
                                maxPortsLength > CANVAS.ELEMENT_MIN_HEIGHT ? maxPortsLength : CANVAS.ELEMENT_MIN_HEIGHT;
                        }

                        return {
                            ...node,
                            width: newWidth,
                            height: newHeight,
                            data: {
                                ...element,
                                elemParams: newParameters,
                                availablePorts: newPorts,
                                view: {
                                    ...element.view,
                                },
                            },
                        };
                    }
                    return node;
                });
            };

            const updateWires = (wiresState: TSchemaConnection[]) => {
                return wiresState
                    .filter((wire) => [wire.source, wire.target].includes(elementId.toString()))
                    .map((wire) => setWire(wire, type, elementId, newPortsNamesByOldPortsNames));
            };

            const nodes = [WorkspaceModes.GROUP, WorkspaceModes.USER_BLOCK].includes(mode)
                ? updateNodes(group?.elements || [])
                : updateNodes(state.schema.schemaItems.elements);

            const elementsWithParams = state.schema.elementsWithParams.map((el) => {
                if (el.id === elementId) {
                    let newParameters: ElemParams[] = [];
                    if (libraryElement) {
                        if (hasParametersConditions(libraryElement)) {
                            newParameters = setParametersByVisibilityConditions(
                                libraryElement,
                                options,
                                optionsArray,
                                currentElementWithParams
                            );
                        } else {
                            newParameters = setDynamicParameters(
                                libraryElement,
                                type,
                                numberOfInputs,
                                numberOfOutputs,
                                optionsArray,
                                currentElementWithParams
                            );
                        }
                    }

                    return { ...el, elemParams: newParameters };
                }
                return el;
            });

            const updatedWires = [WorkspaceModes.GROUP, WorkspaceModes.USER_BLOCK].includes(mode)
                ? updateWires(group?.wires || [])
                : updateWires(state.schema.schemaItems.wires);

            const updatedParamsNames = elementsWithParams
                .find((el) => el.id === elementId)
                ?.elemParams.filter((p) => p.isVisible)
                .map((param) => param.name);

            const newWires = updatedWires.filter(
                (wire) => newPortsFullIds.includes(wire.sourceHandle) || newPortsFullIds.includes(wire.targetHandle)
            );
            const newWiresWithConditions = connections.filter((wire) => {
                const sourceHandleParamName = getHandleName(wire.sourceHandle);
                const targetHandleParamName = getHandleName(wire.targetHandle);
                return (
                    (wire.source === elementId.toString() && updatedParamsNames?.includes(sourceHandleParamName)) ||
                    (wire.target === elementId.toString() && updatedParamsNames?.includes(targetHandleParamName))
                );
            });
            const wiresForDelete = updatedWires.filter(
                (wire) => !newPortsFullIds.includes(wire.sourceHandle) && !newPortsFullIds.includes(wire.targetHandle)
            );
            const wiresForDeleteWithCond = connections.filter((con) => {
                const sourceHandleParamName = getHandleName(con.sourceHandle);
                const targetHandleParamName = getHandleName(con.targetHandle);
                return (
                    (elementId.toString() === con.source && !updatedParamsNames?.includes(sourceHandleParamName)) ||
                    (elementId.toString() === con.target && !updatedParamsNames?.includes(targetHandleParamName))
                );
            });
            const wiresWithoutBlock = connections.filter(
                (con) => con.source !== elementId.toString() && con.target !== elementId.toString()
            );

            dispatch(
                actions.updateNodesAndConnections({
                    nodes,
                    connections: [
                        ...wiresWithoutBlock,
                        ...(libraryElement && !hasParametersConditions(libraryElement)
                            ? newWires
                            : newWiresWithConditions),
                    ],
                    elementsWithParams,
                })
            );

            libraryElement && !hasParametersConditions(libraryElement)
                ? dispatch(actions.markConnectionsPortsAsUnconnected(wiresForDelete))
                : dispatch(actions.markConnectionsPortsAsUnconnected(wiresForDeleteWithCond));
        }
    };
