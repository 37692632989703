import React, { useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { v4 as uuidV4 } from 'uuid';

import { TranslationKey } from '@repeat/translations';
import { IconButton } from '@repeat/ui-kit';

import {
    InputListMainField,
    SInputList,
    SInputListError,
    SInputListHeader,
    SInputListItemWrapper,
    SInputListLabel,
    SInputListWrapper,
} from './SInputList';

import { messages } from '../../features/RightBar/translation';

interface InputListProps {
    id: string;
    initialValues: string;
    onChange: (name: string, value: string) => void;
    label?: string | { __html: string };
    disabled?: boolean;
}

const prepareValues = (values: string) => values.split(';').filter(Boolean);

export const InputDataTable: React.FC<InputListProps> = ({ id, initialValues, label, disabled = false, onChange }) => {
    const intl = useIntl();
    const filedId = useMemo(uuidV4, []);
    const valuesArray = prepareValues(initialValues);

    const [values, setValues] = useState<string[]>(valuesArray);
    const [errors, setErrors] = useState<boolean[]>(Array(valuesArray.length).fill(false));
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const inputRefs = useRef<HTMLInputElement[]>([]);

    const validationExp = useMemo(() => /^(-)?\d+(\.\d+)?$/, []);

    const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newValues = [...values];
        newValues[index] = event.target.value;
        setValues(newValues);

        const isValid = validationExp.test(event.target.value);
        const newErrors = [...errors];
        newErrors[index] = !isValid;
        setErrors(newErrors);
        onChange(id, newValues.join(';'));
    };

    const handleMainInputChange = (event: React.ChangeEvent) => {
        const target = event.target as HTMLInputElement;
        const inputValues = target.value.split(';');

        const newValues = inputValues.map((value) => value.trim());
        setValues(newValues);

        const newErrors = newValues.map((value) => !validationExp.test(value));
        setErrors(newErrors);
        onChange(id, newValues.join(';'));
    };

    const isCanAddValue = () => {
        return values.every((value, index) => value !== '' && !errors[index]);
    };

    const handleAddValue = () => {
        setValues([...values, '']);
        setErrors([...errors, false]);
    };

    const handleRemoveValue = (index: number) => {
        const newValues = values.filter((val, i) => i !== index);
        const newErrors = errors.filter((val, i) => i !== index);
        setValues(newValues);
        setErrors(newErrors);
        onChange(id, newValues.join(';'));
    };

    const handleKeyPress = (event: React.KeyboardEvent, index: number) => {
        if (event.code === 'ArrowDown' || event.code === 'ArrowUp' || event.code === 'Enter') {
            event.preventDefault();
        }

        if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
            const direction = event.code === 'ArrowDown' ? 1 : -1;
            const newIndex = index + direction;

            if (newIndex >= 0 && newIndex < values.length) {
                return inputRefs.current[newIndex]?.focus();
            }
        }

        if ((isCanAddValue() && event.code === 'Enter') || (isCanAddValue() && event.code === 'ArrowDown')) {
            return new Promise((resolve) => {
                handleAddValue();
                setTimeout(() => resolve(true), 100);
            }).finally(() => {
                const newIndex = values.length;
                if (newIndex >= 0 && inputRefs.current[newIndex]) {
                    inputRefs.current[newIndex]?.focus();
                }
            });
        }
    };

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <SInputList id={filedId}>
            <SInputListHeader>
                {label && <SInputListLabel htmlFor={filedId} dangerouslySetInnerHTML={label as { __html: string }} />}
                {!disabled && <IconButton fill={'var(--ui-label)'} noHover name={'chevron'} onClick={handleOpen} />}
                <InputListMainField>
                    <input
                        disabled={disabled}
                        type={'text'}
                        value={values.join(';')}
                        onChange={handleMainInputChange}
                    />
                </InputListMainField>
            </SInputListHeader>
            <SInputListWrapper isOpen={isOpen}>
                {values.map((value, index) => (
                    <SInputListItemWrapper key={`wrapper-input-list${index}`}>
                        <input
                            ref={(el) => {
                                if (el) {
                                    inputRefs.current[index] = el;
                                } else {
                                    delete inputRefs.current[index];
                                }
                            }}
                            key={`input-list-${index}`}
                            type='text'
                            value={value}
                            disabled={disabled}
                            onChange={(event) => handleInputChange(index, event)}
                            onKeyDown={(event) => handleKeyPress(event, index)}
                        />
                        <IconButton
                            data-name={'remove-value'}
                            noHover
                            name={'close'}
                            onClick={() => handleRemoveValue(index)}
                        />
                        {errors[index] && (
                            <SInputListError style={{ color: 'red' }} key={`error-input-list${index}`}>
                                {intl.formatMessage(messages[TranslationKey.ONLY_DIGITS_ERROR_MSG])}
                            </SInputListError>
                        )}
                    </SInputListItemWrapper>
                ))}
                <button data-name={'input-list-add-button'} onClick={handleAddValue} disabled={!isCanAddValue()}>
                    +
                </button>
            </SInputListWrapper>
        </SInputList>
    );
};
