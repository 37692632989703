import { FC } from 'react';

import { useAppDispatch } from '@repeat/hooks';
import { IconButton, uiColors } from '@repeat/ui-kit';

import { ApplicationActions } from '@repeat/common-slices';
import { ModalTypes, TBlockFileUpload } from '@repeat/models';
import { SElementControlPanel } from '../SComponents';

interface IElementEditFile {
    selected: boolean;
    type: string;
}

export const ElementEditFile: FC<IElementEditFile> = ({ selected, type }) => {
    const dispatch = useAppDispatch();

    const pulseqUpdate: TBlockFileUpload = {
        type: ModalTypes.ELEMENT_FILE_UPDATE,
        data: { type },
    };

    const handleUploadFile = () => {
        dispatch(ApplicationActions.showModal({ modal: pulseqUpdate }));
    };

    return (
        <SElementControlPanel selected={selected} fileUpload>
            <IconButton fill={uiColors.darkGrey} noHover name={'uploadFile'} onClick={handleUploadFile} />
        </SElementControlPanel>
    );
};
