import { FC, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';

import { getElementNotification } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { TElement, WorkspaceModes } from '@repeat/models';
import { showElementConfigurationsModal, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { pxToRem, ShowMore } from '@repeat/ui-kit';

import { ElementParameters } from './PropertiesAndParameters/ElementParameters';
import { ElementProperties } from './PropertiesAndParameters/ElementProperties';
import { IndicatorParameterForm } from './PropertiesAndParameters/IndicatorParameterForm';
import { ParametersSettings } from './PropertiesAndParameters/ParametersSettings';
import { PropertiesSettings } from './PropertiesAndParameters/PropertiesSettings';
import { SSelectedText } from './SNodeCurrent';
import { messages } from './translation';

import { useElementNotificationMessage } from '../../hooks/useElementNotificationMessage';
import { useWorkspaceDataContext } from '../Workspace/DataProvider/DataProvider';

export const NodeCurrent: FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const { mode: workspaceMode, userBlockId: userBlockID, readonly } = useWorkspaceDataContext();

    const selectedItems = useAppSelector(workspaceSelectors.selectedItems);
    const selectedNode = selectedItems.elements[0];

    const [isPropertiesSettingsBlockVisible, setIsPropertiesSettingsBlockVisible] = useState(false);
    const isJythonSelected = selectedNode?.data?.type === 'jython'; //TODO add universal flag to similar blocks
    const isGroupBlockSelected = selectedNode?.data?.type === 'group';

    const hasConfigurations = selectedNode?.data?.hasConfigurations;
    const selectedConfiguration = selectedNode?.data?.selectedConfiguration;
    const selectTypeHandler = () => {
        dispatch(showElementConfigurationsModal());
    };

    const getNotificationType = useCallback((element: TElement) => getElementNotification(element), []);
    const notificationType = selectedNode ? getNotificationType(selectedNode.data) : null;

    const { getMessage } = useElementNotificationMessage(notificationType);

    return (
        <>
            {notificationType !== null &&
                (selectedNode?.data.isDeprecated ? (
                    <ShowMore>{getMessage(notificationType)}</ShowMore>
                ) : (
                    <ShowMore title={formatMessage(messages[TranslationKey.ELEMENT_ATTENTION_LABEL])}>
                        {getMessage(notificationType)}
                    </ShowMore>
                ))}

            {selectedNode?.data.isIndicator && workspaceMode !== WorkspaceModes.SUBMODEL && !userBlockID && (
                <IndicatorParameterForm indicatorId={selectedNode?.data.id} />
            )}

            {hasConfigurations && (
                <ShowMore title={formatMessage(messages[TranslationKey.ELEMENT_CONFIGURATION])}>
                    {selectedConfiguration?.id !== null ? (
                        <SSelectedText>{selectedConfiguration?.name}</SSelectedText>
                    ) : (
                        <SSelectedText>
                            {formatMessage(messages[TranslationKey.ELEMENT_CONFIGURATION_CUSTOMIZABLE])}
                        </SSelectedText>
                    )}
                    {workspaceMode !== WorkspaceModes.SUBMODEL && !userBlockID && (
                        <Button type='submit' onClick={selectTypeHandler}>
                            {formatMessage(messages[TranslationKey.CHANGE])}
                        </Button>
                    )}
                </ShowMore>
            )}

            {workspaceMode !== WorkspaceModes.CODE_EDITOR && !selectedNode?.data.diff?.isErrorGettingProject && (
                <>
                    <ElementProperties />
                    {isGroupBlockSelected && !readonly && (
                        <>
                            {!isPropertiesSettingsBlockVisible ? (
                                !userBlockID && (
                                    <Button
                                        style={{
                                            marginLeft: pxToRem(16),
                                            marginTop: `-${pxToRem(16)}`,
                                            padding: 0,
                                        }}
                                        onClick={() => setIsPropertiesSettingsBlockVisible(true)}
                                    >
                                        {intl.formatMessage(messages[TranslationKey.WORKSPACE_ADD_PROPERTY])}
                                    </Button>
                                )
                            ) : (
                                <Button
                                    style={{
                                        marginLeft: pxToRem(16),
                                        marginTop: `-${pxToRem(16)}`,
                                        padding: 0,
                                    }}
                                    onClick={() => setIsPropertiesSettingsBlockVisible(false)}
                                >
                                    {intl.formatMessage(messages[TranslationKey.HIDE])}
                                </Button>
                            )}{' '}
                            {isPropertiesSettingsBlockVisible && (
                                <PropertiesSettings setVisibility={setIsPropertiesSettingsBlockVisible} />
                            )}
                        </>
                    )}
                </>
            )}

            {isJythonSelected ? (
                <ParametersSettings />
            ) : (
                !selectedNode?.data.diff?.isErrorGettingProject && <ElementParameters />
            )}
        </>
    );
};
