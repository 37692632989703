import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { SchemaItemTypes, TWorkspaceMode, WorkspaceModes } from '@repeat/models';
import { workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

import { STopPanelProjectName, STopPanelProjectNameClickable } from './STopPanel';
import { TopPanelBreadcrumbs } from './TopPanelBreadcrumbs';
import { messages } from './translation';

import { useWorkspaceDataContext } from '../DataProvider/DataProvider';
import { updateWorkspacePathItems } from 'libs/repeat/store/src/lib/slices/workspace';

export const TopPanelProjectName = () => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const {
        mode,
        projectName,
        userBlockName,
        previousMode,
        groupId,
        userBlockId,
        userBlockEditor,
        readonly,
        elementId,
    } = useWorkspaceDataContext();

    const node = useAppSelector(workspaceSelectors.getSchemaNodeById(elementId));
    const selectedItemsCount = useAppSelector(workspaceSelectors.schemaSelectedItemsCount);
    const schemaElements = useAppSelector(workspaceSelectors.schemaElements);
    const isMainGroupLevel = useAppSelector(workspaceSelectors.isMainGroupLevel);
    const resultGroupId = readonly && !isMainGroupLevel ? schemaElements[0]?.id : groupId;

    const [currentProjectName, setCurrentProjectName] = useState<string>(
        (projectName ? projectName : userBlockName) as string
    );

    const handleClearSelection = useCallback(() => {
        dispatch(workspaceActions.setSelectedItems({ ids: [], type: SchemaItemTypes.NODE }));
    }, []);

    const handleProjectNameClick = useCallback(async () => {
        const data = {
            mode:
                previousMode === WorkspaceModes.DEMO || previousMode === WorkspaceModes.USER_BLOCK
                    ? previousMode
                    : WorkspaceModes.MAIN,
            elementId: userBlockEditor ? resultGroupId : null,
            userBlockId: userBlockEditor ? userBlockId : null,
            groupId: userBlockEditor ? resultGroupId : null,
            readonly:
                previousMode === WorkspaceModes.DEMO ||
                previousMode === WorkspaceModes.PREVIEW ||
                (previousMode === WorkspaceModes.USER_BLOCK && !userBlockEditor),
        };
        await dispatch(workspaceActions.changeWorkspaceMode(data));
        dispatch(workspaceActions.resetWorkspacePath());
    }, [previousMode, groupId, mode]);

    const handleGroupNameClick = (el: { id: string; name: string; mode: TWorkspaceMode; readonly: boolean }) => {
        dispatch(
            workspaceActions.changeWorkspaceMode({
                mode: el.mode,
                elementId: el.id,
                groupId: el.id,
                previousMode: mode,
            })
        );
        dispatch(updateWorkspacePathItems(el.id, el.mode));
    };

    useEffect(() => {
        setCurrentProjectName(() => (projectName ? projectName : userBlockName) as string);
    }, [projectName, userBlockName]);

    return (
        <STopPanelProjectName data-tour='workspace-project-name'>
            {!userBlockId && !groupId && (
                <>
                    {(mode === WorkspaceModes.MAIN ||
                        mode === WorkspaceModes.DEMO ||
                        mode === WorkspaceModes.PREVIEW) &&
                        selectedItemsCount > 0 && (
                            <STopPanelProjectNameClickable onClick={handleClearSelection}>
                                {currentProjectName}
                            </STopPanelProjectNameClickable>
                        )}
                    {(mode === WorkspaceModes.MAIN ||
                        mode === WorkspaceModes.DEMO ||
                        mode === WorkspaceModes.PREVIEW) &&
                        selectedItemsCount === 0 && <span>{currentProjectName}</span>}
                    {mode === WorkspaceModes.CODE_EDITOR && node && (
                        <>
                            <STopPanelProjectNameClickable onClick={handleProjectNameClick}>
                                {currentProjectName}
                            </STopPanelProjectNameClickable>
                            <small>&nbsp;/&nbsp;</small>
                            <span>
                                {node.data.shortName} [{node.data.index}]
                            </span>
                        </>
                    )}
                    {mode === WorkspaceModes.FSM_EDITOR && node && (
                        <>
                            <STopPanelProjectNameClickable onClick={handleProjectNameClick}>
                                {currentProjectName}
                            </STopPanelProjectNameClickable>
                            &nbsp;/&nbsp;
                            <span>
                                {node.data.shortName} [{node.data.index}]
                            </span>
                        </>
                    )}
                    {mode === WorkspaceModes.SUBMODEL && node && (
                        <>
                            <STopPanelProjectNameClickable onClick={handleProjectNameClick}>
                                {currentProjectName}
                            </STopPanelProjectNameClickable>
                            &nbsp;/&nbsp;
                            <span>
                                {node.data.shortName} [{node.data.index}]
                            </span>
                        </>
                    )}
                    {mode === WorkspaceModes.CODE_EDITOR && !node && (
                        <>
                            <STopPanelProjectNameClickable onClick={handleProjectNameClick}>
                                {currentProjectName}
                            </STopPanelProjectNameClickable>
                            &nbsp;/&nbsp;
                            {formatMessage(messages[TranslationKey.SCHEMA_ELEMENT_DELETED])}
                        </>
                    )}
                </>
            )}
            {(!!userBlockId || !!groupId) && (
                <TopPanelBreadcrumbs
                    handleProjectNameClick={handleProjectNameClick}
                    handleGroupNameClick={handleGroupNameClick}
                    projectName={currentProjectName}
                />
            )}
        </STopPanelProjectName>
    );
};
