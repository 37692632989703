import { FC, useEffect, useRef, useState } from 'react';

import { useAppSelector } from '@repeat/hooks';
import { ILibraryItem, ModalTypes, TNewBlockInfoModal, TPropertyValue } from '@repeat/models';
import { InputField, StyledForm } from '@repeat/ui-kit';

import { useFormContext } from '../EngineerFormContext/EngineerFormContext';
import { EngineerService } from '../EngineerService';
import { SInputNotice, SPropertyValuesForm } from '../styled/SEditBlock';

interface IEditBlockEnProps {
    currentBlockProp: ILibraryItem;
    engineerService: EngineerService;
}

export const EditBlockEn: FC<IEditBlockEnProps> = (props) => {
    const [currentBlockEnValue, setCurrentBlockEnValue] = useState<ILibraryItem>();

    const nestedInputRefs = useRef(new Map());
    const [focusedIndex, setFocusedIndex] = useState<string>('');

    const { getEnBlock, enFormState, updateEnFormState } = useFormContext();

    const modals = useAppSelector((state) => state.app.modals);
    const modal = modals.find((m) => m.type === ModalTypes.NEWBLOCK_INFO) as TNewBlockInfoModal;
    const [block] = useState(modal.data.block);
    const [localBlock, setLocalBlock] = useState<ILibraryItem>(block);

    useEffect(() => {
        (async () => {
            const currentDBBlockEN = await getEnBlock(block.type);
            if (currentDBBlockEN) {
                setCurrentBlockEnValue(currentDBBlockEN);
            } else {
                setCurrentBlockEnValue(block);
            }
        })();
    }, []);

    useEffect(() => {
        if (nestedInputRefs.current.get(focusedIndex)) {
            nestedInputRefs.current.get(focusedIndex).focus();
        }
    }, [enFormState]);

    const preventRULettersInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const isCyrillic = /[а-яА-ЯЁё]/.test(e.key);
        if (isCyrillic) {
            e.preventDefault();
        }
    };

    if (!currentBlockEnValue) {
        return <div>Загрузка...</div>;
    }

    return (
        <StyledForm>
            <>
                <InputField
                    ref={(el) => nestedInputRefs.current.set(`name`, el)}
                    label='Название'
                    type='text'
                    id='standard-required'
                    value={enFormState?.name}
                    onChange={(e) => {
                        updateEnFormState('name', e.target.value);
                        setFocusedIndex(`enName`);
                    }}
                />
                <SInputNotice>{block?.name}</SInputNotice>
            </>
            <h5>Свойства блока (EN)</h5>
            {enFormState?.elemProps?.map((elemProp, index1) => (
                <>
                    <InputField
                        ref={(el) => nestedInputRefs.current.set(`enElemProps-${index1}`, el)}
                        key={elemProp.description}
                        label={elemProp.name}
                        onChange={(e) => {
                            const currentProps = enFormState.elemProps;
                            const updatedProps = currentProps?.map((item, i) => {
                                return i === index1 ? { ...item, description: e.target.value } : item;
                            });

                            updateEnFormState('elemProps', updatedProps);

                            setFocusedIndex(`enElemProps-${index1}`);
                        }}
                        value={elemProp.description ?? ''}
                        type='text'
                        onKeyDown={preventRULettersInput}
                    />

                    <SInputNotice>{elemProp.description}</SInputNotice>
                    {elemProp.availableValues &&
                        elemProp.availableValues?.map((value, index2) => {
                            return (
                                <SPropertyValuesForm>
                                    <h6>Значения свойства "{value.title}"</h6>

                                    <InputField
                                        ref={(el) =>
                                            nestedInputRefs.current.set(`enAvailableValues-${index1}-${index2}`, el)
                                        }
                                        value={
                                            enFormState?.elemProps
                                                ? enFormState?.elemProps[index1].availableValues?.[index2].title
                                                : ''
                                        }
                                        onChange={(e) => {
                                            const currentProps = enFormState.elemProps;
                                            const currentElemProp = enFormState.elemProps![index1];
                                            let changedAvValues: TPropertyValue[];
                                            if (currentElemProp.availableValues) {
                                                changedAvValues = currentElemProp.availableValues.map((item, i) =>
                                                    i === index2 ? { ...item, title: e.target.value } : item
                                                );
                                            }
                                            const newElemProps = currentProps?.map((item, i) =>
                                                i === index1 ? { ...item, availableValues: changedAvValues } : item
                                            );
                                            updateEnFormState('elemProps', newElemProps);

                                            setFocusedIndex(`enAvailableValues-${index1}-${index2}`);
                                        }}
                                        key={value.value}
                                        type='text'
                                        onKeyDown={preventRULettersInput}
                                    />
                                    <SInputNotice>{value.value}</SInputNotice>
                                </SPropertyValuesForm>
                            );
                        })}
                </>
            ))}

            <h5>Параметры блока (EN)</h5>
            {enFormState?.elemParams?.map((elemParam, index) => (
                <>
                    <InputField
                        ref={(el) => nestedInputRefs.current.set(`enElemParams-${index}`, el)}
                        value={elemParam.description ?? ''}
                        onChange={(e) => {
                            const currentParams = enFormState.elemParams;
                            const updatedParams = currentParams?.map((item, i) =>
                                i === index ? { ...item, description: e.target.value } : item
                            );
                            updateEnFormState('elemParams', updatedParams);

                            setFocusedIndex(`enElemParams-${index}`);
                        }}
                        key={elemParam.description}
                        label={elemParam.name}
                        type='text'
                        onKeyDown={preventRULettersInput}
                    />

                    <SInputNotice>{elemParam.description}</SInputNotice>
                </>
            ))}

            <h5>Параметры датчика (EN)</h5>
            {enFormState?.stateParameters?.map((stateParam, index) => (
                <>
                    <InputField
                        ref={(el) => nestedInputRefs.current.set(`enStateParams-${index}`, el)}
                        value={stateParam.description ?? ''}
                        onChange={(e) => {
                            const currentParams = enFormState.stateParameters;
                            const updatedParams = currentParams?.map((item, i) =>
                                i === index ? { ...item, description: e.target.value } : item
                            );
                            updateEnFormState('stateParameters', updatedParams);
                            setFocusedIndex(`enStateParams-${index}`);
                        }}
                        key={stateParam.description}
                        label={stateParam.name}
                        type='text'
                        onKeyDown={preventRULettersInput}
                    />

                    <SPropertyValuesForm>
                        <InputField
                            ref={(el) => nestedInputRefs.current.set(`enStateParamsUnit-${index}`, el)}
                            value={stateParam.unit ?? ''}
                            onChange={(e) => {
                                const currentParams = enFormState.stateParameters;
                                const updatedParams = currentParams?.map((item, i) =>
                                    i === index ? { ...item, unit: e.target.value } : item
                                );
                                updateEnFormState('stateParameters', updatedParams);

                                setFocusedIndex(`enStateParamsUnit-${index}`);
                            }}
                            key={stateParam.unit}
                            label={'Единица измерения'}
                            type='text'
                            onKeyDown={preventRULettersInput}
                        />
                    </SPropertyValuesForm>

                    <SInputNotice>{stateParam.description}</SInputNotice>
                </>
            ))}
        </StyledForm>
    );
};
