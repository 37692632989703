import styled, { css } from 'styled-components';
import { pxToRem } from '@repeat/ui-kit';

const TRIGGER_SIZE = 24;
const SNodeToolbarItemStyle = css`
    border-radius: 4px;
    background: var(--ui-sidebar);
    height: ${TRIGGER_SIZE}px;
    width: ${TRIGGER_SIZE}px;
    padding: 4px;
    margin-bottom: 4px;
    transition: all 0.3s ease-in-out;
    opacity: 0.6;
`;

export const SElementToolbar = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    > * {
        ${SNodeToolbarItemStyle};

        &:hover {
            opacity: 1;
        }
    }

    button {
        svg {
            max-height: ${pxToRem(20)};
            max-width: ${pxToRem(20)};
        }
    }
`;
