import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { SAVE_NEW_PROJECT_FORM, validateEmptySpaces, validateExistingProjectName } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { Project, TWorkspaceMode } from '@repeat/models';
import { saveNewProject, workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { InputField, StyledForm } from '@repeat/ui-kit';

import { projectMessages as messages } from '../translations';

interface ISaveNewProjectForm {
    project: Pick<Project, 'projectName' | 'projectId'>;
    onValid: (isValid: boolean) => void;
}

export const SaveNewProjectForm: FC<ISaveNewProjectForm> = ({ project, onValid }) => {
    const dispatch = useAppDispatch();
    const projectsState = useAppSelector((state) => state.projects);
    const status = projectsState.status;
    const projects = projectsState.items || [];
    const { readonly, mode, previousMode } = useAppSelector((state) => state.workspace.meta);

    const { formatMessage } = useIntl();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm<Pick<Project, 'projectName'>>({ mode: 'onChange' });

    useEffect(() => {
        onValid(isValid);
    }, [isValid]);

    const onSubmit = (data: Pick<Project, 'projectName'>) => {
        dispatch(saveNewProject({ ...data, projectId: project.projectId }))
            .then(() => {
                dispatch(
                    workspaceActions.changeWorkspaceMode({ readonly, mode: mode as TWorkspaceMode, previousMode })
                );
            })
            .finally(() => reset());
    };

    const inputProjectNameErrors =
        (errors.projectName?.type === 'required' &&
            true &&
            formatMessage(messages[TranslationKey.ERROR_PROJECT_NAME_IS_EMPTY])) ||
        (errors.projectName?.type === 'uniqueName' && formatMessage(messages[TranslationKey.ERROR_NEW_PROJECT_NAME])) ||
        (errors.projectName?.type === 'noWhitespace' &&
            formatMessage(messages[TranslationKey.ERROR_PROJECT_NAME_IS_EMPTY]));
    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)} id={SAVE_NEW_PROJECT_FORM}>
            <InputField
                label={formatMessage(messages[TranslationKey.PROJECT_NAME])}
                type='text'
                id='standard-required'
                {...register('projectName', {
                    required: true,
                    validate: {
                        noWhitespace: validateEmptySpaces,
                        uniqueName: (value) => validateExistingProjectName(value, projects),
                    },
                })}
                error={!!errors?.projectName}
                errorText={inputProjectNameErrors}
                defaultValue={project.projectName}
                maxLength={100}
            />
        </StyledForm>
    );
};
