import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHotkeysContext } from 'react-hotkeys-hook';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useNavigationPrompt } from '@repeat/common-hooks';
import {
    getLocalStorageState,
    IS_USE_LIBRARY_FROM_DB,
    isLocalStorageState,
    monoLogoUrl,
    setLocalStorageState,
    VERSION,
} from '@repeat/constants';
import { useAppDispatch, useAppSelector, useProjectId } from '@repeat/hooks';
import { SchemaItemTypes, SolverTypes, TWorkspaceMode, WorkspaceModes } from '@repeat/models';
import {
    appUserSelectors,
    closeProject,
    initializeWorkspace,
    uninitializeWorkspace,
    workspaceActions,
} from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Footer, Header, Layout, LoaderFullscreen, Main as MainBlock, Sidebar, TourContext } from '@repeat/ui-kit';

import { workspaceMessages } from './translation';

import { ModalProvider } from '../../features/BlockDescriptionModal/BlockDescriptionModalContext';
import { LeftBar } from '../../features/LeftBar/LeftBar';
import { ModelControl } from '../../features/ModelControl/ModelControl';
import { Navigation } from '../../features/Navigation/Navigation';
import { RightBar } from '../../features/RightBar/RightBar';
import { Visualization } from '../../features/Visualization/Visualization';
import { Canvas } from '../../features/Workspace/Canvas/Canvas';
import { CodeEditor } from '../../features/Workspace/CodeEditor/CodeEditor';
import { useWorkspaceDataContext } from '../../features/Workspace/DataProvider/DataProvider';
import { EventLog } from '../../features/Workspace/EventLog/EventLog';
import { FSMCanvas } from '../../features/Workspace/FSMCanvas/FSMCanvas';
import { ReadonlyCanvas } from '../../features/Workspace/ReadonlyCanvas/ReadonlyCanvas';
import { Tools } from '../../features/Workspace/Tools/Tools';
import { TopPanel } from '../../features/Workspace/TopPanel/TopPanel';
import { Variables } from '../../features/Workspace/Variables/Variables';

export const WorkspacePageLayout = () => {
    const [isFooterActive, setIsFooterActive] = useState(getLocalStorageState('footer'));
    const [activeFooterTab, setActiveFooterTab] = useState(() => {
        const activeTab = localStorage.getItem('footer-tab');
        if (activeTab && typeof activeTab === 'string') {
            return parseInt(activeTab);
        }
        return 0;
    });
    const [isFooterFullscreen, setIsFooterFullscreen] = useState(() => {
        return getLocalStorageState('footer-fullscreen');
    });

    const userIsAuth = useAppSelector(appUserSelectors.isAuth);
    const { isInitialized, isLoading } = useAppSelector((state) => state.workspace.meta);
    const { solverType } = useAppSelector((state) => state.workspace.schema);
    const { charts } = useAppSelector((state) => state.workspace.graphs);
    const { tools, globalVariables } = useAppSelector((state) => state.workspace.settings);

    const navigate = useNavigate();
    const { formatMessage, locale } = useIntl();
    const { projectId, userBlockId } = useProjectId();
    const dispatch = useAppDispatch();
    const { enableScope, disableScope } = useHotkeysContext();

    const { setOpen } = useContext(TourContext);

    const { mode, readonly, userBlockEditor: isUserBlockEditorMode, elementId } = useWorkspaceDataContext();

    useNavigationPrompt(`${formatMessage(workspaceMessages[TranslationKey.LEAVE_PAGE_MESSAGE])}`, true, () => {
        if (projectId || userBlockId) {
            dispatch(closeProject());
        }
    });

    const isModelingAvailable = !isUserBlockEditorMode;

    const footerTabs = useMemo(
        () => [
            {
                name: 'visualization',
                text: `${formatMessage(workspaceMessages[TranslationKey.WORKSPACE_PAGE_VISUALIZATION])}`,
                content: <Visualization mode={mode as TWorkspaceMode} />,
                disabled: isUserBlockEditorMode,
                ...(charts && { badge: Object.keys(charts).length }),
            },
            {
                name: 'journal',
                text: formatMessage(workspaceMessages[TranslationKey.WORKSPACE_PAGE_JOURNAL]),
                content: <EventLog />,
                disabled: isUserBlockEditorMode,
            },
            {
                name: 'variable',
                text: formatMessage(workspaceMessages[TranslationKey.WORKSPACE_PAGE_VARIABLES]),
                content: <Variables />,
                disabled: isUserBlockEditorMode,
                ...(globalVariables &&
                    Object.keys(globalVariables).length > 0 && { badge: Object.keys(globalVariables).length }),
            },
            {
                name: 'browse',
                text: formatMessage(workspaceMessages[TranslationKey.WORKSPACE_PAGE_TOOLS]),
                content: <Tools />,
                disabled: isUserBlockEditorMode,
                ...(tools && { badge: Object.keys(tools).length }),
            },
        ],
        [isUserBlockEditorMode, mode, globalVariables, tools, charts]
    );

    const getSidebarIsOpen = useCallback(
        (sidebarType: string) => {
            if (mode === WorkspaceModes.CODE_EDITOR) {
                return sidebarType !== 'left-bar';
            }

            return isLocalStorageState(sidebarType) ? getLocalStorageState(sidebarType) : true;
        },
        [mode]
    );

    const handleFooter = (arg: boolean) =>
        setIsFooterActive(() => {
            setLocalStorageState('footer', arg);
            return arg;
        });

    const handleTabChangeFooter = (state: number) => {
        setActiveFooterTab(state);
        setLocalStorageState('footer-tab', state);
    };

    const handleFullscreenFooter = (state: boolean) => {
        setIsFooterFullscreen(state);
        setLocalStorageState('footer-fullscreen', state);
    };

    const handleOpenFooter = () => {
        const sidebar = document.querySelector('div[data-name="elements-container"]') as HTMLDivElement;
        const searchBar = document.querySelector('div[data-name="elements-search"]') as HTMLDivElement;
        const rightBar = document.querySelector('div[data-tour="right-sidebar"]') as HTMLDivElement;
        const tagCloud = document.querySelector('div[data-name="tag-cloud"]') as HTMLDivElement;
        const elementName = document.querySelector('div[data-name="element-name"]') as HTMLDivElement;
        const elementNameHeight = elementName ? elementName.clientHeight : 0;
        if (sidebar !== null) {
            if (tagCloud && elementName) {
                const height =
                    window.innerHeight / 2 - (tagCloud.clientHeight + elementNameHeight + searchBar.clientHeight);
                sidebar.style.maxHeight = `${height / 2}px`;
            }
        }
        if (rightBar !== null) {
            rightBar.style.height = 'calc(100% / 2)';
            rightBar.style.overflowY = 'auto';
        }
    };

    const handleCloseFooter = () => {
        const sidebar = document.querySelector('div[data-name="elements-container"]') as HTMLDivElement;
        const rightBar = document.querySelector('div[data-tour="right-sidebar"]') as HTMLDivElement;
        const tagCloud = document.querySelector('div[data-name="tag-cloud"]') as HTMLDivElement;
        if (sidebar !== null) {
            if (tagCloud) {
                sidebar.style.maxHeight = `calc(100vh - ${tagCloud?.clientHeight}px)`;
            } else {
                sidebar.style.maxHeight = 'initial';
            }
        }
        if (rightBar !== null) {
            rightBar.style.height = `auto`;
        }
    };

    const handleClearSelection = () => {
        dispatch(workspaceActions.setSelectedItems({ ids: [], type: SchemaItemTypes.NODE }));
    };

    useEffect(() => {
        if (!isInitialized && !isLoading) {
            dispatch(initializeWorkspace(IS_USE_LIBRARY_FROM_DB));
        }

        if (isInitialized) {
            enableScope('workspacePage');

            return () => {
                dispatch(uninitializeWorkspace());
                disableScope('workspacePage');
            };
        }
        return;
    }, [isInitialized, isLoading]);

    useEffect(() => {
        if (isInitialized && solverType && ![SolverTypes.MDCORE, SolverTypes.JAUTO].includes(solverType)) {
            navigate('/projects');
        }
    }, [isInitialized, solverType]);

    if (!isInitialized) {
        return <LoaderFullscreen />;
    }

    return (
        <Layout withFooter withRightSidebar>
            <Header
                Navigation={userIsAuth && <Navigation setTourOpen={(open) => setOpen(open)} />}
                version={VERSION}
                logoUrl={monoLogoUrl(locale)}
                indexUrl='/projects'
            >
                {isModelingAvailable && <ModelControl />}
            </Header>
            <Sidebar
                open={getSidebarIsOpen('left-bar')}
                onMount={(e) => {
                    const isLeftBarOpen = localStorage.getItem('left-bar');
                    if (isLeftBarOpen === null) {
                        setLocalStorageState('left-bar', true);
                    }
                }}
                onTriggerClick={(e) => setLocalStorageState('left-bar', e)}
            >
                <LeftBar />
            </Sidebar>
            <ModalProvider>
                <Sidebar
                    open={getSidebarIsOpen('right-bar')}
                    onMount={(e) => {
                        const isRightBarOpen = localStorage.getItem('right-bar');
                        if (isRightBarOpen === null) {
                            setLocalStorageState('right-bar', true);
                        }
                    }}
                    onTriggerClick={(e) => setLocalStorageState('right-bar', e)}
                    position={'RIGHT'}
                >
                    <RightBar />
                </Sidebar>
            </ModalProvider>
            <TopPanel
                isFooterActive={isFooterActive}
                onHandleFooter={handleFooter}
                onHandleClearSelection={handleClearSelection}
            />
            <MainBlock>
                {readonly && mode !== WorkspaceModes.CODE_EDITOR && mode !== WorkspaceModes.FSM_EDITOR && (
                    <ReadonlyCanvas />
                )}

                {!readonly && mode !== WorkspaceModes.CODE_EDITOR && mode !== WorkspaceModes.FSM_EDITOR && <Canvas />}

                {mode === WorkspaceModes.CODE_EDITOR && elementId !== null && <CodeEditor elementId={elementId} />}

                {mode === WorkspaceModes.FSM_EDITOR && <FSMCanvas />}
            </MainBlock>
            <Footer
                onMount={() => {
                    if (isFooterActive) {
                        setTimeout(handleOpenFooter, 500);
                    }
                }}
                isFullscreen={isFooterFullscreen}
                onFullScreen={(state) => handleFullscreenFooter(state)}
                onTabChange={(state) => handleTabChangeFooter(state)}
                onOpen={() => handleOpenFooter()}
                onClose={() => handleCloseFooter()}
                setIsActiveHandler={handleFooter}
                activeTab={activeFooterTab}
                isActive={isFooterActive}
                tabs={footerTabs}
            />
        </Layout>
    );
};
