import React, { useContext } from 'react';

import { AbilityContext } from '../../../context/Can';
import { CodeGeneration } from '../CodeGeneration/CodeGeneration';
import { CodeGenerationNoName } from '../CodeGeneration/CodeGenerationNoName';
import { useWorkspaceDataContext } from '../DataProvider/DataProvider';

export const TopPanelCodeGenMenu = () => {
    const { projectId, projectName } = useWorkspaceDataContext();
    const ability = useContext(AbilityContext);
    const isCodeGenAvailable = ability.can('use', `tool-CODE_GENERATION`);

    return (
        <>
            {projectId && isCodeGenAvailable && (
                <CodeGeneration projectId={parseInt(projectId)} projectName={projectName as string} />
            )}
            {projectId && <CodeGenerationNoName projectId={parseInt(projectId)} projectName={projectName as string} />}
        </>
    );
};
