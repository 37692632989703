import { FC, memo, useEffect, useState } from 'react';

import { SContextMenu, SContextMenuOption } from './SContextMenu';

import { Icon } from '../Icon/Icon';

export type IContextMenuItem = {
    text: string;
    onClick: (id: number) => void;
    icon?: string;
};

export type TTarget = number | null;

export interface IContextMenuData {
    handleContextMenuRightClick: (event: any, position?: any) => void;
    handleContextMenuLeftClick: (event: any) => void;
    handleGlobalClick: (event: any) => void;
    target: TTarget;
    setTarget: (newTarget: TTarget) => void;
    Menu: any;
}

interface IMenu {
    items: IContextMenuItem[];
}

function adjustContextMenuPosition(menuRect: { width: number; height: number; left: number; top: number }): {
    left: number;
    top: number;
} {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    let newLeft = menuRect.left;
    let newTop = menuRect.top;

    if (menuRect.left + menuRect.width > windowWidth) {
        newLeft = windowWidth - menuRect.width;
        if (newLeft < 0) {
            newLeft = 0;
        }
    }

    if (menuRect.top + menuRect.height > windowHeight) {
        newTop = windowHeight - menuRect.height;
        if (newTop < 0) {
            newTop = 0;
        }
    }

    return { left: newLeft, top: newTop };
}

export const useContextMenu = () => {
    const [target, setTarget] = useState<TTarget>(null);
    const [position, setPosition] = useState({ top: '0', left: '0' });
    const [visible, setVisible] = useState(false);

    const handleSetTarget = (newTarget: number | null) => {
        setTimeout(() => setTarget(() => newTarget), 100);
    };

    const handleContextMenuRightClick = (event: any) => {
        event.preventDefault();
        setTarget(null);
        const styles = {
            top: `${event.clientY + 4}px`,
            left: `${event.clientX + 4}px`,
        };
        setVisible(true);
        setPosition(styles);
    };

    const handleContextMenuLeftClick = (event: any) => {
        event.preventDefault();
        setTarget(null);

        const target = event.target as HTMLElement;

        if (target.getAttribute('data-name') !== 'context-menu-item') {
            setVisible(false);
        }
    };

    const handleGlobalClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        if (!target.closest('[data-name="context-menu"]')) {
            setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleGlobalClick);
        return () => {
            document.removeEventListener('click', handleGlobalClick);
        };
    }, []);

    const Menu: FC<IMenu> = ({ items }) => {
        if (typeof target === 'number' && visible) {
            const menuRect = {
                width: 200, // Предполагаемая ширина меню
                height: items.length * 40, // Предполагаемая высота меню (40px на один элемент)
                left: parseInt(position.left),
                top: parseInt(position.top),
            };

            const adjustedPosition = adjustContextMenuPosition(menuRect);

            return (
                <SContextMenu
                    onClick={(event) => event.stopPropagation()}
                    data-name={`context-menu-${target}`}
                    key={`context-menu-${target}`}
                    style={{ top: `${adjustedPosition.top}px`, left: `${adjustedPosition.left}px` }}
                    visible={visible}
                >
                    {items.map((item, index) => {
                        return (
                            <SContextMenuOption
                                className={'context-menu-item'}
                                key={`context-menu-item-${index}`}
                                data-name={'context-menu-item'}
                                onClick={() => {
                                    if (target) {
                                        item.onClick(target);
                                        setVisible(false);
                                        setTarget(null);
                                    }
                                }}
                            >
                                {item.icon && <Icon name={item.icon} />}
                                {item.text}
                            </SContextMenuOption>
                        );
                    })}
                </SContextMenu>
            );
        }
        return null;
    };

    const returnData: IContextMenuData = {
        handleContextMenuRightClick,
        handleContextMenuLeftClick,
        handleGlobalClick,
        target,
        setTarget: handleSetTarget,
        Menu: memo((props: IMenu) => <Menu {...props} />),
    };

    return returnData;
};

export { SContextMenuItem, SContextMenu, SContextMenuContainer } from './SContextMenu';
