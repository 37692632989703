import { NavigateFunction } from 'react-router-dom';

import { AnyAction, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import hash from 'object-hash';
import { v4 as uuidv4 } from 'uuid';

import { ApplicationActions } from '@repeat/common-slices';
import {
    calculateBlockHeight,
    calculatePortsLength,
    CANVAS,
    countMaxOfInputsAndOutputs,
    findGroups,
    getAppLocale,
    getHandleName,
    IS_USE_USER_BLOCKS,
    replaceSubstring,
    setWorkspaceMode,
} from '@repeat/constants';
import {
    ElemParams,
    ElemProps,
    ILibraryItem,
    IModulesCounter,
    IWorkspaceState,
    LibraryTypes,
    ModalTypes,
    NotificationTypes,
    PortTypes,
    SolverTypes,
    Statuses,
    TLibraryItemPort,
    TLibraryType,
    TProxyMap,
    TSchemaConnection,
    TSchemaGroup,
    TSchemaHandle,
    TSchemaNode,
    TUserBlockData,
    WorkspaceModes,
} from '@repeat/models';
import { makeSchemaNode, UserBlockService } from '@repeat/services';
import { fileManagerActions, workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { EFileManagerItemType, TFileManagerElement } from '@repeat/ui-kit';

import { AppDispatch, RootStateFn } from '../../../store';
import { actions, fetchLibraryItems, fetchLibraryPortTypes } from '../index';
import { getActionModulesResult, getModulesDiff } from '../modules/modulesSlice';
import { increaseUserBlocksCount } from '../schema/helper';

export const userBlocksReducers = {
    getUserBlocksRequest: (state: IWorkspaceState) => {
        state.userBlocks.getUserBlocks.status = Statuses.LOADING;
    },
    getUserBlocksSuccess: (state: IWorkspaceState, { payload }: PayloadAction<ILibraryItem[]>) => {
        state.userBlocks.items = payload;
        state.libraryItems.items.push(...payload);
        state.userBlocks.getUserBlocks.status = Statuses.SUCCEEDED;
    },
    getUserBlocksFailed: (state: IWorkspaceState, action: PayloadAction<{ error: string }>) => {
        state.userBlocks.getUserBlocks.status = Statuses.FAILED;
        state.userBlocks.getUserBlocks.error = action.payload.error;
    },

    addUserBlockRequest: (state: IWorkspaceState) => {
        state.userBlocks.addUserBlock.status = Statuses.LOADING;
    },
    addUserBlockSuccess: (state: IWorkspaceState, { payload }: PayloadAction<ILibraryItem>) => {
        state.libraryItems.items.push(payload);
        state.userBlocks.items.push(payload);
        state.userBlocks.addUserBlock.status = Statuses.SUCCEEDED;
    },
    addUserBlockFailed: (state: IWorkspaceState, action: PayloadAction<{ error: string }>) => {
        state.userBlocks.addUserBlock.status = Statuses.FAILED;
        state.userBlocks.addUserBlock.error = action.payload.error;
    },

    deleteUserBlocksRequest: (state: IWorkspaceState) => {
        state.userBlocks.deleteUserBlocks.status = Statuses.LOADING;
    },
    deleteUserBlocksSuccess: (state: IWorkspaceState, { payload }: PayloadAction<string>) => {
        state.userBlocks.items = state.userBlocks.items.filter((item) => item.blockId && item.blockId !== payload);
        state.userBlocks.deleteUserBlocks.status = Statuses.SUCCEEDED;
    },
    deleteUserBlocksFailed: (state: IWorkspaceState, action: PayloadAction<{ error: string }>) => {
        state.userBlocks.deleteUserBlocks.status = Statuses.FAILED;
        state.userBlocks.deleteUserBlocks.error = action.payload.error;
    },

    getUserBlockRequest: (state: IWorkspaceState) => {
        state.userBlocks.getUserBlock.status = Statuses.LOADING;
    },
    getUserBlockSuccess: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ groups: TSchemaGroup[]; id: string; blockId: string }>
    ) => {
        state.schema.schemaItems.userBlockGroups = payload.groups;
        state.userBlocks.getUserBlock.status = Statuses.SUCCEEDED;
    },
    getUserBlockFailed: (state: IWorkspaceState, action: PayloadAction<{ error: string }>) => {
        state.userBlocks.getUserBlock.status = Statuses.FAILED;
        state.userBlocks.getUserBlock.error = action.payload.error;
    },

    updateElementWithUserBlock: (
        state: IWorkspaceState,
        {
            payload,
        }: PayloadAction<{
            data: { [key: string]: string | number | null };
            id: string;
            image: string | null;
            blockId: string;
            hash: string;
        }>
    ) => {
        const { data, id, image, blockId, hash } = payload;
        const elements = state.schema.schemaItems.elements;
        const elementsWithParams = state.schema.elementsWithParams;
        let selectedElement = state.schema.selectedItems.elements[0];
        const schema = state.schema;
        const userBlocksCount = state.schema.userBlocksCount || {};
        const userBlocks = increaseUserBlocksCount(userBlocksCount, [blockId]);

        const elementIndex = elements.findIndex((el) => el.id === id);
        if (elementIndex !== -1) {
            elements[elementIndex] = {
                ...elements[elementIndex],
                data: {
                    ...elements[elementIndex].data,
                    ...data,
                    image,
                    blockId,
                    hash,
                    view: { ...elements[elementIndex].data.view, isResizable: true },
                },
            };
        }
        const indexInElementsWithParams = elementsWithParams.findIndex((el) => el.id.toString() === id);
        if (indexInElementsWithParams !== -1) {
            elementsWithParams[indexInElementsWithParams] = {
                ...elementsWithParams[indexInElementsWithParams],
                blockId,
            };
        }
        selectedElement = { ...selectedElement, ...data };
        schema.userBlocksCount = userBlocks;
        state.schema.schemaItems.groups = state.schema.schemaItems.groups?.filter(
            (group) => group.id.toString() !== id
        );
    },
    addLibraryItemProperty: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ blockId: string; elementId: string; name: string }>
    ) => {
        const { blockId, elementId, name } = payload;

        const libraryItems = state.libraryItems.items;
        const elements = state.schema.schemaItems.groups?.find((group) => group.parentGroupId === null)?.elements || [];
        const elementMain = state.schema.schemaItems.elements[0];
        const index = libraryItems.findIndex((item) => item.blockId === blockId);
        if (index !== -1) {
            const element = elements.find((el) => el.id === elementId);
            const elementProperty = element?.data.elemProps.find((prop) => prop.name === name);

            if (element && elementProperty) {
                const property = {
                    ...elementProperty,
                    name: `${name}-${elementId}`,
                    description: `${element.data.name} [${element.data.index}] / ${elementProperty.description}`,
                };
                libraryItems[index] = {
                    ...libraryItems[index],
                    elemProps: [...libraryItems[index].elemProps, property],
                };

                if (elementMain) {
                    elementMain.data.elemProps.push(property);

                    const proxyMap = elementMain.data.proxyMap || { props: [], params: [] };

                    const proxyMapProp = {
                        name: `${name}-${elementId}`,
                        internalBlockId: Number(elementId),
                        internalName: name,
                    };
                    elementMain.data.proxyMap = { ...proxyMap, props: [...proxyMap.props, proxyMapProp] };
                }
            }
        }
    },

    setLibraryItemPropertyDescription: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ name: string; description: string }>
    ) => {
        const { name, description } = payload;
        const blockId = state.meta.userBlockId;

        const libraryItems = state.libraryItems.items;
        const index = libraryItems.findIndex((item) => item.blockId === blockId);
        const elementMain = state.schema.schemaItems.elements[0];

        if (index !== -1) {
            const elemProps = libraryItems[index].elemProps.map((prop) => {
                if (prop.name === name) {
                    return { ...prop, description: description };
                }
                return prop;
            }) as ElemProps[];

            libraryItems[index].elemProps = elemProps;

            if (elementMain) {
                const elemProps = elementMain.data.elemProps.map((prop) => {
                    if (prop.name === name) {
                        return { ...prop, description: description };
                    }
                    return prop;
                }) as ElemProps[];

                elementMain.data.elemProps = elemProps;
            }
        }
    },
    deleteLibraryItemProperty: (state: IWorkspaceState, { payload }: PayloadAction<{ propertyName: string }>) => {
        const blockId = state.meta.userBlockId;
        const libraryItems = state.libraryItems.items;
        const index = libraryItems.findIndex((item) => item.blockId === blockId);
        const element = state.schema.schemaItems.elements[0];

        if (element) {
            element.data.elemProps = element.data.elemProps.filter((prop) => prop.name !== payload.propertyName);

            const proxyMap = element.data.proxyMap || { props: [], params: [] };

            element.data.proxyMap = {
                ...proxyMap,
                props: proxyMap.props.filter((prop) => prop.name !== payload.propertyName),
            };
        }
    },

    setSchemaSuccess: (state: IWorkspaceState, { payload }: PayloadAction<{ groups: TSchemaGroup[] }>) => {
        const { groups } = payload;
        const group = groups.find((group) => group.parentGroupId === null); // верхняя группа

        return {
            ...state,
            schema: {
                ...state.schema,
                libraryType: LibraryTypes.MDLIBRARY,
                solverType: SolverTypes.MDCORE,
                schemaItems: { ...state.schema.schemaItems, groups },
            },
        };
    },
    addHelperNode: (state: IWorkspaceState, { payload }: PayloadAction<{ node: TSchemaNode | null }>) => {
        const { node } = payload;
        if (!node) {
            return state;
        }
        const elements = node ? [node] : [];

        return {
            ...state,
            userBlocks: {
                ...state.userBlocks,
                getUserBlock: { ...state.userBlocks.getUserBlock, status: Statuses.SUCCEEDED },
            },
            schema: {
                ...state.schema,
                elementsWithParams: [
                    {
                        id: node.data.id,
                        name: node.data.name,
                        index: node.data.index,
                        elemParams: node.data.elemParams,
                        blockId: node.data.blockId,
                    },
                ],
                schemaItems: { ...state.schema.schemaItems, elements },
            },
        };
    },

    setUserBlockDiffIsChanged: (
        state: IWorkspaceState,
        {
            payload,
        }: PayloadAction<{
            id: string;
            hash: string | null;
            elemParams: ElemParams[];
            elemProps: ElemProps[];
            availablePorts: TSchemaHandle[];
            newHeight: number;
            proxyMap: TProxyMap | null;
            changedBlocksUids: string[];
            elemPropsById: Record<string, ElemProps[]>;
            modules?: IModulesCounter;
        }>
    ) => {
        const { id, hash, elemParams, elemProps, availablePorts, newHeight, modules, changedBlocksUids } = payload;
        const portsLibraryInputs: TLibraryItemPort[] = availablePorts.filter((port) => port.type === PortTypes.INPUT);
        const portsLibraryOutputs: TLibraryItemPort[] = availablePorts.filter((port) => port.type === PortTypes.OUTPUT);
        const { elements: stateElements, wires: stateWires, groups: stateGroups } = state.schema.schemaItems;

        const updateWires = (wires: TSchemaConnection[], userBlocksIds: string[]) => {
            const wiresNew = wires.filter(
                (wire) => !userBlocksIds.includes(wire.source) && !userBlocksIds.includes(wire.target)
            );
            return {
                wiresNew,
                deletedWires: wires.filter((x) => !wiresNew.some((y) => x.id === y.id)),
            };
        };

        const updateElements = (
            elements: TSchemaNode[],
            wiresNew: TSchemaConnection[],
            deletedWires: TSchemaConnection[]
        ) => {
            const wiresNewSourceIds = wiresNew.map((wire) => wire.source);
            const wiresNewTargetIds = wiresNew.map((wire) => wire.target);

            const deletedWiresSourceIds = deletedWires.map((wire) => wire.source);
            const deletedWiresTargetIds = deletedWires.map((wire) => wire.target);
            return elements.map((el) => {
                if (el.data.type === 'userBlock' && el.data.blockId && el.data.blockId === id) {
                    const diffAdd = { userBlockIsChanged: true };

                    const diffRes = el.data.diff
                        ? { ...el.data.diff, ...diffAdd }
                        : { connectedProject: false, isErrorGettingProject: false, ...diffAdd };

                    const availablePorts = el.data.availablePorts;

                    const availablePortsInputs = availablePorts.filter((p) => p.type === PortTypes.INPUT);
                    const availablePortsOutputs = availablePorts.filter((p) => p.type === PortTypes.OUTPUT);

                    const availablePortsInputsNames = availablePortsInputs.map((p) => p.name);
                    const availablePortsOutputsNames = availablePortsOutputs.map((p) => p.name);

                    let portsResultInputs = [...availablePortsInputs];
                    let portsResultOutputs = [...availablePortsOutputs];

                    if (availablePortsInputs.length < portsLibraryInputs.length) {
                        portsLibraryInputs.forEach((port) => {
                            if (!availablePortsInputsNames.includes(port.name)) {
                                portsResultInputs.push({ ...port, isConnected: false } as TSchemaHandle);
                            }
                        });
                    } else if (availablePortsInputs.length > portsLibraryInputs.length) {
                        const portsLibraryInputsNames = portsLibraryInputs.map((p) => p.name);
                        portsResultInputs = portsResultInputs
                            .filter((p) => portsLibraryInputsNames.includes(p.name))
                            .map((port) => ({
                                ...port,
                                isConnected: false,
                            }));
                    } else {
                        portsResultInputs = portsLibraryInputs.map((port) => {
                            return {
                                ...port,
                                isConnected: false,
                            };
                        }) as TSchemaHandle[];
                    }

                    if (availablePortsOutputs.length < portsLibraryOutputs.length) {
                        portsLibraryOutputs.forEach((port) => {
                            if (!availablePortsOutputsNames.includes(port.name)) {
                                portsResultOutputs.push({ ...port, isConnected: false } as TSchemaHandle);
                            }
                        });
                    } else if (availablePortsOutputs.length > portsLibraryOutputs.length) {
                        const portsLibraryOutputsNames = portsLibraryOutputs.map((p) => p.name);
                        portsResultOutputs = portsResultOutputs
                            .filter((p) => portsLibraryOutputsNames.includes(p.name))
                            .map((port) => ({
                                ...port,
                                isConnected: false,
                            }));
                    } else {
                        portsResultOutputs = portsLibraryOutputs.map((port) => {
                            return {
                                ...port,
                                isConnected: false,
                            };
                        }) as TSchemaHandle[];
                    }
                    const elemPropsByIdNames = new Set(payload.elemPropsById[el.id].map((p) => p.name));
                    const elemPropsRest = payload.elemProps.filter((p) => !elemPropsByIdNames.has(p.name));

                    const elemProps = payload.elemPropsById[el.id].map((prop) => {
                        const description = payload.elemProps.find((p) => p.name === prop.name)?.description;
                        if (description) {
                            return { ...prop, description };
                        }
                        return prop;
                    });
                    return {
                        ...el,
                        height: newHeight,
                        data: {
                            ...el.data,
                            diff: diffRes,
                            elemProps: [...elemProps, ...elemPropsRest],
                            hash,
                            elemParams: payload.elemParams,
                            availablePorts: payload.availablePorts,
                            view: { ...el.data.view, minHeight: newHeight },
                            proxyMap: payload.proxyMap,
                            modules,
                        },
                    };
                }
                if (deletedWiresSourceIds.includes(el.id) && deletedWiresTargetIds.includes(el.id)) {
                    const wiresBySource = deletedWires.filter((wire) => wire.source === el.id);
                    const wiresByTarget = deletedWires.filter((wire) => wire.target === el.id);

                    const portsNamesBySource = wiresBySource.map((wire) => getHandleName(wire.sourceHandle));
                    const portsNamesByTarget = wiresByTarget.map((wire) => getHandleName(wire.targetHandle));
                    const portsNames = [...portsNamesBySource, ...portsNamesByTarget];

                    const ports = el.data.availablePorts.map((port) => {
                        if (portsNames.includes(port.name)) {
                            return { ...port, isConnected: false };
                        }
                        return port;
                    });
                    return { ...el, data: { ...el.data, availablePorts: ports } };
                }
                if (deletedWiresSourceIds.includes(el.id)) {
                    const wires = deletedWires.filter((wire) => wire.source === el.id);
                    const portsNames = wires.map((wire) => getHandleName(wire.sourceHandle));

                    const ports = el.data.availablePorts.map((port) => {
                        if (portsNames.includes(port.name)) {
                            return { ...port, isConnected: false };
                        }
                        return port;
                    });
                    return { ...el, data: { ...el.data, availablePorts: ports } };
                }
                if (deletedWiresTargetIds.includes(el.id)) {
                    const wires = deletedWires.filter((wire) => wire.target === el.id);
                    const portsNames = wires.map((wire) => getHandleName(wire.targetHandle));

                    const ports = el.data.availablePorts.map((port) => {
                        if (portsNames.includes(port.name)) {
                            return { ...port, isConnected: false };
                        }
                        return port;
                    });
                    return { ...el, data: { ...el.data, availablePorts: ports } };
                }
                if (el.data.type === 'userBlock' && wiresNewSourceIds.includes(el.id)) {
                    const wire = wiresNew.find((wire) => wire.source === el.id);
                    const sourcePortName = getHandleName(wire?.sourceHandle || '');

                    const ports = el.data.availablePorts.map((port) => {
                        if (port.name === sourcePortName) {
                            return { ...port, isConnected: true };
                        }
                        return port;
                    });
                    return { ...el, data: { ...el.data, availablePorts: ports } };
                }

                if (el.data.type === 'userBlock' && wiresNewTargetIds.includes(el.id)) {
                    const wire = wiresNew.find((wire) => wire.target === el.id);

                    const targetPortName = getHandleName(wire?.targetHandle || '');
                    const ports = el.data.availablePorts.map((port) => {
                        if (port.name === targetPortName) {
                            return { ...port, isConnected: true };
                        }
                        return port;
                    });
                    return { ...el, data: { ...el.data, availablePorts: ports } };
                }
                return el;
            });
        };

        const updateSchema = (
            elements: TSchemaNode[],
            wires: TSchemaConnection[]
        ): { elements: TSchemaNode[]; wires: TSchemaConnection[] } => {
            const userBlocksElementsIds: string[] = elements
                .filter(
                    (el) =>
                        el.data.type === 'userBlock' && el.data.blockId && changedBlocksUids.includes(el.data.blockId)
                )
                .map((el) => el.id);

            const { wiresNew, deletedWires } = updateWires(wires, userBlocksElementsIds);

            const elementsNew = updateElements(elements, wiresNew, deletedWires);
            return { elements: elementsNew, wires: wiresNew };
        };

        const elementsWithParams = state.schema.elementsWithParams.map((el) => {
            if (el.blockId === id) {
                return { ...el, elemParams };
            }
            return el;
        });

        const { elements, wires } = updateSchema(stateElements, stateWires);
        const groups = stateGroups?.map((group) => {
            const { elements, wires } = updateSchema(group.elements, group.wires);
            return { ...group, elements, wires };
        });

        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: { ...state.schema.schemaItems, elements, wires, groups },
                elementsWithParams,
            },
        };
    },
    setUserBlockDiffIsDeleted: (state: IWorkspaceState, { payload }: PayloadAction<string[]>) => {
        const blockIds = payload;
        const updateElements = (elements: TSchemaNode[]) => {
            return elements.map((el) => {
                if (el.data.type === 'userBlock' && el.data.blockId && blockIds.includes(el.data.blockId)) {
                    const diffAdd = { userBlockIsDeleted: true, userBlockIsChanged: false };

                    const diffRes = el.data.diff
                        ? { ...el.data.diff, ...diffAdd }
                        : { connectedProject: false, isErrorGettingProject: false, ...diffAdd };
                    return { ...el, data: { ...el.data, diff: diffRes } };
                }
                return el;
            });
        };
        const elements = updateElements(state.schema.schemaItems.elements);
        const groupsState = state.schema.schemaItems.groups || [];
        const groups = groupsState.map((group) => ({ ...group, elements: updateElements(group.elements) }));
        return {
            ...state,
            schema: {
                ...state.schema,
                schemaItems: { ...state.schema.schemaItems, elements, groups },
            },
        };
    },
    updateUserBlocksSuccess: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ [blockId: string]: TUserBlockData }>
    ) => {
        const blocksIds = Object.keys(payload);
        const elements = state.schema.schemaItems.elements.map((el) => {
            if (el.data.type === 'userBlock' && el.data.blockId && blocksIds.includes(el.data.blockId)) {
                return {
                    ...el,
                    data: { ...el.data, ...payload[el.data.blockId], view: { ...el.data.view, minHeight: el.height } },
                };
            }
            return el;
        });
        return { ...state, schema: { ...state.schema, schemaItems: { ...state.schema.schemaItems, elements } } };
    },
    setLibraryItemParameterDescription: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ name: string; description: string }>
    ) => {
        const blockId = state.meta.userBlockId;
        const libraryItems = state.libraryItems.items.map((item) => {
            if (item.blockId === blockId) {
                const elemParams = item.elemParams.map((p) => {
                    if (p.name === payload.name) {
                        return { ...p, description: payload.description };
                    }
                    return p;
                });
                return { ...item, elemParams };
            }
            return item;
        });
        return { ...state, libraryItems: { ...state.libraryItems, items: libraryItems } };
    },
    editUserBlock: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ blockId: string; userData: TUserBlockData }>
    ) => {
        const libraryItems = state.libraryItems.items.map((item) => {
            if (item.blockId === payload.blockId) {
                return { ...item, ...payload.userData, shortName: payload.userData['name'] };
            }
            return item;
        });
        return { ...state, libraryItems: { ...state.libraryItems, items: libraryItems } };
    },
    editUserBlockParameters: (state: IWorkspaceState, { payload }: PayloadAction<ILibraryItem>) => {
        const userBlocks = state.userBlocks.items.map((item) => {
            if (item.blockId === payload.blockId) {
                return payload;
            }
            return item;
        });
        const libraryItems = state.libraryItems.items.map((item) => {
            if (item.blockId === payload.blockId) {
                return payload;
            }
            return item;
        });
        const groups = state.schema.schemaItems.groups?.map((group) => {
            if (group.parentGroupId === null) {
                return { ...group, name: payload.name };
            }
            return group;
        });
        return {
            ...state,
            userBlocks: { ...state.userBlocks, items: userBlocks },
            libraryItems: { ...state.libraryItems, items: libraryItems },
            schema: {
                ...state.schema,
                schemaItems: { ...state.schema.schemaItems, groups },
            },
        };
    },
    transformUserBlockToGroup: (
        state: IWorkspaceState,
        { payload }: PayloadAction<{ groups: TSchemaGroup[]; elements: TSchemaNode[] }>
    ) => ({
        ...state,
        userBlocks: {
            ...state.userBlocks,
            getUserBlock: { ...state.userBlocks.getUserBlock, status: Statuses.SUCCEEDED },
        },
        schema: {
            ...state.schema,
            schemaItems: { ...state.schema.schemaItems, groups: payload.groups, elements: payload.elements },
            selectedItems: {
                ...state.schema.selectedItems,
                elements: state.schema.selectedItems.elements.map((el) => ({
                    ...el,
                    data: { ...el.data, type: 'group', picId: 2189, description: '' },
                })),
            },
        },
    }),
};

export const addUserBlock =
    (element: TSchemaNode, userData: TUserBlockData) =>
    // все вложенные группы надо отправлять на сохранение
    async (dispatch: AppDispatch, getState: RootStateFn) => {
        const state = getState();
        const status = state.workspace.userBlocks.addUserBlock.status;
        const globalVariables = state.workspace.settings.globalVariables;
        if (status === Statuses.LOADING) {
            return;
        }
        dispatch(actions.addUserBlockRequest());
        const groupsState = state.workspace.schema.schemaItems.groups;
        const group = groupsState?.find((group) => group.id.toString() === element.id);
        const groups = group && groupsState ? [group, ...findGroups(group, groupsState)] : [];
        const groupsWithName = groups.map((group) => {
            if (group.parentGroupId === null) {
                return { ...group, name: userData.name || '' };
            }
            return group;
        });

        try {
            const libraryItem = state.workspace.libraryItems.items.find(
                (item) => item.type === 'group'
            ) as ILibraryItem;

            const data = {
                type: 'userBlock',
                name: userData.name || '',
                shortName: userData.name || '',
                description: userData.description || '',
                picId: 1,
                library: LibraryTypes.CUSTOM,
                subLibrary: userData.subLibrary || '',
            };

            const dataForHash = {
                elemParams: element.data.elemParams,
                elemProps: element.data.elemProps,
                availablePorts: element.data.availablePorts,
                proxyMap: element.data.proxyMap || null,
                groups,
            };
            const hashData = hash(dataForHash);
            const height = calculateBlockHeight(element.data.availablePorts);

            const libraryItemNew = libraryItem
                ? {
                      ...libraryItem,
                      isActive: true,
                      isNew: false,
                      elemParams: element.data.elemParams,
                      elemProps: element.data.elemProps,
                      availablePorts: element.data.availablePorts,
                      proxyMap: element.data.proxyMap || null,
                      hash: hashData,
                      view: { ...libraryItem.view, minHeight: height, isResizable: true },
                      ...data,
                  }
                : libraryItem;

            const response = await UserBlockService.saveBlock({
                block: libraryItemNew,
                image: userData.image || null,
                schema: {
                    groups: groupsWithName,
                    elements: [],
                    wires: [],
                    proxyMap: element.data.proxyMap || null,
                },
                globalVariables,
            });

            dispatch(
                actions.addUserBlockSuccess({
                    ...response.data.block,
                    image: response.data.image || null,
                    view: {
                        isRotatable: true,
                        isResizable: true,
                        isImageRotatable: true,
                        minWidth: 90,
                        minHeight: height,
                    },
                })
            );
            dispatch(ApplicationActions.hideModal({ type: ModalTypes.USER_BLOCK_SAVE }));

            dispatch(
                actions.updateElementWithUserBlock({
                    data,
                    id: element.id,
                    image: response.data.image || null,
                    blockId: response.data.id,
                    hash: hashData,
                })
            );
        } catch (error: any) {
            const errorKey = TranslationKey.ERROR_UNKNOWN;
            dispatch(actions.addUserBlockFailed({ error: errorKey }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
        }
    };

export const getUserBlock = (blockId: string, id: string) => async (dispatch: AppDispatch, getState: RootStateFn) => {
    const status = getState().workspace.userBlocks.getUserBlock.status;

    if (status === Statuses.LOADING) {
        return;
    }
    // просмотр схемы блока - доп узел schemaItems.userBlockGroups, нельзя использовать основной schemaItems.groups
    dispatch(actions.getUserBlockRequest());

    try {
        const response = await UserBlockService.getBlock({
            blockId,
        });

        dispatch(actions.getUserBlockSuccess({ groups: response.data.data.groups, id, blockId }));
    } catch (error: any) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;
        dispatch(actions.getUserBlockFailed({ error: errorKey }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: errorKey,
                },
            })
        );
    }
    dispatch(ApplicationActions.hideModal({ type: ModalTypes.USER_BLOCK_SAVE }));
};

const updateProxyMap = (proxyMap: TProxyMap, newIds: Record<string, number>) => {
    return {
        params: proxyMap.params.map((param) => {
            if (Object.keys(newIds).includes(param.internalBlockId.toString())) {
                return {
                    ...param,
                    internalBlockId: newIds[param.internalBlockId.toString()],
                };
            }
            return param;
        }),
        props: proxyMap.props.map((prop) => {
            if (Object.keys(newIds).includes(prop.internalBlockId.toString())) {
                return {
                    ...prop,
                    internalBlockId: newIds[prop.internalBlockId.toString()],
                };
            }
            return prop;
        }),
    };
};

export const transformUserBlockToGroup =
    (blockId: string, id: string) => async (dispatch: AppDispatch, getState: RootStateFn) => {
        const status = getState().workspace.userBlocks.getUserBlock.status;
        const schemaItems = getState().workspace.schema.schemaItems;
        const groupsState = schemaItems.groups || [];
        const elementsState = schemaItems.elements;

        if (status === Statuses.LOADING) {
            return;
        }

        try {
            const response = await UserBlockService.getBlock({
                blockId,
            });
            const groupsResponse = response.data.data.groups;
            const mainGroupId = groupsResponse.find((group) => group.parentGroupId === null)?.id.toString() || '';
            const newIdsByOld: Record<string, number> = { [mainGroupId]: Number(id) };

            const groupsResponseElements = groupsResponse.map((group) => group.elements).flat();

            groupsResponseElements.forEach((el, i) => {
                const newId = Date.now() + i;
                newIdsByOld[el.id] = newId;
            });

            const addingGroups = groupsResponse.map((group) => {
                const elements = group.elements.map((el, index) => {
                    if (el.data.type !== 'group') {
                        const id = newIdsByOld[el.id];
                        return { ...el, id: id.toString(), data: { ...el.data, id } };
                    }
                    if (el.data.type === 'group' && Object.keys(newIdsByOld).includes(el.id)) {
                        const proxyMap = updateProxyMap(el.data.proxyMap || { params: [], props: [] }, newIdsByOld);

                        return {
                            ...el,
                            id: newIdsByOld[el.id].toString(),
                            data: { ...el.data, id: newIdsByOld[el.id], proxyMap },
                        };
                    }
                    return el;
                });

                const wires = group.wires.map((wire, index) => {
                    const oldIds = Object.keys(newIdsByOld);
                    return {
                        ...wire,
                        id: Math.abs(Number(group.id) - Date.now() - index).toString(),
                        ...(oldIds.includes(wire.source) && {
                            source: replaceSubstring(wire.source, wire.source, newIdsByOld[wire.source].toString()),
                            sourceHandle: replaceSubstring(
                                wire.sourceHandle,
                                wire.source,
                                newIdsByOld[wire.source].toString()
                            ),
                        }),
                        ...(oldIds.includes(wire.target) && {
                            target: replaceSubstring(wire.target, wire.target, newIdsByOld[wire.target].toString()),
                            targetHandle: replaceSubstring(
                                wire.targetHandle,
                                wire.target,
                                newIdsByOld[wire.target].toString()
                            ),
                        }),
                    };
                });

                return {
                    ...group,
                    id: newIdsByOld[group.id.toString()],
                    elements,
                    wires,
                    ...(group.parentGroupId !== null && {
                        parentGroupId: newIdsByOld[group.parentGroupId.toString()].toString(),
                    }),
                };
            });
            const groups = [...groupsState, ...addingGroups];
            const elements = elementsState.map((el) => {
                if (el.id === id) {
                    const proxyMap = updateProxyMap(el.data.proxyMap || { params: [], props: [] }, newIdsByOld);

                    return { ...el, data: { ...el.data, type: 'group', picId: 2189, proxyMap, description: '' } };
                }
                return el;
            });
            dispatch(actions.transformUserBlockToGroup({ groups, elements }));
        } catch (error: any) {
            const errorKey = TranslationKey.ERROR_UNKNOWN;
            dispatch(actions.getUserBlockFailed({ error: errorKey }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
        }
    };

export const loadUserBlock =
    (blockId: string, blockName?: string, navigate?: NavigateFunction) =>
    async (dispatch: AppDispatch, getState: RootStateFn) => {
        const status = getState().workspace.userBlocks.getUserBlock.status;

        if (status === Statuses.LOADING) {
            return;
        }
        dispatch(workspaceActions.resetWorkspacePath());
        localStorage.removeItem('projectId');
        localStorage.removeItem('currentProjectName');
        localStorage.removeItem('isUserBlock');
        localStorage.setItem('userBlockId', blockId);
        setWorkspaceMode(WorkspaceModes.USER_BLOCK);

        if (blockName) {
            localStorage.setItem('userBlockName', blockName || '');
        }
        dispatch(actions.getUserBlockRequest());

        try {
            dispatch(workspaceActions.setIsUserBlockEditor());
            const response = await UserBlockService.getBlock({
                blockId,
            });

            const userBlock = response.data.data;
            const userBlockGroups = userBlock.groups;
            const userBlockModules = userBlock.modules || null;
            const mainGroupid = userBlockGroups.find((group) => group.parentGroupId === null)?.id;

            await dispatch(
                actions.setSchemaSuccess({
                    groups: response.data.data.groups as TSchemaGroup[],
                })
            );

            if (navigate) {
                navigate('/', { replace: true });
            }

            const solverType = getState().workspace.schema.solverType;
            const libraryType = getState().workspace.schema.libraryType;

            if (!solverType || !libraryType) {
                return;
            }

            const locale = getAppLocale();

            await dispatch(fetchLibraryItems(solverType, libraryType.toLowerCase() as TLibraryType, locale));
            await dispatch(getUserBlocks());
            await dispatch(fetchLibraryPortTypes());
            const groupId =
                response.data.data.groups.find((group) => group.parentGroupId === null)?.id.toString() || null;

            // воображаемый блок, который будет лежать в основном массиве элементов, у него id должен быть как у верхней группы
            const libraryItems = getState().workspace.libraryItems.items;
            const currentItem = libraryItems.find((item) => item.blockId === blockId);
            const portsTypes = getState().workspace.libraryPortTypes.items;

            const newNode =
                currentItem && mainGroupid
                    ? makeSchemaNode(
                          currentItem,
                          {
                              id: mainGroupid,
                              index: '',
                              position: { x: 0, y: 0 },
                              nodeType: 'element',
                          },
                          portsTypes
                      )
                    : null;

            dispatch(actions.addHelperNode({ node: newNode }));

            dispatch(actions.setModules(userBlockModules));

            dispatch(
                actions.changeWorkspaceMode({
                    mode: WorkspaceModes.USER_BLOCK,
                    groupId,
                    elementId: groupId,
                    userBlockId: blockId,
                    previousMode: null,
                    readonly: false,
                })
            );
            if (newNode) {
                dispatch(workspaceActions.addWorkspacePathItem({ nodeData: newNode.data }));
            }
        } catch (error) {
            console.error(error);

            const errorKey = TranslationKey.ERROR_UNKNOWN;
            dispatch(actions.getUserBlockFailed({ error: errorKey }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
        }
    };

export const editUserBlock =
    (
        userData?: TUserBlockData,
        params?: ElemParams[],
        props?: ElemProps[],
        proxyMap?: TProxyMap,
        availablePorts?: TSchemaHandle[]
    ) =>
    async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
        const state = getState();

        try {
            const blockId = state.workspace.meta.userBlockId;
            const groups = state.workspace.schema.schemaItems.groups || [];
            const globalVariables = state.workspace.settings.globalVariables || {};
            const { elements, wires } = state.workspace.schema.schemaItems;

            const libraryItem = state.workspace.libraryItems.items.find(
                (item: ILibraryItem) => item.blockId === blockId
            ) as ILibraryItem;
            const element = state.workspace.schema.schemaItems.elements[0];

            const data = {
                elemParams: element.data.elemParams || libraryItem.elemParams,
                elemProps: element.data.elemProps || libraryItem.elemProps,
                proxyMap: element.data.proxyMap || libraryItem.proxyMap || null,
                availablePorts: element.data.availablePorts || libraryItem.availablePorts,
                view: { ...element.data.view, minHeight: calculateBlockHeight(element.data.availablePorts) },
            };

            const dataForHash = {
                ...data,
                groups,
            };
            const hashData = hash(dataForHash);

            const libraryItemNew =
                libraryItem && Object.keys(libraryItem).length !== 0
                    ? {
                          ...libraryItem,
                          ...data,
                          hash: hashData,
                      }
                    : { ...libraryItem, proxyMap: libraryItem.proxyMap || null };

            const itemRequest = userData
                ? {
                      ...libraryItemNew,
                      ...userData,
                      shortName: userData.name,
                  }
                : libraryItemNew;
            await UserBlockService.editBlock({
                block: itemRequest,
                schema: {
                    groups,
                    elements: [],
                    wires: [],
                    proxyMap: element.data.proxyMap || libraryItem.proxyMap || null,
                },
                image: userData?.image || null,
                globalVariables,
            });
            if (blockId && userData) {
                dispatch(actions.editUserBlock({ blockId, userData }));
            }
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.SUCCESS,
                        message: TranslationKey.USER_BLOCK_SAVED,
                    },
                })
            );
        } catch (error: any) {
            const errorKey = TranslationKey.ERROR_UNKNOWN;
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
        }
    };

export const editUserBlockExternalParameters =
    (userData: TUserBlockData, userBlock: ILibraryItem) => async (dispatch: AppDispatch, getState: RootStateFn) => {
        const state = getState();

        try {
            const blockUpdated = {
                ...userBlock,
                ...userData,
                proxyMap: userBlock.proxyMap || null,
                shortName: userData.name,
            };
            await UserBlockService.editBlockParameters({
                block: blockUpdated,
                image: userData.image || '',
            });

            dispatch(actions.editUserBlockParameters(blockUpdated));

            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.SUCCESS,
                        message: TranslationKey.USER_BLOCK_SAVED,
                    },
                })
            );
        } catch (error: any) {
            const errorKey = TranslationKey.ERROR_UNKNOWN;
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
        }
    };

export const getUserBlocks = () => async (dispatch: AppDispatch, getState: RootStateFn) => {
    if (!IS_USE_USER_BLOCKS) {
        return;
    }
    const status = getState().workspace.userBlocks.getUserBlocks.status;

    if (status === Statuses.LOADING) {
        return;
    }
    dispatch(actions.getUserBlocksRequest());

    try {
        const response = await UserBlockService.getBlocks();

        const blocks = response.data ? response.data.map((item) => ({ ...item.block, image: item.image })) : [];
        dispatch(actions.getUserBlocksSuccess(blocks));
    } catch (error: any) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;
        dispatch(actions.getUserBlocksFailed({ error: errorKey }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: errorKey,
                },
            })
        );
    }
};

const prepareUserBlocksCategoriesToFileManager = (data: any) => {
    const categories = Array.from(new Set(data.map((block: any) => block.block.subLibrary))) as string[];
    const dates = categories.map((category) => {
        return {
            createdAt: data.find((block: any) => {
                if (block.block.subLibrary === category && block.createdAt) {
                    return block;
                }
            })?.createdAt,
            updatedAt: data.find((block: any) => {
                if (block.block.subLibrary === category && block.createdAt) {
                    return block;
                }
            })?.updatedAt,
        };
    });

    return categories.map((category, index) => ({
        name: category,
        id: index + 1,
        isNew: false,
        isEdit: false,
        type: EFileManagerItemType.DIR,
        uuid: uuidv4(),
        updatedAt: dates[index].updatedAt,
        createdAt: dates[index].createdAt,
        isDisabled: false,
    })) as TFileManagerElement[];
};

const prepareUserBlocksFromCategoriesToFileManager = (data: any) => {
    return data.map((block: any, index: number) => ({
        name: block.block.name,
        id: index + 1,
        isNew: false,
        isEdit: false,
        type: EFileManagerItemType.FILE,
        uuid: block.id,
        updatedAt: block.updatedAt,
        createdAt: block.createdAt,
        isDisabled: false,
        data: block.block,
    })) as TFileManagerElement[];
};

/**
 * @param id - file manager element id
 * @param isSaving - type of requesting operation
 */
export const getFileManagerUserBlocks =
    (id: number, category: string | null) => async (dispatch: AppDispatch, getState: RootStateFn) => {
        if (!IS_USE_USER_BLOCKS) {
            return;
        }
        const status = getState().workspace.userBlocks.getUserBlocks.status;

        if (status === Statuses.LOADING) {
            return;
        }
        dispatch(actions.getUserBlocksRequest());

        try {
            if (id === 0) {
                const response = await UserBlockService.getBlocks();
                const blocks = response.data ? response.data.map((item) => ({ ...item.block, image: item.image })) : [];
                const preparedData = prepareUserBlocksCategoriesToFileManager(response.data || []);
                dispatch(actions.getUserBlocksSuccess(blocks));
                dispatch(fileManagerActions.getFilesAndFoldersSuccess({ data: preparedData }));
                return preparedData;
            }

            // TODO avoid using category
            if (category) {
                const response = await UserBlockService.getBlocksFromSubLibrary(category);
                const preparedData = prepareUserBlocksFromCategoriesToFileManager(response.data || []);
                dispatch(fileManagerActions.getFilesAndFoldersSuccess({ data: [...preparedData] }));
                dispatch(actions.getUserBlocksSuccess(getState().workspace.userBlocks.items));
                return preparedData;
            }
            return;
        } catch (error: any) {
            const errorKey = TranslationKey.ERROR_UNKNOWN;
            dispatch(actions.getUserBlocksFailed({ error: errorKey }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
            return;
        }
    };

export const deleteUserBlocks = (blockIds: string) => async (dispatch: AppDispatch) => {
    dispatch(actions.deleteUserBlocksRequest());

    try {
        const response = await UserBlockService.deleteBlocks({ blockIds: [blockIds] });
        dispatch(actions.deleteUserBlocksSuccess(blockIds));
    } catch (error) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;
        dispatch(actions.deleteUserBlocksFailed({ error: errorKey }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: errorKey,
                },
            })
        );
    }
};

export const updateUserBlocks = () => async (dispatch: AppDispatch, getState: RootStateFn) => {
    const userBlocksCount = getState().workspace.schema.userBlocksCount || {};
    const userBlocksKeys = Object.keys(userBlocksCount);
    const schemaUserBlockIds = Object.keys(userBlocksCount);
    const userBlocks = getState().workspace.userBlocks.items;
    const elements = getState().workspace.schema.schemaItems.elements;
    const groups = getState().workspace.schema.schemaItems.groups || [];
    const appModules = getState().workspace.modules;

    const updatedUserBlocksData: { [blockId: string]: TUserBlockData } = {};
    const userBlocksInSchema = userBlocks.filter((block) => block.blockId && userBlocksKeys.includes(block.blockId));

    userBlocksInSchema.forEach((block) => {
        if (block.blockId) {
            const { name, subLibrary, description, image } = block;
            updatedUserBlocksData[block.blockId] = { name, subLibrary: subLibrary || '', description, image };
        }
    });

    const libraryUserBlockIds = userBlocks.map((block) => block.blockId);

    const deletedBlocksIds = schemaUserBlockIds.filter((id) => !libraryUserBlockIds.includes(id));

    const libraryHashByBlockId: { [blockId: string]: string | null } = {};
    userBlocks.forEach((block) => {
        if (block.blockId) {
            libraryHashByBlockId[block.blockId] = block.hash || null;
        }
    });

    const changedBlocks: TSchemaNode[] = [];
    const elemPropsById: Record<string, ElemProps[]> = {};
    elements.forEach((el) => {
        if (
            el.data.blockId &&
            libraryUserBlockIds.includes(el.data.blockId) &&
            libraryHashByBlockId[el.data.blockId] !== el.data.hash
        ) {
            changedBlocks.push(el);
            elemPropsById[el.id] = el.data.elemProps;
        }
    });
    groups.forEach((group) => {
        group.elements.forEach((el) => {
            if (
                el.data.blockId &&
                libraryUserBlockIds.includes(el.data.blockId) &&
                libraryHashByBlockId[el.data.blockId] !== el.data.hash
            ) {
                changedBlocks.push(el);
                elemPropsById[el.id] = el.data.elemProps;
            }
        });
    });
    const changedBlocksIds: string[] = changedBlocks.map((block: TSchemaNode) => block.data.blockId as string);

    if (changedBlocksIds.length !== 0) {
        let changeActionModules: IModulesCounter = {};

        changedBlocksIds.forEach((id, index) => {
            const userBlockInfo = userBlocks.find((block) => block.blockId === id);
            if (userBlockInfo) {
                const { hash, elemParams, elemProps, availablePorts, proxyMap, modules } = userBlockInfo;
                const maxPortsLength = calculatePortsLength(
                    countMaxOfInputsAndOutputs(availablePorts),
                    CANVAS.PORT_MARGIN
                );
                const newHeight = Math.max(maxPortsLength, CANVAS.ELEMENT_MIN_HEIGHT);

                const changes = {
                    id,
                    hash: hash || null,
                    elemParams,
                    elemProps,
                    availablePorts: availablePorts as TSchemaHandle[],
                    newHeight,
                    proxyMap: proxyMap || null,
                    changedBlocksUids: changedBlocksIds,
                    elemPropsById,
                    modules,
                };

                dispatch(actions.setUserBlockDiffIsChanged(changes));

                changeActionModules = {
                    ...changeActionModules,
                    ...getModulesDiff(userBlockInfo.modules as IModulesCounter, modules as IModulesCounter),
                };
            }
        });

        // Calculate and update modules blocks counter
        const { updatedModules, newlyActivatedModules, deactivatedModules } = getActionModulesResult(
            changeActionModules,
            appModules
        );
        dispatch(workspaceActions.updateModules(updatedModules));

        if (newlyActivatedModules.length > 0) {
            dispatch(workspaceActions.sendMessageAboutNewlyPermissions(newlyActivatedModules));
        }
        if (deactivatedModules.length > 0) {
            dispatch(workspaceActions.sendMessageAboutDeactivatedPermissions(deactivatedModules));
        }
    }

    if (deletedBlocksIds.length !== 0) {
        dispatch(actions.setUserBlockDiffIsDeleted(deletedBlocksIds));
    }

    dispatch(actions.updateUserBlocksSuccess(updatedUserBlocksData));
};
