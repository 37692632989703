import styled, { css } from 'styled-components';

import { pxToRem, radius, zIndex } from '@repeat/ui-kit';

const InputStyles = css`
    background-color: var(--ui-input);
    border: none;
    padding: ${pxToRem(4)} ${pxToRem(8)};
    width: 100%;
    outline: none;

    &:focus,
    &:active {
        border-color: var(--ui-border-hover);
    }
`;

export const SInputListError = styled.span`
    font-size: ${pxToRem(12)};
    color: red;
    margin: 0 ${pxToRem(8)};
`;

export const SInputListLabel = styled.label`
    color: var(--ui-label);
    display: flex;
    align-items: center;
    padding: 0 ${pxToRem(8)} 0 0;
    justify-content: space-between;
    font-size: ${pxToRem(12)};
    width: 100%;
    position: relative;
`;

export const InputListMainField = styled.div`
    display: flex;

    input {
        ${InputStyles};
        background-color: var(--ui-input);
        border-radius: ${radius.default};
        border-width: 1px;
        border-style: solid;
        border-color: var(--ui-border);
        color: var(--ui-text);
        padding-right: ${pxToRem(22)};
    }
`;

export const SInputListItemWrapper = styled.div`
    background-color: transparent;
    border-bottom: 1px solid var(--ui-border);
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    width: 100%;

    [data-name='remove-value'] {
        padding: 0 !important;
    }

    input {
        ${InputStyles};
    }

    i {
        display: none;
        position: absolute;
        right: ${pxToRem(2)};
        top: -${pxToRem(21)};
        max-width: ${pxToRem(16)};

        svg {
            fill: var(--ui-label);
        }
    }

    &:hover {
        i {
            display: block;
        }
    }

    &:last-of-type {
        border-bottom: 0;
    }
`;

export const SInputListHeader = styled.header`
    background-color: var(--ui-sidebar);
    position: sticky;
    top: ${pxToRem(38)};
    z-index: ${zIndex.top};

    &::before,
    &::after {
        background-color: var(--ui-sidebar);
        content: '';
        height: ${pxToRem(47)};
        bottom: 0;
        position: absolute;
        width: ${pxToRem(8)};
        z-index: ${zIndex.top};
    }

    &::before {
        left: -${pxToRem(8)};
    }

    &::after {
        right: -${pxToRem(8)};
    }

    button {
        background: var(--ui-input);
        border-left: 1px solid var(--ui-border);
        border-radius: 0;
        position: absolute;
        top: 2px;
        right: 1px;
        height: calc(100% - 4px);
        z-index: 11;
        width: 20px;
    }

    i {
        min-width: ${pxToRem(20)};
    }
`;

export const SInputListWrapper = styled.div<{ isOpen: boolean }>`
    box-shadow: 0 0 0 1px var(--ui-border);
    border-radius: ${radius.default};
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    max-height: ${({ isOpen }) => (isOpen ? `100%` : '0')};
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    margin: ${pxToRem(8)} 0;

    [data-name='input-list-add-button'] {
        border: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        padding: 0;
        font-size: 16px;
        justify-content: center;
        position: absolute;
        max-height: ${pxToRem(24)};
        max-width: ${pxToRem(24)};
        height: ${pxToRem(24)};
        width: ${pxToRem(24)};
        bottom: -12px;
        right: -12px;
    }
`;

export const SInputList = styled.div`
    display: flex;
    flex-direction: column;
`;
