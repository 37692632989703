import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { MEMORY_USAGE_LIMIT } from '@repeat/constants';
import { useAppSelector } from '@repeat/hooks';
import { TWorkspaceMode, WorkspaceModes } from '@repeat/models';
import { workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { IconButton, Panel, Tooltip, uiColors } from '@repeat/ui-kit';

import { SWorkspacePanel, SWorkspacePanelItem } from './STopPanel';
import { TopPanelCodeGenMenu } from './TopPanelCodeGenMenu';
import { TopPanelModulesMenu } from './TopPanelModulesMenu';
import { TopPanelProjectName } from './TopPanelProjectName';
import { TopPanelReadonly } from './TopPanelReadonly';
import { TopPanelSaveMenu } from './TopPanelSaveMenu';
import { messages } from './translation';

import { workspaceMessages } from '../../../pages/WorkspacePage/translation';
import { ProjectSettings, ProjectSettingsItemTypes } from '../../ProjectSettings/ProjectSettings';
import { useWorkspaceDataContext } from '../DataProvider/DataProvider';
import { UndoRedoButtons } from '../UndoRedoButtons/UndoRedoButtons';

export interface IWorkspaceTopPanelProps {
    isFooterActive: boolean;
    onHandleFooter: (args: boolean) => void;
    onHandleClearSelection: () => void;
}

const arrowBackModes: TWorkspaceMode[] = [WorkspaceModes.DEMO, WorkspaceModes.MAIN, WorkspaceModes.PREVIEW];

export const TopPanel: FC<IWorkspaceTopPanelProps> = ({ isFooterActive, onHandleFooter, onHandleClearSelection }) => {
    const { formatMessage } = useIntl();

    const workspaceMetaUserBlockId = useAppSelector(workspaceSelectors.workspaceMetaUserBlockId);

    const navigate = useNavigate();
    const { mode, previousMode, readonly, userBlockEditor } = useWorkspaceDataContext();

    const handleCloseClick = () => {
        if (previousMode === WorkspaceModes.DEMO) {
            return navigate('/projects/demo');
        }
        if (previousMode === WorkspaceModes.USER_BLOCK) {
            return navigate('/projects/userBlocks');
        }
        return navigate('/projects');
    };

    return (
        <Panel>
            <SWorkspacePanel>
                {(arrowBackModes.includes(mode as TWorkspaceMode) ||
                    (mode === WorkspaceModes.USER_BLOCK && userBlockEditor)) && (
                    <SWorkspacePanelItem>
                        <Tooltip text={formatMessage(messages[TranslationKey.BACK_TO_PROJECTS])}>
                            <IconButton
                                fill={uiColors.darkGrey}
                                noHover
                                onClick={handleCloseClick}
                                name={'arrowLeft'}
                            />
                        </Tooltip>
                    </SWorkspacePanelItem>
                )}
                <SWorkspacePanelItem>
                    <TopPanelProjectName />
                </SWorkspacePanelItem>
                {!readonly && (
                    <SWorkspacePanelItem>
                        <TopPanelSaveMenu />
                    </SWorkspacePanelItem>
                )}
                {!readonly && !userBlockEditor && (
                    <SWorkspacePanelItem>
                        <TopPanelCodeGenMenu />
                    </SWorkspacePanelItem>
                )}
                {readonly && <TopPanelReadonly />}
            </SWorkspacePanel>
            <SWorkspacePanel>
                {mode !== WorkspaceModes.CODE_EDITOR && (
                    <>
                        {!readonly && (
                            <SWorkspacePanelItem>
                                <TopPanelModulesMenu />
                            </SWorkspacePanelItem>
                        )}

                        {!readonly && !workspaceMetaUserBlockId && (
                            <SWorkspacePanelItem>
                                <Tooltip
                                    text={`${formatMessage(
                                        workspaceMessages[TranslationKey.WORKSPACE_MEMORY_USAGE]
                                    )}, ${formatMessage(workspaceMessages[TranslationKey.WORKSPACE_MEMORY_LIMIT], {
                                        size: MEMORY_USAGE_LIMIT,
                                    })}`}
                                >
                                    <ProjectSettings
                                        mode={ProjectSettingsItemTypes.COMPACT}
                                        onClick={onHandleClearSelection}
                                    />
                                </Tooltip>
                            </SWorkspacePanelItem>
                        )}

                        {!readonly && !workspaceMetaUserBlockId && (
                            <SWorkspacePanelItem>
                                <Tooltip
                                    text={formatMessage(workspaceMessages[TranslationKey.WORKSPACE_PAGE_VISUALIZATION])}
                                >
                                    <IconButton
                                        data-tour='workspace-visualization-trigger'
                                        noHover
                                        name={'visualization'}
                                        onClick={() => {
                                            onHandleFooter(!isFooterActive);
                                        }}
                                    />
                                </Tooltip>
                            </SWorkspacePanelItem>
                        )}

                        {!readonly && (
                            <SWorkspacePanelItem>
                                <UndoRedoButtons />
                            </SWorkspacePanelItem>
                        )}
                    </>
                )}
            </SWorkspacePanel>
        </Panel>
    );
};
