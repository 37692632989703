import { FC, useEffect, useRef, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';

import { ElemParams, ILibraryItemViewData, TLibraryItemPort } from '@repeat/models';

import { Port } from './Port/Port';
import {
    BottomItemsWrapper,
    Container,
    LeftItemsWrapper,
    MiddleContainer,
    RightItemsWrapper,
    SBlock,
    SContainerWrapper,
    SSizesBlockInfoValueAccent,
    SSizesInfo,
    SSizesInfoLabel,
    SSizesInfoRow,
    SSizesInfoValue,
    SToolWrapper,
    TopItemsWrapper,
} from './SPortsVisualManager';
import {
    BLOCK_MIN_HEIGHT,
    BLOCK_MIN_WIDTH,
    IVisualManagerPortInterface,
    makeAvailablePorts,
    makeView,
    makeVisualManagerPorts,
} from './util';

interface IPortVisualManagerProps {
    elemParams: ElemParams[];
    availablePorts: TLibraryItemPort[];
    view: ILibraryItemViewData;
    onChange: (ports: TLibraryItemPort[], view: ILibraryItemViewData) => void;
}

export const PortsVisualManager: FC<IPortVisualManagerProps> = ({ elemParams, availablePorts, view, onChange }) => {
    const targetRef = useRef<HTMLDivElement>(null);

    const [width, setWidth] = useState(BLOCK_MIN_WIDTH);
    const [height, setHeight] = useState(BLOCK_MIN_HEIGHT);

    const [portsMap, setPortsMap] = useState(makeVisualManagerPorts(elemParams, availablePorts));

    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        setPortsMap(makeVisualManagerPorts(elemParams, availablePorts));
    }, [elemParams, availablePorts]);

    useEffect(() => {
        if (targetRef.current) {
            setWidth(targetRef.current.offsetWidth);
            setHeight(targetRef.current.offsetHeight);
        }
    }, [targetRef]);

    useEffect(() => {
        if (targetRef.current) {
            setWidth(targetRef.current.offsetWidth);
            setHeight(targetRef.current.offsetHeight);
        }

        setIsDirty(true);
    }, [portsMap, width, height]);

    useEffect(() => {
        if (isDirty) {
            setIsDirty(false);

            const availablePorts = makeAvailablePorts(portsMap);
            const updatedView = makeView(view, width, height);

            onChange && onChange(availablePorts, updatedView);
        }
    }, [isDirty]);

    const setList = (list: IVisualManagerPortInterface[], side: string) => {
        setPortsMap((map) => ({ ...map, [side]: list }));
    };

    return (
        <SToolWrapper>
            <SSizesInfo>
                <SSizesInfoRow>
                    <SSizesInfoLabel>Блок</SSizesInfoLabel>
                    <SSizesInfoValue>
                        Ш x В:{' '}
                        <SSizesBlockInfoValueAccent>
                            {width} x {height}
                        </SSizesBlockInfoValueAccent>
                    </SSizesInfoValue>
                </SSizesInfoRow>
            </SSizesInfo>
            <SContainerWrapper>
                <Container>
                    <TopItemsWrapper>
                        <ReactSortable
                            list={portsMap.top}
                            setList={(list) => setList(list, 'top')}
                            animation={150}
                            group='shared-group-name'
                        >
                            {portsMap.top.map((item) => (
                                <Port key={item.id} item={item} titlePosition='top' />
                            ))}
                        </ReactSortable>
                    </TopItemsWrapper>
                    <MiddleContainer>
                        <LeftItemsWrapper>
                            <ReactSortable
                                list={portsMap.left}
                                setList={(list) => setList(list, 'left')}
                                animation={150}
                                group='shared-group-name'
                            >
                                {portsMap.left.map((item) => (
                                    <Port key={item.id} item={item} titlePosition='left' />
                                ))}
                            </ReactSortable>
                        </LeftItemsWrapper>
                        <SBlock ref={targetRef} />
                        <RightItemsWrapper>
                            <ReactSortable
                                list={portsMap.right}
                                setList={(list) => setList(list, 'right')}
                                animation={150}
                                group='shared-group-name'
                            >
                                {portsMap.right.map((item) => (
                                    <Port key={item.id} item={item} titlePosition='right' />
                                ))}
                            </ReactSortable>
                        </RightItemsWrapper>
                    </MiddleContainer>
                    <BottomItemsWrapper>
                        <ReactSortable
                            list={portsMap.bottom}
                            setList={(list) => setList(list, 'bottom')}
                            animation={150}
                            group='shared-group-name'
                        >
                            {portsMap.bottom.map((item) => (
                                <Port key={item.id} item={item} titlePosition='bottom' />
                            ))}
                        </ReactSortable>
                    </BottomItemsWrapper>
                </Container>
            </SContainerWrapper>
        </SToolWrapper>
    );
};
