import { FC, FormEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { getStylesByVariant, IS_SHOW_OPTIONS_SAVE_BUTTON, sanitizeHTML, unicodeToHtml } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ElemParams, TSchemaNode } from '@repeat/models';
import { setUndoRedoHotkeysPressed, workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { InputField, SContextMenuItem, ShowMore, TInputVariant } from '@repeat/ui-kit';

import { useWorkspaceDataContext } from '../../Workspace/DataProvider/DataProvider';
import { SPropertyItemBlock, StyledLabel, StyledPropertiesItem } from '../ItemOptions/SItemProperty';
import { messages } from '../translation';

interface IIndicatorParameterFormProps {
    indicatorId: number;
}

export const IndicatorParameterForm: FC<IIndicatorParameterFormProps> = ({ indicatorId }) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const { readonly } = useWorkspaceDataContext();

    const [chosenElementId, setChosenElementId] = useState<number | string>('');
    const [indicatorParameter, setIndicatorParameter] = useState<string>('');
    const [indicatorUnits, setIndicatorUnits] = useState<string>('custom');
    const [unitsFactor, setUnitsFactor] = useState<string>('1');
    const [hotkeyPressed, setHotkeyPressed] = useState(false);
    const [isLabelInput, setIsLabelInput] = useState(false);

    const [variant, setVariant] = useState<{ element: TInputVariant; parameter: TInputVariant }>({
        element: 'default',
        parameter: 'default',
    });

    const filteredElements = useAppSelector(workspaceSelectors.elementsWithStateParameters);
    const schemaElements = useAppSelector(workspaceSelectors.schemaElements);
    const meterElement = useAppSelector(workspaceSelectors.currentNodeIndicator);
    const meterUnitsValue = useAppSelector((state) =>
        state.workspace.schema.schemaItems.elements
            .find((el) => el.data?.id === meterElement.id)
            ?.data.elemProps.find((el) => el.name === 'units')
            ?.value.toString()
    );

    const meterUnitsFactorValue = useAppSelector((state) =>
        state.workspace.schema.schemaItems.elements
            .find((el) => el.data?.id === meterElement.id)
            ?.data.elemProps.find((el) => el.name === 'unitsFactor')
            ?.value.toString()
    );

    useEffect(() => {
        setVariant({ element: 'default', parameter: 'default' });
    }, [meterElement.id]);

    useEffect(() => {
        setChosenElementId(meterElement.indicator?.connectedElementId || '');
        setIndicatorParameter(meterElement.indicator?.parameter || '');
        setIndicatorUnits(meterUnitsValue || 'custom');
        setUnitsFactor(meterUnitsFactorValue || '1');
    }, [meterElement]);

    const changeElement = (e: SelectChangeEvent) => {
        setChosenElementId(e.target.value);
        setVariant({ ...variant, element: 'warning' });
    };
    const currentElementParameters = filteredElements?.find(
        (el: TSchemaNode) => el.data?.id === Number(chosenElementId)
    )?.data.stateParameters;

    const currentIndicatorUnits =
        schemaElements
            ?.find((el: TSchemaNode) => el.data?.id === meterElement.id)
            ?.data.elemProps.find((el) => el.name === 'units')?.availableValues || [];

    const changeParameter = (e: SelectChangeEvent) => {
        dispatch(
            workspaceActions.setIndicatorParameter({
                indicatorId,
                connectedElementId: Number(chosenElementId),
                parameter: e.target.value,
                unitsPropValue: indicatorUnits !== '' ? indicatorUnits : 'custom',
                unitsFactor: unitsFactor !== '' ? unitsFactor : '1',
            })
        );
        setVariant({ element: 'success', parameter: 'success' });
    };

    const changeUnitsFactor = (value: string) => {
        setUnitsFactor(value);

        dispatch(
            workspaceActions.setIndicatorParameter({
                indicatorId,
                connectedElementId: Number(chosenElementId),
                parameter: indicatorParameter,
                unitsPropValue: indicatorUnits,
                unitsFactor: value,
            })
        );
        setVariant({ element: 'success', parameter: 'success' });
    };

    const inputOnKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if ((event.key === 'z' && event.ctrlKey) || (event.key === 'z' && event.metaKey)) {
            setHotkeyPressed(true);
            dispatch(setUndoRedoHotkeysPressed({ event }));
        }
        if (!(event.key === 'z' && event.ctrlKey) && !(event.key === 'z' && event.metaKey)) {
            setHotkeyPressed(false);
        }
    };

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        if (variant.element === 'success' && variant.parameter === 'success') {
            timer = setTimeout(() => {
                setVariant({ element: 'default', parameter: 'default' });
            }, 500);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [variant]);

    const indicatorParameterHandler = () => {
        if (indicatorId) {
            dispatch(
                workspaceActions.setIndicatorParameter({
                    indicatorId,
                    connectedElementId: Number(chosenElementId),
                    parameter: indicatorParameter,
                    unitsPropValue: indicatorUnits,
                    unitsFactor: unitsFactor !== '' ? unitsFactor : '1',
                })
            );
        }
    };

    return (
        <ShowMore title={intl.formatMessage(messages[TranslationKey.VARIABLE])}>
            <form
                onSubmit={(e: FormEvent) => {
                    e.preventDefault();
                    indicatorParameterHandler();
                }}
            >
                <FormControl style={{ width: '100%', margin: '8px 0 16px' }} size={'small'}>
                    <InputLabel id='demo-simple-select-standard-label'>
                        {intl.formatMessage(messages['workspace.context_menu.element'])}
                    </InputLabel>
                    <Select
                        disabled={readonly}
                        labelId='demo-simple-select-standard-label'
                        id='demo-simple-select-standard'
                        label={intl.formatMessage(messages[TranslationKey.WORKSPACE_ELEMENT_TITLE])}
                        value={chosenElementId || ''}
                        onChange={changeElement}
                        onClick={() => {
                            !readonly && setIndicatorParameter('');
                        }}
                        sx={variant && getStylesByVariant(variant.element)}
                    >
                        {filteredElements.length === 0 ? (
                            <SContextMenuItem value='' key={'workspace.context_menu.item.no_elements'}>
                                <em>{intl.formatMessage(messages[TranslationKey.NO_ELEMENTS])}</em>
                            </SContextMenuItem>
                        ) : (
                            filteredElements?.map((el: { data: { name: string; id: number; index: string } }) => (
                                <SContextMenuItem
                                    value={el.data.id}
                                    key={`select-element-${el.data.id}-${el.data.index}`}
                                >
                                    {el.data.name} {el.data.index}
                                </SContextMenuItem>
                            ))
                        )}
                    </Select>
                </FormControl>
                <FormControl disabled={!chosenElementId} style={{ width: '100%', margin: '0 0 16px' }} size={'small'}>
                    <InputLabel id='demo-simple-select-standard-label'>
                        {intl.formatMessage(messages[TranslationKey.PARAMETER])}
                    </InputLabel>
                    <Select
                        disabled={readonly}
                        sx={variant && getStylesByVariant(variant.parameter)}
                        labelId='demo-simple-select-standard-label'
                        id='demo-simple-select-standard'
                        label={intl.formatMessage(messages['workspace.context_menu.parameter'])}
                        onChange={changeParameter}
                        value={indicatorParameter}
                    >
                        {!currentElementParameters || currentElementParameters?.length === 0 ? (
                            <SContextMenuItem value=''>
                                <em>{intl.formatMessage(messages['workspace.context_menu.item.no_parameters'])}</em>
                            </SContextMenuItem>
                        ) : (
                            currentElementParameters?.map((el: ElemParams) => {
                                const itemText =
                                    el.unit === '' || !el.unit
                                        ? `${el.description}`
                                        : `${el.description}, ${unicodeToHtml(el.unit)}`;
                                return (
                                    <SContextMenuItem value={el.name} key={`select-${el.name}`}>
                                        <span dangerouslySetInnerHTML={sanitizeHTML(itemText)} />
                                    </SContextMenuItem>
                                );
                            })
                        )}
                    </Select>
                </FormControl>
                {/* <FormControl disabled={!chosenElementId} style={{ width: '100%', margin: '0 0 16px' }} size={'small'}>
                    <InputLabel id='demo-simple-select-standard-label-units'>
                        {intl.formatMessage(messages[TranslationKey.METER_UNITS])}
                    </InputLabel>
                    <Select
                        sx={variant && getStylesByVariant(variant.parameter)}
                        labelId='demo-simple-select-standard-label-units'
                        id='demo-simple-select-standards'
                        // label={intl.formatMessage(messages[TranslationKey.METER_UNITS])}
                        onChange={changeUnitsValue}
                        value={indicatorUnits}
                    >
                        {!currentIndicatorUnits || currentIndicatorUnits?.length === 0 ? (
                            <SContextMenuItem value=''>
                                <em>{intl.formatMessage(messages['workspace.context_menu.item.no_parameters'])}</em>
                            </SContextMenuItem>
                        ) : (
                            currentIndicatorUnits?.map((el: TPropertyValue) => {
                                const itemText = el.title === '' || !el.title ? `${el.title}` : `${el.title}`;
                                return (
                                    <SContextMenuItem value={el.value} key={`select-${el.value}`}>
                                        <span dangerouslySetInnerHTML={sanitizeHTML(itemText)} />
                                    </SContextMenuItem>
                                );
                            })
                        )}
                    </Select>
                </FormControl> */}
                <StyledPropertiesItem>
                    <SPropertyItemBlock isEditable={true} style={{ flexDirection: 'column' }}>
                        <StyledLabel
                            isLabelInput={isLabelInput}
                            dangerouslySetInnerHTML={sanitizeHTML(
                                unicodeToHtml(intl.formatMessage(messages[TranslationKey.METER_UNITS_FACTOR]))
                            )}
                        />
                        <InputField
                            disabled={readonly}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                changeUnitsFactor(e.target.value);
                            }}
                            onKeyDown={inputOnKeyDownHandler}
                            id={'input-custom-units'}
                            type='text'
                            value={unitsFactor}
                            size='small'
                        />
                    </SPropertyItemBlock>
                </StyledPropertiesItem>

                {IS_SHOW_OPTIONS_SAVE_BUTTON && (
                    <Button type='submit'>{intl.formatMessage(messages[TranslationKey.SAVE])}</Button>
                )}
            </form>
        </ShowMore>
    );
};
