import { FC, startTransition, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { batch } from 'react-redux';
import {
    DefaultEdgeOptions,
    Node,
    OnSelectionChangeParams,
    ReactFlowInstance,
    useEdgesState,
    useNodesState,
} from 'reactflow';

import 'reactflow/dist/style.css';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { SchemaItemTypes, TSchemaConnection, TSchemaNode } from '@repeat/models';
import { elementsSelectors, workspaceActions, workspaceSelectors } from '@repeat/store';

import { CustomEdge } from '../Canvas/CustomEdge/CustomEdge';
import { Element } from '../Canvas/Elements/Element/Element';
import { ImageElement } from '../Canvas/Elements/ImageElement/ImageElement';
import { TextElement } from '../Canvas/Elements/TextElement/TextElement';
import { mapElementsToNodes, mapWiresToConnections } from '../Canvas/helper/canvasHelper';
import { useWorkspaceDataContext } from '../DataProvider/DataProvider';
import { DefaultCanvas } from '../DefaultCanvas/DefaultCanvas';

const defaultEdgeOptions: DefaultEdgeOptions = {
    type: 'custom-edge',
};

export const ReadonlyCanvas: FC = () => {
    const dispatch = useAppDispatch();
    const { mode } = useWorkspaceDataContext();

    const currentItems = useAppSelector(workspaceSelectors.currentItems);

    const [isInitialized, setIsInitialized] = useState(false);
    const [isSelectionInitialized, setIsSelectionInitialized] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const elements = currentItems?.elements || [];
    const wires = currentItems?.wires || [];

    const selectedItems = useAppSelector(elementsSelectors.getSelectedElements);

    const [defaultSelectedNodes, setDefaultSelectedNodes] = useState<string[]>(
        selectedItems.elements.map((node) => node.id)
    );

    const [defaultSelectedConnections] = useState<string[]>(selectedItems.wires.map((w) => w.id));

    const nodeTypes = useMemo(() => ({ image: ImageElement, element: Element, text: TextElement }), []);
    const edgeTypes = useMemo(() => ({ 'custom-edge': CustomEdge }), []);

    const initialNodes = useMemo(() => mapElementsToNodes(elements, defaultSelectedNodes) as Node[], []);
    const initialEdges: TSchemaConnection[] = useMemo(
        () => mapWiresToConnections(wires, defaultSelectedConnections) as TSchemaConnection[],
        []
    );

    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
    const [nodes, setNodes] = useNodesState(initialNodes);
    const [edges, setEdges] = useEdgesState(initialEdges);

    const reactFlowWrapper = useRef<HTMLInputElement>(null);

    useEffect(() => {
        startTransition(() => {
            if (isSelectionInitialized) {
                const selectedNodesBuffer = selectedItems.elements.map((node) => node.id);
                const selectedConnectionsBuffer = selectedItems.wires.map((connection) => connection.id);
                setNodes(mapElementsToNodes(elements, selectedNodesBuffer) as Node[]);

                setEdges(mapWiresToConnections(wires, selectedConnectionsBuffer) as TSchemaConnection[]);
            }
        });
    }, [elements, wires, selectedItems, isSelectionInitialized, mode]);

    useEffect(() => {
        if (reactFlowInstance) {
            reactFlowInstance.fitView();
        }
    }, [mode]);

    const handleSelectionChange = useCallback(
        ({ nodes, edges }: OnSelectionChangeParams) => {
            if (!isInitialized) {
                return;
            }
            return new Promise((resolve, reject) => {
                if (nodes.length === 0 && edges.length === 0) {
                    return reject({ nodes, edges });
                }
                if (nodes.length > 0 || edges.length > 0) {
                    return resolve({ nodes, edges });
                }
            })
                .then(({ nodes, edges }: { nodes: TSchemaNode[]; edges: TSchemaConnection[] }) => {
                    const selectedNodesBuffer = new Set([] as string[]);
                    const selectedEdgesBuffer = new Set([] as string[]);
                    nodes.forEach((node: TSchemaNode) => {
                        if (!selectedNodesBuffer.has(node.id)) {
                            selectedNodesBuffer.add(node.id);
                        }
                    });
                    edges.forEach((edge: TSchemaConnection) => {
                        if (!selectedEdgesBuffer.has(edge.id)) {
                            selectedEdgesBuffer.add(edge.id);
                        }
                    });
                    return {
                        selectedNodesBuffer: Array.from(selectedNodesBuffer),
                        selectedEdgesBuffer: Array.from(selectedEdgesBuffer),
                        nodes,
                    };
                })
                .then(({ selectedNodesBuffer, selectedEdgesBuffer, nodes }: any) => {
                    batch(() => {
                        dispatch(
                            workspaceActions.setSelectedItems({ ids: selectedNodesBuffer, type: SchemaItemTypes.NODE })
                        );
                        dispatch(
                            workspaceActions.setSelectedItems({
                                ids: selectedEdgesBuffer,
                                type: SchemaItemTypes.CONNECTION,
                            })
                        );
                    });
                    setIsSelectionInitialized(true);
                    return { selectedNodesBuffer, selectedEdgesBuffer, nodes };
                })
                .catch(() => {
                    batch(() => {
                        dispatch(workspaceActions.setSelectedItems({ ids: [], type: SchemaItemTypes.NODE }));
                        dispatch(workspaceActions.setSelectedItems({ ids: [], type: SchemaItemTypes.CONNECTION }));
                    });
                    setIsSelectionInitialized(true);
                    setDefaultSelectedNodes([]);
                });
        },
        [isInitialized]
    );

    const handleInit = useCallback((reactFlowInstance: ReactFlowInstance) => {
        setReactFlowInstance(reactFlowInstance);
        setIsInitialized(true);
        reactFlowInstance.fitView();
    }, []);

    const handleClearSelection = useCallback(() => {
        batch(() => {
            dispatch(workspaceActions.setSelectedItems({ ids: [], type: SchemaItemTypes.NODE }));
            dispatch(workspaceActions.setSelectedItems({ ids: [], type: SchemaItemTypes.CONNECTION }));
        });
    }, []);

    return (
        <DefaultCanvas
            isInitialized={isInitialized}
            isLoading={!isInitialized}
            wrapperRef={reactFlowWrapper}
            ref={ref}
            nodeTypes={nodeTypes}
            nodes={nodes}
            edges={edges}
            edgeTypes={edgeTypes}
            defaultEdgeOptions={defaultEdgeOptions}
            onInit={handleInit}
            onSelectionChange={handleSelectionChange}
            onNodesChange={() => false}
            onPaneClick={handleClearSelection}
            disableKeyboardA11y
        />
    );
};
