import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ILibraryItem, ILibraryItemImportData } from 'libs/models/src/lib/libraryItem';
import { NotificationTypes } from 'libs/models/src/lib/notification';
import { StyledForm } from 'libs/ui-kit/src/lib/Form/StyledForm';

import { ApplicationActions } from '@repeat/common-slices';
import { IMPORT_LIBRARYITEM_FORM, LIBRARYITEM_FILE_EXTENSION } from '@repeat/constants';
import { useAppDispatch } from '@repeat/hooks';
import { TranslationKey } from '@repeat/translations';

import { DropZone } from '../../DropZone/DropZone';
import { EngineerService } from '../EngineerService';
import { messages } from '../translations';

interface IImportBlockForm {
    onValid: (isValid: boolean) => void;
}

const engineerService = new EngineerService('engineerBlocks');

export const ImportBlockForm: FC<IImportBlockForm> = ({ onValid }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { formatMessage } = useIntl();

    const {
        handleSubmit,
        formState: { isValid },
        setError,
        clearErrors,
    } = useForm<ILibraryItemImportData>({ mode: 'onChange' });

    useEffect(() => {
        onValid(isValid);
    }, [isValid]);

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onSubmit = () => {};

    const acceptFileTypes = { 'text/plain': [`.${LIBRARYITEM_FILE_EXTENSION}`] };
    const maxFileSize = 20971520;

    const handleImportBlockError = () => {
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: TranslationKey.BLOCK_IMPORT_ERROR,
                },
            })
        );
    };

    const onFileUpload = (file: File) => {
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.result && typeof reader.result === 'string') {
                try {
                    const store = await engineerService.getBlocks();

                    const fileData = JSON.parse(reader.result);

                    if (fileData.length > 1) {
                        for (const item of fileData) {
                            item.picId = 3000;
                            const existingItem = store.find((dataItem: ILibraryItem) => dataItem.type === item.type);

                            if (existingItem) {
                                const isEqual =
                                    JSON.stringify(
                                        Object.fromEntries(
                                            Object.entries(existingItem).filter(
                                                ([key]) => key !== 'createdAt' && key !== 'id'
                                            )
                                        )
                                    ) ===
                                    JSON.stringify(
                                        Object.fromEntries(
                                            Object.entries(item).filter(([key]) => key !== 'createdAt' && key !== 'id')
                                        )
                                    );

                                if (!isEqual) {
                                    const updatedItem = {
                                        ...existingItem,
                                        ...item,
                                        updatedAt: new Date().toLocaleString('ru-RU'),
                                    };
                                    await engineerService.updateBlockByType(existingItem.type!, updatedItem);
                                }
                            } else {
                                item.createdAt = new Date().toLocaleString('ru-RU');
                                item.subLibrary = '';
                                item.isActive = true;
                                item.hasPorts = true;
                                item.isNew = true;
                                item.hasManagedPorts = false;
                                item.hasConfigurations = false;
                                item.isViewOnly = false;
                                item.aliases = [];
                                item.portsAmount = item.elemParams.length;
                                if (!Object.prototype.hasOwnProperty.call(item, 'stateParameters')) {
                                    item.stateParameters = [];
                                }
                                item.shortName = item.name;

                                await engineerService.addBlock(item);
                            }
                        }
                    } else {
                        const item = fileData;
                        item.picId = 3000;
                        const existingItem = store.find((dataItem: ILibraryItem) => dataItem.type === item.type);

                        if (existingItem) {
                            const isEqual =
                                JSON.stringify(
                                    Object.fromEntries(
                                        Object.entries(existingItem).filter(
                                            ([key]) => key !== 'createdAt' && key !== 'id'
                                        )
                                    )
                                ) ===
                                JSON.stringify(
                                    Object.fromEntries(
                                        Object.entries(item).filter(([key]) => key !== 'createdAt' && key !== 'id')
                                    )
                                );

                            if (!isEqual) {
                                const updatedItem = {
                                    ...existingItem,
                                    ...item,
                                    updatedAt: new Date().toLocaleString('ru-RU'),
                                };
                                await engineerService.updateBlockByType(existingItem.type!, updatedItem);
                            } else {
                                throw new Error();
                            }
                        } else {
                            item.createdAt = new Date().toLocaleString('ru-RU');
                            item.subLibrary = '';
                            item.isActive = true;
                            item.shortName = item.name;
                            item.hasPorts = true;
                            item.isNew = true;
                            item.hasManagedPorts = false;
                            item.hasConfigurations = false;
                            item.isViewOnly = false;
                            item.portsAmount = item.elemParams.length;
                            item.aliases = [];
                            if (!Object.prototype.hasOwnProperty.call(item, 'stateParameters')) {
                                item.stateParameters = [];
                            }

                            await engineerService.addBlock(item);
                        }
                    }
                    navigate(0);
                } catch (err) {
                    handleImportBlockError();
                    console.error(err);
                }
            }
        };

        reader.readAsText(file);
    };

    const handleDropZoneError = (hasError: boolean) => {
        if (!hasError) {
            clearErrors();
            return;
        }

        setError('file', { type: 'required' });
    };

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)} id={IMPORT_LIBRARYITEM_FORM}>
            <DropZone
                dropZoneText={formatMessage(messages[TranslationKey.LIBRARYITEM_IMPORT_FILE_DESCRIPTION])}
                buttonText={formatMessage(messages[TranslationKey.LIBRARYITEM_IMPORT_SELECT_FILE])}
                acceptFileTypes={acceptFileTypes}
                maxFileCount={1}
                maxFileSize={maxFileSize}
                onDropFile={onFileUpload}
                onError={handleDropZoneError}
                error={!isValid}
            />
        </StyledForm>
    );
};
