import { FC, memo } from 'react';
import { Position } from 'reactflow';

import styled from 'styled-components';

import { useStore } from '@reactflow/core';

import { pxToRem } from '@repeat/ui-kit';

import { SFSMHandle } from '../SFSMStyles';

const SNodeWrapper = styled.div`
    border-radius: 50%;
    background: #000000;
    min-width: 48px;
    min-height: 48px;
    position: relative;

    .drag-handle__custom {
        height: calc(100% - 32px);
        width: calc(100% - 32px);
        margin: ${pxToRem(16)};
        position: absolute;
    }
`;

const SNodeInner = styled.div`
    width: 100%;
`;
interface IStartElement {
    id: string;
}

const connectionNodeIdSelector = (state: any) => state.connectionNodeId;

export const FSMStartElement: FC<IStartElement> = memo(({ id }) => {
    const connectionNodeId = useStore(connectionNodeIdSelector);
    const edges = useStore((state) => state.edges);

    const isConnecting = !!connectionNodeId;
    const isTarget = connectionNodeId && connectionNodeId !== id;
    const hasSourceConnection = edges.some((edge) => edge.source === id);

    return (
        <SNodeWrapper>
            <div className={'drag-handle__custom'}>
                <SNodeInner>
                    {!isConnecting && (
                        <SFSMHandle
                            className='oneHandle'
                            position={Position.Right}
                            type='source'
                            isConnectable={!hasSourceConnection}
                        />
                    )}
                    {(!isConnecting || isTarget) && (
                        <SFSMHandle
                            className='oneHandle'
                            position={Position.Left}
                            type='target'
                            isConnectableStart={false}
                        />
                    )}
                </SNodeInner>
            </div>
        </SNodeWrapper>
    );
});
