import { ListenerMiddlewareInstance } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { ApplicationActions } from '@repeat/common-slices';
import { MAX_CHARTS_GRID_COUNT, screenshotGenerator } from '@repeat/constants';
import { useProjectId } from '@repeat/hooks';
import { NotificationTypes, PlatformTypes, TState } from '@repeat/models';
import {
    copyItems,
    editUserBlock,
    pasteItems,
    Redo,
    saveProject,
    saveProjectPreview,
    Undo,
    workspaceActions,
} from '@repeat/store';
import { TranslationKey } from '@repeat/translations';

export const appendHotkeyListeners = (listenerMiddleware: ListenerMiddlewareInstance) => {
    listenerMiddleware.startListening({
        actionCreator: ApplicationActions.setHotkeyPressed,
        effect: (action, listenerApi) => {
            const {
                workspace: {
                    meta: { mode, previousMode, readonly },
                },
            } = listenerApi.getState() as TState;

            if (!readonly) {
                const state = listenerApi?.getState() as TState;
                const {
                    selectedItems,
                    libraryType,
                    solverType,
                    schemaItems: { elements },
                } = state.workspace.schema;
                const {
                    graphs: { charts },
                } = state.workspace;
                const undoRedo = state.workspace.undoRedo;
                const platform = state.app.meta.platform;
                const { projectId, userBlockId } = useProjectId();
                if (action?.payload?.keys && action?.payload?.keys?.length !== 0) {
                    const ctrlOrMetaPressed =
                        (action.payload.ctrl && platform !== PlatformTypes.MAC_OS) ||
                        (action.payload.meta && platform === PlatformTypes.MAC_OS);
                    const shiftPressed = action.payload.shift;
                    switch (action.payload.keys[0]) {
                        case 'delete':
                        case 'backspace':
                            if (localStorage.getItem('matrix-table-open') === 'true') {
                                break;
                            }
                            if (selectedItems) {
                                listenerApi.dispatch(workspaceActions.deleteSchemaItems());
                            }
                            break;
                        case 'a':
                            if (ctrlOrMetaPressed) {
                                listenerApi.dispatch(workspaceActions.selectAllItems());
                            }
                            break;
                        case 'c':
                            if (ctrlOrMetaPressed) {
                                listenerApi.dispatch(copyItems());
                            }
                            break;
                        case 'v':
                            if (ctrlOrMetaPressed) {
                                listenerApi.dispatch(pasteItems());
                            }
                            break;
                        case 'z':
                            if (ctrlOrMetaPressed && !shiftPressed && undoRedo.canUndo) {
                                listenerApi.dispatch(Undo());
                            }
                            if (ctrlOrMetaPressed && shiftPressed && undoRedo.canRedo) {
                                listenerApi.dispatch(Redo());
                            }
                            break;
                        case 'g':
                            if (ctrlOrMetaPressed) {
                                if (charts && Object.keys(charts).length < MAX_CHARTS_GRID_COUNT) {
                                    const uuid = uuidv4();
                                    listenerApi.dispatch(workspaceActions.addEmptyChart({ uuid }));
                                } else {
                                    listenerApi.dispatch(
                                        ApplicationActions.showNotification({
                                            notification: {
                                                type: NotificationTypes.WARNING,
                                                message: TranslationKey.CHART_ELEMENT_MAX_COUNT,
                                            },
                                        })
                                    );
                                }
                            }
                            break;
                        case 's':
                            if (ctrlOrMetaPressed && projectId && solverType && libraryType) {
                                listenerApi.dispatch(saveProject(projectId, libraryType, solverType));
                                screenshotGenerator((canvas: string) =>
                                    listenerApi.dispatch(saveProjectPreview(canvas, projectId))
                                );
                            }
                            if (ctrlOrMetaPressed && userBlockId) {
                                listenerApi.dispatch(editUserBlock());
                            }
                            break;
                        default:
                            break;
                    }
                }
            } else {
                listenerApi.dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: TranslationKey.WORKSPACE_VIEW_MODE_ACTION_NOT_ALLOWED,
                        },
                    })
                );
            }
        },
    });
};
