import { useIntl } from 'react-intl';
import { useReactFlow } from 'reactflow';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ModalTypes, Project, TModal } from '@repeat/models';
import { editUserBlock, saveProject, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { IconButton, LoaderDefault, Tooltip, uiColors } from '@repeat/ui-kit';

import { messages } from './translation';

import { useProjectScreenshot } from '../../Project/hooks/useProjectScreenshot';
import { useWorkspaceDataContext } from '../DataProvider/DataProvider';

export const TopPanelSaveMenu = () => {
    const reactFlow = useReactFlow();
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const { handleScreenShot, handleDownloadScreenshot, isDownloading, isSaving } = useProjectScreenshot();
    const { userBlockEditor, projectId, projectName, mode } = useWorkspaceDataContext();

    const libraryType = useAppSelector(workspaceSelectors.libraryType);
    const solverType = useAppSelector(workspaceSelectors.solverType);

    const handleSaveProject = async () => {
        if (userBlockEditor) {
            await dispatch(editUserBlock());
        } else {
            if (projectId && solverType && libraryType) {
                await dispatch(saveProject(parseInt(projectId), libraryType, solverType));
                await handleScreenShot(parseInt(projectId));
            }
        }
    };

    const handleSaveAsNew = (project: Pick<Project, 'projectName' | 'projectId'>) => {
        const modal: TModal = {
            type: ModalTypes.PROJECT_SAVE_NEW,
            data: { project },
        };
        dispatch(ApplicationActions.showModal({ modal }));
    };

    return (
        <>
            <Tooltip text={formatMessage(messages[TranslationKey.SAVE])}>
                {!isSaving && (
                    <IconButton
                        data-tour='workspace-project-save'
                        fill={uiColors.darkGrey}
                        noHover
                        name='save'
                        onClick={handleSaveProject}
                    />
                )}
                {isSaving && <LoaderDefault width={28} height={26} color={'var(--ui-text)'} />}
            </Tooltip>
            {!userBlockEditor && (
                <Tooltip text={formatMessage(messages[TranslationKey.SAVE_AS])}>
                    <IconButton
                        fill={uiColors.darkGrey}
                        noHover
                        name='saveEdit'
                        onClick={() =>
                            projectId &&
                            handleSaveAsNew({
                                projectName: projectName as string,
                                projectId: parseInt(projectId),
                            })
                        }
                    />
                </Tooltip>
            )}
            <Tooltip text={formatMessage(messages[TranslationKey.SCHEMA_SCREENSHOT])}>
                {!isDownloading && (
                    <IconButton
                        fill={uiColors.darkGrey}
                        noHover
                        name='screenShot'
                        onClick={async () => {
                            return new Promise((resolve) => {
                                reactFlow.fitView();
                                setTimeout(() => resolve(true), 100);
                            }).then(() => handleDownloadScreenshot());
                        }}
                    />
                )}
                {isDownloading && <LoaderDefault width={28} height={26} color={'var(--ui-text)'} />}
            </Tooltip>
        </>
    );
};
