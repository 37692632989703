import styled from 'styled-components';

import { border, pxToRem } from '@repeat/ui-kit';

export const SWorkspacePanel = styled.div`
    align-items: center;
    display: flex;
    max-height: ${pxToRem(26)};
    margin-top: ${pxToRem(8)};
    width: auto;
`;

export const SWorkspacePanelItem = styled.div`
    background-color: var(--ui-sidebar);
    box-shadow: 0 0 1px 1px var(--ui-shadow);
    align-items: center;
    display: flex;
    justify-content: center;
    min-width: ${pxToRem(26)};
    height: auto;
    width: auto;
    position: relative;

    i svg {
        fill: var(--ui-icons);
    }

    & {
        > div {
            height: ${pxToRem(26)};
            padding: 0 ${pxToRem(6)};
        }

        > *:hover {
            i svg {
                fill: var(--ui-icons-hover);
            }
        }
    }
`;

export const STopPanelProjectName = styled.div<{ noSave?: boolean; isGroup?: boolean }>`
    display: flex;
    align-items: center;
    max-height: ${pxToRem(26)};
    max-width: ${pxToRem(300)};

    span {
        font-size: ${pxToRem(14)};
        display: inline-block;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: normal;
        width: auto;
    }
`;

export const STopPanelProjectNameClickable = styled.span`
    cursor: pointer;
    color: var(--ui-main-blue);
`;

export const STopPanelBreadCrumbsMenu = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    button {
        border: 0;
        background: none;
        cursor: pointer;
    }
`;

export const STopPanelDropdown = styled.ul<{ isVisible: boolean }>`
    position: absolute;
    top: ${pxToRem(32)};
    left: ${pxToRem(0)};
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    padding: ${pxToRem(5)};
    background-color: var(--ui-sidebar);
    box-shadow: 0 0 1px 1px rgb(0 0 0 / 8%);
    font-size: ${pxToRem(14)};
`;

export const STopPanelDropdownItem = styled.li`
    align-items: center;
    display: flex;
    border-bottom: ${border.default};
    max-width: ${pxToRem(300)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:last-child {
        border-bottom: 0;
    }
`;
