import React, { FC, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';

import { ApplicationActions } from '@repeat/common-slices';
import { FSM_ELEMENT_TYPE } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { ModalTypes, NotificationTypes, TProjectConfirmSaving, TWorkspaceMode, WorkspaceModes } from '@repeat/models';
import { getUserBlock, setSubmodelItems, workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Icon, ShowMore, uiColors } from '@repeat/ui-kit';

import { SModeControlButtons } from './SRightBar';

import { messages } from '../CurrentItemOptions/translation';
import { useWorkspaceDataContext } from '../Workspace/DataProvider/DataProvider';

export const ModeControlButtons: FC = memo(() => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const { mode, previousMode, readonly, userBlockEditor, userBlockId } = useWorkspaceDataContext();

    const selectedItems = useAppSelector(workspaceSelectors.selectedItems);
    const selectedNode = selectedItems.elements[0];
    const workspaceMetaGroupId = useAppSelector(workspaceSelectors.workspaceMetaGroupId);
    const currentSubmodelProjectById = useAppSelector(workspaceSelectors.currentSubmodelProjectById(selectedNode?.id));
    const mainGroupId = useAppSelector(workspaceSelectors.mainGroupId)?.toString()

    const isCanEditCode =
        selectedNode?.data.elemProps.filter((property) => property.type === 'code').length > 0 &&
        mode !== WorkspaceModes.SUBMODEL;
    const isCanViewFSM = selectedNode?.data.type === FSM_ELEMENT_TYPE && mode !== WorkspaceModes.SUBMODEL;
    const isCanViewProject = selectedNode?.data?.type === 'project';
    const isCanViewUserBlock = selectedNode?.data?.type === 'userBlock';
    const isCanEditFSM = mode === WorkspaceModes.FSM_EDITOR;
    const isCanEditProject = mode === WorkspaceModes.SUBMODEL && !userBlockId;
    const isCanEditUserBlock = !userBlockEditor && userBlockId && mode === WorkspaceModes.USER_BLOCK;
    const isCodeEditorActive = mode === WorkspaceModes.CODE_EDITOR;
    const isGroupEditorActive = mode === WorkspaceModes.GROUP;
    const isShowBackButton =
        availableBackButtonMode.includes(mode as TWorkspaceMode) &&
        (isCanEditUserBlock || isCanEditProject || isCodeEditorActive || isGroupEditorActive || isCanEditFSM);

    const handleBackToSchemaClick = () => {
        const data = {
            mode:
                previousMode === WorkspaceModes.DEMO || previousMode === WorkspaceModes.USER_BLOCK
                    ? previousMode
                    : WorkspaceModes.MAIN,
            elementId: userBlockEditor ? mainGroupId : null,
            userBlockId: userBlockEditor ? userBlockId : null,
            groupId: userBlockEditor ? mainGroupId : null,
            readonly:
                previousMode === WorkspaceModes.DEMO ||
                previousMode === WorkspaceModes.PREVIEW ||
                (previousMode === WorkspaceModes.USER_BLOCK && !userBlockEditor),
        };
        dispatch(workspaceActions.changeWorkspaceMode(data));
        dispatch(workspaceActions.resetWorkspacePath())
    };

    const handleDelete = useCallback(() => {
        dispatch(workspaceActions.deleteSchemaItems());
    }, []);

    const handleEditCode = useCallback(() => {
        dispatch(
            workspaceActions.changeWorkspaceMode({
                mode: WorkspaceModes.CODE_EDITOR,
                elementId: selectedNode.id,
                readonly,
            })
        );
    }, [selectedNode]);

    const handleEditFSM = useCallback(() => {
        dispatch(
            workspaceActions.changeWorkspaceMode({
                mode: WorkspaceModes.FSM_EDITOR,
                elementId: selectedNode.id,
                readonly,
            })
        );
    }, [selectedNode]);

    const handleViewProjectBlock = () => {
        if (!selectedNode) {
            return;
        }
        if (selectedNode.data.type === 'project') {
            if (mode === WorkspaceModes.SUBMODEL) {
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: TranslationKey.WORKSPACE_VIEW_MODE_ACTION_NOT_ALLOWED,
                        },
                    })
                );
                return;
            }
            if (selectedNode.data.diff?.isErrorGettingProject) {
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.ERROR,
                            message: TranslationKey.WORKSPACE_ERROR_GETTING_PROJECT_DATA,
                        },
                    })
                );
                return;
            }
            dispatch(
                workspaceActions.changeWorkspaceMode({
                    mode: WorkspaceModes.SUBMODEL,
                    elementId: selectedNode.id,
                    groupId: workspaceMetaGroupId ? workspaceMetaGroupId : null,
                    readonly: true,
                })
            );
            const projectId = currentSubmodelProjectById?.projectId;
            if (projectId) {
                dispatch(setSubmodelItems({ projectId: Number(projectId) }));
            }
        }
    };

    const handleViewUserBlock = () => {
        if (!selectedNode) return;

        if (selectedNode.data.diff?.userBlockIsDeleted) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: 'Блок удален',
                    },
                })
            );
            return;
        }
        if (mode === WorkspaceModes.SUBMODEL) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.WARNING,
                        message: TranslationKey.WORKSPACE_VIEW_MODE_ACTION_NOT_ALLOWED,
                    },
                })
            );
            return;
        }

        localStorage.setItem('isUserBlock', 'true');

        dispatch(
            workspaceActions.changeWorkspaceMode({
                mode: WorkspaceModes.USER_BLOCK,
                elementId: selectedNode.id,
                groupId: selectedNode.id,
                userBlockId: selectedNode.data.blockId,
                readonly: true,
            })
        );

        if (selectedNode?.data.blockId) {
            dispatch(getUserBlock(selectedNode.data.blockId, selectedNode.id));
        }
    };

    const handleEditProject = () => {
        const modal: TProjectConfirmSaving = {
            type: ModalTypes.PROJECT_CONFIRM_SAVING,
        };
        dispatch(ApplicationActions.showModal({ modal }));
    };

    return (
        <SModeControlButtons>
            <ShowMore title={formatMessage(messages[TranslationKey.WORKSPACE_ELEMENT_ACTIONS])}>
                {!selectedNode && (isCanEditProject || isCanEditUserBlock) && previousMode !== WorkspaceModes.DEMO && (
                    <Button onClick={handleEditProject} type='button'>
                        <Icon name='edit' fill={uiColors.mainBlue} />
                        {formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_BLOCK_EDIT_SCHEMA])}
                    </Button>
                )}
                {isCanViewFSM && selectedNode && mode !== WorkspaceModes.FSM_EDITOR && (
                    <Button onClick={handleEditFSM} type='button'>
                        <Icon name='edit' fill={uiColors.mainBlue} />
                        {readonly && formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_BLOCK_VIEW])}
                        {!readonly && formatMessage(messages[TranslationKey.EDIT])}
                    </Button>
                )}
                {isCanViewProject && (mode === WorkspaceModes.DEMO || mode === WorkspaceModes.PREVIEW) && (
                    <Button onClick={handleViewProjectBlock} type='button'>
                        <Icon name='edit' fill={uiColors.mainBlue} />
                        {readonly && formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_BLOCK_VIEW])}
                        {!readonly && formatMessage(messages[TranslationKey.EDIT])}
                    </Button>
                )}
                {isCanEditCode && selectedNode && !isCodeEditorActive && (
                    <Button onClick={handleEditCode} type='button'>
                        <Icon name='edit' fill={uiColors.mainBlue} />
                        {readonly && formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_BLOCK_VIEW])}
                        {!readonly && formatMessage(messages[TranslationKey.SCHEMA_ELEMENT_EDIT_CODE])}
                    </Button>
                )}

                {!readonly && (
                    <>
                        {isCanViewProject && selectedNode && (
                            <Button onClick={handleViewProjectBlock} type='button'>
                                <Icon name='edit' fill={uiColors.mainBlue} />
                                {formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_BLOCK_VIEW])}
                            </Button>
                        )}
                        {isCanViewUserBlock && selectedNode && (
                            <Button onClick={handleViewUserBlock} type='button'>
                                <Icon name='edit' fill={uiColors.mainBlue} />
                                {formatMessage(messages[TranslationKey.WORKSPACE_PROJECT_BLOCK_VIEW])}
                            </Button>
                        )}
                        {selectedNode && !isCodeEditorActive && (
                            <Button onClick={handleDelete} type='button'>
                                <Icon name='close' fill={uiColors.mainBlue} />
                                {formatMessage(messages[TranslationKey.DELETE])}
                            </Button>
                        )}
                    </>
                )}

                {isShowBackButton && (
                    <Button onClick={handleBackToSchemaClick} type='button'>
                        <Icon name='back' fill={uiColors.mainBlue} />
                        {formatMessage(messages[TranslationKey.SCHEMA_ELEMENT_BACK_TO_SCHEMA])}
                    </Button>
                )}
            </ShowMore>
        </SModeControlButtons>
    );
});

const availableBackButtonMode = [
    WorkspaceModes.FSM_EDITOR,
    WorkspaceModes.CODE_EDITOR,
    WorkspaceModes.USER_BLOCK,
    WorkspaceModes.GROUP,
    WorkspaceModes.SUBMODEL,
];
