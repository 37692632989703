import React, { FC } from 'react';

import styled from 'styled-components';

import { layoutSize, pxToRem, zIndex } from '@repeat/ui-kit';

export const SPanel = styled.div`
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: ${layoutSize.header};
    max-width: 100vw;
    z-index: ${zIndex.top};
    padding: 0 calc(${layoutSize.sidebar} + ${pxToRem(16)});
    transition: all 0.3s ease;
    width: 100%;
`;

export const Panel: FC<{ children: React.ReactNode }> = ({ children }) => {
    return <SPanel data-name={'layout-top-panel'}>{children}</SPanel>;
};
