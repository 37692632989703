import { FC, MouseEvent, useContext } from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@repeat/ui-kit';

import { SToolsItem, SToolsItemIcon, SToolsItemName } from './STools';
import { ITab, ToolsTabsContext } from './ToolsTabs/ToolsTabsContext';
import { toolsIcons, toolsTabContent, toolsTabNames, toolsTabType, toolsType } from './helpers';
import { messages } from './translation';
import { useWorkspaceDataContext } from '../DataProvider/DataProvider';

export interface IToolsItem {
    id: string;
    isActive?: boolean;
    type: toolsType.COMMON | toolsType.ELECTRIC;
    name: string;
    icon: string;
    link?: string;
    onPrepend?: (args: Pick<ITab, 'id' | 'type'>) => void;
    onClick?: (args: Pick<ITab, 'id' | 'type'>) => void;
}

const ToolsItem: FC<IToolsItem> = (props) => {
    const { formatMessage } = useIntl();
    const { readonly } = useWorkspaceDataContext();

    const { name, icon, link = '!#' } = props;

    const { handleGetTabById, handleTabOpen, activeTab } = useContext(ToolsTabsContext);

    const handleTabUpdate = (args: Pick<ITab, 'id' | 'type'>) => {
        const { id, type } = args;
        const currentTab = handleGetTabById(id);

        if (currentTab) {
            const updatedTab = {
                ...currentTab,
                type,
                icon: toolsIcons[type as keyof typeof toolsIcons],
                name: formatMessage(
                    messages[toolsTabNames[type as keyof typeof toolsTabNames] as keyof typeof messages]
                ),
                content: toolsTabContent[type as keyof typeof toolsTabContent],
            } as ITab;
            return handleTabOpen(updatedTab);
        }
    };

    const handleClick = (event: MouseEvent) => {
        const type = props.id as toolsTabType;
        const tabId = activeTab?.id;

        if (!tabId) {
            return;
        }

        props.onPrepend && props.onPrepend({ id: tabId, type });

        if (props.link) {
            return;
        }

        event.preventDefault();

        if (props.onClick) {
            return props.onClick({ id: tabId, type });
        }

        return handleTabUpdate({ id: tabId, type });
    };

    return (
        <SToolsItem target={'_blank'} {...(!readonly && { onClick: handleClick, href: link })}>
            <SToolsItemIcon>
                <Icon name={icon}></Icon>
            </SToolsItemIcon>
            <SToolsItemName>{name}</SToolsItemName>
        </SToolsItem>
    );
};

export default ToolsItem;
