import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Drawer from 'react-modern-drawer';

import 'react-modern-drawer/dist/index.css';
import { InputMatrixAlert } from '@components/InputMatrixTable/InputMatrixAlert';
import { InputMatrixConstructor } from '@components/InputMatrixTable/InputMatrixConstructor';
import { InputMatrixField } from '@components/InputMatrixTable/InputMatrixField';
import { InputMatrixFooter } from '@components/InputMatrixTable/InputMatrixFooter';
import { InputMatrixHeader } from '@components/InputMatrixTable/InputMatrixHeader';
import { constructorFieldNames, Matrix, MatrixTableProps } from '@components/InputMatrixTable/types';
import { v4 as uuidv4 } from 'uuid';

import { TranslationKey } from '@repeat/translations';
import { IContextMenuItem, useContextMenu } from '@repeat/ui-kit';

import {
    SInputMatrix,
    SInputMatrixFieldWrapper,
    SInputMatrixTable,
    SInputMatrixTableButtons,
    SInputMatrixTableWrapper,
} from './SInputMatrixTable';
import { messages } from './translations';

export const InputMatrixTable: React.FC<MatrixTableProps> = ({
    initialMatrixString,
    label,
    id,
    disabled,
    help,
    onChange,
}) => {
    const { formatMessage } = useIntl();

    const [matrix, setMatrix] = useState<Matrix>([]);
    const [focusedCell, setFocusedCell] = useState<string>('1.1');
    const [errors, setErrors] = useState<Record<string, boolean>>({});
    const [constructorErrors, setConstructorErrors] = useState<Record<string, boolean>>({});
    const [selectionError, setSelectionError] = useState<boolean>(false);
    const [removeRowError, setRemoveRowError] = useState<boolean>(false);
    const [removeColError, setRemoveColError] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const [selectedCol, setSelectedCol] = useState<number | null>(null);
    const [isMatrixOpen, setIsMatrixOpen] = useState(false);
    const [contextMenu, setContextMenu] = useState<IContextMenuItem[] | null>(null);
    const [rowCount, setRowCount] = useState(1);
    const [colCount, setColCount] = useState(2);

    const refId = useMemo(() => id || uuidv4(), [id]);

    const { Menu, handleContextMenuRightClick, setTarget } = useContextMenu();

    const contextMenuItems = useMemo(
        () => [
            {
                icon: 'delete',
                text: formatMessage(messages[TranslationKey.DELETE]),
                onClick: () => {
                    if (selectedRow !== null || selectedCol !== null) {
                        onDelete();
                        setSelectionError(false);
                    } else {
                        setSelectionError(true);
                    }
                },
            },
        ],
        [selectedCol, selectedRow]
    );

    const checkConstructorErrors = (name: string, value: string) => {
        if ((value && parseInt(value) < 1) || Number.isNaN(parseInt(value))) {
            if (Number.isNaN(parseInt(value)) && name === constructorFieldNames.ROW_COUNT) {
                setRowCount(0);
            }
            if (Number.isNaN(parseInt(value)) && name === constructorFieldNames.COL_COUNT) {
                setColCount(0);
            }
            return setConstructorErrors({ ...constructorErrors, [name]: true });
        }
        return setConstructorErrors({ ...constructorErrors, [name]: false });
    };

    const matrixToString = (matrix: Matrix): string => matrix.map((row) => `<${row.map(String).join(';')}>`).join('_');

    const stringToMatrix = (str: string): Matrix =>
        str
            ? str.split('_').map((rowStr) =>
                  rowStr
                      .replace(/<|>/g, '')
                      .split(';')
                      .map((cell) => (cell.trim() === '' ? '' : cell))
              )
            : [[]];

    const defaultValue = useRef(initialMatrixString);

    useEffect(() => {
        if (initialMatrixString) {
            setMatrix(stringToMatrix(initialMatrixString));
        } else {
            const defaultMatrix = Array.from({ length: 1 }, (_, rowIndex) =>
                Array.from({ length: 2 }, (_, colIndex) => (rowIndex === 0 ? '0' : ''))
            );
            setMatrix(defaultMatrix);
        }
    }, [initialMatrixString]);

    useEffect(() => {
        if (rowCount !== matrix.length || colCount !== matrix[0].length) {
            if (matrix.length > 0) {
                setRowCount(matrix.length);
                setColCount(matrix[0].length);
            } else {
                setRowCount(0);
                setColCount(0);
            }
        }
        onChange(refId, matrixToString(matrix));
    }, [matrix]);

    useEffect(() => {
        if (initialMatrixString) {
            checkMatrixDimensions(stringToMatrix(initialMatrixString));
            validateMatrix(stringToMatrix(initialMatrixString));
        }
    }, [initialMatrixString]);

    const applyMatrixSize = () => {
        if (constructorErrors[constructorFieldNames.ROW_COUNT] || constructorErrors[constructorFieldNames.COL_COUNT]) {
            return;
        }
        const newMatrix: Matrix = Array.from({ length: rowCount }, (_, rowIndex) =>
            Array.from({ length: colCount }, (_, colIndex) => {
                if (matrix[rowIndex] && matrix[rowIndex][colIndex] !== undefined) {
                    return matrix[rowIndex][colIndex];
                }
                return '';
            })
        );

        return setMatrix(newMatrix);
    };

    const onDelete = () => {
        setContextMenu(null);
        if (selectedRow !== null) {
            deleteSelectedRow();
        }
        if (selectedCol !== null) {
            deleteSelectedColumn();
        }
    };

    const handleSetRowCount = (val: string) => setRowCount(Number(val));
    const handleSetColCount = (val: string) => setColCount(Number(val));

    const handleBackToDefaultValue = () => {
        return new Promise((resolve) => {
            if (defaultValue.current) {
                setMatrix(() => {
                    return stringToMatrix(defaultValue?.current as string);
                });
            }
            resolve(defaultValue?.current);
        }).then((value: string) => {
            validateMatrix(stringToMatrix(value));
        });
    };

    const handleContextMenu = (event: React.MouseEvent, id: number) => {
        const { preventDefault, clientX, clientY, currentTarget } = event;
        const { clientWidth } = currentTarget;
        event.preventDefault();
        handleContextMenuRightClick({ preventDefault, clientX: clientX - clientWidth / 2, clientY });
        setTarget(id);
        return setContextMenu(contextMenuItems);
    };

    const validateMatrix = (newMatrix: Matrix): boolean => {
        let isValid = true;

        const newErrors: { [key: string]: boolean } = {};

        newMatrix.forEach((row, rowIndex) => {
            row.forEach((cell, colIndex) => {
                const cellId = `${rowIndex + 1}.${colIndex + 1}`;

                if (!/^-?\d*$/.test(String(cell)) && cell !== '') {
                    newErrors[cellId] = true;
                    isValid = false;
                } else {
                    newErrors[cellId] = false;
                }
            });
        });

        setErrors(newErrors);

        return isValid;
    };

    const validateCell = (id: string, value: string): boolean => {
        if (!/^-?\d*$/.test(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [id]: true,
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: false,
        }));
        return true;
    };

    const updateCell = (id: string, value: string) => {
        const [rowIndex, colIndex] = id.split('.').map(Number);
        if (!/;/.test(value)) {
            validateCell(id, value);
            const newMatrix = [...matrix];
            newMatrix[rowIndex - 1][colIndex - 1] = value === '' ? '' : value;
            setMatrix(newMatrix);
        }
    };

    const handleChangeMainField = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        validateMatrix(stringToMatrix(value));
        setMatrix(stringToMatrix(value));
    };

    const addRow = () => {
        const newRow = Array(matrix[0]?.length || 0).fill('0');
        setMatrix([...matrix, newRow]);
    };

    const addColumn = () => {
        const newMatrix = matrix.map((row) => [...row, '0']) as Matrix;
        setMatrix(newMatrix);
    };

    const selectRow = (rowIndex: number) => {
        setSelectedRow(rowIndex);
        setSelectedCol(null);
    };

    const selectCol = (colIndex: number) => {
        setSelectedCol(colIndex);
        setSelectedRow(null);
    };

    const isSelectedRow = (rowIndex: number): boolean => selectedRow === rowIndex;
    const isSelectedCol = (colIndex: number): boolean => selectedCol === colIndex;

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const [currentRow, currentCol] = focusedCell.split('.').map(Number);
        let newRow = currentRow;
        let newCol = currentCol;

        switch (event.key) {
            case 'ArrowUp':
                if (currentRow > 1) newRow = currentRow - 1;
                break;
            case 'ArrowDown':
                setTimeout(() => {
                    addRow();
                    newRow = currentRow + 1;
                }, 300);
                break;
            case 'ArrowLeft':
                if (currentCol > 1) newCol = currentCol - 1;
                break;
            case 'ArrowRight':
                setTimeout(() => {
                    addColumn();
                    newCol = currentCol + 1;
                }, 300);
                break;
            default:
                break;
        }

        setFocusedCell(`${newRow}.${newCol}`);
    };

    const checkMatrixDimensions = (matrix: Matrix): boolean => {
        if (matrix.length === 0) return false;

        const rowLength = matrix[0].length;
        for (const row of matrix) {
            if (row.length !== rowLength) {
                return false;
            }
        }
        return true;
    };

    const handleTableClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const target = event.target as HTMLElement;
        if (!target.closest('button') && !target.closest('input')) {
            setSelectedRow(null);
            setSelectedCol(null);
        }
    };

    const handleOpenMatrixTable = () => {
        setIsMatrixOpen((state) => {
            localStorage.setItem('matrix-table-open', `${!state}`);
            return !state;
        });
    };

    const handleCloseMatrixTable = () => {
        const isValid = validateMatrix(matrix);
        if (isValid) {
            setIsMatrixOpen((state) => {
                localStorage.setItem('matrix-table-open', `${!state}`);
                return !state;
            });
        }
    };

    const deleteSelectedRow = () => {
        if (selectedRow !== null) {
            if (matrix.length > 1) {
                const newMatrix = matrix.filter((_, rowIndex) => rowIndex + 1 !== selectedRow);
                setMatrix(newMatrix);
                setSelectedRow(null);
                setRemoveRowError(false);
            } else {
                setRemoveRowError(true);
            }
        }
    };

    const deleteSelectedColumn = () => {
        if (selectedCol !== null) {
            if (matrix[0]?.length > 1) {
                const newMatrix = matrix.map((row) => row.filter((_, colIndex) => colIndex + 1 !== selectedCol));
                setMatrix(newMatrix);
                setSelectedCol(null);
                setRemoveColError(false);
            } else {
                setRemoveColError(true);
            }
        }
    };

    // Обработка вставки из буфера обмена
    const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
        event.preventDefault(); // Предотвращаем стандартное поведение вставки

        // Получаем текст из буфера обмена
        const clipboardData = event.clipboardData.getData('text/plain');

        // Разбираем данные из буфера обмена
        const pastedMatrix = clipboardData
            .split('\n') // Разделяем строки
            .filter((row) => row.trim() !== '') // Удаляем пустые строки
            .map((row) => row.split('\t').map((cell) => (cell.trim() === '' ? '' : cell))); // Разделяем ячейки

        // Определяем текущую позицию фокуса
        const [startRow, startCol] = focusedCell.split('.').map(Number);

        // Создаем новую матрицу
        const newMatrix = [...matrix];

        // Проходим по каждой строке и ячейке вставленной матрицы
        pastedMatrix.forEach((row, rowIndex) => {
            const targetRow = startRow - 1 + rowIndex;

            // Если строки недостаточно, добавляем новые строки
            if (targetRow >= newMatrix.length) {
                newMatrix.push(Array(newMatrix[0]?.length || 0).fill(''));
            }

            row.forEach((cell, colIndex) => {
                const targetCol = startCol - 1 + colIndex;

                // Если столбцов недостаточно, добавляем новые столбцы во всех строках
                if (targetCol >= newMatrix[targetRow].length) {
                    newMatrix.forEach((row) => {
                        row.push(...Array(targetCol - row.length + 1).fill(''));
                    });
                }

                // Обновляем значение ячейки
                newMatrix[targetRow][targetCol] = cell;
            });
        });

        // Обновляем состояние матрицы
        setMatrix(newMatrix);
    };

    return (
        <SInputMatrix data-name={'matrix-table'} onPaste={handlePaste} tabIndex={0}>
            <SInputMatrixFieldWrapper>
                <InputMatrixField
                    label={label}
                    help={help}
                    disabled={disabled}
                    refId={refId}
                    value={matrixToString(matrix)}
                    error={
                        Object.keys(errors).filter((error, index) => errors[error] !== false).length > 0 ||
                        !checkMatrixDimensions(matrix)
                    }
                    errorText={formatMessage(messages[TranslationKey.MATRIX_TABLE_WRONG_FORMAT])}
                    onOpenMatrix={handleOpenMatrixTable}
                    onChange={handleChangeMainField}
                />
            </SInputMatrixFieldWrapper>
            <Drawer open={isMatrixOpen} direction='right' size='70vw'>
                <SInputMatrixTableWrapper onClick={handleTableClick} onPaste={handlePaste}>
                    <InputMatrixHeader onOpenMatrixTable={handleOpenMatrixTable} />
                    <InputMatrixConstructor
                        errors={constructorErrors}
                        applyMatrixSize={applyMatrixSize}
                        rowCount={rowCount}
                        colCount={colCount}
                        checkConstructorErrors={checkConstructorErrors}
                        handleSetColCount={handleSetColCount}
                        handleSetRowCount={handleSetRowCount}
                    />
                    <InputMatrixAlert
                        selectionError={selectionError}
                        removeColError={removeColError}
                        removeRowError={removeRowError}
                        setSelectionError={setSelectionError}
                        setRemoveRowError={setRemoveRowError}
                        setRemoveColError={setRemoveColError}
                    />
                    <SInputMatrixTable onKeyDown={handleKeyDown}>
                        <table>
                            <thead>
                                <tr>
                                    <th>{/*<button>&#9633;</button>*/}</th>
                                    {Array.from({ length: matrix[0]?.length || 0 }).map((_, colIndex) => (
                                        <th
                                            key={colIndex}
                                            className={isSelectedCol(colIndex + 1) ? 'selected-col' : ''}
                                        >
                                            <button onClick={() => selectCol(colIndex + 1)}>{colIndex + 1}</button>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody onContextMenu={(event: React.MouseEvent) => handleContextMenu(event, 1)}>
                                {matrix.map((row, rowIndex) => (
                                    <tr key={rowIndex} className={isSelectedRow(rowIndex + 1) ? 'selected-row' : ''}>
                                        <td>
                                            <button onClick={() => selectRow(rowIndex + 1)}>{rowIndex + 1}</button>
                                        </td>
                                        {row.map((cell, colIndex) => {
                                            const cellId = `${rowIndex + 1}.${colIndex + 1}`;
                                            return (
                                                <td key={cellId}>
                                                    <input
                                                        type='text'
                                                        value={cell === '' ? '' : cell}
                                                        onChange={(e) => updateCell(cellId, e.target.value)}
                                                        style={{
                                                            ...((isSelectedRow(rowIndex + 1) ||
                                                                isSelectedCol(colIndex + 1)) && {
                                                                backgroundColor: '#f9f9a3',
                                                            }),
                                                            ...(errors[cellId] && {
                                                                backgroundColor: 'var(--ui-alert) !important',
                                                            }),
                                                        }}
                                                        onFocus={() => setFocusedCell(cellId)}
                                                    />
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </tbody>
                            <SInputMatrixTableButtons>
                                <button data-name={'add-row'} onClick={addRow}>
                                    +
                                </button>
                                <button data-name={'add-column'} onClick={addColumn}>
                                    +
                                </button>
                            </SInputMatrixTableButtons>
                        </table>
                        <Menu items={contextMenu} />
                    </SInputMatrixTable>
                    <InputMatrixFooter
                        matrix={matrix}
                        errors={errors}
                        checkMatrixDimensions={checkMatrixDimensions}
                        onReset={handleBackToDefaultValue}
                        onCloseMatrix={handleCloseMatrixTable}
                    />
                </SInputMatrixTableWrapper>
            </Drawer>
        </SInputMatrix>
    );
};
