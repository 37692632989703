import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.DELETE]: {
        id: TranslationKey.DELETE,
        defaultMessage: ru[TranslationKey.DELETE],
    },
    [TranslationKey.MATRIX_TABLE_ROW_COUNT]: {
        id: TranslationKey.MATRIX_TABLE_ROW_COUNT,
        defaultMessage: ru[TranslationKey.MATRIX_TABLE_ROW_COUNT],
    },
    [TranslationKey.MATRIX_TABLE_COL_COUNT]: {
        id: TranslationKey.MATRIX_TABLE_COL_COUNT,
        defaultMessage: ru[TranslationKey.MATRIX_TABLE_COL_COUNT],
    },
    [TranslationKey.MATRIX_TABLE_ADD_ROW]: {
        id: TranslationKey.MATRIX_TABLE_ADD_ROW,
        defaultMessage: ru[TranslationKey.MATRIX_TABLE_ADD_ROW],
    },
    [TranslationKey.MATRIX_TABLE_ADD_COL]: {
        id: TranslationKey.MATRIX_TABLE_ADD_COL,
        defaultMessage: ru[TranslationKey.MATRIX_TABLE_ADD_COL],
    },
    [TranslationKey.MATRIX_TABLE_APPLY]: {
        id: TranslationKey.MATRIX_TABLE_APPLY,
        defaultMessage: ru[TranslationKey.MATRIX_TABLE_APPLY],
    },
    [TranslationKey.MATRIX_TABLE_RESET_DEFAULT]: {
        id: TranslationKey.MATRIX_TABLE_RESET_DEFAULT,
        defaultMessage: ru[TranslationKey.MATRIX_TABLE_RESET_DEFAULT],
    },
    [TranslationKey.MATRIX_TABLE_EDITOR_HEADER]: {
        id: TranslationKey.MATRIX_TABLE_EDITOR_HEADER,
        defaultMessage: ru[TranslationKey.MATRIX_TABLE_EDITOR_HEADER],
    },
    [TranslationKey.MATRIX_TABLE_WARNING_DELETE]: {
        id: TranslationKey.MATRIX_TABLE_WARNING_DELETE,
        defaultMessage: ru[TranslationKey.MATRIX_TABLE_WARNING_DELETE],
    },
    [TranslationKey.MATRIX_TABLE_WARNING_DELETE_LAST]: {
        id: TranslationKey.MATRIX_TABLE_WARNING_DELETE_LAST,
        defaultMessage: ru[TranslationKey.MATRIX_TABLE_WARNING_DELETE_LAST],
    },
    [TranslationKey.MATRIX_TABLE_WRONG_FORMAT]: {
        id: TranslationKey.MATRIX_TABLE_WRONG_FORMAT,
        defaultMessage: ru[TranslationKey.MATRIX_TABLE_WRONG_FORMAT],
    },
});
