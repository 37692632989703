import React, { forwardRef } from 'react';
import ReactFlow, { Background, BackgroundVariant, ConnectionMode, ReactFlowProps, SelectionMode } from 'reactflow';

import { Controls } from '@components/ReactFlow/ReactFlowControls';
import { ReactFlowMiniMap } from '@components/ReactFlow/ReactFlowMiniMap/ReactFlowMiniMap';

import { CANVAS } from '@repeat/constants';
import { useAppSelector } from '@repeat/hooks';
import { PlatformTypes } from '@repeat/models';
import { Loader } from '@repeat/ui-kit';

import { SCanvasLoaderWrapper, SCanvasWrapper } from '../Canvas/SCanvas';

export interface IDefaultCanvasProps extends ReactFlowProps {
    children?: React.ReactNode;
    isLoading: boolean;
    isInitialized: boolean;
    wrapperRef?: React.Ref<HTMLDivElement>;
}

export const DefaultCanvas = forwardRef<HTMLDivElement, IDefaultCanvasProps>(
    ({ children, isLoading, isInitialized, wrapperRef, ...props }, ref) => {
        const { platform } = useAppSelector((state) => state.app.meta);

        return (
            <SCanvasWrapper ref={wrapperRef} data-name='canvasContainer'>
                <ReactFlow
                    ref={ref}
                    fitView
                    className='validationflow'
                    minZoom={CANVAS.MIN_ZOOM}
                    maxZoom={CANVAS.MAX_ZOOM}
                    proOptions={{ hideAttribution: true }}
                    snapToGrid={true}
                    snapGrid={[CANVAS.GRID_STEP, CANVAS.GRID_STEP]}
                    connectionMode={ConnectionMode.Loose}
                    selectionKeyCode={'Shift'}
                    selectionMode={SelectionMode.Partial}
                    multiSelectionKeyCode={
                        platform === PlatformTypes.WINDOWS || platform === PlatformTypes.ASTRA_LINUX
                            ? 'Control'
                            : 'Meta'
                    }
                    deleteKeyCode={null}
                    zoomOnDoubleClick={false}
                    {...props}
                >
                    {(!isInitialized || isLoading) && (
                        <SCanvasLoaderWrapper>
                            <Loader />
                        </SCanvasLoaderWrapper>
                    )}
                    <Background variant={BackgroundVariant.Dots} gap={CANVAS.GRID_STEP} />
                    <Controls />
                    <ReactFlowMiniMap />
                    {children}
                </ReactFlow>
            </SCanvasWrapper>
        );
    }
);
