import { FC, useEffect, useState } from 'react';

import { useAppSelector } from '@repeat/hooks';
import { workspaceSelectors } from '@repeat/store';

import { TWorkspaceMode } from '@repeat/models';
import { IconButton, uiColors } from '@repeat/ui-kit';
import { useWorkspaceDataContext } from '../DataProvider/DataProvider';
import {
    STopPanelBreadCrumbsMenu,
    STopPanelDropdown,
    STopPanelDropdownItem,
    STopPanelProjectName,
    STopPanelProjectNameClickable,
} from './STopPanel';

interface IBreadCrumbsProps {
    handleProjectNameClick: (isRoot?: boolean) => void;
    handleGroupNameClick: (el: {
        id: string | null;
        name: string;
        mode: TWorkspaceMode | null;
        readonly: boolean;
    }) => void;
    projectName: string;
}

function getTextWidth(text: string, font?: string) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
        return 0;
    }
    context.font = font || '14px Rosatom';
    const metrics = context.measureText(text);
    return metrics.width;
}

function getTextArrayWidth(text: string[]) {
    return getTextWidth(text.join(' / ')) + 8;
}

export const TopPanelBreadcrumbs: FC<IBreadCrumbsProps> = ({
    handleProjectNameClick,
    handleGroupNameClick,
    projectName,
}) => {
    const { userBlockEditor: isUserBlockEditorMode, path } = useWorkspaceDataContext();

    const isMainGroupUserBlockEditor = useAppSelector(workspaceSelectors.isMainGroupUserBlockEditor);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const namesAll = isUserBlockEditorMode
        ? [...path.map((item) => item.name)].reverse()
        : [projectName, ...path.map((item) => item.name)].reverse();

    const namesVisible = [namesAll[0]];
    const namesHidden: string[] = [];
    let hasHidden = false;

    namesAll.slice(1).forEach((name) => {
        if (hasHidden) {
            namesHidden.push(name);
        } else {
            if (Number(getTextArrayWidth([...namesVisible, name])) <= 300) {
                namesVisible.push(name);
            } else {
                namesHidden.push(name);
                hasHidden = true;
            }
        }
    });
    const pathVisible = path.slice(-namesVisible.length);
    const pathHidden = path.slice(0, path.length - namesVisible.length);

    useEffect(() => {
        if (namesHidden.length > 0) {
            setIsButtonVisible(true);
        } else {
            setIsButtonVisible(false);
        }
        if (isMainGroupUserBlockEditor) {
            setIsButtonVisible(false);
        }
    }, [namesHidden, isMainGroupUserBlockEditor]);

    return (
        <STopPanelBreadCrumbsMenu>
            {isButtonVisible && (
                <IconButton
                    style={{ flexShrink: 0 }}
                    fill={uiColors.darkGrey}
                    noHover
                    name='menu'
                    onClick={() => {
                        setIsDropdownVisible(!isDropdownVisible);
                    }}
                ></IconButton>
            )}
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', maxWidth: '260px' }}>
                {namesHidden.length === 0 && !isUserBlockEditorMode && (
                    <>
                        <STopPanelProjectNameClickable onClick={() => handleProjectNameClick(true)}>
                            {projectName}
                        </STopPanelProjectNameClickable>{' '}
                        &nbsp;/&nbsp;
                    </>
                )}
                {pathVisible.map((el, index) =>
                    index === pathVisible.length - 1 ? (
                        <span key={el.id}>{el.name}</span>
                    ) : (
                        <STopPanelProjectName key={el.id}>
                            <STopPanelProjectNameClickable
                                onClick={() => {
                                    el.id && handleGroupNameClick(el);
                                }}
                            >
                                {el.name}&nbsp;/&nbsp;
                            </STopPanelProjectNameClickable>
                        </STopPanelProjectName>
                    )
                )}
            </div>
            {namesHidden.length !== 0 && (
                <STopPanelDropdown isVisible={isDropdownVisible}>
                    {pathHidden.reverse().map((item, index) => (
                        <STopPanelDropdownItem key={item.id}>
                            <STopPanelProjectName>
                                <STopPanelProjectNameClickable
                                    onClick={() => {
                                        item.id && handleGroupNameClick(item);
                                        setIsDropdownVisible(false);
                                    }}
                                >
                                    {item.name}
                                </STopPanelProjectNameClickable>
                            </STopPanelProjectName>
                        </STopPanelDropdownItem>
                    ))}
                    {!isUserBlockEditorMode && (
                        <STopPanelDropdownItem>
                            <STopPanelProjectName>
                                <STopPanelProjectNameClickable onClick={() => handleProjectNameClick()}>
                                    {projectName}
                                </STopPanelProjectNameClickable>
                            </STopPanelProjectName>
                        </STopPanelDropdownItem>
                    )}
                </STopPanelDropdown>
            )}
        </STopPanelBreadCrumbsMenu>
    );
};
