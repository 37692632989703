import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector, useProjectId } from '@repeat/hooks';
import { IVariables, ModalTypes, Statuses, TUserBlockData, TUserBlockSaveModal } from '@repeat/models';
import { addUserBlock, getProjects, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Alert, AlertType, Button, Loader, Modal } from '@repeat/ui-kit';

import { blockOptionsMessages as messages } from './translation';

import { findGroups } from '@repeat/constants';
import { UserBlockForm } from '../Project/UserBlockForm/UserBlockForm';

type ElementData = {
    data: {
        elemProps: { value: string }[];
    };
};

type Group = {
    elements: ElementData[];
};

function checkElements(elementsContainer: any, valueMap: IVariables): boolean {
    function checkElement(element: ElementData): boolean {
        for (const elemProp of element.data.elemProps) {
            const value = elemProp.value;
            if (value in valueMap) {
                return false;
            }
        }
        return true;
    }

    function checkGroup(group: Group): boolean {
        for (const element of group.elements) {
            if (!checkElement(element)) {
                return false;
            }
        }
        return true;
    }

    for (const element of elementsContainer.elements) {
        if (!checkElement(element)) {
            return false;
        }
    }

    if (elementsContainer.groups) {
        for (const group of elementsContainer.groups) {
            if (!checkGroup(group)) {
                return false;
            }
        }
    }

    return true;
}

export const UserBlockSaveModal = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const { projectId } = useProjectId() || { projectId: null };

    const data = useAppSelector((state) => state.app.modals[0]) as TUserBlockSaveModal;
    const status = useAppSelector((state) => state.workspace.userBlocks.addUserBlock.status);
    const variables = useAppSelector((state) => state.workspace.settings.globalVariables) || {};

    const {
        data: { element, groups },
    } = data;
    const group = groups.find((group) => group.id.toString() === element.id);
    const groupName = group?.name || '';

    const innerGroups = group ? findGroups(group, groups) : [];

    const isCanAddUserBlock =
        group && checkElements({ ...group, groups: [...innerGroups, group] }, variables as IVariables);

    const subLibraries = useAppSelector(workspaceSelectors.customSubLibraries);

    const [userData, setUserData] = useState<TUserBlockData>({
        name: groupName,
        subLibrary: subLibraries[0] || groupName,
        image: '',
    });

    const handleClose = () => {
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.USER_BLOCK_SAVE }));
    };

    const handleAdd = () => {
        dispatch(addUserBlock(element, userData));
    };

    const [isValidForm, setIsValidForm] = useState(false);
    const projectsState = useAppSelector((state) => state.projects);
    const isProjectsInitialized = projectsState.status === Statuses.SUCCEEDED;

    useEffect(() => {
        if (isProjectsInitialized === false) {
            dispatch(getProjects());
        }
    }, [dispatch, isProjectsInitialized]);

    const handleFormValidate = (isValid: boolean) => {
        setIsValidForm(isValid);
    };

    const FooterButtons = () => (
        <>
            <Button variant='secondary' onClick={handleClose}>
                {intl.formatMessage(messages[TranslationKey.CANCEL])}
            </Button>
            <Button onClick={handleAdd} disabled={!isValidForm || status === Statuses.LOADING || !isCanAddUserBlock}>
                {intl.formatMessage(messages[TranslationKey.SAVE])}
            </Button>
        </>
    );

    return (
        <Modal
            id={ModalTypes.USER_BLOCK_SAVE}
            title={intl.formatMessage(messages[TranslationKey.USER_BLOCK_NEW])}
            isOpen={true}
            hasOverlay={true}
            onClose={handleClose}
            type='default'
            size='big'
            footer={<FooterButtons />}
        >
            {!isCanAddUserBlock && (
                <Alert type={AlertType.WARNING}>
                    {intl.formatMessage(messages[TranslationKey.USER_BLOCK_MODAL_WARN])}
                </Alert>
            )}
            {projectsState.status === Statuses.LOADING && <Loader />}
            {projectsState.status === Statuses.FAILED && (
                <span>{intl.formatMessage(messages[TranslationKey.WORKSPACE_ERROR_GETTING_PROJECTS_LIST])}</span>
            )}
            <UserBlockForm onValid={handleFormValidate} groupName={groupName} data={userData} setData={setUserData} newBlock />
        </Modal>
    );
};
