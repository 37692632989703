import { ChangeEvent, FC, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useReactFlow } from 'reactflow';

import { useAppDispatch } from '@repeat/hooks';
import { TWorkspaceMode } from '@repeat/models';
import { workspaceActions } from '@repeat/store';
import { IconButton, uiColors } from '@repeat/ui-kit';

import { useWorkspaceDataContext } from '../../../DataProvider/DataProvider';
import {
    SElementControlPanel,
    SElementCustomName,
    SElementCustomNameWrapper,
    SElementEditCustomNameText,
    SElementEditCustomNameTextarea,
} from '../SComponents';

interface IElementEditCustomName {
    id: number;
    rotation: number;
    selected: boolean;
    defaultValue: string;
}

const adjustTextAreaHeight = (textAreRef: MutableRefObject<HTMLTextAreaElement>) => {
    if (textAreRef && textAreRef.current !== null) {
        textAreRef.current.style.height = 'auto';
        textAreRef.current.style.height = `${textAreRef.current.scrollHeight}px`;
    }
};

export const useElementEditCustomName = ({ defaultValue, id, selected, rotation }: IElementEditCustomName) => {
    const dispatch = useAppDispatch();
    const { mode: workspaceMode, readonly } = useWorkspaceDataContext();

    const [isEdit, setIsEdit] = useState(false);
    const [value, setValue] = useState(defaultValue);
    const [isValueChanged, setIsValueChanged] = useState(false);
    const textAreRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        if (!selected) {
            setIsEdit(false);
        }
    }, [selected]);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleChange = useCallback(
        (event: ChangeEvent) => {
            const target = event.target as HTMLTextAreaElement;
            const currentValue = target.value;
            if (!isValueChanged && defaultValue !== value) {
                setIsValueChanged(true);
            }
            setTimeout(() => setValue(currentValue), 0);
        },
        [isValueChanged, value]
    );

    const handleSave = useCallback(
        (value: string) => {
            dispatch(
                workspaceActions.changeElementCustomName({
                    id,
                    name: value,
                    workspaceMode: workspaceMode as TWorkspaceMode,
                })
            );
            setIsEdit(false);
        },
        [value, id]
    );

    const handleEditCustomName = useCallback((val: boolean) => {
        setIsEdit(val);
    }, []);

    const ElementEditCustomNameTrigger = () => (
        <SElementCustomNameWrapper>
            <SElementControlPanel selected={selected}>
                {!isEdit && (
                    <IconButton
                        fill={isEdit ? uiColors.mainBlue : uiColors.darkGrey}
                        noHover
                        name={'edit'}
                        onClick={() => handleEditCustomName(true)}
                    />
                )}
                {isEdit && (
                    <IconButton
                        fill={isEdit ? uiColors.mainBlue : uiColors.darkGrey}
                        noHover
                        name={isValueChanged ? 'done' : 'close'}
                        onClick={() => {
                            if (isValueChanged) {
                                return handleSave(value);
                            }
                            return setIsEdit(false);
                        }}
                    />
                )}
            </SElementControlPanel>
        </SElementCustomNameWrapper>
    );

    return {
        ElementEditCustomNameTrigger,
        handleChange,
        handleSave,
        isEdit,
    };
};

export interface IElementCustomName {
    id: string;
    rotation: number;
    customName: string;
    isEdit: boolean;
    handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleSave: (value: string) => void;
}

export const ElementCustomName: FC<IElementCustomName> = ({
    id,
    rotation,
    customName,
    isEdit,
    handleChange,
    handleSave,
}) => {
    const textAreRef = useRef<HTMLTextAreaElement | null>(null);
    const { getNode } = useReactFlow();
    const node = getNode(id);

    const handleKeyPress = useCallback(
        (event: React.KeyboardEvent) => {
            adjustTextAreaHeight(textAreRef as MutableRefObject<HTMLTextAreaElement>);
            const target = event.target as HTMLTextAreaElement;
            if (event.code === 'Enter' || event.code === 'Escape') {
                handleSave(target.value);
            }
        },
        [textAreRef]
    );

    if (!node) return null;

    return (
        <SElementCustomName rotation={rotation}>
            {!isEdit && <SElementEditCustomNameText>{customName}</SElementEditCustomNameText>}
            {isEdit && (
                <SElementEditCustomNameTextarea
                    onKeyDown={(event: React.KeyboardEvent) => handleKeyPress(event)}
                    ref={textAreRef}
                    maxLength={56}
                    defaultValue={customName}
                    autoFocus={true}
                    onChange={(event) => handleChange(event)}
                />
            )}
        </SElementCustomName>
    );
};
