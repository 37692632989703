import { ListenerMiddlewareInstance } from '@reduxjs/toolkit';

import { ElemToolTypes, IElemTool, IWire, TState } from '@repeat/models';
import { addElemCur, schemaActions } from '@repeat/store';

export const appendSchemaListeners = (listenerMiddleware: ListenerMiddlewareInstance) => {
    listenerMiddleware.startListening({
        actionCreator: schemaActions.addWireFirstElement,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(
                schemaActions.markPortAsUsed({ elementId: action.payload.elementId, num: action.payload.num })
            );
        },
    });

    listenerMiddleware.startListening({
        actionCreator: schemaActions.addWireSecondElement,
        effect: (action, listenerApi) => {
            listenerApi.dispatch(
                schemaActions.markPortAsUsed({ elementId: action.payload.elementId, num: action.payload.num })
            );
        },
    });

    listenerMiddleware.startListening({
        actionCreator: schemaActions.deleteItemsGroup,
        effect: async (action, listenerApi) => {
            const previousState = listenerApi.getOriginalState() as TState;

            const selectedItems = previousState.schema.selectedItems;
            const selectedElementsIds = selectedItems.filter((item) => item.type !== 'wire').map((i) => i.id);
            const selectedWires = selectedItems.filter((item) => item.type === 'wire') as IWire[];

            previousState.schema.schemaItems.wires.map((wire: IWire) => {
                if (
                    selectedElementsIds.includes(wire.firstElement) ||
                    selectedElementsIds.includes(wire.secondElement)
                ) {
                    listenerApi.dispatch(
                        schemaActions.markPortAsNotUsed({ elementId: wire.firstElement, num: wire.firstPort })
                    );
                    listenerApi.dispatch(
                        schemaActions.markPortAsNotUsed({ elementId: wire.secondElement, num: wire.secondPort })
                    );
                }
            });
            selectedWires.map((wire: IWire) => {
                listenerApi.dispatch(
                    schemaActions.markPortAsNotUsed({ elementId: wire.firstElement, num: wire.firstPort })
                );
                listenerApi.dispatch(
                    schemaActions.markPortAsNotUsed({ elementId: wire.secondElement, num: wire.secondPort })
                );
            });
        },
    });

    listenerMiddleware.startListening({
        actionCreator: schemaActions.deleteCrashWire,
        effect: async (action, listenerApi) => {
            const previousState = listenerApi.getOriginalState() as TState;
            const wires = [...previousState.schema.schemaItems.wires];
            const notConnectedWire = wires.pop();

            if (notConnectedWire) {
                listenerApi.dispatch(
                    schemaActions.markPortAsNotUsed({
                        elementId: notConnectedWire.firstElement,
                        num: notConnectedWire.firstPort,
                    })
                );
            }
        },
    });
    listenerMiddleware.startListening({
        actionCreator: schemaActions.addElement,
        effect: async (action, listenerApi) => {
            listenerApi.dispatch(addElemCur({ ...action.payload, type: ElemToolTypes.ELEMENT } as IElemTool));
        },
    });
};
