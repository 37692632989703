import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { SInputMatrixConstructor } from '@components/InputMatrixTable/SInputMatrixTable';
import { messages } from '@components/InputMatrixTable/translations';

import { TranslationKey } from '@repeat/translations';
import { Button, InputField } from '@repeat/ui-kit';

enum constructorFieldNames {
    ROW_COUNT = 'ROW_COUNT',
    COL_COUNT = 'COL_COUNT',
}

export interface IInputMatrixConstructorProps {
    errors: Record<string, boolean>;
    rowCount: number;
    colCount: number;
    checkConstructorErrors: (name: string, value: string) => void;
    handleSetRowCount: (val: string) => void;
    handleSetColCount: (val: string) => void;
    applyMatrixSize: () => void;
}

export const InputMatrixConstructor: FC<IInputMatrixConstructorProps> = ({
    errors,
    rowCount,
    colCount,
    checkConstructorErrors,
    handleSetRowCount,
    handleSetColCount,
    applyMatrixSize,
}) => {
    const { formatMessage } = useIntl();

    return (
        <SInputMatrixConstructor>
            <InputField
                error={errors[constructorFieldNames.ROW_COUNT]}
                name={constructorFieldNames.ROW_COUNT}
                type='number'
                label={formatMessage(messages[TranslationKey.MATRIX_TABLE_ROW_COUNT]) + ':'}
                value={rowCount}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    checkConstructorErrors(e.target.name, e.target.value);
                    handleSetRowCount(e.target.value);
                }}
                size={'small'}
            />
            <InputField
                error={errors[constructorFieldNames.COL_COUNT]}
                name={constructorFieldNames.COL_COUNT}
                type='number'
                label={formatMessage(messages[TranslationKey.MATRIX_TABLE_COL_COUNT]) + ':'}
                value={colCount}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    checkConstructorErrors(e.target.name, e.target.value);
                    handleSetColCount(e.target.value);
                }}
                size={'small'}
            />
            <Button
                onClick={applyMatrixSize}
                size={'small'}
                disabled={errors[constructorFieldNames.ROW_COUNT] || errors[constructorFieldNames.COL_COUNT]}
            >
                {formatMessage(messages[TranslationKey.MATRIX_TABLE_APPLY])}
            </Button>
        </SInputMatrixConstructor>
    );
};
