import React from 'react';
import { useIntl } from 'react-intl';

import { useModulesSort } from '@repeat/common-hooks';
import { useAppSelector, useGetTariffData } from '@repeat/hooks';
import { appUserSelectors, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Dropdown, Icon, Tooltip } from '@repeat/ui-kit';

import { workspaceMessages } from '../../../pages/WorkspacePage/translation';
import { SLivePermissionStatus, SNavigationDropDownFirstItem } from '../../Navigation/SNavigation';

export const TopPanelModulesMenu = () => {
    const { formatMessage } = useIntl();

    const { permissions, isConnected } = useAppSelector(workspaceSelectors.livePermissions);
    const modules = useAppSelector((state) => state.workspace.modules);
    const currentUserPermissions = useAppSelector(appUserSelectors.currentUserPermissions);

    const sorted = useModulesSort(currentUserPermissions || []);
    const { userModules } = useGetTariffData();

    return (
        <>
            <Tooltip
                text={formatMessage(workspaceMessages[TranslationKey.WORKSPACE_LIVE_PERMISSION_MODULES])}
                style={{ maxWidth: '130px', marginRight: 0 }}
            >
                <Dropdown
                    onMouseOver={(event: React.MouseEvent) => event.stopPropagation()}
                    clickOutside
                    trigger={<SLivePermissionStatus status={isConnected} />}
                >
                    {permissions &&
                        Object.keys(permissions).map((permission, index) => (
                            <SNavigationDropDownFirstItem
                                key={`available-modules-permission-${permission}-${index}`}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <span>
                                    {formatMessage({
                                        id: `${userModules(permission)}`,
                                    })}
                                    &nbsp;
                                </span>
                                <SLivePermissionStatus
                                    size={'small'}
                                    status={permissions[permission as keyof typeof permissions]}
                                />
                            </SNavigationDropDownFirstItem>
                        ))}
                </Dropdown>
            </Tooltip>
            <Tooltip text={formatMessage(workspaceMessages[TranslationKey.WORKSPACE_INVOLVED_MODULES])}>
                <Dropdown
                    onMouseOver={(event: React.MouseEvent) => event.stopPropagation()}
                    clickOutside
                    trigger={<Icon name='modules' fill='white' />}
                >
                    {sorted?.map((permission, index) => (
                        <SNavigationDropDownFirstItem key={`user-modules-permission-${permission}-${index}`}>
                            <span>
                                {formatMessage({
                                    id: `${userModules(permission)}`,
                                })}
                                :&nbsp;
                            </span>
                            {modules !== null && modules[permission] ? <span>{modules[permission]}</span> : 0}
                        </SNavigationDropDownFirstItem>
                    ))}
                </Dropdown>
            </Tooltip>
        </>
    );
};
