import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.WORKSPACE_PROJECT_TITLE]: {
        id: TranslationKey.WORKSPACE_PROJECT_TITLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_TITLE],
    },
    [TranslationKey.WORKSPACE_ELEMENT_TITLE]: {
        id: TranslationKey.WORKSPACE_ELEMENT_TITLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_ELEMENT_TITLE],
    },
    [TranslationKey.WORKSPACE_CONNECTION_TITLE]: {
        id: TranslationKey.WORKSPACE_CONNECTION_TITLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_CONNECTION_TITLE],
    },
    [TranslationKey.DELETE]: {
        id: TranslationKey.DELETE,
        defaultMessage: ru[TranslationKey.DELETE],
    },
    [TranslationKey.WORKSPACE_OBJECTS_TITLE]: {
        id: TranslationKey.WORKSPACE_OBJECTS_TITLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_OBJECTS_TITLE],
    },
    [TranslationKey.SCHEMA_ELEMENT_BACK_TO_SCHEMA]: {
        id: TranslationKey.SCHEMA_ELEMENT_BACK_TO_SCHEMA,
        defaultMessage: ru[TranslationKey.SCHEMA_ELEMENT_BACK_TO_SCHEMA],
    },
    [TranslationKey.PROJECTS_COLUMN_CREATED_AT]: {
        id: TranslationKey.PROJECTS_COLUMN_CREATED_AT,
        defaultMessage: ru[TranslationKey.PROJECTS_COLUMN_CREATED_AT],
    },
    [TranslationKey.PROJECTS_COLUMN_UPDATED_AT]: {
        id: TranslationKey.PROJECTS_COLUMN_UPDATED_AT,
        defaultMessage: ru[TranslationKey.PROJECTS_COLUMN_UPDATED_AT],
    },
    [TranslationKey.WORKSPACE_ADD_PROPERTY]: {
        id: TranslationKey.WORKSPACE_ADD_PROPERTY,
        defaultMessage: ru[TranslationKey.WORKSPACE_ADD_PROPERTY],
    },
    [TranslationKey.HIDE]: {
        id: TranslationKey.HIDE,
        defaultMessage: ru[TranslationKey.HIDE],
    },
    [TranslationKey.SETTINGS]: {
        id: TranslationKey.SETTINGS,
        defaultMessage: ru[TranslationKey.SETTINGS],
    },
    [TranslationKey.ELEMENT]: {
        id: TranslationKey.ELEMENT,
        defaultMessage: ru[TranslationKey.ELEMENT],
    },
    [TranslationKey.WORKSPACE_BLOCK_TITLE]: {
        id: TranslationKey.WORKSPACE_BLOCK_TITLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_BLOCK_TITLE],
    },
    [TranslationKey.ONLY_DIGITS_ERROR_MSG]: {
        id: TranslationKey.ONLY_DIGITS_ERROR_MSG,
        defaultMessage: ru[TranslationKey.ONLY_DIGITS_ERROR_MSG],
    },
    [TranslationKey.TABLE_DOCUMENT_COLUMN_TYPE]: {
        id: TranslationKey.TABLE_DOCUMENT_COLUMN_TYPE,
        defaultMessage: ru[TranslationKey.TABLE_DOCUMENT_COLUMN_TYPE],
    },
    [TranslationKey.NAME]: {
        id: TranslationKey.NAME,
        defaultMessage: ru[TranslationKey.NAME],
    },
    [TranslationKey.WORKSPACE_CHART_POINT_VALUE]: {
        id: TranslationKey.WORKSPACE_CHART_POINT_VALUE,
        defaultMessage: ru[TranslationKey.WORKSPACE_CHART_POINT_VALUE],
    },

    [TranslationKey.FSM_IN_VARIABLE]: {
        id: TranslationKey.FSM_IN_VARIABLE,
        defaultMessage: ru[TranslationKey.FSM_IN_VARIABLE],
    },
    [TranslationKey.FSM_OUT_VARIABLE]: {
        id: TranslationKey.FSM_OUT_VARIABLE,
        defaultMessage: ru[TranslationKey.FSM_OUT_VARIABLE],
    },
    [TranslationKey.FSM_INTERNAL_VARIABLE]: {
        id: TranslationKey.FSM_INTERNAL_VARIABLE,
        defaultMessage: ru[TranslationKey.FSM_INTERNAL_VARIABLE],
    },
    [TranslationKey.FSM_EXTERNAL_VARIABLE]: {
        id: TranslationKey.FSM_EXTERNAL_VARIABLE,
        defaultMessage: ru[TranslationKey.FSM_EXTERNAL_VARIABLE],
    },
    [TranslationKey.PAGE_VERSIONS_HISTORY_TITLE]: {
        id: TranslationKey.PAGE_VERSIONS_HISTORY_TITLE,
        defaultMessage: ru[TranslationKey.PAGE_VERSIONS_HISTORY_TITLE],
    },
    [TranslationKey.PROJECT_VERSIONS_HISTORY]: {
        id: TranslationKey.PROJECT_VERSIONS_HISTORY,
        defaultMessage: ru[TranslationKey.PROJECT_VERSIONS_HISTORY],
    },
    [TranslationKey.WORKSPACE_PAGE_VARIABLES]: {
        id: TranslationKey.WORKSPACE_PAGE_VARIABLES,
        defaultMessage: ru[TranslationKey.WORKSPACE_PAGE_VARIABLES],
    },
    [TranslationKey.WORKSPACE_ELEMENT_ACTIONS]: {
        id: TranslationKey.WORKSPACE_ELEMENT_ACTIONS,
        defaultMessage: ru[TranslationKey.WORKSPACE_ELEMENT_ACTIONS],
    },
});
