import styled, { css } from 'styled-components';

import { fontSize, layoutSize, pxToRem, uiColors, zIndex } from '@repeat/ui-kit';

export const SHeader = styled.header`
    align-items: center;
    background-color: var(--ui-secondary);
    height: ${pxToRem(parseInt(layoutSize.header))};
    left: 0;
    max-width: 100vw;
    padding: 0 ${pxToRem(16)};
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${zIndex.header};

    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;

    button {
        order: 2;
    }

    svg {
        fill: var(--ui-icon-button);
    }
`;

export const SHeaderTitle = styled.div`
    color: ${uiColors.white};
    font-size: ${fontSize.default};
`;

export const SChartsList = styled.ul`
    padding: ${pxToRem(16)} 0;
`;
export const SChartsListItem = styled.li<{ isActive?: boolean }>`
    div {
        padding: ${pxToRem(8)} ${pxToRem(16)};
        overflow: hidden;
        cursor: pointer;
        color: var(--ui-link-secondary);

        &:hover {
            background: var(--ui-background);
            color: var(--ui-link-secondary-hover);
        }

        ${({ isActive }) =>
            isActive === true &&
            css`
                background: var(--ui-background);
                color: var(--ui-link-secondary-hover);
            `}
    }
`;

export const STrendWrapperBox = styled.div`
    background: var(--ui-background);

    h5 {
        display: flex;
        justify-content: center;
        padding: ${pxToRem(24)} ${pxToRem(24)} 0;
        width: 100%;
    }
`;
export const STrendWrapper = styled.div`
    padding: 16px 24px;

    .modebar {
        visibility: hidden;
    }
`;
export const SChartContent = styled.div`
    margin-right: ${layoutSize.sidebar};
`;
export const SChartTools = styled.div`
    padding: ${pxToRem(16)} 0;
    overflow-y: auto;
    height: 100%;
`;
export const SChartToolName = styled.div`
    font-size: ${pxToRem(14)};
    color: var(--ui-text);
    font-weight: 700;
    margin: ${pxToRem(8)} 0;
    padding: 0;
`;
