import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import styled from 'styled-components';

import { Autocomplete, TextField } from '@mui/material';

import { TUserBlockData } from 'libs/models/src/lib/element';

import { useAppSelector } from '@repeat/hooks';
import { workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { InputField, pxToRem, uiColors } from '@repeat/ui-kit';

import { validateExistingBlockName } from '@repeat/constants';
import { ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import { ImageCrop } from './ImageEditor/ImageCrop';
import { SAutocomplete, SUserBlockFormItem } from './SUserBlockForm';
import { TextEditor } from './TextEditor/TextEditor';
import { messages } from './translation';
import { ILibraryItem } from '@repeat/models';

interface IProjectBlockForm {
    onValid: (isValid: boolean) => void;
    groupName: string;
    data: TUserBlockData;
    setData: (state: TUserBlockData) => void;
    newBlock?: boolean;
}

const STextEditor = styled.div`
    margin-bottom: ${pxToRem(16)};
    em {
        font-style: italic;
    }
`;

interface IAutocompleteListBoxProps extends React.HTMLAttributes<HTMLUListElement> {
    'data-test-id'?: string;
}

export const UserBlockForm: FC<IProjectBlockForm> = ({ onValid, groupName, data, setData, newBlock }) => {
    const { formatMessage } = useIntl();
    const subLibraries = useAppSelector(workspaceSelectors.customSubLibraries);
    const isUserBlockEditorMode = useAppSelector(workspaceSelectors.isUserBlockEditor);
    const userBlocks = useAppSelector((state) => state.workspace.userBlocks.items);
    const [valueAutocomplete, setValueAutocomplete] = useState(data.subLibrary || '');

    const {
        register,
        setError,
        formState: { errors, isValid },
    } = useForm<{ blockName: string }>({ mode: 'onChange' });

    const handleSelect = (option: string) => {
        setData({ ...data, subLibrary: option });
    };

    const handleOnChangeBlockName = (e: ChangeEvent) => {
        const target = e.target as HTMLInputElement;
        setData({ ...data, name: target.value });
    };
    const setValue = (value: string) => {
        setData({ ...data, description: value });
    };
    const validate = (value: string, blocks: ILibraryItem[]) => {
        if (newBlock) {
            return validateExistingBlockName(value, blocks);
        }
        return validateExistingBlockName(
            value,
            blocks.filter((block) => block.blockId !== data.blockId)
        );
    };
    useEffect(() => {
        if (data.name && data.subLibrary) {
            onValid(isValid);
        }

        if (!validate(data.name, userBlocks)) {
            setError('blockName', {
                message: formatMessage(messages[TranslationKey.USER_BLOCK_NEW_ERROR_DUPLICATE]),
                type: 'uniqueName',
            });
        }
    }, [data.name, data.subLibrary, isValid]);

    const inputBlockNameErrors =
        errors.blockName?.type === 'uniqueName' &&
        formatMessage(messages[TranslationKey.USER_BLOCK_NEW_ERROR_DUPLICATE]);

    return (
        <>
            <SUserBlockFormItem>
                <InputField
                    defaultValue={data.name}
                    label={formatMessage(messages[TranslationKey.USER_BLOCK_TITLE])}
                    type='text'
                    {...register('blockName', {
                        required: true,
                        validate: {
                            uniqueName: (value: string) => validate(value, userBlocks),
                        },
                        onChange: handleOnChangeBlockName,
                    })}
                    error={!!errors?.blockName}
                    errorText={inputBlockNameErrors}
                />
            </SUserBlockFormItem>
            <SAutocomplete>
                <Autocomplete
                    freeSolo
                    noOptionsText={formatMessage(messages[TranslationKey.USER_BLOCK_NO_SUBSECTIONS])}
                    onChange={(e: any, option: string) => {
                        if (option === null) {
                            onValid(false);
                        } else {
                            handleSelect(option);
                        }
                    }}
                    onBlur={(e: any) => {
                        handleSelect(e.target.value);
                    }}
                    size='small'
                    id='grouped-demo'
                    options={subLibraries}
                    getOptionLabel={(option: string) => option || ''}
                    value={valueAutocomplete}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={formatMessage(messages[TranslationKey.USER_BLOCK_SUBSECTION])}
                            placeholder={formatMessage(messages[TranslationKey.USER_BLOCK_SUBSECTION_PLACEHOLDER])}
                        />
                    )}
                    ListboxProps={
                        {
                            'data-test-id': 'visualization-params-box',
                        } as IAutocompleteListBoxProps
                    }
                    renderOption={(props, option: string, state) => {
                        return (
                            <li {...props} key={option}>
                                {option}
                            </li>
                        );
                    }}
                    defaultValue={data.subLibrary}
                />
            </SAutocomplete>

            <STextEditor>
                <TextEditor value={data.description || ''} setValue={setValue} />
            </STextEditor>
            <div style={{ marginBottom: '8px', fontSize: '14px', color: `${uiColors.darkGrey}` }}>
                {formatMessage(messages[TranslationKey.USER_BLOCK_ICON])}
            </div>
            <ImageCrop data={data} setData={setData} isEditorMode={isUserBlockEditorMode} />
        </>
    );
};
