import { FC } from 'react';
import { ItemInterface } from 'react-sortablejs';

import { Variant } from 'libs/ui-kit/src/lib/Tooltip/Tooltip';

import { Tooltip } from '@repeat/ui-kit';

import { SItem, SItemWrapper, SSubTitle, STitle, STitleWrapper } from './SPort';

import { getPortColor } from '../util';

interface IItemProps {
    item: ItemInterface;
    titlePosition: string;
}

export const Port: FC<IItemProps> = ({ item, titlePosition }) => {
    const itemColor = getPortColor(item);

    return (
        <Tooltip text={item.typeConnection} delayShow={800} variant={Variant.LIGHT} style={{ maxWidth: 'none' }}>
            <SItemWrapper position={titlePosition}>
                <STitleWrapper position={titlePosition}>
                    <STitle>{item.title.length > 0 ? item.title : '-'}</STitle>
                    <SSubTitle position={titlePosition}>{item.name}</SSubTitle>
                </STitleWrapper>
                <SItem key={item.id} isConditional={item?.isConditional} color={itemColor} />
            </SItemWrapper>
        </Tooltip>
    );
};
