import { NavigateFunction } from 'react-router-dom';

import { AnyAction, createSlice, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import { TImportedProject } from 'libs/services/src/lib/ProjectsService';

import { ApplicationActions } from '@repeat/common-slices';
import { API_INTERNAL_ERROR_CODE, setWorkspaceMode } from '@repeat/constants';
import {
    IProjectData,
    IProjectInfo,
    IProjectsState,
    IProjectVersion,
    ModalTypes,
    NotificationTypes,
    Project,
    SolverTypes,
    Statuses,
    TDemoProject,
    TProjectCreateData,
    TProjectEditData,
    TTask,
    WorkspaceModes,
} from '@repeat/models';
import { IDownloadFileParams, ProjectsService } from '@repeat/services';
import { loadProject, stopProject, workspaceActions } from '@repeat/store';
import { CODE_LIST, TranslationKey } from '@repeat/translations';

import { AppDispatch, RootStateFn } from '../store';

export const initialState: IProjectsState = {
    items: [],
    versions: {
        status: Statuses.IDLE,
        items: [],
        error: null,
        total: null,
        useVersion: {
            status: Statuses.IDLE,
            error: null,
        },
    },
    status: Statuses.IDLE,
    error: null,
    demoProjects: {
        items: [],
        status: Statuses.IDLE,
        error: null,
    },
    favProjects: {
        items: [],
        status: Statuses.IDLE,
        error: null,
    },
    addFavProject: {
        status: Statuses.IDLE,
        error: null,
    },
    createProject: {
        status: Statuses.IDLE,
        error: null,
    },
    updateProject: {
        status: Statuses.IDLE,
        error: null,
    },
    deleteProject: {
        status: Statuses.IDLE,
        error: null,
    },
    deleteProjects: {
        status: Statuses.IDLE,
        error: null,
    },
    getProject: {
        id: null,
        project: null,
        status: Statuses.IDLE,
        error: null,
    },
    duplicateProject: {
        status: Statuses.IDLE,
        error: null,
    },
    saveNewProject: {
        status: Statuses.IDLE,
        error: null,
    },
    exportProject: {
        status: Statuses.IDLE,
        error: null,
    },
    importProject: {
        fileSizeBytes: 0,
        uploadedBytes: 0,
        status: Statuses.IDLE,
        error: null,
    },
};

const reducers = {
    getProjectsRequest: () => ({
        ...initialState,
        status: Statuses.LOADING,
    }),
    getProjectsSuccess: (state: IProjectsState, action: PayloadAction<Project[]>) => ({
        ...state,
        items: action.payload,
        status: Statuses.SUCCEEDED,
    }),
    getProjectsFailed: (state: IProjectsState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        status: Statuses.FAILED,
        error: action.payload.error,
    }),
    getProjectVersionsRequest: (state: IProjectsState) => ({
        ...state,
        versions: {
            ...state.versions,
            status: Statuses.LOADING,
        },
    }),
    getProjectVersionsSuccess: (
        state: IProjectsState,
        action: PayloadAction<{ isLazy: boolean; total: number; versions: IProjectVersion[] }>
    ) => {
        return {
            ...state,
            versions: {
                ...state.versions,
                items: action.payload.isLazy
                    ? [...state.versions.items, ...action.payload.versions]
                    : action.payload.versions,
                status: Statuses.SUCCEEDED,
                error: null,
                total: action.payload.total,
            },
        };
    },
    getProjectVersionsFailed: (state: IProjectsState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        versions: {
            ...state.versions,
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
    useProjectVersionRequest: (state: IProjectsState) => ({
        ...state,
        versions: {
            ...state.versions,
            useVersion: {
                status: Statuses.LOADING,
                error: null,
            },
        },
    }),
    useProjectVersionSuccess: (state: IProjectsState) => {
        return {
            ...state,
            versions: {
                ...state.versions,
                useVersion: {
                    status: Statuses.SUCCEEDED,
                    error: null,
                },
            },
        };
    },
    useProjectVersionFailed: (state: IProjectsState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        versions: {
            ...state.versions,
            useVersion: {
                status: Statuses.FAILED,
                error: action.payload.error,
            },
        },
    }),
    getDemoProjectsRequest: () => ({
        ...initialState,
        demoProjects: {
            ...initialState.demoProjects,
            status: Statuses.LOADING,
        },
    }),
    getDemoProjectsSuccess: (state: IProjectsState, action: PayloadAction<TDemoProject[]>) => ({
        ...state,
        demoProjects: {
            ...state.demoProjects,
            items: action.payload,
            status: Statuses.SUCCEEDED,
            error: null,
        },
    }),
    getDemoProjectsFailed: (state: IProjectsState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        demoProjects: {
            items: [],
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
    getFavProjectsRequest: (state: IProjectsState) => ({
        ...state,
        favProjects: {
            ...state.favProjects,
            status: Statuses.LOADING,
        },
    }),
    getFavProjectsSuccess: (state: IProjectsState, action: PayloadAction<Project[]>) => ({
        ...state,
        favProjects: {
            ...state.favProjects,
            items: action.payload,
            status: Statuses.SUCCEEDED,
            error: null,
        },
    }),
    getFavProjectsFailed: (state: IProjectsState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        favProjects: {
            items: [],
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
    addFavProjectRequest: (state: IProjectsState, action: PayloadAction) => ({
        ...state,
        addFavProject: {
            ...initialState.favProjects,
            status: Statuses.LOADING,
        },
    }),
    addFavProjectSuccess: (state: IProjectsState, action: PayloadAction) => ({
        ...state,
        addFavProject: {
            status: Statuses.SUCCEEDED,
            error: null,
        },
    }),
    addFavProjectError: (state: IProjectsState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        addFavProject: {
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
    createProjectRequest: (state: IProjectsState) => ({
        ...state,
        createProject: {
            ...initialState.createProject,
            status: Statuses.LOADING,
        },
    }),
    createProjectSuccess: (state: IProjectsState) => ({
        ...state,
        createProject: {
            ...state.createProject,
            status: Statuses.SUCCEEDED,
        },
    }),
    createProjectFailed: (state: IProjectsState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        createProject: {
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
    updateProjectRequest: (state: IProjectsState) => ({
        ...state,
        updateProject: {
            ...initialState.updateProject,
            status: Statuses.LOADING,
        },
    }),
    updateProjectSuccess: (state: IProjectsState, action: PayloadAction<{ id: number }>) => ({
        ...state,
        updateProject: {
            ...state.updateProject,
            status: Statuses.SUCCEEDED,
        },
    }),
    updateProjectFailed: (state: IProjectsState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        updateProject: {
            ...state.updateProject,
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
    deleteProjectRequest: (state: IProjectsState) => ({
        ...state,
        deleteProject: {
            ...initialState.deleteProject,
            status: Statuses.LOADING,
        },
    }),
    deleteProjectSuccess: (state: IProjectsState, action: PayloadAction<{ id: number }>) => ({
        ...state,
        deleteProject: {
            ...state.deleteProject,
            status: Statuses.SUCCEEDED,
        },
    }),
    deleteProjectFailed: (state: IProjectsState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        deleteProject: {
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
    deleteProjectsRequest: (state: IProjectsState) => ({
        ...state,
        deleteProjects: {
            ...state.deleteProjects,
            status: Statuses.LOADING,
        },
    }),
    deleteProjectsSuccess: (state: IProjectsState) => ({
        ...state,
        deleteProjects: {
            ...state.deleteProjects,
            status: Statuses.SUCCEEDED,
        },
    }),
    deleteProjectsFailed: (state: IProjectsState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        deleteProjects: {
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
    getProjectRequest: (state: IProjectsState, action: PayloadAction<{ id: number }>) => ({
        ...state,
        getProject: {
            ...initialState.getProject,
            id: action.payload.id,
            status: Statuses.LOADING,
        },
    }),
    getProjectSuccess: (state: IProjectsState, { payload }: PayloadAction<IProjectInfo>) => ({
        ...state,
        getProject: {
            ...state.getProject,
            project: payload,
            status: Statuses.SUCCEEDED,
        },
    }),
    getProjectFailed: (state: IProjectsState, { payload }: PayloadAction<{ error: string }>) => ({
        ...state,
        getProject: {
            ...initialState.getProject,
            status: Statuses.FAILED,
            error: payload.error,
        },
    }),
    openProject: (state: IProjectsState) => ({
        ...state,
    }),
    closeProject: (state: IProjectsState) => ({
        ...state,
    }),
    duplicateProjectRequest: (state: IProjectsState) => ({
        ...state,
        duplicateProject: {
            ...initialState.duplicateProject,
            status: Statuses.LOADING,
        },
    }),
    duplicateProjectSuccess: (state: IProjectsState, action: PayloadAction<{ project: Project }>) => ({
        ...state,
        items: [...state.items, action.payload.project],
        duplicateProject: {
            ...state.duplicateProject,
            status: Statuses.SUCCEEDED,
        },
    }),
    duplicateProjectFailed: (state: IProjectsState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        duplicateProject: {
            ...state.duplicateProject,
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
    saveNewProjectRequest: (state: IProjectsState) => ({
        ...state,
        saveNewProject: {
            ...initialState.saveNewProject,
            status: Statuses.LOADING,
        },
    }),
    saveNewProjectSuccess: (state: IProjectsState, action: PayloadAction<{ project: Project }>) => ({
        ...state,
        items: [...state.items, action.payload.project],
        saveNewProject: {
            ...state.saveNewProject,
            status: Statuses.SUCCEEDED,
        },
    }),
    saveNewProjectFailed: (state: IProjectsState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        saveNewProject: {
            ...state.saveNewProject,
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
    exportProjectRequest: (state: IProjectsState) => ({
        ...state,
        exportProject: {
            ...state.exportProject,
            status: Statuses.LOADING,
        },
    }),
    exportProjectSuccess: (state: IProjectsState) => ({
        ...state,
        exportProject: {
            ...state.exportProject,
            status: Statuses.SUCCEEDED,
        },
    }),
    exportProjectFailed: (state: IProjectsState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        exportProject: {
            ...state.exportProject,
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
    importProjectRequest: (state: IProjectsState, action: PayloadAction<{ fileSizeBytes: number }>) => ({
        ...state,
        importProject: {
            ...state.importProject,
            fileSizeBytes: action.payload.fileSizeBytes,
            status: Statuses.LOADING,
        },
    }),
    importProjectProgress: (state: IProjectsState, action: PayloadAction<{ bytes: number }>) => ({
        ...state,
        importProject: {
            ...state.importProject,
            uploadedBytes: action.payload.bytes,
        },
    }),
    importProjectSuccess: (state: IProjectsState) => ({
        ...state,
        importProject: {
            ...state.importProject,
            status: Statuses.SUCCEEDED,
        },
    }),
    importProjectFailed: (state: IProjectsState, action: PayloadAction<{ error: string }>) => ({
        ...state,
        importProject: {
            ...state.importProject,
            status: Statuses.FAILED,
            error: action.payload.error,
        },
    }),
};

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers,
});

export const { actions, reducer } = projectsSlice;

// TODO make using AppDispatch and RootStateFn again
export const getProjects = () => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
    const status = getState().projects.status;

    if (status === Statuses.LOADING) {
        return;
    }
    dispatch(actions.getProjectsRequest());

    try {
        const response = await ProjectsService.getProjects();
        const projects = response.data;

        dispatch(actions.getProjectsSuccess(projects));
    } catch (error: any) {
        const httpStatus = error?.response?.status;
        const errorKey = TranslationKey.ERROR_UNKNOWN;

        dispatch(actions.getProjectsFailed({ error: errorKey }));
    }
};

export const getDemoProjects = () => async (dispatch: AppDispatch, getState: RootStateFn) => {
    const status = getState().projects.demoProjects.status;

    if (status === Statuses.LOADING) {
        return;
    }

    dispatch(actions.getDemoProjectsRequest());

    try {
        const response = await ProjectsService.getDemoProjects();
        const projects = response.data as unknown as TDemoProject[];

        dispatch(actions.getDemoProjectsSuccess(projects));
    } catch (error: any) {
        const httpStatus = error?.response?.status;
        const errorKey = TranslationKey.ERROR_UNKNOWN;

        dispatch(actions.getDemoProjectsFailed({ error: errorKey }));
    }
};

// TODO make using AppDispatch and RootStateFn again
export const getFavProjects = () => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: any) => {
    const status = getState().projects.favProjects.status;

    if (status === Statuses.LOADING) {
        return;
    }

    dispatch(actions.getFavProjectsRequest());

    try {
        const response = await ProjectsService.getFavProjects();
        const projects = response.data;

        dispatch(actions.getFavProjectsSuccess(projects));
    } catch (error: any) {
        const httpStatus = error?.response?.status;
        const errorKey = TranslationKey.ERROR_UNKNOWN;

        dispatch(actions.getFavProjectsFailed({ error: errorKey }));
    }
};

export const createProject =
    (project: TProjectCreateData, navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
        dispatch(actions.createProjectRequest());
        dispatch(workspaceActions.deleteSchema());

        try {
            const folderId = parseInt(localStorage.getItem('folderId') as string) || 0;
            const response = await ProjectsService.createProject({ ...project, folderId });

            const newProject = response.data;
            // TODO для создания проекта лучше сразу передавать тип решателя и библиотеку, минуя отдельный запрос на сохранение
            localStorage.setItem('projectId', newProject.projectId);
            localStorage.setItem('currentProjectName', newProject.projectName);

            dispatch(actions.createProjectSuccess());
            dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECT_CREATE }));
            dispatch(
                workspaceActions.changeWorkspaceMode({
                    mode: WorkspaceModes.MAIN,
                    previousMode: null,
                    readonly: false,
                })
            );
            setWorkspaceMode(WorkspaceModes.MAIN);

            navigate('/', { replace: true });

            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.SUCCESS,
                        message: TranslationKey.PROJECT_CREATED,
                    },
                })
            );
        } catch (error: any) {
            const errorKey = TranslationKey.ERROR_UNKNOWN;

            dispatch(actions.createProjectFailed({ error: errorKey }));

            if (error.response.status === API_INTERNAL_ERROR_CODE) {
                dispatch(workspaceActions.deleteSchema());
            }

            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.ERROR_UNKNOWN,
                    },
                })
            );
        }
    };

export const updateProject = (project: TProjectEditData) => async (dispatch: AppDispatch) => {
    dispatch(actions.updateProjectRequest());

    try {
        const response = await ProjectsService.renameProject({
            projectId: project.projectId,
            projectName: project.projectName,
        });

        dispatch(actions.updateProjectSuccess({ id: project.projectId }));

        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECT_EDIT }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.SUCCESS,
                    message: TranslationKey.PROJECT_SAVED,
                },
            })
        );
    } catch (error) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;

        dispatch(actions.updateProjectFailed({ error: errorKey }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: TranslationKey.ERROR_UNKNOWN,
                },
            })
        );
    }
};

export const duplicateProject = (projectId: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.duplicateProjectRequest());

    try {
        const response = await ProjectsService.duplicateProject(projectId);
        const {
            projectId: id,
            userId,
            projectName,
            role,
            createdAt,
            solverType,
            vm,
            updatedAt,
            preview,
        } = response.data;
        dispatch(
            actions.duplicateProjectSuccess({
                project: { projectId: id, userId, projectName, role, createdAt, updatedAt, solverType, vm, preview },
            })
        );
    } catch (error) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;

        dispatch(actions.duplicateProjectFailed({ error: errorKey }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: TranslationKey.ERROR_UNKNOWN,
                },
            })
        );
    }
};

export const duplicateDemoProject = (projectId: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.duplicateProjectRequest());

    try {
        const response = await ProjectsService.duplicateDemoProject(projectId);
        const {
            projectId: id,
            userId,
            projectName,
            role,
            createdAt,
            solverType,
            vm,
            updatedAt,
            preview,
        } = response.data;
        dispatch(
            actions.duplicateProjectSuccess({
                project: { projectId: id, userId, projectName, role, createdAt, updatedAt, solverType, vm, preview },
            })
        );
    } catch (error) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;

        dispatch(actions.duplicateProjectFailed({ error: errorKey }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: TranslationKey.ERROR_UNKNOWN,
                },
            })
        );
    }
};

export const openNewProject = (projectId: string, projectName: string) => async (dispatch: AppDispatch) => {
    dispatch(workspaceActions.resetWorkspace());
    localStorage.setItem('projectId', projectId);
    localStorage.setItem('currentProjectName', projectName);
    window.dispatchEvent(new Event('localStorageChange'));
};

export const saveNewProject =
    (project: Pick<Project, 'projectName' | 'projectId'>) => async (dispatch: AppDispatch, getState: RootStateFn) => {
        dispatch(actions.saveNewProjectRequest());

        const { schemaItems, externalInfo, proxyMap, version, libraryType, solverType, goToMap } =
            getState().workspace.schema;
        const task = getState().task as TTask;
        const projects = getState().projects.items;
        const projectsSettings = getState().workspace.settings;
        const { charts } = getState().workspace.graphs;
        const currentProject = projects.find((pr) => pr.projectId === project.projectId);
        const data = { ...schemaItems, groups: schemaItems.groups || [] };

        try {
            if (solverType && libraryType && version) {
                const response = await ProjectsService.saveNewProject({
                    solverType,
                    libraryType,
                    data: { ...data, externalInfo, proxyMap, goToMap },
                    header: { ...task, ...projectsSettings, charts: { ...charts }, version },
                    projectName: project.projectName,
                } as IProjectInfo);
                const newProject = response.data;
                const {
                    projectId,
                    projectName,
                    userId,
                    createdAt,
                    solverType: newSolverType,
                    vm,
                    preview,
                } = newProject;
                if (currentProject) {
                    dispatch(
                        actions.saveNewProjectSuccess({
                            project: {
                                projectId,
                                userId,
                                projectName,
                                role: currentProject.role,
                                createdAt,
                                updatedAt: createdAt,
                                solverType: newSolverType,
                                vm,
                                preview,
                            },
                        })
                    );
                }
                dispatch(openNewProject(projectId, projectName));
            }

            dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECT_SAVE_NEW }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.SUCCESS,
                        message: TranslationKey.PROJECT_SAVED,
                    },
                })
            );
        } catch (error) {
            const errorKey = TranslationKey.ERROR_UNKNOWN;

            dispatch(actions.saveNewProjectFailed({ error: errorKey }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.ERROR_UNKNOWN,
                    },
                })
            );
        }
    };

export const deleteProject = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.deleteProjectRequest());

    try {
        const response = await ProjectsService.deleteProject({ projectId: id });

        dispatch(actions.deleteProjectSuccess({ id }));

        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECT_DELETE }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.SUCCESS,
                    message: TranslationKey.PROJECT_DELETED,
                },
            })
        );
    } catch (error) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;

        dispatch(actions.deleteProjectFailed({ error: errorKey }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: TranslationKey.ERROR_UNKNOWN,
                },
            })
        );
    }
};

// TODO make using AppDispatch again
export const saveProjectPreview =
    (dataUrl: string, projectId: number) => async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
        try {
            await ProjectsService.saveProjectPreview({ dataUrl, projectId });
        } catch (e) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: TranslationKey.ERROR_UNKNOWN,
                    },
                })
            );
        }
    };

export const deleteProjects = (ids: number[]) => async (dispatch: AppDispatch) => {
    dispatch(actions.deleteProjectsRequest());

    try {
        const response = await ProjectsService.deleteProjects({ projectIds: ids });

        dispatch(actions.deleteProjectsSuccess());

        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECTS_DELETE }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.SUCCESS,
                    message: TranslationKey.PROJECT_DELETED,
                },
            })
        );
    } catch (error) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;

        dispatch(actions.deleteProjectsFailed({ error: errorKey }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: TranslationKey.ERROR_UNKNOWN,
                },
            })
        );
    }
};

export const getProject = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.getProjectRequest({ id }));

    try {
        const response = await ProjectsService.loadProject(id);
        const projectData = response.data;
        dispatch(actions.getProjectSuccess(projectData));
    } catch (error: any) {
        const { response } = error;
        if (response?.data && response?.data?.errors && response?.data?.errors.length) {
            const code = response?.data?.errors[0].code;
            const context = response?.data?.errors[0]?.context.modules as string[];
            if (parseInt(code) in CODE_LIST && context) {
                const modal = {
                    type: ModalTypes.OPEN_BUSY_PROJECT_MODAL,
                    data: {
                        code: parseInt(code),
                        modules: [...context],
                    },
                };
                dispatch(ApplicationActions.showModal({ modal }));
            }
        } else {
            const errorKey = TranslationKey.ERROR_UNKNOWN;
            dispatch(actions.getProjectFailed({ error: errorKey }));
        }
    }
};

export const getDemoProject = (id: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.getProjectRequest({ id }));

    try {
        const response = await ProjectsService.loadDemoProject(id);
        const projectData = response.data;
        dispatch(actions.getProjectSuccess(projectData));
    } catch (error) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;
        dispatch(actions.getProjectFailed({ error: errorKey }));
    }
};

export const getProjectVersionPreview = (projectId: number, versionId: number) => async (dispatch: AppDispatch) => {
    dispatch(actions.getProjectRequest({ id: projectId }));

    try {
        const response = await ProjectsService.viewProjectVersion({ projectId, versionId });
        const projectData = response.data;
        await dispatch(actions.getProjectSuccess(projectData));
    } catch (error) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;
        dispatch(actions.getProjectFailed({ error: errorKey }));
    }
};

export const openProject =
    (id: number, navigate: NavigateFunction) => async (dispatch: AppDispatch, getState: RootStateFn) => {
        await dispatch(getProject(id));

        const projectData = getState().projects.getProject.project;
        if (!projectData) {
            // TODO make notification about getting project data
            return;
        }

        if (![SolverTypes.MDCORE, SolverTypes.JAUTO].includes(projectData.solverType)) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.WARNING,
                        message: TranslationKey.PROJECT_NOT_SUPPORTED_USDS,
                    },
                })
            );
            return;
        }

        dispatch(actions.openProject());

        localStorage.setItem('projectId', id.toString());
        localStorage.setItem('currentProjectName', projectData.projectName);
        setWorkspaceMode(WorkspaceModes.MAIN);

        dispatch(
            workspaceActions.changeWorkspaceMode({
                mode: WorkspaceModes.MAIN,
                previousMode: WorkspaceModes.MAIN,
                readonly: false,
                userBlockId: null,
            })
        );

        navigate('/', { replace: true });
    };

export const openDemoProject =
    <T extends TDemoProject>(project: T, navigate: NavigateFunction) =>
    async (dispatch: AppDispatch, getState: RootStateFn) => {
        dispatch(
            workspaceActions.changeWorkspaceMode({
                mode: WorkspaceModes.DEMO,
                previousMode: WorkspaceModes.DEMO,
                userBlockId: null,
                readonly: true,
            })
        );
        await dispatch(getDemoProject(project.exampleId));

        const projectData = getState().projects.getProject.project as unknown as IProjectData;
        if (!projectData) {
            // TODO make notification about getting project data
            return;
        }

        if (![SolverTypes.MDCORE, SolverTypes.JAUTO].includes(projectData.solverType)) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.WARNING,
                        message: TranslationKey.PROJECT_NOT_SUPPORTED_USDS,
                    },
                })
            );
            return;
        }

        dispatch(actions.openProject());

        localStorage.setItem('projectId', projectData.projectId.toString());
        localStorage.setItem('currentProjectName', project.projectName);
        setWorkspaceMode(WorkspaceModes.DEMO);

        navigate('/', { replace: true });
    };

export const previewProjectVersion =
    (projectId: number, date: string, versionId: number) => async (dispatch: AppDispatch) => {
        setWorkspaceMode(WorkspaceModes.PREVIEW);
        dispatch(
            workspaceActions.changeWorkspaceMode({
                mode: WorkspaceModes.PREVIEW,
                userBlockId: null,
                elementId: null,
                readonly: true,
            })
        );
        await dispatch(loadProject(projectId, false, date, versionId, 'preview'));

        localStorage.setItem('projectId', projectId.toString());
    };

export const setProjectVersion =
    (projectId: number, date: string, versionId: number) => async (dispatch: AppDispatch) => {
        setWorkspaceMode(WorkspaceModes.MAIN);
        dispatch(
            workspaceActions.changeWorkspaceMode({
                mode: WorkspaceModes.MAIN,
                userBlockId: null,
                previousMode: null,
                readonly: false,
            })
        );
        await dispatch(loadProject(projectId, false, date, versionId, 'set'));

        localStorage.setItem('projectId', projectId.toString());
    };

export const closeProject = () => async (dispatch: AppDispatch) => {
    dispatch(actions.closeProject());
    await dispatch(stopProject());

    localStorage.removeItem('projectId');
    localStorage.removeItem('currentProjectName');
    localStorage.removeItem('userBlockId');
    localStorage.removeItem('userBlockName');
};

export const addProjectToFavorites = (id: number, status: boolean) => async (dispatch: AppDispatch) => {
    dispatch(actions.addFavProjectRequest());

    try {
        await ProjectsService.addFavProject({ projectId: id, status });

        dispatch(actions.addFavProjectSuccess());

        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.SUCCESS,
                    message: status ? TranslationKey.PAGE_FAV_PROJECT_SUCCESS : TranslationKey.PAGE_FAV_PROJECT_DELETE,
                },
            })
        );
    } catch (error) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;
        dispatch(actions.addFavProjectError({ error: errorKey }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: errorKey,
                },
            })
        );
    }
};

export const addProjectsToFavorites = (ids: number[], status: boolean) => async (dispatch: AppDispatch) => {
    dispatch(actions.addFavProjectRequest());

    try {
        await ProjectsService.addFavProjects({ projects: ids, status });

        dispatch(actions.addFavProjectSuccess());

        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.SUCCESS,
                    message: status ? TranslationKey.PAGE_FAV_PROJECT_SUCCESS : TranslationKey.PAGE_FAV_PROJECT_DELETE,
                },
            })
        );
    } catch (error) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;
        dispatch(actions.addFavProjectError({ error: errorKey }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: errorKey,
                },
            })
        );
    }
};

export const addProjectToDemo = (projectId: number) => async (dispatch: AppDispatch) => {
    try {
        await ProjectsService.setToDemoProjects({ projectId });

        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.SUCCESS,
                    message: TranslationKey.PAGE_DEMO_PROJECT_SUCCESS,
                },
            })
        );
    } catch (error) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: errorKey,
                },
            })
        );
    }
};

export const removeProjectFromDemo = (exampleId: number) => async (dispatch: AppDispatch) => {
    try {
        await ProjectsService.removeFromDemoProjects({ exampleId });

        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.SUCCESS,
                    message: TranslationKey.PAGE_DEMO_PROJECT_REMOVED,
                },
            })
        );
    } catch (error) {
        const errorKey = TranslationKey.ERROR_UNKNOWN;
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: errorKey,
                },
            })
        );
    }
};

export const exportProject =
    ({
        projectId,
        onDownload,
        abortController,
    }: {
        projectId: number;
        onDownload: (localUrl: string, fileExtension: string, filename?: string | null) => void;
        abortController?: AbortController;
    }) =>
    async (dispatch: AppDispatch) => {
        try {
            const params: IDownloadFileParams = {
                onLoadStart: () => {
                    dispatch(actions.exportProjectRequest());
                },
                onLoadSuccess: (localUrl: string, fileExtension: string, filename?: string | null) => {
                    onDownload(localUrl, fileExtension, filename);
                    dispatch(actions.exportProjectSuccess());
                },
                onLoadError: (error) => {
                    const errorKey = TranslationKey.ERROR_PROJECT_NOT_EXPORTED;

                    dispatch(actions.exportProjectFailed({ error: errorKey }));
                    dispatch(
                        ApplicationActions.showNotification({
                            notification: {
                                type: NotificationTypes.ERROR,
                                message: errorKey,
                            },
                        })
                    );
                },
                abortController,
            };

            await ProjectsService.exportProject(projectId, params);

            return;
        } catch (error) {
            const errorKey = TranslationKey.ERROR_PROJECT_NOT_EXPORTED;

            dispatch(actions.exportProjectFailed({ error: errorKey }));
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );

            console.error(error);
        }
    };

export const importProject = (file: File, navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
    dispatch(
        actions.importProjectRequest({
            fileSizeBytes: file.size,
        })
    );

    const onChunkUploaded = (uploadedBytes: number) => {
        dispatch(actions.importProjectProgress({ bytes: uploadedBytes }));
    };

    const onUploaded = (importedProject: TImportedProject) => {
        dispatch(actions.importProjectSuccess());
        if (importedProject.projectId) {
            dispatch(openProject(importedProject.projectId, navigate));
        }

        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECT_IMPORT }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.SUCCESS,
                    message: TranslationKey.PROJECT_IMPORTED,
                },
            })
        );
    };

    try {
        await ProjectsService.importProject({
            file,
            onChunkUploaded,
            onUploaded,
        });
    } catch (error) {
        const errorKey = TranslationKey.ERROR_PROJECT_NOT_IMPORTED;
        dispatch(actions.importProjectFailed({ error: errorKey }));
        dispatch(ApplicationActions.hideModal({ type: ModalTypes.PROJECT_IMPORT }));
        dispatch(
            ApplicationActions.showNotification({
                notification: {
                    type: NotificationTypes.ERROR,
                    message: errorKey,
                },
            })
        );
    }
};

export const getProjectVersions =
    (projectId: number, offset?: number, count?: number) => async (dispatch: AppDispatch) => {
        const isLazy = Boolean(offset && offset !== 1);

        if (!isLazy) {
            dispatch(actions.getProjectVersionsRequest());
        }

        try {
            const data = await ProjectsService.getProjectVersions({ projectId, offset, count });
            dispatch(actions.getProjectVersionsSuccess({ ...data.data, isLazy }));
            return [...data.data.versions];
        } catch (e: any) {
            dispatch(actions.getProjectVersionsFailed({ error: 'Error' }));
            return [];
        }
    };

export const changeProjectVersionsComment =
    (projectId: number, versionId: number, comment: string) => async (dispatch: AppDispatch) => {
        try {
            await ProjectsService.changeProjectVersionsComment({ projectId, versionId, comment });
        } catch (e: any) {
            const errorKey = TranslationKey.ERROR_UNKNOWN;
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.ERROR,
                        message: errorKey,
                    },
                })
            );
        }
    };
