import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { messages } from '@components/InputMatrixTable/translations';

import { TranslationKey } from '@repeat/translations';
import { Button, EVariantButton } from '@repeat/ui-kit';

import { Matrix } from './types';

export interface IInputMatrixFooterProps {
    matrix: Matrix;
    errors: Record<string, boolean>;
    onReset: () => void;
    onCloseMatrix: () => void;
    checkMatrixDimensions: (matrix: Matrix) => boolean;
}

export const InputMatrixFooter: FC<IInputMatrixFooterProps> = ({
    matrix,
    errors,
    onReset,
    onCloseMatrix,
    checkMatrixDimensions,
}) => {
    const { formatMessage } = useIntl();

    return (
        <footer>
            <Button variant={EVariantButton.SECONDARY} onClick={onReset}>
                {formatMessage(messages[TranslationKey.MATRIX_TABLE_RESET_DEFAULT])}
            </Button>
            <Button
                onClick={onCloseMatrix}
                disabled={
                    Object.keys(errors).filter((error, index) => errors[error] !== false).length > 0 ||
                    !checkMatrixDimensions(matrix)
                }
            >
                {formatMessage(messages[TranslationKey.MATRIX_TABLE_APPLY])}
            </Button>
        </footer>
    );
};
