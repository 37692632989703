import { FormEvent, startTransition, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { createBlob } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { EUserPermissions, Statuses } from '@repeat/models';
import { shortCircuitCalculate } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { Button, Form, Icon, LoaderDefault, pxToRem, Tooltip, TooltipPosition, uiColors } from '@repeat/ui-kit';

import { SAttentionMessage } from './SToolsCircuitContent';

import { SCircuitWrapper, SToolsLoader } from '../STools';
import { ToolsTabsContext } from '../ToolsTabs/ToolsTabsContext';
import { messages } from '../translation';
import { useWorkspaceDataContext } from '../../DataProvider/DataProvider';

export interface ICircuitFormData {
    fault: string;
    case: string;
    lv_tol_percent: string;
    tk_s: string;
}

const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '36px',
    width: 'auto',
};

function downloadCSV(parsedData: any): void {
    const blob = createBlob(parsedData);
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'SC_Result.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        setTimeout(() => {
            link.click();
            document.body.removeChild(link);
        }, 100);
    }
}

export const ToolsCircuitContent = () => {
    const dispatch = useAppDispatch();
    const { readonly } = useWorkspaceDataContext();

    const { status } = useAppSelector((state) => state.workspace.tools.shortCircuit);
    const livePermissions = useAppSelector((state) => state.workspace.livePermissions.permissions);

    const { formatMessage } = useIntl();

    const faultSelectOptions = useMemo(
        () => [
            {
                name: formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_3]),
                value: '3ph',
            },
            {
                name: formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_2]),
                value: '2ph',
            },
            /*TODO: remove when service would be complete */
            // {
            //     name: formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT_1]),
            //     value: '1ph',
            // },
        ],
        []
    );

    const caseSelectOptions = useMemo(
        () => [
            {
                name: formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE_MAX]),
                value: 'max',
            },
            {
                name: formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE_MIN]),
                value: 'min',
            },
        ],
        []
    );

    const { activeTab, handleSetTabData } = useContext(ToolsTabsContext);

    const [formData, setFormData] = useState<ICircuitFormData>({
        fault: activeTab?.data ? activeTab?.data.fault : faultSelectOptions[0].value,
        case: activeTab?.data ? activeTab?.data.case : caseSelectOptions[0].value,
        lv_tol_percent: activeTab?.data ? activeTab?.data.lv_tol_percent : '10',
        tk_s: activeTab?.data ? activeTab?.data.tk_s : '1',
    });

    const [formErrors, setFormErrors] = useState({
        tk_s: { error: false, errorText: '' },
    });

    useEffect(() => {
        startTransition(() => {
            setFormData({
                fault: activeTab?.data ? activeTab?.data.fault : formData.fault,
                case: activeTab?.data ? activeTab?.data.case : formData.case,
                lv_tol_percent: activeTab?.data ? activeTab?.data.lv_tol_percent : formData.lv_tol_percent,
                tk_s: activeTab?.data ? activeTab?.data.tk_s : formData.tk_s,
            });
        });
    }, [activeTab]);

    const handleChange = (key: string, value: any) =>
        new Promise((resolve) => {
            const newData = { ...formData, [key]: value };
            resolve(newData);
        })
            .then((data) => {
                setFormData(data as ICircuitFormData);
                return data;
            })
            .then((data) => {
                if (activeTab) {
                    handleSetTabData(activeTab.id, data as Record<string, string>);
                }
            });

    const onSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const requestData: Record<string, string> = { ...formData };
            await dispatch(shortCircuitCalculate(requestData, downloadCSV));
        },
        [formData]
    );

    return (
        <SCircuitWrapper>
            <Form
                onSubmit={(event: FormEvent<HTMLFormElement>) => onSubmit(event)}
                justify='center'
                noValidate
                autoComplete='off'
            >
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id='fault_type'>
                        {formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT])}
                    </InputLabel>
                    <Select
                        disabled={readonly}
                        labelId={'fault_type'}
                        label={formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_FAULT])}
                        name={'fault'}
                        size='small'
                        value={formData['fault']}
                        onChange={(event) => {
                            handleChange(event.target.name, event.target.value);
                        }}
                    >
                        {faultSelectOptions.map((option, index) => (
                            <MenuItem key={uuidv4()} value={option.value}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id='case_type'>
                        {formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE])}
                    </InputLabel>
                    <Select
                        disabled={readonly}
                        labelId={'case_type'}
                        label={formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_CASE])}
                        size='small'
                        name={'case'}
                        value={formData['case']}
                        onChange={(event) => {
                            handleChange(event.target.name, event.target.value);
                        }}
                    >
                        {caseSelectOptions.map((option, index) => (
                            <MenuItem key={uuidv4()} value={option.value}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                    <InputLabel id='lvl_to_percent_type_type'>
                        {formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_PERCENT])}
                    </InputLabel>
                    <Select
                        disabled={readonly}
                        name={'lv_tol_percent'}
                        labelId={'lvl_to_percent_type'}
                        label={formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_PERCENT])}
                        size='small'
                        value={formData['lv_tol_percent']}
                        onChange={(event) => {
                            handleChange(event.target.name, event.target.value + '');
                        }}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                    </Select>
                    <Tooltip
                        position={TooltipPosition.TOP}
                        text={formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_PERCENT_FULL])}
                    >
                        <Icon fill={uiColors.darkGrey} name={'help'} />
                    </Tooltip>
                </FormControl>
                <FormControl
                    style={{
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        minWidth: 120,
                        marginLeft: `${pxToRem(16)}`,
                    }}
                >
                    <TextField
                        disabled={readonly}
                        error={formErrors['tk_s'].error}
                        size='small'
                        label={formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_DURATION])}
                        value={formData['tk_s']}
                        autoComplete={'off'}
                        type='text'
                        id='tk_s'
                        onChange={(event) => {
                            const value = event.target.value;

                            handleChange('tk_s', value);

                            if (!value || parseInt(value) <= 0) {
                                setFormErrors({
                                    ...formErrors,
                                    tk_s: {
                                        error: true,
                                        errorText: formatMessage(messages[TranslationKey.OBLIGATORY_FIELD_MSG]),
                                    },
                                });
                            } else {
                                setFormErrors({
                                    ...formErrors,
                                    tk_s: {
                                        error: false,
                                        errorText: '',
                                    },
                                });
                            }
                        }}
                    />
                    {formErrors['tk_s'].error && (
                        <FormHelperText style={{ marginLeft: 0 }} error>
                            {formErrors['tk_s'].errorText}
                        </FormHelperText>
                    )}
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Button
                        disabled={
                            readonly && (formErrors['tk_s'].error || !livePermissions[EUserPermissions.ELECTRODYNAMICS])
                        }
                        style={buttonStyle}
                        type='submit'
                    >
                        {formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_START])}
                        {status === Statuses.LOADING && (
                            <SToolsLoader>
                                <LoaderDefault />
                            </SToolsLoader>
                        )}
                    </Button>
                </FormControl>
            </Form>
            <div>
                {' '}
                {!livePermissions[EUserPermissions.ELECTRODYNAMICS] && (
                    <SAttentionMessage>
                        {formatMessage(messages[TranslationKey.WORKSPACE_SHORT_CIRCUIT_TOOL_NO_LIVE_PERMISSION])}
                    </SAttentionMessage>
                )}
            </div>
        </SCircuitWrapper>
    );
};
