import { defineMessages, useIntl } from 'react-intl';

import styled from 'styled-components';

import { ru, TranslationKey } from '@repeat/translations';
import { IconButton, pxToRem, uiColors } from '@repeat/ui-kit';

const SMenuReadOnlyModeItem = styled.div`
    background: var(--ui-sidebar);
    align-items: center;
    border: 1px solid ${uiColors.orange};
    border-radius: ${pxToRem(2)};
    cursor: default;
    color: ${uiColors.darkGrey};
    font-size: ${pxToRem(14)};
    display: flex;
    margin-left: ${pxToRem(16)};
    padding: ${pxToRem(4)} ${pxToRem(8)};
    white-space: nowrap;

    i {
        margin-right: ${pxToRem(4)};
    }
`;

const messages = defineMessages({
    [TranslationKey.WORKSPACE_VIEW_MODE]: {
        id: TranslationKey.WORKSPACE_VIEW_MODE,
        defaultMessage: ru[TranslationKey.WORKSPACE_VIEW_MODE],
    },
});

export const TopPanelReadonly = () => {
    const { formatMessage } = useIntl();

    return (
        <SMenuReadOnlyModeItem>
            <IconButton fill={'var(--ui-icons)'} style={{ cursor: 'default' }} noHover name={'eyeOpen'} />
            <span>{formatMessage(messages[TranslationKey.WORKSPACE_VIEW_MODE])}</span>
        </SMenuReadOnlyModeItem>
    );
};
