import { FC, memo, MutableRefObject, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import Moveable, { OnResize, OnResizeEnd, OnRotate, OnRotateEnd } from 'react-moveable';
import { useUpdateNodeInternals } from 'reactflow';

import { CANVAS, libraryElementImageSrc } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { TElement, TSchemaNode, TWorkspaceMode } from '@repeat/models';
import { appUserSelectors, elementsSelectors, workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { IconButton, uiColors } from '@repeat/ui-kit';

import { useWorkspaceDataContext } from '../../../DataProvider/DataProvider';
import { useBlockImage } from '../../hooks/useBlockImage';
import { useInitNodeViewData } from '../../hooks/useInitNodeViewData';
import { invert, setAngle } from '../Element/Element';
import { ElementToolbar } from '../ElementToolbar/ElementToolbar';
import { SImageNodeWrapper, SImageWrapper } from '../SComponents';
import { messages } from '../translations';

export const ImageElement: FC<{ id: string; data: TElement; selected: boolean }> = memo(({ id, data, selected }) => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const updateNodeInternals = useUpdateNodeInternals();
    const { readonly, mode } = useWorkspaceDataContext();

    const { elements: selectedElements } = useAppSelector(elementsSelectors.getSelectedElements);
    const currentIconModeValue = useAppSelector(appUserSelectors.currentUserIconMode)!;
    const currentNode = useAppSelector(workspaceSelectors.getCurrentElementById(id)) as TSchemaNode;

    const { imageURL, ImageLoader } = useBlockImage(data, mode as TWorkspaceMode);

    const changedValues = useMemo<{
        width: number;
        height: number;
        rotated: boolean;
        rotation: number;
    }>(
        () => ({
            width: currentNode?.width ? currentNode.width : 0,
            height: currentNode?.height ? currentNode.height : 0,
            rotated: currentNode?.rotation ? !!currentNode.rotation : false,
            rotation: currentNode?.rotation ? currentNode.rotation : 0,
        }),
        [currentNode]
    );

    const elementImageRef = useRef<HTMLImageElement | null>(null);
    const moveableRef = useRef<Moveable | null>(null);
    const imageElementRef = useRef<HTMLDivElement | null>(null);
    const inputRef = useRef<MutableRefObject<HTMLInputElement | null>>(null);

    const { view, solver: solverType, library, picId } = data;

    const size = useMemo(
        () => ({
            width: currentNode?.width ? currentNode.width : CANVAS.GRID_STEP * 10,
            height: currentNode?.height ? currentNode.height : CANVAS.GRID_STEP * 4,
        }),
        [currentNode]
    );

    const elementImage = libraryElementImageSrc(`/assets`, solverType, library, `${picId}`, currentIconModeValue);

    const { isInitialized } = useInitNodeViewData(currentNode, changedValues);

    const handleZIndex = async () => {
        const { zIndex } = currentNode;
        if (!zIndex) {
            await dispatch(
                workspaceActions.changeElementExposure({
                    id: data.id,
                    zIndex: -1,
                    workspaceMode: mode as TWorkspaceMode,
                })
            );
        } else {
            await dispatch(
                workspaceActions.changeElementExposure({
                    id: data.id,
                    workspaceMode: mode as TWorkspaceMode,
                })
            );
        }
        updateNodeInternals(data.id.toString());
    };

    if (!isInitialized || !currentNode) {
        return null;
    }

    return (
        <SImageNodeWrapper
            style={{
                ...(currentNode?.zIndex && { zIndex: `${currentNode.zIndex} !important` }),
            }}
            ref={imageElementRef}
            width={size.width}
            height={size.height}
            isOutlined={selected}
            iconType={currentIconModeValue}
        >
            <SImageWrapper
                rotation={view.isImageRotatable ? 0 : invert(currentNode.rotation)}
                ref={elementImageRef}
                width={currentNode.width}
                height={currentNode.height}
            >
                <img src={imageURL || currentNode?.customImage || elementImage} alt='' />
                {!readonly && selectedElements.length === 1 && (
                    <ElementToolbar selected={selected}>
                        <ImageLoader text={formatMessage(messages[TranslationKey.ELEMENT_ADD_IMAGE_TOOLTIP])} />
                        <IconButton
                            fill={currentNode?.zIndex ? uiColors.mainBlue : uiColors.darkGrey}
                            noHover
                            onClick={handleZIndex}
                            name={'exposure'}
                            title={
                                currentNode?.zIndex
                                    ? formatMessage(messages[TranslationKey.ELEMENT_EXPOSURE_TOOLTIP_TOP])
                                    : formatMessage(messages[TranslationKey.ELEMENT_EXPOSURE_TOOLTIP])
                            }
                        />
                    </ElementToolbar>
                )}
            </SImageWrapper>
            {view && selected && selectedElements.length === 1 && !readonly && (
                <Moveable
                    ref={moveableRef}
                    persistData={{ rotation: invert(changedValues.rotation) }}
                    edge={true}
                    origin={true}
                    throttleRotate={90}
                    throttleResize={CANVAS.GRID_STEP}
                    target={imageElementRef}
                    rotatable={view.isRotatable || false}
                    resizable={view.isResizable || false}
                    draggable={true}
                    rotationPosition={'top'}
                    onRotate={({ target, drag, rotation }: OnRotate) => {
                        const newRotation = setAngle(rotation);
                        target.style.transform = `rotate(${newRotation}deg)`;
                    }}
                    onRotateEnd={(event: OnRotateEnd) => {
                        const { lastEvent } = event;
                        if (lastEvent && lastEvent?.rotate !== undefined) {
                            const { rotate } = lastEvent;
                            dispatch(
                                workspaceActions.changeElementRotation({
                                    id: data.id,
                                    rotation: setAngle(rotate),
                                    workspaceMode: mode as TWorkspaceMode,
                                })
                            );
                        }
                    }}
                    onResize={({ target, width, height, delta }: OnResize) => {
                        const dataWidth = data.view.minWidth;
                        const dataHeight = data.view.minHeight;

                        if (dataWidth && width < dataWidth) {
                            return false;
                        }

                        if (dataHeight && height < dataHeight) {
                            return false;
                        }

                        if (width >= CANVAS.ELEMENT_MIN_WIDTH) {
                            delta[0] && (target!.style.width = `${width}px`);
                        }
                        if (height >= CANVAS.ELEMENT_MIN_HEIGHT) {
                            delta[1] && (target!.style.height = `${height}px`);
                        }
                        return;
                    }}
                    onResizeEnd={(event: OnResizeEnd) => {
                        const { lastEvent } = event;
                        if (lastEvent?.width && lastEvent?.height) {
                            const { width, height } = lastEvent;
                            dispatch(
                                workspaceActions.changeElementSize({
                                    id: data.id,
                                    size: { width, height },
                                    workspaceMode: mode as TWorkspaceMode,
                                })
                            );
                        }
                    }}
                />
            )}
        </SImageNodeWrapper>
    );
});
