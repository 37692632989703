import { defineMessages } from 'react-intl';

import { ru, TranslationKey } from '@repeat/translations';

export const messages = defineMessages({
    [TranslationKey.WORKSPACE_PROPERTIES]: {
        id: TranslationKey.WORKSPACE_PROPERTIES,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROPERTIES],
    },
    [TranslationKey.WORKSPACE_PARAMETERS]: {
        id: TranslationKey.WORKSPACE_PARAMETERS,
        defaultMessage: ru[TranslationKey.WORKSPACE_PARAMETERS],
    },
    [TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS]: {
        id: TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS,
        defaultMessage: ru[TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS],
    },
    [TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS_INPUTS]: {
        id: TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS_INPUTS,
        defaultMessage: ru[TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS_INPUTS],
    },
    [TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS_OUTPUTS]: {
        id: TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS_OUTPUTS,
        defaultMessage: ru[TranslationKey.WORKSPACE_RIGHT_SIDEBAR_PARAMETERS_OUTPUTS],
    },
    [TranslationKey.WORKSPACE_RIGHT_SIDEBAR_WARNING_DELETE_CONNECTED_PORT]: {
        id: TranslationKey.WORKSPACE_RIGHT_SIDEBAR_WARNING_DELETE_CONNECTED_PORT,
        defaultMessage: ru[TranslationKey.WORKSPACE_RIGHT_SIDEBAR_WARNING_DELETE_CONNECTED_PORT],
    },
    [TranslationKey.WORKSPACE_WIRE]: {
        id: TranslationKey.WORKSPACE_WIRE,
        defaultMessage: ru[TranslationKey.WORKSPACE_WIRE],
    },
    [TranslationKey.WORKSPACE_PROPERTIES_NOT_FOUND]: {
        id: TranslationKey.WORKSPACE_PROPERTIES_NOT_FOUND,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROPERTIES_NOT_FOUND],
    },
    [TranslationKey.WORKSPACE_PARAMETERS_NOT_FOUND]: {
        id: TranslationKey.WORKSPACE_PARAMETERS_NOT_FOUND,
        defaultMessage: ru[TranslationKey.WORKSPACE_PARAMETERS_NOT_FOUND],
    },
    [TranslationKey.SAVE]: {
        id: TranslationKey.SAVE,
        defaultMessage: ru[TranslationKey.SAVE],
    },
    [TranslationKey.DELETE]: {
        id: TranslationKey.DELETE,
        defaultMessage: ru[TranslationKey.DELETE],
    },
    [TranslationKey.ROTATE]: {
        id: TranslationKey.ROTATE,
        defaultMessage: ru[TranslationKey.ROTATE],
    },
    [TranslationKey.WORKSPACE_ELEMENT_ACTIONS]: {
        id: TranslationKey.WORKSPACE_ELEMENT_ACTIONS,
        defaultMessage: ru[TranslationKey.WORKSPACE_ELEMENT_ACTIONS],
    },
    [TranslationKey.WORKSPACE_ELEMENT_POSITIONING]: {
        id: TranslationKey.WORKSPACE_ELEMENT_POSITIONING,
        defaultMessage: ru[TranslationKey.WORKSPACE_ELEMENT_POSITIONING],
    },
    [TranslationKey.WORKSPACE_ELEMENT_POSITION]: {
        id: TranslationKey.WORKSPACE_ELEMENT_POSITION,
        defaultMessage: ru[TranslationKey.WORKSPACE_ELEMENT_POSITION],
    },
    [TranslationKey.WORKSPACE_ELEMENT_SIZE]: {
        id: TranslationKey.WORKSPACE_ELEMENT_SIZE,
        defaultMessage: ru[TranslationKey.WORKSPACE_ELEMENT_SIZE],
    },
    [TranslationKey.ELEMENT_CONFIGURATION]: {
        id: TranslationKey.ELEMENT_CONFIGURATION,
        defaultMessage: ru[TranslationKey.ELEMENT_CONFIGURATION],
    },
    [TranslationKey.ELEMENT_CONFIGURATION_NOTICE]: {
        id: TranslationKey.ELEMENT_CONFIGURATION_NOTICE,
        defaultMessage: ru[TranslationKey.ELEMENT_CONFIGURATION_NOTICE],
    },
    [TranslationKey.ELEMENT_CONFIGURATION_CUSTOMIZABLE]: {
        id: TranslationKey.ELEMENT_CONFIGURATION_CUSTOMIZABLE,
        defaultMessage: ru[TranslationKey.ELEMENT_CONFIGURATION_CUSTOMIZABLE],
    },
    [TranslationKey.ERROR_GETTING_ELEMENT_PROPERTIES_SETS]: {
        id: TranslationKey.ERROR_GETTING_ELEMENT_PROPERTIES_SETS,
        defaultMessage: ru[TranslationKey.ERROR_GETTING_ELEMENT_PROPERTIES_SETS],
    },
    [TranslationKey.ELEMENT_CONFIGURATION_CURRENT]: {
        id: TranslationKey.ELEMENT_CONFIGURATION_CURRENT,
        defaultMessage: ru[TranslationKey.ELEMENT_CONFIGURATION_CURRENT],
    },
    [TranslationKey.CHANGE]: {
        id: TranslationKey.CHANGE,
        defaultMessage: ru[TranslationKey.CHANGE],
    },
    [TranslationKey.SELECT]: {
        id: TranslationKey.SELECT,
        defaultMessage: ru[TranslationKey.SELECT],
    },
    [TranslationKey.PARAMETER]: {
        id: TranslationKey.PARAMETER,
        defaultMessage: ru[TranslationKey.PARAMETER],
    },
    [TranslationKey.NO_PARAMETERS]: {
        id: TranslationKey.NO_PARAMETERS,
        defaultMessage: ru[TranslationKey.NO_PARAMETERS],
    },
    [TranslationKey.NO_ELEMENTS]: {
        id: TranslationKey.NO_ELEMENTS,
        defaultMessage: ru[TranslationKey.NO_ELEMENTS],
    },
    [TranslationKey.ELEMENT]: {
        id: TranslationKey.ELEMENT,
        defaultMessage: ru[TranslationKey.ELEMENT],
    },
    [TranslationKey.VARIABLE]: {
        id: TranslationKey.VARIABLE,
        defaultMessage: ru[TranslationKey.VARIABLE],
    },
    [TranslationKey.ELEMENT_CONFIGURATION_IS_NOT_RELEVANT]: {
        id: TranslationKey.ELEMENT_CONFIGURATION_IS_NOT_RELEVANT,
        defaultMessage: ru[TranslationKey.ELEMENT_CONFIGURATION_IS_NOT_RELEVANT],
    },
    [TranslationKey.ELEMENT_ATTENTION_LABEL]: {
        id: TranslationKey.ELEMENT_ATTENTION_LABEL,
        defaultMessage: ru[TranslationKey.ELEMENT_ATTENTION_LABEL],
    },
    [TranslationKey.SCHEMA_ELEMENT_EDIT_CODE]: {
        id: TranslationKey.SCHEMA_ELEMENT_EDIT_CODE,
        defaultMessage: ru[TranslationKey.SCHEMA_ELEMENT_EDIT_CODE],
    },
    [TranslationKey.SCHEMA_ELEMENT_BACK_TO_SCHEMA]: {
        id: TranslationKey.SCHEMA_ELEMENT_BACK_TO_SCHEMA,
        defaultMessage: ru[TranslationKey.SCHEMA_ELEMENT_BACK_TO_SCHEMA],
    },
    [TranslationKey.WORKSPACE_PROPERTIES_NOT_DEFINED]: {
        id: TranslationKey.WORKSPACE_PROPERTIES_NOT_DEFINED,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROPERTIES_NOT_DEFINED],
    },
    [TranslationKey.WORKSPACE_PARAMETERS_NOT_DEFINED]: {
        id: TranslationKey.WORKSPACE_PARAMETERS_NOT_DEFINED,
        defaultMessage: ru[TranslationKey.WORKSPACE_PARAMETERS_NOT_DEFINED],
    },
    [TranslationKey.WORKSPACE_PROPERTY]: {
        id: TranslationKey.WORKSPACE_PROPERTY,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROPERTY],
    },
    [TranslationKey.WORKSPACE_PROJECT_BLOCK_VIEW]: {
        id: TranslationKey.WORKSPACE_PROJECT_BLOCK_VIEW,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_BLOCK_VIEW],
    },
    [TranslationKey.WORKSPACE_ELEMENT_TITLE]: {
        id: TranslationKey.WORKSPACE_ELEMENT_TITLE,
        defaultMessage: ru[TranslationKey.WORKSPACE_ELEMENT_TITLE],
    },
    [TranslationKey.WORKSPACE_ADD_PROPERTY]: {
        id: TranslationKey.WORKSPACE_ADD_PROPERTY,
        defaultMessage: ru[TranslationKey.WORKSPACE_ADD_PROPERTY],
    },
    [TranslationKey.HIDE]: {
        id: TranslationKey.HIDE,
        defaultMessage: ru[TranslationKey.HIDE],
    },
    [TranslationKey.SCHEMA_ELEMENT_IS_DEPRECATED]: {
        id: TranslationKey.SCHEMA_ELEMENT_IS_DEPRECATED,
        defaultMessage: ru[TranslationKey.SCHEMA_ELEMENT_IS_DEPRECATED],
    },
    [TranslationKey.WORKSPACE_PROPERTIES_CUSTOM_NAME]: {
        id: TranslationKey.WORKSPACE_PROPERTIES_CUSTOM_NAME,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROPERTIES_CUSTOM_NAME],
    },
    [TranslationKey.METER_UNITS]: {
        id: TranslationKey.METER_UNITS,
        defaultMessage: ru[TranslationKey.METER_UNITS],
    },
    [TranslationKey.METER_UNITS_FACTOR]: {
        id: TranslationKey.METER_UNITS_FACTOR,
        defaultMessage: ru[TranslationKey.METER_UNITS_FACTOR],
    },
    [TranslationKey.EDIT]: {
        id: TranslationKey.EDIT,
        defaultMessage: ru[TranslationKey.EDIT],
    },
    [TranslationKey.WORKSPACE_PROJECT_BLOCK_EDIT_SCHEMA]: {
        id: TranslationKey.WORKSPACE_PROJECT_BLOCK_EDIT_SCHEMA,
        defaultMessage: ru[TranslationKey.WORKSPACE_PROJECT_BLOCK_EDIT_SCHEMA],
    },
});
