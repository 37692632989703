import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import styled from 'styled-components';

import { Element, ElementsWrapper } from '@components/Elements';

import { ApplicationActions } from '@repeat/common-slices';
import { libraryElementImageSrc } from '@repeat/constants';
import { environment } from '@repeat/environment';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import {
    EUserPermissions,
    ILibraryItem,
    LibraryTypes,
    NotificationTypes,
    SolverTypes,
    TLibraryType,
    TSolverType,
    WorkspaceModes,
} from '@repeat/models';
import { appUserSelectors, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { pxToRem, StyledAccordion } from '@repeat/ui-kit';

import { LibraryItemsBlock } from './LibraryItemsBlock/LibraryItemsBlock';
import { messages } from './translation';

import { EngineerService } from '../../EngineerMode/EngineerService';
import { useWorkspaceDataContext } from '../../Workspace/DataProvider/DataProvider';

const { production } = environment || { production: false };

const SLibrariesContainer = styled.div`
    overflow-y: auto;
`;

export const SLibraryItemsAccordion = styled(StyledAccordion)`
    margin: 0;
    padding: ${pxToRem(8)} 0 0 ${pxToRem(16)};
`;

interface ISortedLibraries {
    isSort: boolean;
    tagList: string[];
    libraryList: { [key: string]: unknown };
}

const SortedLibraries: FC<ISortedLibraries> = memo(({ isSort, tagList, libraryList }) => {
    if (!isSort) {
        return (
            <SLibraryItemsAccordion>
                {Object.keys(libraryList).map((item) => libraryList[item] as React.ReactNode)}
            </SLibraryItemsAccordion>
        );
    }
    return (
        <SLibraryItemsAccordion>
            {Object.keys(libraryList).map((item) => {
                if (tagList.includes(item)) {
                    return libraryList[item] as React.ReactNode;
                }
                return null;
            })}
        </SLibraryItemsAccordion>
    );
});

//TODO: unused
const SortedDevBlocks: FC<ISortedLibraries> = memo(({ isSort, tagList, libraryList }) => {
    if (!isSort) {
        return (
            <SLibraryItemsAccordion>
                {Object.keys(libraryList).map((item) => libraryList[item] as React.ReactNode)}
            </SLibraryItemsAccordion>
        );
    }
    return (
        <SLibraryItemsAccordion>
            {Object.keys(libraryList).map((item) => {
                if (tagList.includes(item)) {
                    return libraryList[item] as React.ReactNode;
                }
                return null;
            })}
        </SLibraryItemsAccordion>
    );
});

interface ILibraryItems {
    libraryType: TLibraryType;
    solverType: TSolverType;
    isSort: boolean;
    tagList: string[];
    searchedItems: ILibraryItem[];
    getFilteredItems: (name: string) => ILibraryItem[];
    isFiltered: boolean;
}

export const LibraryItems: FC<ILibraryItems> = ({
    libraryType,
    solverType,
    isSort,
    tagList,
    searchedItems,
    getFilteredItems,
    isFiltered,
}) => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const { readonly } = useWorkspaceDataContext();

    const workspaceMode = useAppSelector(workspaceSelectors.workspaceMode);
    const isEngineer = useAppSelector(appUserSelectors.currentUserMode);
    const livePermissions = useAppSelector((state) => state.workspace.livePermissions.permissions);
    const currentIconModeValue = useAppSelector(appUserSelectors.currentUserIconMode)!;

    const [devBlocks, setDevBlocks] = useState<ILibraryItem[]>([]);

    const engineerService = new EngineerService('engineerBlocks');

    const fetchData = useCallback(async () => {
        const allData = await engineerService.getBlocks();
        setDevBlocks(allData);
    }, []);

    useEffect(() => {
        fetchData().catch((err) => console.error('Failed to get blocks: ', err));
    }, []);

    const onDragStart = (event: React.DragEvent<HTMLDivElement>, item: ILibraryItem) => {
        if (item.isDisabled) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.WARNING,
                        message: TranslationKey.ELEMENT_IS_BLOCKED,
                    },
                })
            );
            return;
        }
        if (
            workspaceMode === WorkspaceModes.SUBMODEL ||
            workspaceMode === WorkspaceModes.DEMO ||
            workspaceMode === WorkspaceModes.PREVIEW
        ) {
            dispatch(
                ApplicationActions.showNotification({
                    notification: {
                        type: NotificationTypes.WARNING,
                        message: TranslationKey.WORKSPACE_VIEW_MODE_ACTION_NOT_ALLOWED,
                    },
                })
            );
            return;
        }

        event.dataTransfer.setData('application/reactflow', JSON.stringify(item));
        event.dataTransfer.effectAllowed = 'move';
    };

    const isSolverTypeIncluded = [SolverTypes.JAUTO, SolverTypes.MDCORE].includes(solverType);

    //TODO: unused, check
    const getFilteredDevItems = (libraryType: string) => {
        return devBlocks.filter((item) => item.library === libraryType);
    };

    const EngineerBlocks = {
        [formatMessage(messages[TranslationKey.DEVELOPMENT])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.BASE] && !readonly}
                items={devBlocks}
                key={formatMessage(messages[TranslationKey.DEVELOPMENT])}
                subtitle={formatMessage(messages[TranslationKey.DEVELOPMENT])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
    };

    const MDCoreLibraries = {
        [formatMessage(messages[TranslationKey.CUSTOM])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.BASE] && !readonly}
                items={getFilteredItems(LibraryTypes.CUSTOM)}
                key={formatMessage(messages[TranslationKey.CUSTOM])}
                subtitle={formatMessage(messages[TranslationKey.CUSTOM])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
        [formatMessage(messages[TranslationKey.SIMULATION])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.BASE] && !readonly}
                items={getFilteredItems(LibraryTypes.SIMULATION)}
                key={formatMessage(messages[TranslationKey.SIMULATION])}
                subtitle={formatMessage(messages[TranslationKey.SIMULATION])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
        [formatMessage(messages[TranslationKey.AUTO])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.BASE] && !readonly}
                items={getFilteredItems(LibraryTypes.AUTO)}
                key={formatMessage(messages[TranslationKey.AUTO])}
                subtitle={formatMessage(messages[TranslationKey.AUTO])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
        [formatMessage(messages[TranslationKey.MECHANICS])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.MECHANICS] && !readonly}
                items={getFilteredItems(LibraryTypes.MECHANICS)}
                key={formatMessage(messages[TranslationKey.MECHANICS])}
                subtitle={formatMessage(messages[TranslationKey.MECHANICS])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
        [formatMessage(messages[TranslationKey.THERMOHYDRAULICS])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.HEATHYDRO] && !readonly}
                items={getFilteredItems(LibraryTypes.THERMOHYDRAULICS)}
                key={formatMessage(messages[TranslationKey.THERMOHYDRAULICS])}
                subtitle={formatMessage(messages[TranslationKey.THERMOHYDRAULICS])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
        [formatMessage(messages[TranslationKey.ISOTHERMAL_HYDRAULICS])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.HYDRO] && !readonly}
                items={getFilteredItems(LibraryTypes.ISOTHERMAL_HYDRAULICS)}
                key={formatMessage(messages[TranslationKey.ISOTHERMAL_HYDRAULICS])}
                subtitle={formatMessage(messages[TranslationKey.ISOTHERMAL_HYDRAULICS])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
        [formatMessage(messages[TranslationKey.PNEUMATICS])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.PNEUMO] && !readonly}
                items={getFilteredItems(LibraryTypes.PNEUMATICS)}
                key={formatMessage(messages[TranslationKey.PNEUMATICS])}
                subtitle={formatMessage(messages[TranslationKey.PNEUMATICS])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
        [formatMessage(messages[TranslationKey.HEAT_EXCHANGE])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.HEATHYDRO] && !readonly}
                items={getFilteredItems(LibraryTypes.HEAT_EXCHANGE)}
                key={formatMessage(messages[TranslationKey.HEAT_EXCHANGE])}
                subtitle={formatMessage(messages[TranslationKey.HEAT_EXCHANGE])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
        [formatMessage(messages[TranslationKey.ELECTROCITY])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.ELECTRODYNAMICS] && !readonly}
                items={getFilteredItems(LibraryTypes.ELECTROCITY)}
                key={formatMessage(messages[TranslationKey.ELECTROCITY])}
                subtitle={formatMessage(messages[TranslationKey.ELECTROCITY])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
        [formatMessage(messages[TranslationKey.ELECTRONICS])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.ELECTRODYNAMICS] && !readonly}
                items={getFilteredItems(LibraryTypes.ELECTRONICS)}
                key={formatMessage(messages[TranslationKey.ELECTRONICS])}
                subtitle={formatMessage(messages[TranslationKey.ELECTRONICS])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
        [formatMessage(messages[TranslationKey.SYSTEM_DESIGN])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.BASE] && !readonly}
                items={getFilteredItems(LibraryTypes.SYSTEM_DESIGN)}
                key={formatMessage(messages[TranslationKey.SYSTEM_DESIGN])}
                subtitle={formatMessage(messages[TranslationKey.SYSTEM_DESIGN])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
        [formatMessage(messages[TranslationKey.ELECTRIC_DRIVE])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.BASE] && !readonly}
                items={getFilteredItems(LibraryTypes.ELECTRIC_DRIVE)}
                key={formatMessage(messages[TranslationKey.ELECTRIC_DRIVE])}
                subtitle={formatMessage(messages[TranslationKey.ELECTRIC_DRIVE])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
        [formatMessage(messages[TranslationKey.EXTERNAL_MODELS])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.BASE] && !readonly}
                items={getFilteredItems(LibraryTypes.EXTERNAL_MODELS)}
                key={formatMessage(messages[TranslationKey.EXTERNAL_MODELS])}
                subtitle={formatMessage(messages[TranslationKey.EXTERNAL_MODELS])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
        [formatMessage(messages[TranslationKey.CODE_GENERATION])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.CODE_GENERATION] && !readonly}
                items={getFilteredItems(LibraryTypes.CODE_GENERATION)}
                key={formatMessage(messages[TranslationKey.CODE_GENERATION])}
                subtitle={formatMessage(messages[TranslationKey.CODE_GENERATION])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
        [formatMessage(messages[TranslationKey.COMMON])]: (
            <LibraryItemsBlock
                accessible={livePermissions[EUserPermissions.BASE] && !readonly}
                items={getFilteredItems(LibraryTypes.COMMON)}
                key={formatMessage(messages[TranslationKey.COMMON])}
                subtitle={formatMessage(messages[TranslationKey.COMMON])}
                solverType={solverType}
                libraryType={libraryType}
                onDragStart={onDragStart}
                isFiltered={isFiltered}
            />
        ),
    };

    const getImageSrc = useCallback((solverType: TSolverType, libraryType: TLibraryType, picId: number) => {
        const assetsPath = `/assets`;
        return libraryElementImageSrc(assetsPath, solverType, libraryType, `${picId}`, currentIconModeValue);
    }, []);

    if (isSolverTypeIncluded) {
        return (
            <SLibrariesContainer data-name={'elements-container'} data-tour='library-items-wrapper'>
                {isEngineer && <SortedLibraries isSort={isSort} libraryList={EngineerBlocks} tagList={tagList} />}
                <SortedLibraries isSort={isSort} libraryList={MDCoreLibraries} tagList={tagList} />
            </SLibrariesContainer>
        );
    }

    return (
        <ElementsWrapper data-name={'elements-container'} data-tour='library-items-wrapper'>
            {searchedItems.map((el: ILibraryItem) => (
                <Element
                    solverType={solverType}
                    libraryType={libraryType}
                    options={el}
                    key={el.picId}
                    onDragStart={(e) => {
                        onDragStart(e, el);
                    }}
                    imageSrc={getImageSrc(solverType, libraryType, el.picId)}
                    newTitle={formatMessage(messages[TranslationKey.LIBRARY_NEW_BLOCK])}
                />
            ))}
        </ElementsWrapper>
    );
};
