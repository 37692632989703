import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import { TUserBlockEditModal } from 'libs/models/src/lib/modal';

import { ApplicationActions } from '@repeat/common-slices';
import { useAppDispatch, useAppSelector, useProjectId } from '@repeat/hooks';
import { ModalTypes, Project, TModal, WorkspaceModes } from '@repeat/models';
import { closeProject, saveProject, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { IconButton, pxToRem, Tooltip, TooltipPosition, uiColors } from '@repeat/ui-kit';

import { messages } from './translation';

import { useProjectScreenshot } from '../../Project/hooks/useProjectScreenshot';
import { SElementId } from '../../RightBar/SRightBar';
import { useWorkspaceDataContext } from '../DataProvider/DataProvider';

const SWorkspaceProjectName = styled.div<{ noSave?: boolean }>`
    font-size: ${pxToRem(14)};
    display: inline-block;
    width: 100%;

    ${({ noSave }) =>
        !noSave &&
        `
        max-width: ${pxToRem(256)};
        overflow: hidden;
        text-overflow: ellipsis;
        padding: ${pxToRem(5)} ${pxToRem(8)};
    `}
`;

const SWorkspaceUserBlockName = styled(SWorkspaceProjectName)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const MAX_NORMAL_CHAR_COUNT = 27;

export const WorkspaceProjectName: FC<{ noSave?: boolean; noClose?: boolean; onClick?: () => void }> = ({
    noSave = false,
    noClose = false,
    onClick,
}) => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { readonly, mode } = useWorkspaceDataContext();

    const isUserBlockEditorMode = useAppSelector(workspaceSelectors.isUserBlockEditorMode);
    const workspaceMetaUserBlockId = useAppSelector(workspaceSelectors.workspaceMetaUserBlockId);
    const currentSubmodelProject = useAppSelector(workspaceSelectors.currentSubmodelProject);
    const currentSubmodelProjectName = currentSubmodelProject?.projectName || '';
    const currentSubmodelProjectId = useAppSelector(workspaceSelectors.currentSubmodelProject);
    const libraryItemNameAndDescription = useAppSelector(workspaceSelectors.libraryItemNameAndDescription);

    const { projectId } = useProjectId();

    const { handleScreenShot } = useProjectScreenshot();

    const [currentProjectName, setCurrentProjectName] = useState<string>('');

    const libraryType = useAppSelector(workspaceSelectors.libraryType);
    const solverType = useAppSelector(workspaceSelectors.solverType);

    const handleSaveClick = async () => {
        if (projectId && solverType && libraryType) {
            await dispatch(saveProject(projectId, libraryType, solverType));
            handleScreenShot(projectId);
        }
    };
    const handleSaveNewClick = (project: Pick<Project, 'projectName' | 'projectId'>) => {
        const modal: TModal = {
            type: ModalTypes.PROJECT_SAVE_NEW,
            data: { project },
        };
        dispatch(ApplicationActions.showModal({ modal }));
    };

    const handleCloseClick = () => {
        dispatch(closeProject());
        navigate('/projects');
    };

    useEffect(() => {
        if (
            (mode === WorkspaceModes.SUBMODEL || mode === WorkspaceModes.GROUP) &&
            currentSubmodelProjectName &&
            !isUserBlockEditorMode
        ) {
            setCurrentProjectName(currentSubmodelProjectName);
        } else if (isUserBlockEditorMode) {
            setCurrentProjectName(libraryItemNameAndDescription.name);
        } else {
            setCurrentProjectName(localStorage.getItem('currentProjectName') || '');
        }
    }, [mode, libraryItemNameAndDescription]);

    const handleEditUserBlock = () => {
        if (!workspaceMetaUserBlockId) {
            return;
        }
        const modal: TUserBlockEditModal = {
            type: ModalTypes.USER_BLOCK_EDIT,
            data: { blockId: workspaceMetaUserBlockId },
        };
        dispatch(ApplicationActions.showModal({ modal }));
    };

    if (currentProjectName) {
        return (
            <>
                {!isUserBlockEditorMode && (
                    <>
                        <SWorkspaceProjectName
                            style={{
                                wordBreak:
                                    currentProjectName.includes(' ') &&
                                    currentProjectName[MAX_NORMAL_CHAR_COUNT] !== ' '
                                        ? 'normal'
                                        : 'break-all',
                            }}
                            data-tour='workspace-project-name'
                            noSave={noSave}
                            onClick={onClick && onClick}
                        >
                            {currentProjectName}
                        </SWorkspaceProjectName>
                        <SElementId>
                            id: {mode === WorkspaceModes.SUBMODEL ? currentSubmodelProjectId?.projectId : projectId}
                        </SElementId>
                    </>
                )}
                {isUserBlockEditorMode && (
                    <SWorkspaceUserBlockName>
                        <SWorkspaceProjectName
                            style={{
                                wordBreak:
                                    currentProjectName.includes(' ') &&
                                    currentProjectName[MAX_NORMAL_CHAR_COUNT] !== ' '
                                        ? 'normal'
                                        : 'break-all',
                            }}
                            data-tour='workspace-project-name'
                            noSave={noSave}
                            onClick={onClick && onClick}
                        >
                            {currentProjectName}
                        </SWorkspaceProjectName>
                        {!readonly && (
                            <Tooltip
                                text={formatMessage(messages[TranslationKey.USER_BLOCK_EDIT_VIEW_TITLE])}
                                position={TooltipPosition.LEFT}
                            >
                                <IconButton name='edit' fill={uiColors.mainBlue} onClick={handleEditUserBlock} />
                            </Tooltip>
                        )}
                    </SWorkspaceUserBlockName>
                )}

                {!noClose && (
                    <Tooltip text={formatMessage(messages[TranslationKey.CLOSE_PROJECT])}>
                        <IconButton
                            data-tour='workspace-project-close'
                            fill={uiColors.darkGrey}
                            noHover
                            name='close'
                            onClick={handleCloseClick}
                        />
                    </Tooltip>
                )}
                {!noSave && (
                    <>
                        <Tooltip text={formatMessage(messages[TranslationKey.SAVE])}>
                            <IconButton
                                data-tour='workspace-project-save'
                                fill={uiColors.darkGrey}
                                noHover
                                name='save'
                                onClick={handleSaveClick}
                            />
                        </Tooltip>
                        <Tooltip text={formatMessage(messages[TranslationKey.SAVE_AS])}>
                            <IconButton
                                fill={uiColors.darkGrey}
                                noHover
                                name='saveEdit'
                                onClick={() =>
                                    projectId && handleSaveNewClick({ projectName: currentProjectName, projectId })
                                }
                            />
                        </Tooltip>
                    </>
                )}
            </>
        );
    }

    return null;
};
