import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Moveable, { OnResize, OnResizeEnd, OnRotate, OnRotateEnd } from 'react-moveable';
import { useUpdateNodeInternals } from 'reactflow';

import { v4 as uuidv4 } from 'uuid';

import { getUserBlock } from 'libs/repeat/store/src/lib/slices/workspace/userBlocks/userBlocksSlice';

import { ApplicationActions } from '@repeat/common-slices';
import {
    CANVAS,
    ELEMENTS_COLORS,
    ELEMENTS_INITIALIZED_BY_TEXT_FILE,
    ELEMENTS_WITH_CUSTOM_IMAGE,
    FSM_ELEMENT_TYPE,
    getElementNotification,
    libraryElementImageSrc,
} from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import {
    ElementNotificationTypes,
    ElemParams,
    ElemProps,
    IChartItemParameter,
    LibraryTypes,
    NotificationTypes,
    SchemaItemTypes,
    TElement,
    TElementNotificationType,
    TSchemaNode,
    TWorkspaceMode,
    WorkspaceModes,
} from '@repeat/models';
import { appUserSelectors, setSubmodelItems, workspaceActions, workspaceSelectors } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import { uiColors } from '@repeat/ui-kit';

import { messages } from '../../../../Visualization/translation';
import { useWorkspaceDataContext } from '../../../DataProvider/DataProvider';
import { useBlockImage } from '../../hooks/useBlockImage';
import { IHandleViewData, useInitNodeViewData } from '../../hooks/useInitNodeViewData';
import { ElementCustomName, useElementEditCustomName } from '../ElementCustomName/ElementEditCustomName';
import { ElementEditFile } from '../ElementEditFile/ElementEditFile';
import { ElementHandle } from '../ElementHandle/ElementHandle';
import { ElementNotification } from '../ElementNotification/ElementNotification';
import { ElementToolbar } from '../ElementToolbar/ElementToolbar';
import { ElementTrends } from '../ElementTrend/ElementTrend';
import { SElement, SImageWrapper, SNodeIndex, SNodeWrapper } from '../SComponents';

export const invert = (number: number) => {
    return -number;
};

export const setAngle = (rotation: number) => {
    return rotation % 360;
};

interface IElement {
    id: string;
    data: TElement;
    selected: boolean;
    rotation?: number;
}
const COMPLEX_ELEMENTS_TYPES = ['project', 'userBlock', 'group', 'jython', FSM_ELEMENT_TYPE];

export const Element: FC<IElement> = memo(({ id, data, selected, rotation }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { mode: workspaceMode } = useWorkspaceDataContext();

    const defaultParameterTitle = intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_CSV_TIME_TITLE]);
    const timeParameter = {
        key: 'time',
        title: defaultParameterTitle,
        elementTitle: defaultParameterTitle,
        parameterTitle: defaultParameterTitle,
    } as IChartItemParameter;

    const selectedElements = useAppSelector(workspaceSelectors.selectedItems).elements;
    const workspaceMetaGroupId = useAppSelector(workspaceSelectors.workspaceMetaGroupId);
    const iconType = useAppSelector(appUserSelectors.currentUserIconMode);
    const charts = useAppSelector(workspaceSelectors.charts);
    const notification: TElementNotificationType | null = useAppSelector(
        workspaceSelectors.getSchemaBlockNotificationById(id)
    );
    const currentIconModeValue = useAppSelector(appUserSelectors.currentUserIconMode)!;

    const currentSubmodelProjectById = useAppSelector(workspaceSelectors.currentSubmodelProjectById(id));

    const currentNode = useAppSelector(workspaceSelectors.getCurrentElementById(id)) as TSchemaNode;

    const currentNodeLibrary = currentNode?.data?.library;
    const currentNodeSublibrary = currentNode?.data?.subLibrary;

    const [changedValues, setChangedValues] = useState<{
        width: number;
        height: number;
        rotated: boolean;
        rotation: number;
    }>({
        width: currentNode?.width ? currentNode.width : 0,
        height: currentNode?.height ? currentNode.height : 0,
        rotated: currentNode?.rotation ? !!currentNode.rotation : false,
        rotation: currentNode?.rotation ? currentNode.rotation : 0,
    });

    const [isNotificationVisible, setNotificationVisible] = useState(false);

    const selectAllParamHandler = useCallback((ids: string[], status: boolean, elementID: string) => {
        const currentParameters = data.elemParams.filter((item) => ids.includes(item.name));
        const filteredIds = currentParameters.map((currentParameter) => ({
            uuid: uuidv4(),
            key: `${currentNode.data.id}_${currentParameter.name}`,
            title: `${currentNode.data.name} / ${currentParameter.description}`,
            elementTitle: `${currentNode.data.name}`,
            parameterTitle: `${currentParameter.description}`,
        }));
        if (currentParameters.length) {
            dispatch(workspaceActions.addPackChart({ list: filteredIds, XParameter: timeParameter }));
        }
    }, []);

    const { readonly, mode, previousMode } = useWorkspaceDataContext();
    const updateNodeInternals = useUpdateNodeInternals();
    const { imageURL, ImageLoader } = useBlockImage(data, mode as TWorkspaceMode);
    const { view, solver: solverType, library, picId } = data;

    const { isInitialized, viewData } = useInitNodeViewData(currentNode, changedValues, () =>
        updateNodeInternals(`${id}`)
    );

    const elementRef = useRef<HTMLDivElement | null>(null);
    const elementImageRef = useRef<HTMLImageElement | null>(null);
    const moveableRef = useRef<Moveable | null>(null);

    useEffect(() => {
        setChangedValues(() => ({
            width: currentNode?.width ? currentNode.width : 0,
            height: currentNode?.height ? currentNode.height : 0,
            rotated: currentNode?.rotation ? !!currentNode.rotation : false,
            rotation: currentNode?.rotation ? currentNode.rotation : 0,
        }));
        setTimeout(() => {
            if (moveableRef && moveableRef.current) {
                moveableRef.current.updateRect();
            }
        }, 10);
        updateNodeInternals(id);
    }, [currentNode]);

    useEffect(() => {
        if (isInitialized && id) {
            updateNodeInternals(id);
        }
    }, [isInitialized, currentNode?.data.availablePorts]);

    const selectParamHandler = useCallback(
        (id: string, status: boolean, elementID: string, elementIndex: string) => {
            const index = Object.keys(charts).length;
            const parameter = `${data.id}_${id}`;
            const currentParameter = data.elemParams.find((item) => item.name === id) as ElemParams;
            const uuid = uuidv4();
            const parameterTitle = currentParameter?.unit
                ? `${currentParameter.description}, ${currentParameter.unit}`
                : `${currentParameter.description}`;
            const parameters = [
                {
                    uuid,
                    key: parameter,
                    title: `${currentNode.data.name} [${elementIndex}] / ${parameterTitle}`,
                    elementTitle: `${currentNode.data.name} [${elementIndex}]`,
                    parameterTitle: `${parameterTitle}`,
                },
            ];
            if (parameter.length) {
                dispatch(
                    workspaceActions.addChartWithParameters({
                        XParameters: timeParameter,
                        YParameters: parameters,
                        uuid,
                        index,
                    })
                );
            }
        },
        [charts]
    );

    const getNotificationType = useCallback(
        (element: TElement) => getElementNotification(element) || notification,
        [notification]
    );
    const notificationType = currentNode?.data ? getNotificationType(currentNode.data) : null;

    const getBlockBackground = useCallback(
        (blockType: string): string => {
            if (!['GoTo', 'GoFrom'].includes(blockType)) {
                return uiColors.white;
            }

            switch (notificationType) {
                case ElementNotificationTypes.GOTO_RELATED_BLOCK:
                    return uiColors.yellow;
                case ElementNotificationTypes.GOTO_WITHOUT_PAIR:
                    return uiColors.lightRed;
                case ElementNotificationTypes.GOTO_NOT_UNIQUE:
                    return uiColors.lightRed;
                default:
                    return uiColors.lightYellow;
            }
        },
        [notificationType]
    );

    const handleClick = async (event: React.MouseEvent) => {
        if (data.type === 'jython') {
            if (workspaceMode === WorkspaceModes.SUBMODEL) {
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: TranslationKey.WORKSPACE_VIEW_MODE_ACTION_NOT_ALLOWED,
                        },
                    })
                );
                return;
            }
            dispatch(
                workspaceActions.changeWorkspaceMode({
                    mode: WorkspaceModes.CODE_EDITOR,
                    elementId: id,
                    readonly,
                })
            );
        }
        if (data.type === FSM_ELEMENT_TYPE) {
            if (workspaceMode === WorkspaceModes.SUBMODEL) {
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: TranslationKey.WORKSPACE_VIEW_MODE_ACTION_NOT_ALLOWED,
                        },
                    })
                );
                return;
            }
            dispatch(
                workspaceActions.changeWorkspaceMode({
                    mode: WorkspaceModes.FSM_EDITOR,
                    elementId: id,
                    readonly,
                })
            );
        }
        if (data.type === 'project') {
            if (workspaceMode === WorkspaceModes.SUBMODEL) {
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: TranslationKey.WORKSPACE_VIEW_MODE_ACTION_NOT_ALLOWED,
                        },
                    })
                );
                return;
            }
            if (data.diff?.isErrorGettingProject) {
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.ERROR,
                            message: TranslationKey.WORKSPACE_ERROR_GETTING_PROJECT_DATA,
                        },
                    })
                );
                return;
            }
            dispatch(
                workspaceActions.changeWorkspaceMode({
                    mode: WorkspaceModes.SUBMODEL,
                    elementId: id,
                    groupId: workspaceMetaGroupId ? workspaceMetaGroupId : null,
                    readonly: true,
                })
            );
            const projectId = currentSubmodelProjectById?.projectId;
            if (projectId) {
                dispatch(setSubmodelItems({ projectId: Number(projectId) }));
            }
        }
        if (data.type === 'group') {
            if (workspaceMode === WorkspaceModes.SUBMODEL) {
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: TranslationKey.WORKSPACE_VIEW_MODE_ACTION_NOT_ALLOWED,
                        },
                    })
                );
                return;
            }
            dispatch(
                workspaceActions.changeWorkspaceMode({
                    mode: WorkspaceModes.GROUP,
                    elementId: id,
                    groupId: id,
                    readonly: readonly,
                })
            );
        }
        if (data.type === 'userBlock') {
            if (data.diff?.userBlockIsDeleted) {
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.ERROR,
                            message: 'Блок удален',
                        },
                    })
                );
                return;
            }
            if (workspaceMode === WorkspaceModes.SUBMODEL) {
                dispatch(
                    ApplicationActions.showNotification({
                        notification: {
                            type: NotificationTypes.WARNING,
                            message: TranslationKey.WORKSPACE_VIEW_MODE_ACTION_NOT_ALLOWED,
                        },
                    })
                );
                return;
            }

            localStorage.setItem('isUserBlock', 'true');

            dispatch(
                workspaceActions.changeWorkspaceMode({
                    mode: WorkspaceModes.USER_BLOCK,
                    elementId: id,
                    groupId: id,
                    userBlockId: data.blockId,
                    readonly: true,
                })
            );

            if (data.blockId) {
                await dispatch(getUserBlock(data.blockId, id));
            }
        }
        if (COMPLEX_ELEMENTS_TYPES.includes(data.type)) {
            dispatch(workspaceActions.addWorkspacePathItem({ nodeData: data }));
        }
    };

    const Handles: FC<{ handles: IHandleViewData[] }> = memo(({ handles }) => {
        const { elemParams } = currentNode ? currentNode.data : { elemParams: [] };

        if (Array.isArray(handles)) {
            return (
                <>
                    {handles.map((handleViewData, index: number) => (
                        <ElementHandle
                            key={`${data.picId}-${data.id}-${index}`}
                            data={data}
                            elemParams={elemParams}
                            handleViewData={handleViewData}
                            changedValues={changedValues}
                            index={index}
                        />
                    ))}
                </>
            );
        }
        return null;
    });

    const handleReadonlyClick = () => {
        dispatch(workspaceActions.setSelectedItems({ ids: [currentNode.id], type: SchemaItemTypes.NODE }));
    };

    const { handleChange, handleSave, isEdit, ElementEditCustomNameTrigger } = useElementEditCustomName({
        id: currentNode?.data.id,
        defaultValue: currentNode?.customName || '',
        selected: selected,
        rotation: invert(changedValues.rotation) || invert(currentNode?.rotation),
    });

    if (!isInitialized || !currentNode) {
        return null;
    }

    const additionalProps = {
        background: getBlockBackground(data.type),
        ...(view && view.isBackground && { background: ELEMENTS_COLORS[library] }),
        style: {
            ...(currentNode?.rotation && { transform: `rotate(${currentNode.rotation}deg)` }),
            ...(view && view.minHeight && { minHeight: view.minHeight }),
        },
    };

    const renderIcon = (block: TElement) => {
        if (['InPort', 'OutPort'].includes(block.type)) {
            const index = block.elemProps.find((prop: ElemProps) => prop.name === 'parentParameter')?.value;

            return <b style={{ color: iconType === 'new' ? 'white' : 'black' }}>{index}</b>;
        }

        if (['GoTo', 'GoFrom'].includes(block.type)) {
            const name = block.elemProps.find((property: ElemProps) => property.name === 'tagTitle')?.value;

            return <b style={{ color: iconType === 'new' ? 'white' : 'black' }}>{name}</b>;
        }
        if (block.library === LibraryTypes.DEVELOPMENT) {
            return <b>{block.name}</b>;
        }

        const libraryType = (block?.library as string) || 'electrocity';
        const assetsPath = `/assets`;
        const elementImage =
            block.image && block.type === 'userBlock'
                ? block.image
                : libraryElementImageSrc(assetsPath, solverType, libraryType, `${picId}`, currentIconModeValue);
        const customImage = imageURL || currentNode?.customImage || elementImage;

        return <img src={customImage} alt={block.shortName} data-node-id={block.id} />;
    };

    return (
        <SElement>
            <ElementNotification
                isVisible={isNotificationVisible}
                notificationType={notificationType}
                currentNode={currentNode}
            />
            <SNodeWrapper
                {...additionalProps}
                {...(readonly && { onClick: handleReadonlyClick })}
                width={currentNode.width}
                height={currentNode.height}
                ref={elementRef}
                isOutlined={selected && selectedElements.length > 1}
                notificationType={notificationType}
                onDoubleClick={handleClick}
                iconType={iconType}
                currentNodeLibrary={currentNodeLibrary}
                currentNodeSubLibrary={currentNodeSublibrary}
                currentNodeType={currentNode.data.type!}
            >
                <SNodeIndex rotation={invert(changedValues.rotation) || invert(currentNode.rotation)}>
                    {data.index}
                </SNodeIndex>
                <SImageWrapper
                    rotation={view.isImageRotatable ? 0 : invert(currentNode.rotation)}
                    ref={elementImageRef}
                    width={currentNode.width}
                    height={currentNode.height}
                    onMouseEnter={() => {
                        setNotificationVisible(true);
                    }}
                    onMouseLeave={() => {
                        setNotificationVisible(false);
                    }}
                >
                    {renderIcon(currentNode.data)}
                </SImageWrapper>

                {viewData.handles?.length > 0 && <Handles handles={viewData.handles} />}

                {selectedElements.length === 1 && (
                    <ElementToolbar selected={selected}>
                        {currentNode.data.elemParams && currentNode.data.elemParams.length > 0 && (
                            <ElementTrends
                                selectAllParamHandler={selectAllParamHandler}
                                selectParamHandler={selectParamHandler}
                                elementData={currentNode.data}
                                parameters={currentNode.data.elemParams}
                                selected={selected}
                            />
                        )}
                        {!readonly && <ElementEditCustomNameTrigger />}
                        {!readonly && ELEMENTS_WITH_CUSTOM_IMAGE.includes(data.type) && (
                            <ImageLoader
                                text={intl.formatMessage(messages[TranslationKey.ELEMENT_ADD_IMAGE_TOOLTIP])}
                            />
                        )}
                        {!readonly && (ELEMENTS_INITIALIZED_BY_TEXT_FILE.includes(data.type) || data.isFromFile) && (
                            <ElementEditFile selected={selected} type={currentNode.data.type} />
                        )}
                    </ElementToolbar>
                )}
            </SNodeWrapper>
            <ElementCustomName
                rotation={rotation || 0}
                id={currentNode?.id}
                customName={currentNode?.customName || ''}
                handleChange={handleChange}
                handleSave={handleSave}
                isEdit={isEdit}
            />
            {!readonly && view && selected && selectedElements.length === 1 && (
                <Moveable
                    ref={moveableRef}
                    persistData={{ rotation: invert(changedValues.rotation) }}
                    edge={true}
                    origin={true}
                    throttleRotate={90}
                    throttleResize={CANVAS.GRID_STEP}
                    target={elementRef}
                    rotatable={view.isRotatable || false}
                    resizable={view.isResizable || false}
                    draggable={true}
                    rotationPosition={'top'}
                    onRotate={({ target, drag, rotation }: OnRotate) => {
                        const newRotation = setAngle(rotation);
                        target.style.transform = `rotate(${newRotation}deg)`;
                        setChangedValues({
                            ...changedValues,
                            rotated: true,
                            rotation: newRotation,
                        });
                    }}
                    onRotateEnd={(event: OnRotateEnd) => {
                        const { lastEvent } = event;
                        if (lastEvent && lastEvent?.rotate !== undefined) {
                            const { rotate } = lastEvent;
                            dispatch(
                                workspaceActions.changeElementRotation({
                                    id: data.id,
                                    rotation: setAngle(rotate),
                                    workspaceMode: workspaceMode as TWorkspaceMode,
                                })
                            );
                        }
                    }}
                    onResize={({ target, width, height, delta }: OnResize) => {
                        const dataWidth = data.view.minWidth;
                        const dataHeight = data.view.minHeight;

                        if (dataWidth && width < dataWidth) {
                            return false;
                        }

                        if (dataHeight && height < dataHeight) {
                            return false;
                        }

                        if (width >= CANVAS.ELEMENT_MIN_WIDTH) {
                            delta[0] && (target!.style.width = `${width}px`);
                        }
                        if (height >= CANVAS.ELEMENT_MIN_HEIGHT) {
                            delta[1] && (target!.style.height = `${height}px`);
                        }
                        setChangedValues({ ...changedValues, width, height });
                        return;
                    }}
                    onResizeEnd={(event: OnResizeEnd) => {
                        const { lastEvent } = event;
                        if (lastEvent?.width && lastEvent?.height) {
                            const { width, height } = lastEvent;
                            dispatch(
                                workspaceActions.changeElementSize({
                                    id: data.id,
                                    size: { width, height },
                                    workspaceMode: workspaceMode as TWorkspaceMode,
                                })
                            );
                        }
                    }}
                />
            )}
        </SElement>
    );
});
