import { FC, useCallback, useState } from 'react';

import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    AutocompleteRenderGroupParams,
    AutocompleteRenderInputParams,
    Chip,
    createFilterOptions,
    FormControlLabel,
    Switch,
    TextField,
} from '@mui/material';

import { unicodeReplaceSymbol, unicodeToHtml } from '@repeat/constants';
import { ElemParams, ILibraryItem, PortPositionTypes, PortTypes } from '@repeat/models';
import { InputField } from '@repeat/ui-kit';

import { SPortsVisualManagerWrapper, SUIForm } from './SEditBlockUI';

import { SAutocomplete } from '../../Visualization/Charts/AutoCompleteParametersForm/SAutocompleteParametersForm';
import { useFormContext } from '../EngineerFormContext/EngineerFormContext';
import { EngineerService } from '../EngineerService';
import { PortsVisualManager } from '../PortsVisualManager/PortVisualManager';
import { SBlockPortEdit, SDisplaySection, SGroupHeader, SGroupItems } from '../styled/SEditBlock';

interface IEditBlockUIProps {
    currentBlockProp: ILibraryItem;
    engineerService: EngineerService;
}

interface IAutocompleteListBoxProps extends React.HTMLAttributes<HTMLUListElement> {
    'data-test-id'?: string;
}

export const EditBlockUI: FC<IEditBlockUIProps> = (props) => {
    const { currentBlockProp } = props;

    const { formState, updateFormState } = useFormContext();

    const portTypes: PortTypes[] = [PortTypes.INPUT, PortTypes.OUTPUT];
    const [block] = useState(currentBlockProp);

    const portPositions = [
        {
            position: PortPositionTypes.LEFT,
            title: 'Слева',
        },
        {
            position: PortPositionTypes.RIGHT,
            title: 'Справа',
        },
        {
            position: PortPositionTypes.TOP,
            title: 'Сверху',
        },
        {
            position: PortPositionTypes.BOTTOM,
            title: 'Снизу',
        },
    ];
    const [paramsToDisplay, setParamsToDisplay] = useState<string[]>();
    const [propsToDisplay, setPropsToDisplay] = useState<string[]>();

    const getOptionLabel = useCallback(
        (option: string) => unicodeReplaceSymbol(option) || '',
        [propsToDisplay, paramsToDisplay]
    );
    const optionEqualToValue = (option: string, value: string) => {
        if (option && value) {
            return option === value;
        }
        return false;
    };

    const renderInput = (label: string) => (params: AutocompleteRenderInputParams) =>
        <TextField {...params} label={label} />;
    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option: string) => `${option} ${unicodeToHtml(option)}`,
    });

    const renderDataTestId = (value: string) =>
        ({
            'data-test-id': `${value}`,
        } as IAutocompleteListBoxProps);

    const renderGroup = (params: AutocompleteRenderGroupParams) => (
        <li key={`${params.group}`}>
            <SGroupHeader>{params.group}</SGroupHeader>
            <SGroupItems>{params.children}</SGroupItems>
        </li>
    );

    const renderTag = (tagValue: string[], getTagProps: AutocompleteRenderGetTagProps) => {
        return tagValue.map((option: string, index: number) => {
            const html = (
                <span
                    style={{ color: 'var(--ui-text)' }}
                    dangerouslySetInnerHTML={{
                        __html: `${option}`,
                    }}
                />
            );
            return <Chip {...getTagProps({ index })} label={html} />;
        });
    };

    const sortPortName = (a: ElemParams, b: ElemParams) => {
        const numA = parseInt(a.name.match(/_\d+$/)![0].slice(1));
        const numB = parseInt(b.name.match(/_\d+$/)![0].slice(1));
        if (numA < numB) {
            return -1;
        }
        return 1;
    };

    return (
        <SUIForm>
            <div>
                <SPortsVisualManagerWrapper>
                    {formState?.elemParams?.length && formState.elemParams.length > 0 && (
                        <PortsVisualManager
                            view={formState.view}
                            elemParams={formState.elemParams}
                            availablePorts={formState.availablePorts}
                            onChange={(availablePorts, view) => {
                                updateFormState('availablePorts', availablePorts);
                                updateFormState('view', view);
                            }}
                        />
                    )}
                </SPortsVisualManagerWrapper>
            </div>
            <div>
                {formState?.elemParams?.map((elemParam, index) => (
                    <SBlockPortEdit>
                        <InputField
                            key={elemParam.name}
                            label={elemParam.name}
                            type='text'
                            value={elemParam.description ?? ''}
                            onChange={(e) => {
                                const currentParams = formState.elemParams;
                                const updatedParams = currentParams?.map((item, i) =>
                                    i === index ? { ...item, description: e.target.value } : item
                                );
                                updateFormState('elemParams', updatedParams);
                            }}
                        />
                    </SBlockPortEdit>
                ))}
                <h5>Отображаемые свойства</h5>
                <FormControlLabel
                    control={
                        <Switch
                            id='isRotatable'
                            checked={formState?.view?.isRotatable ?? false}
                            onChange={(e) => {
                                const currentBlockView = {
                                    ...formState?.view,
                                    isRotatable: e.target.checked,
                                };
                                updateFormState('view', currentBlockView);
                            }}
                        />
                    }
                    label='Вращаемость'
                />
                <FormControlLabel
                    control={
                        <Switch
                            id='isImageRotatable'
                            checked={formState?.view?.isImageRotatable ?? false}
                            onChange={(e) => {
                                const currentBlockView = {
                                    ...formState?.view,
                                    isImageRotatable: e.target.checked,
                                };
                                updateFormState('view', currentBlockView);
                            }}
                        />
                    }
                    label='Вращаемость иконки'
                />

                <FormControlLabel
                    control={
                        <Switch
                            id='isResizable'
                            checked={formState?.view?.isResizable ?? false}
                            onChange={(e) => {
                                const currentBlockView = {
                                    ...formState?.view,
                                    isResizable: e.target.checked,
                                };
                                updateFormState('view', currentBlockView);
                            }}
                        />
                    }
                    label='Изменяемый размер'
                />
                {block.elemParams && (
                    <SDisplaySection>
                        <h5>Отображаемые параметры</h5>
                        <SAutocomplete>
                            <Autocomplete
                                multiple
                                disableClearable
                                size={'small'}
                                id='demo-select-small'
                                options={block.elemParams.map((param) => param.name)}
                                noOptionsText={'Нет таких параметров'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>, option: string[]) => {
                                    updateFormState('parametersToDisplay', option);
                                }}
                                isOptionEqualToValue={optionEqualToValue}
                                getOptionLabel={getOptionLabel}
                                renderInput={renderInput('')}
                                renderOption={(props, option: string, state) => {
                                    return (
                                        <li {...props} key={option}>
                                            {option}
                                        </li>
                                    );
                                }}
                                filterOptions={filterOptions}
                                value={formState?.parametersToDisplay ?? []}
                                renderTags={renderTag}
                            />
                        </SAutocomplete>
                    </SDisplaySection>
                )}

                {block.elemProps && (
                    <SDisplaySection>
                        <h5>Отображаемые Свойства</h5>
                        <SAutocomplete>
                            <Autocomplete
                                multiple
                                disableClearable
                                size={'small'}
                                id='select-properties-display'
                                options={block.elemProps.map((prop) => prop.description)}
                                noOptionsText={'Нет таких свойств'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>, option: string[]) => {
                                    updateFormState('propertiesToDisplay', option);
                                }}
                                isOptionEqualToValue={optionEqualToValue}
                                value={formState?.propertiesToDisplay ?? []}
                                getOptionLabel={getOptionLabel}
                                renderInput={renderInput('')}
                                renderOption={(props, option: string) => {
                                    return (
                                        <li {...props} key={option}>
                                            {option}
                                        </li>
                                    );
                                }}
                                renderGroup={renderGroup}
                                filterOptions={filterOptions}
                                renderTags={renderTag}
                                ListboxProps={renderDataTestId('propertiesToDisplay-box')}
                            />
                        </SAutocomplete>
                    </SDisplaySection>
                )}
            </div>
        </SUIForm>
    );
};
