import React, { FC, memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { v4 as uuidv4 } from 'uuid';

import { chartTitle, MAX_CHART_HEIGHT, MAX_CHARTS_GRID_COUNT } from '@repeat/constants';
import { useAppDispatch, useAppSelector } from '@repeat/hooks';
import { EChartItemType, IChartItem, IChartItemType, ModelStatuses } from '@repeat/models';
import { openInNewWindowOptions } from '@repeat/services';
import { downloadChartData, workspaceActions } from '@repeat/store';
import { TranslationKey } from '@repeat/translations';
import {
    GridView,
    GridViewAppendItem,
    GridViewItem,
    Icon,
    IconButton,
    Tooltip,
    TooltipPosition,
    uiColors,
} from '@repeat/ui-kit';

import { ChartsItem } from './ChartsItem';
import { SChartDownloadLink, SChartGridTitle } from './SCharts';

import { useWorkspaceDataContext } from '../../Workspace/DataProvider/DataProvider';
import { messages } from '../translation';

interface IGridItemTitle {
    chart: IChartItem;
    children: ReactNode;
    isShowDownload: boolean;
    isShowEdit: boolean;
    index: number;
    onDownload: (event: React.MouseEvent, index: number) => void;
    onOpen: () => void;
    onEdit: (index: number) => void;
    onOpenEditor: () => void;
}

const GridTitle: FC<IGridItemTitle> = memo((props) => {
    const intl = useIntl();

    return (
        <SChartGridTitle>
            <span>{props.children}</span>
            {props.isShowDownload && (
                <Tooltip
                    delayShow={300}
                    position={TooltipPosition.TOP}
                    text={intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_DOWNLOAD_CSV])}
                >
                    <SChartDownloadLink
                        rel='noreferrer noopener'
                        target={'_blank'}
                        href=''
                        onClick={(event) => props.onDownload(event, props.index)}
                        download={
                            props.chart?.type === EChartItemType.YFromT
                                ? `chart_${props.index}_${props.chart?.type}_${props.chart?.YParameters?.length}_parameters`
                                : `chart_${props.index}_${props.chart?.type}_${
                                      props.chart?.YParameters?.length + 1
                                  }_parameters`
                        }
                    >
                        <Icon fill={uiColors.darkGrey} name={'download'} />
                    </SChartDownloadLink>
                </Tooltip>
            )}
            {props.isShowEdit && (
                <Tooltip position={TooltipPosition.TOP} text={intl.formatMessage(messages[TranslationKey.EDITING])}>
                    <IconButton
                        noHover
                        name={props.chart.isEdit === false ? 'edit' : 'done'}
                        fill={uiColors.darkGrey}
                        style={{
                            marginRight: '0px',
                            justifySelf: 'end',
                        }}
                        onClick={() => props.onEdit(props.index)}
                    />
                </Tooltip>
            )}
            <Tooltip
                position={TooltipPosition.TOP}
                text={intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_EDITOR_OPEN])}
            >
                <IconButton
                    style={{ marginRight: '0px', justifySelf: 'end' }}
                    noHover
                    name={'analytics'}
                    fill={uiColors.darkGrey}
                    onClick={props.onOpenEditor}
                />
            </Tooltip>
            <Tooltip position={TooltipPosition.TOP} text={intl.formatMessage(messages[TranslationKey.NEW_WINDOW_OPEN])}>
                <IconButton
                    style={{ marginRight: '0px', justifySelf: 'end' }}
                    noHover
                    name={'newWindow'}
                    fill={uiColors.darkGrey}
                    onClick={props.onOpen}
                />
            </Tooltip>
        </SChartGridTitle>
    );
});

export const Charts: FC = () => {
    const subtractionHeight = 86;

    const [isNeedUpdate, setIsNeedUpdate] = useState(false);

    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { readonly } = useWorkspaceDataContext();

    const { charts, data, dataMap } = useAppSelector((state) => state.workspace.graphs);
    const { modelStatus } = useAppSelector((state) => state.workspace.modelControl);

    useEffect(() => {
        setIsNeedUpdate((state) => !state);
    }, [Object.keys(charts).length, charts]);

    const onAppendItem = () => {
        setIsNeedUpdate((v) => !v);
        if (Object.keys(charts).length < MAX_CHARTS_GRID_COUNT) {
            const uuid = uuidv4();
            dispatch(workspaceActions.addEmptyChart({ uuid }));
        }
    };

    const onRemoveItem = (index: number) => {
        if (!readonly) {
            setIsNeedUpdate((v) => !v);
            if (Object.keys(charts).includes(index.toString())) {
                dispatch(workspaceActions.removeChart({ index }));
            }
        }
    };

    const onResizeChart = (index: number, arg: any) => {
        setIsNeedUpdate((v) => !v);
        dispatch(workspaceActions.setSizeCharts({ index, ...arg }));
    };

    const onDownloadCsv = (event: React.MouseEvent, index: number) => {
        const url = dispatch(downloadChartData(index));
        const target = event.currentTarget as HTMLAnchorElement;
        if (url && target) {
            target.setAttribute('href', url);
            return;
        }
    };

    const onOpenInNewHandler = (index: number) => {
        const openFeatures = openInNewWindowOptions();
        return new Promise((resolve) => {
            const isOpen = window.open(`/trend/${index}`, '_blank', openFeatures);
            isOpen &&
                isOpen.addEventListener('DOMContentLoaded', () => {
                    resolve(isOpen);
                    setIsNeedUpdate((v) => !v);
                });
        }).then((res) => {
            setTimeout(() => setIsNeedUpdate((v) => !v), 2000);
        });
    };

    const onSetEditHandler = (index: number) => {
        if (!readonly) {
            dispatch(workspaceActions.setEditChart({ index }));
        }
    };

    const onOpenChartsEditor = useCallback((uuid: string) => {
        if (!readonly) {
            dispatch(workspaceActions.openChartsEditor({ uuid }));
        }
    }, []);

    const chartTypeName = (type: IChartItemType | null) => {
        if (type === EChartItemType.YFromX) {
            return intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_TYPE_Y_X]);
        }
        return intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_TYPE_Y_T]);
    };

    const ChartItems = Object.keys(charts).map((index) => {
        const chartIndex = parseInt(index);
        const chart = charts[chartIndex];
        const { type, XParameters, YParameters, uuid } = charts[chartIndex] as IChartItem;
        const chartDataMapIndex = dataMap[uuid]?.index >= 0 ? dataMap[uuid]?.index : -1;
        const isShowEdit = (data ? Boolean(data[chartDataMapIndex]?.length) : false) || chart.isEdit === true;
        const defaultTitle = `${intl.formatMessage(messages[TranslationKey.CHART])} ${chartTypeName(
            type
        )}. ${intl.formatMessage(messages[TranslationKey.PARAMETERS])}: ${chart.YParameters.length}`;

        const Title = (
            <GridTitle
                chart={charts[chartIndex]}
                index={chartIndex}
                isShowDownload={Boolean(
                    data && data[chartIndex] && [ModelStatuses.STOP, ModelStatuses.DATA].includes(modelStatus)
                )}
                isShowEdit={isShowEdit}
                onOpen={() => onOpenInNewHandler(chartIndex)}
                onDownload={onDownloadCsv}
                onEdit={onSetEditHandler}
                onOpenEditor={() => onOpenChartsEditor(chart.uuid)}
            >
                <strong>
                    <span>{chartIndex + 1}.&nbsp;</span>
                    {YParameters !== null && YParameters?.length >= 1 ? (
                        <>{chartTitle(chart?.layout?.title, defaultTitle)}</>
                    ) : (
                        intl.formatMessage(messages[TranslationKey.WORKSPACE_CHART_SETUP_PARAMETER])
                    )}
                </strong>
            </GridTitle>
        );
        const size = {
            width: charts[chartIndex].width,
            height: charts[chartIndex].height,
        };
        return (
            <GridViewItem
                title={Title}
                options={{ subtractionHeight, tabHeight: 40, defaultItemHeight: MAX_CHART_HEIGHT }}
                id={chartIndex}
                key={`grid-view-item-${charts[chartIndex].uuid}`}
                onClose={() => onRemoveItem(chartIndex)}
                onResize={(args: any) => onResizeChart(chartIndex, args)}
                {...(charts[chartIndex] && size)}
            >
                <ChartsItem
                    isNeedUpdate={isNeedUpdate}
                    index={chartIndex}
                    XParameters={XParameters}
                    YParameters={YParameters}
                />
            </GridViewItem>
        );
    });

    return (
        <GridView>
            {ChartItems}

            {!readonly && (
                <GridViewAppendItem
                    appendText={`${intl.formatMessage(messages[TranslationKey.ADD])}`}
                    itemCount={Object.keys(charts).length}
                    onAppendItem={onAppendItem}
                    maxItemsCount={MAX_CHARTS_GRID_COUNT}
                />
            )}
        </GridView>
    );
};
