import React, { FC } from 'react';
import { NodeToolbar, Position } from 'reactflow';
import { SElementToolbar } from './SElementToolbar';

export interface IElementToolbarProps {
    selected: boolean;
    children: React.ReactNode;
}

export const ElementToolbar: FC<IElementToolbarProps> = ({ selected, children }) => {
    return (
        <NodeToolbar isVisible={selected} align={'start'} offset={8} position={Position.Right}>
            <SElementToolbar>{children}</SElementToolbar>
        </NodeToolbar>
    );
};
