import { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import Button from '@mui/material/Button';

import { useAppDispatch } from '@repeat/hooks';
import { workspaceActions } from '@repeat/store';
import { ru, TranslationKey } from '@repeat/translations';
import { Icon, ShowMore, uiColors } from '@repeat/ui-kit';

import { useWorkspaceDataContext } from '../Workspace/DataProvider/DataProvider';

const messages = defineMessages({
    [TranslationKey.DELETE]: {
        id: TranslationKey.DELETE,
        defaultMessage: ru[TranslationKey.DELETE],
    },
});

export const ConnectionCurrent: FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useAppDispatch();
    const { readonly } = useWorkspaceDataContext();

    const deleteConnection = () => {
        dispatch(workspaceActions.deleteSchemaItems());
    };

    return (
        <ShowMore title={''}>
            {!readonly && (
                <Button
                    onClick={() => {
                        deleteConnection();
                    }}
                    type='button'
                >
                    <Icon name='close' fill={uiColors.mainBlue} />
                    {formatMessage(messages[TranslationKey.DELETE])}
                </Button>
            )}
        </ShowMore>
    );
};
